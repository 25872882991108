//5、2矩形缺
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyRectAngleGap(area, param) {
   console.log("是否调用此函数creatOneCorner（）");
   console.log("是否调用此函数creatOneCorner（）param=", param);
   var xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, 
       xd = param.xd, yd = param.yd, xe = param.xe, ye = param.ye, xf = param.xf, yf = param.yf,
      xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
      x0 = param.x0, y0 = param.y0, w = param.w, h = param.h, r = param.r, rd = param.rd;
   var myRagGapRoundRectangle = new makerjs.models.RoundRectangle(w, h, r);
   if (area == "A") {
      myRagGapRoundRectangle.origin = [xa, ya - h];
   } else if (area == "B") {
      myRagGapRoundRectangle.origin = [xb, yb - h];
   } else if (area == "C") {
      myRagGapRoundRectangle.origin = [xc - w, yc - h];
   } else if (area == "D") {
      myRagGapRoundRectangle.origin=[xd,yd-h/2]; 
   } else if (area == "E") {
      myRagGapRoundRectangle.origin=[xe-w/2,ye-h/2];
   } else if (area == "F") {
      myRagGapRoundRectangle.origin=[xf-w,yf-h/2];
   } else if (area == "G") {
      myRagGapRoundRectangle.origin=[xg,yg];
   } else if (area == "H") {
      myRagGapRoundRectangle.origin=[xh,yh]; 
   } else if (area == "I") {
      myRagGapRoundRectangle.origin=[xi-w,yi];
   }
   return myRagGapRoundRectangle;
};
// 矩形等去角标记 连线函数      
function creatmyRectAngleGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
   console.log("是否调用此函数creatCornerMark()");
   console.log("是否调用此函数param", param);
   console.log("是否调用此函数outParam", outParam);
   console.log("是否调用此函数inputParam", inputParam);
   console.log("是否调用此函数OffsetParam", OffsetParam);
   var H = inputParam.H, R = inputParam.R, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, myZoom = zoom * 1,
      xa = param.xa, ya = param.ya, xb = param.xb,yb = param.yb, xc = param.xc,yc = param.yc, 
      xd = param.xd, yd = param.yd, xe = param.xe, ye = param.ye, xf = param.xf, yf = param.yf,
      xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
      x0 = param.x0, y0 = param.y0, w = param.w, h = param.h, r = param.r, rd = param.rd,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      r = R * 1 * myZoom,
      rd = r / Math.sqrt(2),
      hd=r-rd, 
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;
   if (r !== 0) {
      var TxtR = "R=" + R;
   }
   if (h !== 0) {
      var TxtH = H;
   }
   if (w !== 0) {
      var TxtW = W;
   }
   if (x0 !== 0) {
      var TxtX = X;
   }
   if (y0 !== 0) {
      var TxtY = Y;
   }
   if (area == "A") {
      var myRagGapAMakerX = {
         paths: {
            "XALine0": new makerjs.paths.Line([xa - x0, ya+y0 + markShiftTwo], [xa, ya+y0 + markShiftTwo]),
            "XALine1": new makerjs.paths.Line([xa - x0, ya+y0], [xa - x0, ya+y0 + markShiftFour]),
            "XALine2": new makerjs.paths.Line([xa, ya+y0], [xa, ya+y0 + markShiftFour]),
         }
      };
      var myRagGapAMakerY = {
         paths: {
            // "YALine0": new makerjs.paths.Line([xa+ w, ya], [xa+ w+markShiftFour, ya]),
            // "YALine1": new makerjs.paths.Line([xa+ w, ya-h], [xa+ w+markShiftFour, ya-h]),
            "YALine0": new makerjs.paths.Line([xa+ w+markShiftTwo, ya], [xa+ w+markShiftTwo, ya+y0]),
         }
      };
      var myRagGapAMakerW = {
         paths: {
            "WALine0": new makerjs.paths.Line([xa, ya], [xa, ya+markShiftFour]),
            "WALine1": new makerjs.paths.Line([xa+w, ya], [xa+w, ya+markShiftFour]),
            "WALine2": new makerjs.paths.Line([xa, ya+markShiftTwo], [xa+w, ya+markShiftTwo]),
         }
      };
      var myRagGapAMakerH = {
         paths: {
            "YALine0": new makerjs.paths.Line([xa+ w, ya], [xa+ w+markShiftFour, ya]),
            "YALine1": new makerjs.paths.Line([xa+ w, ya-h], [xa+ w+markShiftFour, ya-h]),
            "YALine2": new makerjs.paths.Line([xa+ w+markShiftTwo, ya-h], [xa+ w+markShiftTwo, ya]),
         }
      };
      var myRagGapAMakerR = {
         paths: {
            "RALine0": new makerjs.paths.Line([xa+hd,ya-hd], [xa+r,ya-r]),
           
         }
      };
      makerjs.model.addCaption(myRagGapAMakerX, TxtX, [xa - x0, ya+y0 + markShiftFour], [xa, ya+y0 + markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapAMakerY, TxtY, [xa+ w+markShiftFour, ya], [xa+ w+markShiftFour, ya+y0]);//Y文字标注 
      makerjs.model.addCaption(myRagGapAMakerH, TxtH, [xa+ w+markShiftFour, ya-h], [xa+ w+markShiftFour, ya]);//H文字标注
      makerjs.model.addCaption(myRagGapAMakerW, TxtW, [xa, ya + markShiftFour], [xa + w, ya + markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapAMakerR, TxtR, [xa+r,ya-r], [xa+r+markShiftFour,ya-r]);//R文字标注  
      var markers = [myRagGapAMakerX, myRagGapAMakerY, myRagGapAMakerW, myRagGapAMakerH, myRagGapAMakerR]
   } else if (area == "B") {
      var myRagGapBMakerX = {
         paths: {
            // "XBLine0": new makerjs.paths.Line([xb - x0, yb+y0 + markShiftTwo], [xb, yb+y0 + markShiftTwo]),
            // "XBLine1": new makerjs.paths.Line([xb - x0, yb+y0], [xb - x0, yb+y0 + markShiftFour]),
            // "XBLine2": new makerjs.paths.Line([xb, yb+y0], [xb, yb+y0 + markShiftFour]),
         }
      };
      var myRagGapBMakerY = {
         paths: {
            // "YBLine0": new makerjs.paths.Line([xb+ w, yb], [xb+ w+markShiftFour, yb]),
            // "YBLine1": new makerjs.paths.Line([xb+ w, yb-h], [xb+ w+markShiftFour, yb-h]),
            "YBLine0": new makerjs.paths.Line([xb+ w+markShiftTwo, yb], [xb+ w+markShiftTwo, yb+y0]),
         }
      };
      var myRagGapBMakerW = {
         paths: {
            "WBLine0": new makerjs.paths.Line([xb, yb], [xb, yb+markShiftFour]),
            "WBLine1": new makerjs.paths.Line([xb+w, yb], [xb+w, yb+markShiftFour]),
            "WBLine2": new makerjs.paths.Line([xb, yb+markShiftTwo], [xb+w, yb+markShiftTwo]),
         }
      };
      var myRagGapBMakerH = {
         paths: {
            "YBLine0": new makerjs.paths.Line([xb+ w, yb], [xb+ w+markShiftFour, yb]),
            "YBLine1": new makerjs.paths.Line([xb+ w, yb-h], [xb+ w+markShiftFour, yb-h]),
            "YBLine2": new makerjs.paths.Line([xb+ w+markShiftTwo, yb-h], [xb+ w+markShiftTwo, yb]),
         }
      };
      var myRagGapBMakerR = {
         paths: {
            "RBLine0": new makerjs.paths.Line([xb+hd,yb-hd], [xb+r,yb-r]),
           
         }
      };
      makerjs.model.addCaption(myRagGapBMakerX, TxtX, [xb - x0, yb+y0 + markShiftFour], [xb, yb+y0 + markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapBMakerY, TxtY, [xb+ w+markShiftFour, yb], [xb+ w+markShiftFour, yb+y0]);//Y文字标注 
      makerjs.model.addCaption(myRagGapBMakerH, TxtH, [xb+ w+markShiftFour, yb-h], [xb+ w+markShiftFour, yb]);//H文字标注
      makerjs.model.addCaption(myRagGapBMakerW, TxtW, [xb, yb + markShiftFour], [xb + w, yb + markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapBMakerR, TxtR, [xb+r,yb-r], [xb+r+markShiftFour,yb-r]);//R文字标注  
      var markers = [myRagGapBMakerX, myRagGapBMakerY, myRagGapBMakerW, myRagGapBMakerH, myRagGapBMakerR]
   } else if (area == "C") {
      var myRagGapCMakerX = {
         paths: {
            "XCLine0": new makerjs.paths.Line([xc + x0, yc+y0 + markShiftTwo], [xc, yc+y0 + markShiftTwo]),
            "XCLine1": new makerjs.paths.Line([xc + x0, yc+y0], [xc + x0, yc+y0 + markShiftFour]),
            "XCLine2": new makerjs.paths.Line([xc, yc+y0], [xc, yc+y0 + markShiftFour]),
         }
      };
      var myRagGapCMakerY = {
         paths: {
            // "YCLine0": new makerjs.paths.Line([xc+ w, yc], [xc+ w+markShiftFour, yc]),
            // "YCLine1": new makerjs.paths.Line([xc+ w, yc-h], [xc+ w+markShiftFour, yc-h]),
            "YCLine0": new makerjs.paths.Line([xc- w-markShiftTwo, yc], [xc- w-markShiftTwo, yc+y0]),
         }
      };
      var myRagGapCMakerW = {
         paths: {
            "WCLine0": new makerjs.paths.Line([xc, yc], [xc, yc+markShiftFour]),
            "WCLine1": new makerjs.paths.Line([xc-w, yc], [xc-w, yc+markShiftFour]),
            "WCLine2": new makerjs.paths.Line( [xc-w, yc+markShiftTwo],[xc, yc+markShiftTwo]),
         }
      };
      var myRagGapCMakerH = {
         paths: {
            "YCLine0": new makerjs.paths.Line([xc- w, yc], [xc- w-markShiftFour, yc]),
            "YCLine1": new makerjs.paths.Line([xc- w, yc-h], [xc- w-markShiftFour, yc-h]),
            "YCLine2": new makerjs.paths.Line([xc- w-markShiftTwo, yc-h], [xc-w-markShiftTwo, yc]),
         }
      };
      var myRagGapCMakerR = {
         paths: {
            "RCLine0": new makerjs.paths.Line([xc-hd,yc-hd], [xc-r,yc-r]),
           
         }
      };
      makerjs.model.addCaption(myRagGapCMakerX, TxtX, [xc , yc+y0 + markShiftFour], [xc+ x0, yc+y0 + markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapCMakerY, TxtY, [xc- w-markShiftFour, yc], [xc- w-markShiftFour, yc+y0]);//Y文字标注 
      makerjs.model.addCaption(myRagGapCMakerH, TxtH, [xc-w-markShiftFour, yc-h], [xc-w-markShiftFour, yc]);//H文字标注
      makerjs.model.addCaption(myRagGapCMakerW, TxtW, [xc- w, yc + markShiftFour], [xc, yc + markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapCMakerR, TxtR, [xc-r-markShiftFour,yc-r], [xc-r,yc-r]);//R文字标注  
      var markers = [myRagGapCMakerX, myRagGapCMakerY, myRagGapCMakerW, myRagGapCMakerH, myRagGapCMakerR]
   }else if (area == "D") {
      var myRagGapDMakerX = {
         paths: {
            "XDLine0": new makerjs.paths.Line([xd - x0, yd+y0+h/2 + markShiftTwo], [xd, yd+y0+h/2 + markShiftTwo]),
            "XDLine1": new makerjs.paths.Line([xd - x0, yd+y0+h/2], [xd - x0, yd+y0+h/2 + markShiftFour]),
            "XDLine2": new makerjs.paths.Line([xd, yd+y0+h/2], [xd, yd+y0+h/2 + markShiftFour]),
         }
      };
      var myRagGapDMakerY = {
         paths: {
            "YDLine0": new makerjs.paths.Line([xd+ w+markShiftTwo, yd+h/2], [xd+ w+markShiftTwo, yd+h/2+y0]),
         }
      };
      var myRagGapDMakerW = {
         paths: {
            "WDLine0": new makerjs.paths.Line([xd, yd+h/2], [xd, yd+h/2+markShiftFour]),
            "WDLine1": new makerjs.paths.Line([xd+w, yd+h/2], [xd+w, yd+h/2+markShiftFour]),
            "WDLine2": new makerjs.paths.Line([xd, yd+h/2+markShiftTwo], [xd+w, yd+h/2+markShiftTwo]),
         }
      };
      var myRagGapDMakerH = {
         paths: {
            "YDLine0": new makerjs.paths.Line([xd+ w, yd+h/2], [xd+ w+markShiftFour, yd+h/2]),
            "YDLine1": new makerjs.paths.Line([xd+ w, yd-h+h/2], [xd+ w+markShiftFour, yd-h+h/2]),
            "YDLine2": new makerjs.paths.Line([xd+ w+markShiftTwo, yd-h+h/2], [xd+ w+markShiftTwo, yd+h/2]),
         }
      };
      var myRagGapDMakerR = {
         paths: {
            "RDLine0": new makerjs.paths.Line([xd+hd,yd-hd+h/2], [xd+r,yd-r+h/2]),
           
         }
      };
      makerjs.model.addCaption(myRagGapDMakerX, TxtX, [xd - x0, yd+y0+h/2 + markShiftFour], [xd, yd+y0+h/2 + markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapDMakerY, TxtY, [xd+ w+markShiftFour, yd+h/2], [xd+ w+markShiftFour, yd+y0+h/2]);//Y文字标注 
      makerjs.model.addCaption(myRagGapDMakerH, TxtH, [xd+ w+markShiftFour, yd-h+h/2], [xd+ w+markShiftFour, yd+h/2]);//H文字标注
      makerjs.model.addCaption(myRagGapDMakerW, TxtW, [xd, yd+h/2 + markShiftFour], [xd + w, yd+h/2 + markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapDMakerR, TxtR, [xd+r,yd-r+h/2], [xd+r+markShiftFour,yd-r+h/2]);//R文字标注  
      var markers = [myRagGapDMakerX, myRagGapDMakerY, myRagGapDMakerW, myRagGapDMakerH, myRagGapDMakerR];
   }else if (area == "E") {
      var myRagGapEMakerX = {
         paths: {
            // "XELine0": new makerjs.paths.Line([xe - x0, ye+y0+h/2 + markShiftTwo], [xe, ye+y0+h/2 + markShiftTwo]),
            // "XELine1": new makerjs.paths.Line([xe - x0, ye+y0+h/2], [xe - x0, ye+y0+h/2 + markShiftFour]),
            // "XELine2": new makerjs.paths.Line([xe, ye+y0+h/2], [xe, ye+y0+h/2 + markShiftFour]),
         }
      };
      var myRagGapEMakerY = {
         paths: {
            // "YELine0": new makerjs.paths.Line([xe+ w+markShiftTwo, ye+h/2], [xe+ w+markShiftTwo, ye+h/2+y0]),
         }
      };
      var myRagGapEMakerW = {
         paths: {
            "WELine0": new makerjs.paths.Line([xe-w/2, ye+h/2], [xe-w/2, ye+h/2+markShiftFour]),
            "WELine1": new makerjs.paths.Line([xe+w/2, ye+h/2], [xe+w/2, ye+h/2+markShiftFour]),
            "WELine2": new makerjs.paths.Line([xe-w/2, ye+h/2+markShiftTwo], [xe+w/2, ye+h/2+markShiftTwo]),
         }
      };
      var myRagGapEMakerH = {
         paths: {
            "YELine0": new makerjs.paths.Line([xe+ w/2, ye+h/2], [xe+ w/2+markShiftFour, ye+h/2]),
            "YELine1": new makerjs.paths.Line([xe+ w/2, ye-h+h/2], [xe+ w/2+markShiftFour, ye-h+h/2]),
            "YELine2": new makerjs.paths.Line([xe+ w/2+markShiftTwo, ye-h+h/2], [xe+ w/2+markShiftTwo, ye+h/2]),
         }
      };
      var myRagGapEMakerR = {
         paths: {
            "RELine0": new makerjs.paths.Line([xe+hd-w/2,ye-hd+h/2], [xe+r-w/2,ye-r+h/2]),
           
         }
      };
      makerjs.model.addCaption(myRagGapEMakerX, TxtX, [xe - x0, ye+y0+h/2 + markShiftFour], [xe, ye+y0+h/2 + markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapEMakerY, TxtY, [xe+ w+markShiftFour, ye+h/2], [xe+ w+markShiftFour, ye+y0+h/2]);//Y文字标注 
      makerjs.model.addCaption(myRagGapEMakerH, TxtH, [xe+ w/2+markShiftFour, ye-h+h/2], [xe+ w/2+markShiftFour, ye+h/2]);//H文字标注
      makerjs.model.addCaption(myRagGapEMakerW, TxtW, [xe-w/2, ye+h/2 + markShiftFour], [xe + w/2, ye+h/2 + markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapEMakerR, TxtR, [xe+r-w/2,ye-r+h/2], [xe-w/2+r+markShiftFour,ye-r+h/2]);//R文字标注  
      var markers = [myRagGapEMakerX, myRagGapEMakerY, myRagGapEMakerW, myRagGapEMakerH, myRagGapEMakerR];
   }else if (area == "F") {
      var myRagGapFMakerX = {
         paths: {
            "XFLine0": new makerjs.paths.Line([xf , yf+y0+h/2 + markShiftTwo], [xf+ x0, yf+y0+h/2 + markShiftTwo]),
            "XFLine1": new makerjs.paths.Line([xf + x0, yf+y0+h/2], [xf + x0, yf+y0+h/2 + markShiftFour]),
            "XFLine2": new makerjs.paths.Line([xf, yf+y0+h/2], [xf, yf+y0+h/2 + markShiftFour]),
         }
      };
      var myRagGapFMakerY = {
         paths: {
            "YFLine0": new makerjs.paths.Line([xf- w-markShiftTwo, yf+h/2], [xf-w-markShiftTwo, yf+h/2+y0]),
         }
      };
      var myRagGapFMakerW = {
         paths: {
            "WFLine0": new makerjs.paths.Line([xf, yf+h/2], [xf, yf+h/2+markShiftFour]),
            "WFLine1": new makerjs.paths.Line([xf-w, yf+h/2], [xf-w, yf+h/2+markShiftFour]),
            "WFLine2": new makerjs.paths.Line([xf, yf+h/2+markShiftTwo], [xf-w, yf+h/2+markShiftTwo]),
         }
      };
      var myRagGapFMakerH = {
         paths: {
            "YFLine0": new makerjs.paths.Line([xf-w, yf+h/2], [xf- w-markShiftFour, yf+h/2]),
            "YFLine1": new makerjs.paths.Line([xf- w, yf-h+h/2], [xf- w-markShiftFour, yf-h+h/2]),
            "YFLine2": new makerjs.paths.Line([xf- w-markShiftTwo, yf-h+h/2], [xf-w-markShiftTwo, yf+h/2]),
         }
      };
      var myRagGapFMakerR = {
         paths: {
            "RFLine0": new makerjs.paths.Line([xf-hd,yf-hd+h/2], [xf-r,yf-r+h/2]),
           
         }
      };
      makerjs.model.addCaption(myRagGapFMakerX, TxtX, [xf, yf+y0+h/2 + markShiftFour], [xf+ x0, yf+y0+h/2 + markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapFMakerY, TxtY, [xf- w-markShiftFour, yf+h/2], [xf-w-markShiftFour, yf+y0+h/2]);//Y文字标注 
      makerjs.model.addCaption(myRagGapFMakerH, TxtH, [xf- w-markShiftFour, yf-h+h/2], [xf- w-markShiftFour, yf+h/2]);//H文字标注
      makerjs.model.addCaption(myRagGapFMakerW, TxtW, [xf- w, yf+h/2 + markShiftFour], [xf, yf+h/2 + markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapFMakerR, TxtR, [xf-r-markShiftFour,yf-r+h/2], [xf-r,yf-r+h/2]);//R文字标注  
      var markers = [myRagGapFMakerX, myRagGapFMakerY, myRagGapFMakerW, myRagGapFMakerH, myRagGapFMakerR];
   } else if (area == "G") {
      var myRagGapGMakerX = {
         paths: {
            "XGLine0": new makerjs.paths.Line([xg - x0, yg-y0 - markShiftTwo], [xg, yg-y0- markShiftTwo]),
            "XGLine1": new makerjs.paths.Line([xg - x0, yg-y0], [xg - x0, yg-y0 - markShiftFour]),
            "XGLine2": new makerjs.paths.Line([xg, yg-y0], [xg, yg-y0 - markShiftFour]),
         }
      };
      var myRagGapGMakerY = {
         paths: {
            "YGLine0": new makerjs.paths.Line([xg+ w+markShiftTwo, yg], [xg+ w+markShiftTwo, yg-y0]),
         }
      };
      var myRagGapGMakerW = {
         paths: {
            "WGLine0": new makerjs.paths.Line([xg, yg], [xg, yg-markShiftFour]),
            "WGLine1": new makerjs.paths.Line([xg+w, yg], [xg+w, yg-markShiftFour]),
            "WGLine2": new makerjs.paths.Line([xg, yg-markShiftTwo], [xg+w, yg-markShiftTwo]),
         }
      };
      var myRagGapGMakerH = {
         paths: {
            "YGLine0": new makerjs.paths.Line([xg+ w, yg], [xg+ w+markShiftFour, yg]),
            "YGLine1": new makerjs.paths.Line([xg+ w, yg+h], [xg+ w+markShiftFour, yg+h]),
            "YGLine2": new makerjs.paths.Line([xg+ w+markShiftTwo, yg+h], [xg+ w+markShiftTwo, yg]),
         }
      };
      var myRagGapGMakerR = {
         paths: {
            "RGLine0": new makerjs.paths.Line([xg+hd,yg+hd], [xg+r,yg+r]),
           
         }
      };
      makerjs.model.addCaption(myRagGapGMakerX, TxtX, [xg - x0, yg-y0 - markShiftFour], [xg, yg-y0 - markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapGMakerY, TxtY, [xg+ w+markShiftFour, yg-y0], [xg+ w+markShiftFour, yg]);//Y文字标注 
      makerjs.model.addCaption(myRagGapGMakerH, TxtH, [xg+ w+markShiftFour, yg], [xg+ w+markShiftFour, yg+h]);//H文字标注
      makerjs.model.addCaption(myRagGapGMakerW, TxtW, [xg, yg - markShiftFour], [xg + w, yg - markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapGMakerR, TxtR, [xg+r,yg+r], [xg+r+markShiftFour,yg+r]);//R文字标注  
      var markers = [myRagGapGMakerX, myRagGapGMakerY, myRagGapGMakerW, myRagGapGMakerH, myRagGapGMakerR]
   } else if (area == "H") {
      var myRagGapHMakerX = {
         paths: {
            // "XHLine0": new makerjs.paths.Line([xh - x0, yh-y0 - markShiftTwo], [xh, yh-y0- markShiftTwo]),
            // "XHLine1": new makerjs.paths.Line([xh - x0, yh-y0], [xh - x0, yh-y0 - markShiftFour]),
            // "XHLine2": new makerjs.paths.Line([xh, yh-y0], [xh, yh-y0 - markShiftFour]),
         }
      };
      var myRagGapHMakerY = {
         paths: {
            "YHLine0": new makerjs.paths.Line([xh+ w+markShiftTwo, yh], [xh+ w+markShiftTwo, yh-y0]),
         }
      };
      var myRagGapHMakerW = {
         paths: {
            "WHLine0": new makerjs.paths.Line([xh, yh], [xh, yh-markShiftFour]),
            "WHLine1": new makerjs.paths.Line([xh+w, yh], [xh+w, yh-markShiftFour]),
            "WHLine2": new makerjs.paths.Line([xh, yh-markShiftTwo], [xh+w, yh-markShiftTwo]),
         }
      };
      var myRagGapHMakerH = {
         paths: {
            "YHLine0": new makerjs.paths.Line([xh+ w, yh], [xh+ w+markShiftFour, yh]),
            "YHLine1": new makerjs.paths.Line([xh+ w, yh+h], [xh+ w+markShiftFour, yh+h]),
            "YHLine2": new makerjs.paths.Line([xh+ w+markShiftTwo, yh+h], [xh+ w+markShiftTwo, yh]),
         }
      };
      var myRagGapHMakerR = {
         paths: {
            "RHLine0": new makerjs.paths.Line([xh+hd,yh+hd], [xh+r,yh+r]),
           
         }
      };
      makerjs.model.addCaption(myRagGapHMakerX, TxtX, [xh - x0, yh-y0 - markShiftFour], [xh, yh-y0 - markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapHMakerY, TxtY, [xh+ w+markShiftFour, yh-y0], [xh+ w+markShiftFour, yh]);//Y文字标注 
      makerjs.model.addCaption(myRagGapHMakerH, TxtH, [xh+ w+markShiftFour, yh], [xh+ w+markShiftFour, yh+h]);//H文字标注
      makerjs.model.addCaption(myRagGapHMakerW, TxtW, [xh, yh - markShiftFour], [xh + w, yh - markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapHMakerR, TxtR, [xh+r,yh+r], [xh+r+markShiftFour,yh+r]);//R文字标注  
      var markers = [myRagGapHMakerX, myRagGapHMakerY, myRagGapHMakerW, myRagGapHMakerH, myRagGapHMakerR]
   } else if (area == "I") {
      var myRagGapIMakerX = {
         paths: {
            "XILine0": new makerjs.paths.Line([xi + x0, yi-y0- markShiftTwo], [xi, yi-y0- markShiftTwo]),
            "XILine1": new makerjs.paths.Line([xi + x0, yi-y0], [xi + x0, yi-y0 - markShiftFour]),
            "XILine2": new makerjs.paths.Line([xi, yi-y0], [xi, yi-y0-markShiftFour]),
         }
      };
      var myRagGapIMakerY = {
         paths: {
            // "YILine0": new makerjs.paths.Line([xi+ w, yi], [xi+ w+markShiftFour, yi]),
            // "YILine1": new makerjs.paths.Line([xi+ w, yi-h], [xi+ w+markShiftFour, yi-h]),
            "YILine0": new makerjs.paths.Line([xi- w-markShiftTwo, yi], [xi- w-markShiftTwo, yi-y0]),
         }
      };
      var myRagGapIMakerW = {
         paths: {
            "WILine0": new makerjs.paths.Line([xi, yi], [xi, yi-markShiftFour]),
            "WILine1": new makerjs.paths.Line([xi-w, yi], [xi-w, yi-markShiftFour]),
            "WILine2": new makerjs.paths.Line( [xi-w, yi-markShiftTwo],[xi, yi-markShiftTwo]),
         }
      };
      var myRagGapIMakerH = {
         paths: {
            "YILine0": new makerjs.paths.Line([xi- w, yi], [xi- w-markShiftFour, yi]),
            "YILine1": new makerjs.paths.Line([xi- w, yi+h], [xi- w-markShiftFour, yi+h]),
            "YILine2": new makerjs.paths.Line([xi- w-markShiftTwo, yi+h], [xi-w-markShiftTwo, yi]),
         }
      };
      var myRagGapIMakerR = {
         paths: {
            "RILine0": new makerjs.paths.Line([xi-hd,yi+hd], [xi-r,yi+r]),
           
         }
      };
      makerjs.model.addCaption(myRagGapIMakerX, TxtX, [xi , yi-y0-markShiftFour], [xi+ x0, yi-y0-markShiftFour]);//X文字标注 
      makerjs.model.addCaption(myRagGapIMakerY, TxtY, [xi- w-markShiftFour, yi-y0], [xi- w-markShiftFour, yi]);//Y文字标注 
      makerjs.model.addCaption(myRagGapIMakerH, TxtH, [xi-w-markShiftFour, yi], [xi-w-markShiftFour, yi+h]);//H文字标注
      makerjs.model.addCaption(myRagGapIMakerW, TxtW, [xi- w, yi- markShiftFour], [xi, yi- markShiftFour]);//W文字标注   
      makerjs.model.addCaption(myRagGapIMakerR, TxtR, [xi-r-markShiftFour,yi+r], [xi-r,yi+r]);//R文字标注  
      var markers = [myRagGapIMakerX, myRagGapIMakerY, myRagGapIMakerW, myRagGapIMakerH, myRagGapIMakerR] 
   }
   console.log("markers||||||||||||||||||=", markers)
   return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RectAngleGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
   var frame = outParam.shapeName;
   console.log("outParam==", outParam)
   console.log("model==", model)
   console.log("inputParam==", inputParam)
   console.log("outParam.shapeName==////////", outParam.shapeName)
   if (typeof (frame) == "undefined") {
      var H = inputParam.H, R = inputParam.R, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
         plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
         glassW = outParam.glassW, glassH = outParam.glassH,
         markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
         markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
         w = W * 1 * myZoom,
         h = H * 1 * myZoom,
         r = R * 1 * myZoom,
         rd = r / Math.sqrt(2),
         x0 = X * 1 * myZoom,
         y0 = Y * 1 * myZoom;
      if (r !== 0) {
         var TxtR = "R=" + R;
      }
      if (h !== 0) {
         var TxtH = H;
      }
      if (w !== 0) {
         var TxtW = W;
      }
      if (x0 !== 0) {
         var TxtX = X;
      }
      if (y0 !== 0) {
         var TxtY = Y;
      }

   } else {
      var H = inputParam.H, R = inputParam.R, W = inputParam.W, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
         plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
         glassW = outParam.glassW, glassH = outParam.glassH,
         markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
         markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
         w = W * 1 * myZoom,
         h = H * 1 * myZoom,
         r = R * 1 * myZoom,
         rd = r / Math.sqrt(2),
         x0 = X * 1 * myZoom,
         y0 = Y * 1 * myZoom;
      if (r !== 0) {
         var TxtR = "R=" + R;
      }
      if (h !== 0) {
         var TxtH = H;
      }
      if (w !== 0) {
         var TxtW = W;
      }
      if (x0 !== 0) {
         var TxtX = X;
      }
      if (y0 !== 0) {
         var TxtY = Y;
      }

   }
   if (frame == "Rectangle") {
      console.log("frame==undefined||||*****")
     //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2-w/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2-w/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
      var OffsetParam = {};
   } else if (frame == "IsoscelesTrapezoid") {

   } else if (frame == "RightAngledTrapezoid") {


   } else if (frame == "OtherTrapezoidsA") {

   } else if (frame == "OtherTrapezoidsB") {

   } else if (frame == "ParallelogramA") {


   } else if (frame == "ParallelogramB") {

   }
   // 坐标参数
   var paramCoordinate = { x0, y0, w, h, r, rd, xa, ya, xb, yb, xc, yc,xd, yd,xe, ye,xf, yf, xg, yg, xh, yh, xi, yi };
   const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
   var areaPoint = "";
   console.log("//角7、地弹簧3 ------", allKeys)
   if (allKeys[0] == frame) {
      allKeys.shift();//去掉数组第一个元素
   }
   console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
   switch (area) {
      case "1": areaPoint = "A"; break;
      case "2": areaPoint = "B"; break;
      case "3": areaPoint = "C"; break;
      case "4": areaPoint = "D"; break;
      case "5": areaPoint = "E"; break;
      case "6": areaPoint = "F"; break;
      case "7": areaPoint = "G"; break;
      case "8": areaPoint = "H"; break;
      case "9": areaPoint = "I"; break;
   }
   /**
   * 画图
   * 
   */
   var ObjName = "myRectAngleGap" + areaPoint;
   var ObjNameMmyRectAngleGap = "myRectAngleGap" + areaPoint + "Mark";//角标记
   ObjName = creatmyRectAngleGap(areaPoint, paramCoordinate);//图形
   console.log("ObjName===|||||||||", ObjName);
   ObjNameMmyRectAngleGap = creatmyRectAngleGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
   console.log("ObjNameMmyRectAngleGap===|||||||||角标注", ObjNameMmyRectAngleGap);
   console.log("cutPlate.models====************", cutPlate.models),
      console.log("plate.models====**************", plate.models),
      console.log("markers.models====*************", markers.models),
      // myRagGapAMakerX, myRagGapAMakerY, myRagGapAMakerW, myRagGapAMakerH, myRagGapAMakerR
      cutPlate.models["myRectAngleGap" + areaPoint + myHolesNum] = ObjName;
   plate.models["myRectAngleGap" + areaPoint + myHolesNum] = ObjName;
   plate.models["myRectAngleGapX" + areaPoint + myHolesNum] = ObjNameMmyRectAngleGap[0];
   plate.models["myRectAngleGapY" + areaPoint + myHolesNum] = ObjNameMmyRectAngleGap[1];
   plate.models["myRectAngleGapW" + areaPoint + myHolesNum] = ObjNameMmyRectAngleGap[2];
   plate.models["myRectAngleGapH" + areaPoint + myHolesNum] = ObjNameMmyRectAngleGap[3];
   plate.models["myRectAngleGapR" + areaPoint + myHolesNum] = ObjNameMmyRectAngleGap[4];
   markers.models["myRectAngleGap" + areaPoint + myHolesNum] = ObjName;
   markers.models["myRectAngleGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRectAngleGap[0];
   markers.models["myRectAngleGapY" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRectAngleGap[1];
   markers.models["myRectAngleGapW" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRectAngleGap[2];
   markers.models["myRectAngleGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRectAngleGap[3];
   markers.models["myRectAngleGapR" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRectAngleGap[4];

   myHolesNum++;
   return { plate, cutPlate, markers, myHolesNum }
};


export default RectAngleGap;