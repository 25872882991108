<!--  -->
<template>
  <div class="login-box">
    <div class="layer">
      <el-form
        :model="logForm"
        ref="numberValidateForm"
        :rules="addAddressRules"
        label-width="70px"
        :inline="true"
        class="login-container"
      >
        <h3 class="login_title">系统登录</h3>
        <el-form-item prop="comName">
          <el-input v-model="logForm.comName" placeholder="请输入用户名"
            ><template slot="prepend">公司名:</template></el-input
          >
        </el-form-item>
        <el-form-item prop="comPassCode">
          <el-input
            type="password"
            v-model="logForm.comPassCode"
            placeholder="请输入密码"
            ><template slot="prepend">密&emsp;码:</template></el-input
          >
        </el-form-item>
        <el-form-item class="login-submit">
          <el-button @click="regFormVisible = true">注 册</el-button>
          <el-button
            type="primary"
            @click.stop="loginSubmit('numberValidateForm')"
            >登录</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-dialog
        title="玻璃尺寸设置"
        :visible.sync="regFormVisible"
        width="24%"
        center
      >
        <el-form
          :model="regForm"
          status-icon
          :rules="regRules"
          ref="regForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="公司名称" prop="comName">
            <el-input v-model.number="regForm.comName"></el-input>
          </el-form-item>
          <el-form-item label="公司电话" prop="comPhone">
            <el-input v-model.number="regForm.comPhone"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input
              type="password"
              v-model="regForm.pass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              type="password"
              v-model="regForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click.stop="regSubmit('regForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('regForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogView",
  data() {
    var checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'));
        } else {
          if (this.regForm.checkPass !== '') {
            this.$refs.regForm.validateField('checkPass');
          }
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.regForm.pass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      addAddressRules: {
        comName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        comPassCode: [
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
      },
      logForm: {
        comName: "",
        comPassCode: "",
      },
      regForm: {
        comName: "",
        comPhone: "",
        pass: "",
        checkPass: "",
      },
      regRules: {
        comName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        comPhone: [{ required: true, validator: checkPhone, trigger: "blur" }],
      },
      regFormVisible: false,
    };
  },
  mounted() {
    window.addEventListener("keydown", this.keyDown);
  },
  methods: {
    //弹出窗口
    myOpenMsg(cont, title) {
      this.$alert(cont, title, {
        confirmButtonText: "确定",
        /*
          callback: action => {
            this.$message({
              type: 'info',
             message: `action: ${ action }`
            });
          }
          */
      });
    },
    loginSubmit(formName) {
      const _this = this;
      const base = process.env.NODE_ENV === "development" ? "/api" : "";
      var myURLToJson = base + "/getData/login.php";
      console.log("myURLToJson=", myURLToJson);
      if (this.logForm.comName == "") {
        this.myOpenMsg("公司名称不能为空！", "贵友提示：");
      } else if (this.logForm.comPassCode == "") {
        this.myOpenMsg("密码不能为空！", "贵友提示：");
      } else {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 表单输入完提交事件
            /**POST */
            this.axios
              .post(myURLToJson, this.logForm, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                // 处理响应
                if (res.data=="3") {
                  console.log("测试登录正确返回token", res.data.token);
                  const token = res.data.token;
                  _this.$store.commit("user/setToken", token);
                  _this.$router.push("/").catch((err) => {});
                } else if (res.data=="1"){
                  this.myOpenMsg("账户还未通过审核，请联系贵友客服开通。联系电话：13704179211", "贵友提示：");
                } else if (res.data=="2"){
                  this.myOpenMsg("密码错误,请重新输入!", "贵友提示：");
                } else if (res.data=="4"){
                  this.myOpenMsg("用户不存在，请注册！", "贵友提示：");
                }
              })
              .catch((error) => {
                // 处理错误
                console.error("error=", error);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
    },
    keyDown(e) {
      //回车则执行登录方法，enter键的ASCII是13
      if (e.keyCode === 13) {
        this.loginSubmit("numberValidateForm"); //需要执行的方法
      }
    },
    regSubmit(regformName) {
      const _this = this;
      const base = process.env.NODE_ENV === "development" ? "/api" : "";
      var myURLToJson = base + "/getData/regist.php";
      console.log("myURLToJson=", myURLToJson);
      if (this.regForm.comName == "") {
        this.myOpenMsg("公司名称不能为空！", "贵友提示：");
      } else if (this.logForm.comPass == "") {
        this.myOpenMsg("密码不能为空！", "贵友提示：");
      } else {
        this.$refs[regformName].validate((valid) => {
          if (valid) {
            // 表单输入完提交事件
            console.log("log*******提交！", this.regForm);
            console.log("myURLToJson*******提交！", myURLToJson);
            /**POST */
            this.axios
              .post(myURLToJson, this.regForm, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              })
              .then((res) => {
                // 处理响应
                if (res.data.flag=="1") {
                  this.myOpenMsg("用户名或者手机号已经被使用，请更换名称或者手机号。", "贵友提示：");
                } else if (res.data.flag=="2"){
                  const token = res.data.token;
                  _this.$store.commit("user/setToken", token);
                  this.regFormVisible = false;
                  this.myOpenMsg("注册成功！请联系贵友客户开通。联系电话：13704179211", "贵友提示：");
                } 
              })
              .catch((error) => {
                // 处理错误
                console.error("error=", error);
              });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      }
      },
    resetForm(regformName) {
        this.$refs[regformName].resetFields();
      },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown, false);
  },
};
</script>

<style lang="less" scoped>
.login-box {
  color: #fff;
  .layer {
    position: absolute;
    height: 100%;
    width: 100%;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url("../assets/bg.jpg"); // 增加这一行设置背景图片
    background-size: cover; // 调整背景图片的尺寸以适应容器
    background-repeat: no-repeat; // 防止背景图片
  }
}
.login-container {
  color: #fff;
  border: 1px solid #0f336b;
  width: 400px;
  padding: 35px 35px 15px 35px;
  margin: 280px auto;
  background-color: #0f336b;
  border-radius: 10px;
  box-shadow: 0 0 25px #cac6c6;
  box-sizing: border-box;
  .login_title {
    margin-bottom: 40px;
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-family: "Times New Roman", Times, serif;
  }
  .el-input {
    width: 250px;
  }
  .login-submit {
    margin-left: 0px;
  }
}
</style>