//缺5、方缺
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmySquareGap(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xb = param.xb, 
  yb = param.yb, xc = param.xc, 
  yc = param.yc, xg = param.xg, yg = param.yg, 
  xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
  x = param.x0, r = param.r, rd = param.rd,w = param.w, h = param.h; 
  
  if (area == "A") {
    var mySquareGap = {
          paths: {
            "LeftLine": new makerjs.paths.Line([xa, ya], [xa, ya-h]),
            "BottomLine": new makerjs.paths.Line([xa, ya-h], [xa+w, ya-h]),
            "RightLine": new makerjs.paths.Line([xa+w, ya-h], [xa+w, ya])
          }
    };
    
    var fillet1 = makerjs.path.fillet(mySquareGap.paths.LeftLine, mySquareGap.paths.BottomLine, r);
    var fillet2 = makerjs.path.fillet(mySquareGap.paths.BottomLine, mySquareGap.paths.RightLine, r);
//add the fillet to the model
mySquareGap.paths.fillet1 = fillet1;
mySquareGap.paths.fillet2 = fillet2;
console.log("mySquareGap==",mySquareGap);
  } else if (area == "B") {
   var mySquareGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xb, yb], [xb, yb-h]),
        "BottomLine": new makerjs.paths.Line([xb, yb-h], [xb+w, yb-h]),
        "RightLine": new makerjs.paths.Line([xb+w, yb-h], [xb+w, yb])
      }
};

var fillet1 = makerjs.path.fillet(mySquareGap.paths.LeftLine, mySquareGap.paths.BottomLine, r);
var fillet2 = makerjs.path.fillet(mySquareGap.paths.BottomLine, mySquareGap.paths.RightLine, r);
//add the fillet to the model
mySquareGap.paths.fillet1 = fillet1;
mySquareGap.paths.fillet2 = fillet2;
  } else if (area == "C") {
   var mySquareGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xc-w, yc], [xc-w, yc-h]),
        "BottomLine": new makerjs.paths.Line([xc-w, yc-h], [xc, yc-h]),
        "RightLine": new makerjs.paths.Line([xc, yc-h], [xc, yc])
      }
};

var fillet1 = makerjs.path.fillet(mySquareGap.paths.LeftLine, mySquareGap.paths.BottomLine, r);
var fillet2 = makerjs.path.fillet(mySquareGap.paths.BottomLine, mySquareGap.paths.RightLine, r);
//add the fillet to the model
mySquareGap.paths.fillet1 = fillet1;
mySquareGap.paths.fillet2 = fillet2;
  } else if (area == "G") {
   var mySquareGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+h]),
        "BottomLine": new makerjs.paths.Line([xg, yg+h], [xg+w, yg+h]),
        "RightLine": new makerjs.paths.Line([xg+w, yg+h], [xg+w, yg])
      }
};
var fillet1 = makerjs.path.fillet(mySquareGap.paths.LeftLine, mySquareGap.paths.BottomLine, r);
var fillet2 = makerjs.path.fillet(mySquareGap.paths.BottomLine, mySquareGap.paths.RightLine, r);
mySquareGap.paths.fillet1 = fillet1;
mySquareGap.paths.fillet2 = fillet2;
  } else if (area == "H") {
   var mySquareGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+h]),
        "BottomLine": new makerjs.paths.Line([xh, yh+h], [xh+w, yh+h]),
        "RightLine": new makerjs.paths.Line([xh+w, yh+h], [xh+w, yh])
      }
};
var fillet1 = makerjs.path.fillet(mySquareGap.paths.LeftLine, mySquareGap.paths.BottomLine, r);
var fillet2 = makerjs.path.fillet(mySquareGap.paths.BottomLine, mySquareGap.paths.RightLine, r);
mySquareGap.paths.fillet1 = fillet1;
mySquareGap.paths.fillet2 = fillet2;
  } else if (area == "I") {
   var mySquareGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xi-w, yi], [xi-w, yi+h]),
        "BottomLine": new makerjs.paths.Line([xi-w, yi+h], [xi, yi+h]),
        "RightLine": new makerjs.paths.Line([xi, yi+h], [xi, yi])
      }
};
var fillet1 = makerjs.path.fillet(mySquareGap.paths.LeftLine, mySquareGap.paths.BottomLine, r);
var fillet2 = makerjs.path.fillet(mySquareGap.paths.BottomLine, mySquareGap.paths.RightLine, r);
mySquareGap.paths.fillet1 = fillet1;
mySquareGap.paths.fillet2 = fillet2;
  }
  return mySquareGap;
};
// 矩形等去角标记 连线函数      
function creatmySquareGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var H=inputParam.H,R=inputParam.R,W=inputParam.W,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
  xa = param.xa, ya = param.ya, xb = param.xb, 
  yb = param.yb, xc = param.xc, 
  yc = param.yc, xg = param.xg, yg = param.yg, 
  xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
  x = param.x0, r = param.r, rd = param.rd,w = param.w, h = param.h, 
        glassW = outParam.glassW, glassH = outParam.glassH,
       markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        w= W*1*myZoom,
               h= H*1*myZoom,
               r=R*1*myZoom,
               rd=r/Math.sqrt(2),
               hd=r-rd, 
                 x0 = X*1*myZoom,
                 y0 = Y*1*myZoom;
                 if(r!==0){
                  var TxtR="R="+R;
                 }
                 if(h!==0){
                  var TxtH=H;
                 }
                 if(w!==0){
                  var TxtW=W;
                 }
                 if(x0!==0){          
                 var TxtX=X;
                 }
                 if(y0!==0){          
                  var TxtY=Y;
                 }

  if (area == "A") {
      var myShgGapAMakerH = {
            paths: {
                  "HALine0": new makerjs.paths.Line([xa+w+markShiftTwo,ya], [xa+w+markShiftTwo,ya-h]),
                  "HALine1": new makerjs.paths.Line([xa+w,ya-h], [xa+w+markShiftFour,ya-h]),
            }
      };
      var myShgGapAMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xa+hd,ya-h+hd], [xa+r,ya-h+r]),
                  }
      };
      var myShgGapAMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]),
                  "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
            }
      };
      var myShgGapAMakerW = {
            paths: {
               "WALine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
               "WALine1": new makerjs.paths.Line([xa+w, ya], [xa+w, ya + markShiftFour]),
               "WALine2": new makerjs.paths.Line([xa, ya+ markShiftTwo], [xa+w, ya + markShiftTwo]),
            }
      };
        makerjs.model.addCaption(myShgGapAMakerH, TxtH, [xa+w+markShiftFour,ya-h], [xa+w+markShiftFour,ya]);//H文字标注
        makerjs.model.addCaption(myShgGapAMakerX, TxtX, [xa - x0, ya + markShiftFour], [xa, ya + markShiftFour]);//X文字标注
        makerjs.model.addCaption(myShgGapAMakerR, TxtR, [xa+r,ya-h+r+markShift], [xa+r+markShiftFour,ya-h+r+markShift]);//R文字标注  
        makerjs.model.addCaption(myShgGapAMakerW, TxtW, [xa, ya+ markShiftFour], [xa+w, ya + markShiftFour]);//W文字标注  
        var markers = [myShgGapAMakerH,myShgGapAMakerX,myShgGapAMakerR,myShgGapAMakerW]
  } else if (area == "B") {
   var myShgGapBMakerH = {
      paths: {
            "HBLine0": new makerjs.paths.Line([xb+w+markShiftTwo,yb], [xb+w+markShiftTwo,yb-h]),
            "HBLine1": new makerjs.paths.Line([xb+w,yb-h], [xb+w+markShiftFour,yb-h]),
      }
};
var myShgGapBMakerR = {
      paths: {
             "RBLine0": new makerjs.paths.Line([xb+hd,yb-h+hd], [xb+r,yb-h+r]),
            }
};
var myShgGapBMakerX = {
      paths: {
            // "XBLine0": new makerjs.paths.Line([xb - x0, yb + markShiftTwo], [xb, yb + markShiftTwo]),
            // "XBLine1": new makerjs.paths.Line([xb - x0, yb], [xb - x0, yb + markShiftFour]),
            // "XBLine2": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
      }
};
var myShgGapBMakerW = {
      paths: {
         "WBLine0": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
         "WBLine1": new makerjs.paths.Line([xb+w, yb], [xb+w, yb + markShiftFour]),
         "WBLine2": new makerjs.paths.Line([xb, yb+ markShiftTwo], [xb+w, yb + markShiftTwo]),
      }
};
  makerjs.model.addCaption(myShgGapBMakerH, TxtH, [xb+w+markShiftFour,yb-h], [xb+w+markShiftFour,yb]);//H文字标注
  makerjs.model.addCaption(myShgGapBMakerX, TxtX, [xb - x0, yb + markShiftFour], [xb, yb + markShiftFour]);//X文字标注
  makerjs.model.addCaption(myShgGapBMakerR, TxtR, [xb+r,yb-h+r+markShift], [xb+r+markShiftFour,yb-h+r+markShift]);//R文字标注  
  makerjs.model.addCaption(myShgGapBMakerW, TxtW, [xb, yb+ markShiftFour], [xb+w, yb + markShiftFour]);//W文字标注  
  var markers = [myShgGapBMakerH,myShgGapBMakerX,myShgGapBMakerR,myShgGapBMakerW]
  } else if (area == "C") {
   var myShgGapCMakerH = {
      paths: {
            "HCLine0": new makerjs.paths.Line([xc-w-markShiftTwo,yc], [xc-w-markShiftTwo,yc-h]),
            "HCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w-markShiftFour,yc-h]),
      }
};
var myShgGapCMakerR = {
      paths: {
             "RCLine0": new makerjs.paths.Line([xc-hd,yc-h+hd], [xc-r,yc-h+r]),
            }
};
var myShgGapCMakerX = {
      paths: {
            "XCLine0": new makerjs.paths.Line([xc+ x0, yc + markShiftTwo], [xc, yc + markShiftTwo]),
            "XCLine1": new makerjs.paths.Line([xc+ x0, yc], [xc+ x0, yc + markShiftFour]),
            "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
      }
};
var myShgGapCMakerW = {
      paths: {
         "WCLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
         "WCLine1": new makerjs.paths.Line([xc-w, yc], [xc-w, yc + markShiftFour]),
         "WCLine2": new makerjs.paths.Line([xc, yc+ markShiftTwo], [xc-w, yc + markShiftTwo]),
      }
};
  makerjs.model.addCaption(myShgGapCMakerH, TxtH, [xc-w-markShiftFour,yc-h], [xc-w-markShiftFour,yc]);//H文字标注
  makerjs.model.addCaption(myShgGapCMakerX, TxtX, [xc, yc + markShiftFour], [xc+ x0, yc + markShiftFour]);//X文字标注
  makerjs.model.addCaption(myShgGapCMakerR, TxtR, [xc-r-markShiftFour,yc-h+r+markShift], [xc-r,yc-h+r+markShift]);//R文字标注  
  makerjs.model.addCaption(myShgGapCMakerW, TxtW, [xc-w, yc + markShiftFour], [xc, yc+ markShiftFour]);//W文字标注  
  var markers = [myShgGapCMakerH,myShgGapCMakerX,myShgGapCMakerR,myShgGapCMakerW]
  } else if (area == "G") {
   var myShgGapGMakerH = {
      paths: {
            "HGLine0": new makerjs.paths.Line([xg+w+markShiftTwo,yg], [xg+w+markShiftTwo,yg+h]),
            "HGLine1": new makerjs.paths.Line([xg+w,yg+h], [xg+w+markShiftFour,yg+h]),
      }
};
var myShgGapGMakerR = {
      paths: {
             "RGLine0": new makerjs.paths.Line([xg+hd,yg+h-hd], [xg+r,yg+h-r]),
            }
};
var myShgGapGMakerX = {
      paths: {
            "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
            "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
            "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
      }
};
var myShgGapGMakerW = {
      paths: {
         "WGLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
         "WGLine1": new makerjs.paths.Line([xg+w, yg], [xg+w, yg - markShiftFour]),
         "WGLine2": new makerjs.paths.Line([xg, yg- markShiftTwo], [xg+w, yg- markShiftTwo]),
      }
};
  makerjs.model.addCaption(myShgGapGMakerH, TxtH, [xg+w+markShiftFour,yg], [xg+w+markShiftFour,yg+h]);//H文字标注
  makerjs.model.addCaption(myShgGapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//X文字标注
  makerjs.model.addCaption(myShgGapGMakerR, TxtR, [xg+r,yg+h-r-markShift], [xg+r+markShiftFour,yg+h-r-markShift]);//R文字标注  
  makerjs.model.addCaption(myShgGapGMakerW, TxtW, [xg, yg- markShiftFour], [xg+w, yg - markShiftFour]);//W文字标注  
  var markers = [myShgGapGMakerH,myShgGapGMakerX,myShgGapGMakerR,myShgGapGMakerW]
  } else if (area == "H") {
   var myShgGapHMakerH = {
      paths: {
            "HHLine0": new makerjs.paths.Line([xh+w+markShiftTwo,yh], [xh+w+markShiftTwo,yh+h]),
            "HHLine1": new makerjs.paths.Line([xh+w,yh+h], [xh+w+markShiftFour,yh+h]),
      }
};
var myShgGapHMakerR = {
      paths: {
             "RHLine0": new makerjs.paths.Line([xh+hd,yh+h-hd], [xh+r,yh+h-r]),
            }
};
var myShgGapHMakerX = {
      paths: {
            "XHLine0": new makerjs.paths.Line([xh - x0, yh - markShiftTwo], [xh, yh - markShiftTwo]),
            "XHLine1": new makerjs.paths.Line([xh - x0, yh], [xh - x0, yh - markShiftFour]),
            "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour]),
      }
};
var myShgGapHMakerW = {
      paths: {
         "WHLine0": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour*2]),
         "WHLine1": new makerjs.paths.Line([xh+w, yh], [xh+w, yh - markShiftFour*2]),
         "WHLine2": new makerjs.paths.Line([xh, yh- markShiftFour-markShift], [xh+w, yh- markShiftFour-markShift]),
      }
};
  makerjs.model.addCaption(myShgGapHMakerH, TxtH, [xh+w+markShiftFour,yh], [xh+w+markShiftFour,yh+h]);//H文字标注
  makerjs.model.addCaption(myShgGapHMakerX, TxtX, [xh - x0, yh - markShiftFour], [xh, yh - markShiftFour]);//X文字标注
  makerjs.model.addCaption(myShgGapHMakerR, TxtR, [xh+r,yh+h-r-markShift], [xh+r+markShiftFour,yh+h-r-markShift]);//R文字标注  
  makerjs.model.addCaption(myShgGapHMakerW, TxtW, [xh, yh- markShiftFour*2], [xh+w, yh - markShiftFour*2]);//W文字标注  
  var markers = [myShgGapHMakerH,myShgGapHMakerX,myShgGapHMakerR,myShgGapHMakerW]
  } else if (area == "I") {
   var myShgGapIMakerH = {
      paths: {
            "HILine0": new makerjs.paths.Line([xi-w-markShiftTwo,yi], [xi-w-markShiftTwo,yi+h]),
            "HILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w-markShiftFour,yi+h]),
      }
};
var myShgGapIMakerR = {
      paths: {
             "RILine0": new makerjs.paths.Line([xi-hd,yi+h-hd], [xi-r,yi+h-r]),
            }
};
var myShgGapIMakerX = {
      paths: {
            "XILine0": new makerjs.paths.Line([xi+ x0, yi - markShiftTwo], [xi, yi - markShiftTwo]),
            "XILine1": new makerjs.paths.Line([xi+ x0, yi], [xi+ x0, yi - markShiftFour]),
            "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
      }
};
var myShgGapIMakerW = {
      paths: {
         "WILine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
         "WILine1": new makerjs.paths.Line([xi-w, yi], [xi-w, yi - markShiftFour]),
         "WILine2": new makerjs.paths.Line([xi, yi- markShiftTwo], [xi-w, yi - markShiftTwo]),
      }
};
  makerjs.model.addCaption(myShgGapIMakerH, TxtH, [xi-w-markShiftFour,yi], [xi-w-markShiftFour,yi+h]);//H文字标注
  makerjs.model.addCaption(myShgGapIMakerX, TxtX, [xi, yi - markShiftFour], [xi+ x0, yi - markShiftFour]);//X文字标注
  makerjs.model.addCaption(myShgGapIMakerR, TxtR, [xi-r-markShiftFour,yi+h-r-markShift], [xi-r,yi+h-r-markShift]);//R文字标注  
  makerjs.model.addCaption(myShgGapIMakerW, TxtW, [xi-w, yi - markShiftFour], [xi, yi-markShiftFour]);//W文字标注  
  var markers = [myShgGapIMakerH,myShgGapIMakerX,myShgGapIMakerR,myShgGapIMakerW]
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function SquareGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){


   var frame = outParam.shapeName;
   console.log("outParam==", outParam)
   console.log("model==", model)
   console.log("inputParam==", inputParam)
   console.log("outParam.shapeName==////////", outParam.shapeName)
   if (typeof (frame) == "undefined") {
         var H=inputParam.H,R=inputParam.R,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
         plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
               glassW = outParam.glassW, glassH = outParam.glassH,
               markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
               markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
               w= W*1*myZoom,
               h= H*1*myZoom,
               r=R*1*myZoom,
               rd=r/Math.sqrt(2), 
                 x0 = X*1*myZoom,
                 y0 = Y*1*myZoom;
                 if(r!==0){
                  var TxtR="R="+R;
                 }
                 if(h!==0){
                  var TxtH=H;
                 }
                 if(w!==0){
                  var TxtW=W;
                 }
                 if(x0!==0){          
                 var TxtX=X;
                 }
                 if(y0!==0){          
                  var TxtY=Y;
                 }
   } else {
         var H=inputParam.H,R=inputParam.R,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
         plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
               glassW = outParam.glassW, glassH = outParam.glassH,
               markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
               markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
               w= W*1*myZoom,
   h= H*1*myZoom,
   r=R*1*myZoom,
   rd=r/Math.sqrt(2), 
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     if(r!==0){
      var TxtR="R="+R;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
   }
   if (frame == "Rectangle") {
         console.log("frame==undefined||||*****")
         //在A区域 计算孔的位置       
         var xa = x0, ya = glassH * 1 - y0,
               //B区域//只有Y标注没有X标注 
               xb = glassW * 1 / 2, yb = glassH * 1 - y0,
               //C区域
               xc = glassW * 1 - x0, yc = glassH * 1 - y0,
               //在G区域 计算孔的位置
               xg = x0, yg = y0,
               //在H区域 计算孔的位置
               //只标注Y
               xh = glassW * 1 / 2, yh = y0,
               //在I区域 计算孔的位置
               xi = glassW * 1 - x0, yi = y0;
         var OffsetParam = {};
   } else if (frame == "IsoscelesTrapezoid") {
 
   } else if (frame == "RightAngledTrapezoid") {d
 
   } else if (frame == "OtherTrapezoidsA") {
 
   } else if (frame == "OtherTrapezoidsB") {
 
   } else if (frame == "ParallelogramA") {
 
 
   } else if (frame == "ParallelogramB") {
 
   }
   // 坐标参数
   var paramCoordinate = { x0,rd,w,h,r,xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
   const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
   var areaPoint = "";
   console.log("//角7、地弹簧3 ------", allKeys)
   if (allKeys[0] == frame) {
         allKeys.shift();//去掉数组第一个元素
   }
   console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
   switch (area) {
         case "1": areaPoint = "A"; break;
         case "2": areaPoint = "B"; break;
         case "3": areaPoint = "C"; break;
         case "7": areaPoint = "G"; break;
         case "8": areaPoint = "H"; break;
         case "9": areaPoint = "I"; break;
   }
   /**
   * 画图
   * 
   */
   var ObjName = "mySquareGap" + areaPoint;
   var ObjNameMmySquareGap = "mySquareGap" + areaPoint + "Mark";//角标记
   ObjName = creatmySquareGap(areaPoint, paramCoordinate);//图形
   console.log("ObjName===|||||||||", ObjName);
   ObjNameMmySquareGap = creatmySquareGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
   console.log("ObjNameMmySquareGap===|||||||||角标注", ObjNameMmySquareGap);
   console.log("cutPlate.models====************", cutPlate.models),
         console.log("plate.models====**************", plate.models),
         console.log("markers.models====*************", markers.models),
      // myShgGapAMakerH,myShgGapAMakerX,myShgGapAMakerD,myShgGapAMakerW
         cutPlate.models["mySquareGap" + areaPoint + myHolesNum] = ObjName;
   plate.models["mySquareGap" + areaPoint + myHolesNum] = ObjName;
   plate.models["mySquareGapH" + areaPoint + myHolesNum] = ObjNameMmySquareGap[0];
   plate.models["mySquareGapX" + areaPoint + myHolesNum] = ObjNameMmySquareGap[1];
   plate.models["mySquareGapR" + areaPoint + myHolesNum] = ObjNameMmySquareGap[2];
   plate.models["mySquareGapW" + areaPoint + myHolesNum] = ObjNameMmySquareGap[3];
   markers.models["mySquareGap" + areaPoint + myHolesNum] = ObjName;
   markers.models["mySquareGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmySquareGap[0];
   markers.models["mySquareGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmySquareGap[1];
   markers.models["mySquareGapR" + areaPoint + myHolesNum + "Mark"] = ObjNameMmySquareGap[2];
   markers.models["mySquareGapW" + areaPoint + myHolesNum + "Mark"] = ObjNameMmySquareGap[3];

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default SquareGap;