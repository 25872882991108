//角3、方角
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(8);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatCorner(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xahx = param.xahx, xahy = param.xahy, xawx = param.xawx, xawy = param.xawy, rawx = param.rawx, rawy = param.rawy, rahx = param.rahx, rahy = param.rahy,
    xc = param.xc, yc = param.yc, xcwx = param.xcwx, xcwy = param.xcwy, xchx = param.xchx, xchy = param.xchy, rcwx = param.rcwx, rcwy = param.rcwy, rchx = param.rchx, rchy = param.rchy,
    xg = param.xg, yg = param.yg, xgwx = param.xgwx, xgwy = param.xgwy, xghx = param.xghx, xghy = param.xghy, rgwx = param.rgwx, rgwy = param.rgwy, rghx = param.rghx, rghy = param.rghy,
    xi = param.xi, yi = param.yi, xiwx = param.xiwx, xiwy = param.xiwy, xihx = param.xihx, xihy = param.xihy, riwx = param.riwx, riwy = param.riwy, rihx = param.rihx, rihy = param.rihy,
    R = param.R;
  if (area == "A") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };

    //   var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLtLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLineC": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArcC": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLineC": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopRLine": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "BottomLine": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myCorner = {
      paths: {        
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopLtine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRightArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopRtLine": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightTopLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightRtLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "BottomRightLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomTopLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRtArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine2": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xi, yi]),
        "RightLfLine": new makerjs.paths.Line([xi, yi], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomRtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xg, yg], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRtArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine2": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLfLine": new makerjs.paths.Line([xiwx, xiwy], [xg, yg]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xc, yc]),
        "RightLine": new makerjs.paths.Line([xc, yc], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightRtLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xa, ya]),
        "TopLine": new makerjs.paths.Line([xa, ya], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopLeftArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "TopLine2": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightDnLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "RightRtLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myCorner = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xghx, xghy], [xahx, xahy]),
        "LeftRLine": new makerjs.paths.Line([xahx, xahy], [rawx, rawy]),
        "TopLeftArc": new makerjs.paths.Arc([rawx, rawy], [rahx, rahy], R, false, false),
        "TopLtine": new makerjs.paths.Line([rahx, rahy], [xawx, xawy]),
        "TopLine": new makerjs.paths.Line([xawx, xawy], [xcwx, xcwy]),
        "TopDnLine": new makerjs.paths.Line([xcwx, xcwy], [rchx, rchy]),
        "TopRtArc": new makerjs.paths.Arc([rchx, rchy], [rcwx, rcwy], R, false, false),
        "TopRLine": new makerjs.paths.Line([rcwx, rcwy], [xchx, xchy]),
        "RtLine": new makerjs.paths.Line([xchx, xchy], [xihx, xihy]),
        "RightLfLine": new makerjs.paths.Line([xihx, xihy], [riwx, riwy]),
        "BottomRtArc": new makerjs.paths.Arc([riwx, riwy],[rihx, rihy],  R, false, false),
        "RightLine": new makerjs.paths.Line([rihx, rihy], [xiwx, xiwy]),
        "BottomLLine": new makerjs.paths.Line([xiwx, xiwy], [xgwx, xgwy]),
        "RightDnLine": new makerjs.paths.Line([xgwx, xgwy], [rghx, rghy]),
        "BottomLtArc": new makerjs.paths.Arc([rghx, rghy], [rgwx, rgwy], R, false, false),
        "BottomLfLine": new makerjs.paths.Line([rgwx, rgwy], [xghx, xghy]),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return myCorner;
};
// 矩形等去角标记 连线函数
function creatCornerMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var HH = inputParam.H, WW = inputParam.W, H = outParam.H, H1 = outParam.H1, H2 = outParam.H2, W = outParam.W, W1 = outParam.W1, W2 = outParam.W2,
    markShiftTwo = fixedParam.markShiftTwo * 1, myZoom = zoom * 1,
    rd = OffsetParam.rMark * 1,
    //   ahd=OffsetParam.ahd*1,chd=OffsetParam.chd*1,cwd=OffsetParam.cwd*1,cwdd=OffsetParam.cwdd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa * 1, ya = param.ya * 1, xahx = param.xahx * 1, xahy = param.xahy * 1, xawx = param.xawx * 1, xawy = param.xawy * 1,
    xc = param.xc * 1, yc = param.yc * 1, xcwx = param.xcwx * 1, xcwy = param.xcwy * 1, xchx = param.xchx * 1, xchy = param.xchy * 1,
    xg = param.xg * 1, yg = param.yg * 1, xgwx = param.xgwx * 1, xgwy = param.xgwy * 1, xghx = param.xghx * 1, xghy = param.xghy * 1,
    xi = param.xi * 1, yi = param.yi * 1, xiwx = param.xiwx * 1, xiwy = param.xiwy * 1, xihx = param.xihx * 1, xihy = param.xihy * 1, R = param.R,
    h = H * 1 * myZoom,
    h1 = H1 * 1 * myZoom,
    h2 = H2 * 1 * myZoom,
    w = W * 1 * myZoom,
    w1 = W1 * 1 * myZoom,
    w2 = W2 * 1 * myZoom,

    hh = HH * 1 * myZoom,
    ww = WW * 1 * myZoom;
  if (R !== 0) {
    var TxtR = "R=" + R;
  }

  if (area == "A") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字  
    var markers = {
      myCornerMarkA,
    }

  } else if (area == "C") {
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字  
    var markers = {
      myCornerMarkC,
    }
    // var myCornerMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字  
    var markers = {
      myCornerMarkG,
    }
    // var myCornerMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+R, hh-R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+R, hh-R], [xi-ww+R+markShiftTwo*2, hh-R]);//半径文字  
    var markers = {
      myCornerMarkI,
    }
    // var myCornerMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
   var markers = {
    myCornerMarkA,
    myCornerMarkC,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
    makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字   
   var markers = {
    myCornerMarkA,
    myCornerMarkG,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+ R, hh-R]),//左上角
      }
    };
   
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
    makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+ R, hh-R], [xi-ww+ R+markShiftTwo*2, hh-R]);//半径文字  
   var markers = {
    myCornerMarkA,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };

    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字
makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字  
  var markers = {
    myCornerMarkC,
    myCornerMarkG,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+ R, hh-R]),//左上角
      }
    };  
  makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字   
  makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+ R, hh-R], [xi-ww+ R+markShiftTwo*2, hh-R]);//半径文字 
   var markers = {
    myCornerMarkC,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
   
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+R, hh-R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字 
    makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+R, hh-R], [xi-ww+R+markShiftTwo*2, hh-R]);//半径文字  
   var markers = {
    myCornerMarkG,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字
    makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字   
   var markers = {
    myCornerMarkA,
    myCornerMarkC,
    myCornerMarkG,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+ R, hh-R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字
    makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+ R, hh-R], [xi-ww+ R+markShiftTwo*2, hh-R]);//半径文字 
   var markers = {
    myCornerMarkA,
    myCornerMarkC,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+ R, hh-R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
    makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字 
    makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+ R, hh-R], [xi-ww+ R+markShiftTwo*2, hh-R]);//半径文字 
   var markers = {
    myCornerMarkA,
    myCornerMarkG,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
   
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+ R, hh-R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字
     makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字 
     makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+ R, hh-R], [xi-ww+ R+markShiftTwo*2, hh-R]);//半径文字 
   var markers = {
    myCornerMarkC,
    myCornerMarkG,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myCornerMarkA = {
      paths: {
        "Line": new makerjs.paths.Line([xa + ww - R, ya - hh + R], [xa + ww - rd, ya - hh + rd]),//左上角
      }
    };
    var myCornerMarkC = {
      paths: {
        "Line": new makerjs.paths.Line([xc-ww+rd, yc-hh+rd], [xc-ww+R, yc-hh+R]),//左上角
      }
    };
    var myCornerMarkG = {
      paths: {
        "Line": new makerjs.paths.Line([ww-R, hh-R],[ww-rd, hh-rd]),//左上角
      }
    };
    var myCornerMarkI = {
      paths: {
        "Line": new makerjs.paths.Line([xi-ww+rd, hh-rd], [xi-ww+ R, hh-R]),//左上角
      }
    };
    makerjs.model.addCaption(myCornerMarkA, TxtR, [xa + ww - R - markShiftTwo * 2, ya - hh + R + markShiftTwo], [xa + ww - R, ya - hh + R + markShiftTwo]);//半径文字 
    makerjs.model.addCaption(myCornerMarkC, TxtR, [xc-ww+R, yc-hh+R],[xc-ww+R+markShiftTwo * 2, yc-hh+R]);//半径文字
    makerjs.model.addCaption(myCornerMarkG, TxtR, [ww-R- markShiftTwo * 2, hh-R],[ww-R, hh-R]);//半径文字  
    makerjs.model.addCaption(myCornerMarkI, TxtR, [xi-ww+ R, hh-R], [xi-ww+ R+markShiftTwo*2, hh-R]);//半径文字 
   var markers = {
    myCornerMarkA,
    myCornerMarkC,
    myCornerMarkG,
    myCornerMarkI,
    }
    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return { markers };
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Corner(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      R = R * 1 * myZoom,
      rd = R / Math.sqrt(2),
      rMark = R - rd,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;

    if (r !== 0) {
      var TxtR = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  } else {
    var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
      plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      r = R * 1 * myZoom,
      w = W * 1 * myZoom,
      h = H * 1 * myZoom,
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;

    if (r !== 0) {
      var TxtR = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }

  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****")
    console.log("glassW||||", glassW)
    console.log("glassH||||", glassH)
    console.log("glassH||||----R", R)

    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
      awd = 0, ahd = 0, cwd = 0, cwdd = 0, chd = 0, iwd = 0, iwdd = 0, ihd = 0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      rawx = xa + w - R, rawy = ya - h,
      rahx = xa + w, rahy = ya - h + R,
      //C区域
      xc = glassW * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc, xchy = yc - h,//C位置角H坐标 
      rcwx = xc - w+ R, rcwy = yc - h,
      rchx = xc - w, rchy = yc - h + R,

      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      rgwx = xg + w - R, rgwy = yg + h,
      rghx = xg + w, rghy = yg + h - R,

      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0,
      xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi, xihy = yi + h,//I位置角H坐标
      riwx = xi - w + R, riwy = yi + h,
      rihx = xi - w, rihy = yi + h - R;

    var OffsetParam = { rMark };
  } else if (frame == "IsoscelesTrapezoid") {
    //在A区域 计算孔的位置
    var xwd = (glassW * 1 - glassW1 * 1) / 2,
      xa = x0 + xwd, ya = glassH * 1 - y0,
      awd = xwd * h / (glassH * 1),
      cwd = awd,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标   
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - awd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: awd, iwdd: 0, ihd: 0 };
  } else if (frame == "RightAngledTrapezoid") {
    //在A区域 计算孔的位置
    var yhd = glassH * 1 - glassH1 * 1, xwd = glassW * 1 - glassW1 * 1,
      ahd = yhd * w / (glassW1 * 1),//Y方向偏移
      awd = ((glassW1 * 1) - w) * yhd / (glassH * 1), //X方向偏移
      iwd = xwd * h / (glassH * 1), //X方向偏移
      xa = x0, ya = glassH * 1 - y0 - yhd,
      xawx = xa + w, xawy = ya + ahd,//A位置角W坐标
      xahx = xa, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - xwd - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc - ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd, cwd: iwd, cwdd: 0, chd: 0, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsA") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移


      xa = x0 + glassW1 * 1, ya = glassH1 * 1 - y0,
      xawx = xa + w, xawy = ya - ahd,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标 
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH2 * 1 - y0,
      xcwx = xc - w, xcwy = yc + ahd,//C位置角W坐标
      xchx = xc + iwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + gwd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - iwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: iwd, cwdd: 0, chd: ahd, iwd, iwdd: 0, ihd: 0 };
  } else if (frame == "OtherTrapezoidsB") {
    //在A区域 计算孔的位置
    var yhd = glassH1 * 1 - glassH2 * 1,
      ahd = yhd * w / ((glassW * 1) - (glassW1 * 1) - (glassW2 * 1)),//Y方向偏移
      awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A 
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 A 
      iwd = (glassW2 * 1) * h / (glassH2 * 1), //X方向偏移
      gwd = (glassW1 * 1) * h / (glassH1 * 1), //X方向偏移
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 - glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc + cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi - cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd, cwdd: 0, chd: 0, iwd: cwd, iwdd: 0, ihd: 0 };
  } else if (frame == "ParallelogramA") {
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW1 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - awd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + awd, xihy = yi + h;//I位置角H坐标

    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: awd, ihd: 0 };

  } else if (frame == "ParallelogramB") {
    console.log("markers.models=", markers.models)
    //在A区域 计算孔的位置
    var awd = (glassW1 * 1) * h / (glassH * 1), //X方向偏移 A  G
      cwd = (glassW2 * 1) * h / (glassH * 1), //X方向偏移 C  I 
      xa = x0 + glassW1 * 1, ya = glassH * 1 - y0,
      xawx = xa + w, xawy = ya,//A位置角W坐标
      xahx = xa - awd, xahy = ya - h,//A位置角H坐标
      //C区域
      xc = glassW * 1 + glassW2 * 1 - x0, yc = glassH * 1 - y0,
      xcwx = xc - w, xcwy = yc,//C位置角W坐标
      xchx = xc - cwd, xchy = yc - h,//C位置角H坐标
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      xgwx = xg + w, xgwy = yg,//G位置角W坐标
      xghx = xg + awd, xghy = yg + h,//G位置角H坐标
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;
    xiwx = xi - w, xiwy = yi,//I位置角W坐标
      xihx = xi + cwd, xihy = yi + h;//I位置角H坐标
    var OffsetParam = { awd: 0, ahd: 0, cwd: 0, cwdd: awd, chd: 0, iwd: 0, iwdd: cwd, ihd: 0 };

  }

  // 坐标参数
  var paramCoordinate = { R, xa, ya, xawx, xawy, xahx, xahy, rawx, rawy, rahx, rahy, xc, yc, xcwx, xcwy, xchx, xchy, rcwx, rcwy, rchx, rchy, xg, yg, xgwx, xgwy, xghx, xghy, rgwx, rgwy, rghx, rghy, xi, yi, xiwx, xiwy, xihx, xihy, riwx, riwy, rihx, rihy };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//画1、斜角 ------", allKeys)
  if (allKeys[0] == frame) {
    allKeys.shift();//去掉数组第一个元素
  }
  console.log("//画1、斜角 位置去掉数组第一个元素------", allKeys)
  if (allKeys.length == 0) {
    switch (area) {
      case "1": areaPoint = "A"; break;
      case "3": areaPoint = "C"; break;
      case "7": areaPoint = "G"; break;
      case "9": areaPoint = "I"; break;
    }
  } else {
    switch (area) {
      case "1": areaPoint = getStrs("A", allKeys); break;
      case "3": areaPoint = getStrs("C", allKeys); break;
      case "7": areaPoint = getStrs("G", allKeys); break;
      case "9": areaPoint = getStrs("I", allKeys); break;
    }
  }
  /**
  * 画图
  * 
  */
  var ObjName = "myCorner" + areaPoint;
  var ObjNameM = "myCorner" + areaPoint + "Mark";//外框标记
  var ObjNameMCorner = "myCorner" + areaPoint + "MarkCorner";//角标记
  var ObjNameO = "myCorner" + areaPoint + "Obj";
  ObjName = creatCorner(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMCorner = creatCornerMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMCorner===|||||||||角标注", ObjNameMCorner);
  ObjNameO = { ObjName };
  ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
  cutPlate.models = {};
  plate.models = {};
  markers.models = {};
  cutPlate.models["myCorner" + areaPoint] = ObjName;
  plate.models["myCorner" + areaPoint] = ObjName;

  console.log("ObjNameM.markers.models===|||||||||是否有所有标注", ObjNameM.markers.models);
  console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMCorner.markers);


  markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMCorner.markers };

  console.log("markers===|||||||||是否有所有标注", markers);
  /**
  * 画图
  * 
  */

  // if(area==1){
  //   //画3、方角 A位置
  //   /*
  //   var myRagGapARoundRectangle=new makerjs.models.RoundRectangle(w, h, r); 
  //   myRagGapARoundRectangle.origin=[xa,ya-h]; 
  //   */
  //  /*
  //   var myRectangleCornerA=new makerjs.models.Rectangle(w+5, h+5,r);
  //   myRectangleCornerA.origin=[xa-5,ya-h-5+5];
  // ////标记3、方角 A位置
  // var myCornerAMakerW = new makerjs.models.Square(0); //孔的原点       
  // myCornerAMakerW.origin = [xa,ya];
  // var myCornerAMakerH = new makerjs.models.Square(0); //孔的原点       
  // myCornerAMakerH.origin = [xa,ya];
  // // */
  // var myRectangleCornerA = {
  //   paths: {
  //     "Line": new makerjs.paths.Line([rd, rd], [R, R]),//左上角
  //   }
  // };
  // // var myRectangleCornerA=new makerjs.models.RoundRectangle(w+r, h+r,r);
  // //   myRectangleCornerA.origin=[xa-r,ya-h];

  // ////标记3、方角 A位置
  // var myCornerAMakerW = new makerjs.models.Square(0); //孔的原点       
  // myCornerAMakerW.origin = [xa,ya];
  // var myCornerAMakerH = new makerjs.models.Square(0); //孔的原点       
  // myCornerAMakerH.origin = [xa,ya];
  // var myCornerAMakerR = new makerjs.models.Square(0); //孔的原点       
  // myCornerAMakerR.origin = [xa,ya];
  // /*****************************************************************************/
  // makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  // makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([w,0], [w,0+markShiftFour]));//Y轴方向短线
  // makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
  // makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0-h], [0-markShiftThree, 0-h]));//X轴方向短线
  // /*****************************************************************************/ 
  // makerjs.model.addPath(myCornerAMakerR, new makerjs.paths.Line([w-markShiftThree,-h+markShiftThree], [w-r/2, -h+r/2]));//R短线标注
  // makerjs.model.addCaption(myCornerAMakerR, TxtR, [w-markShiftFour-r,-h+5+markShiftFour], [w-markShift, -h+5+markShiftFour]);//R文字标注 
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerAMakerW, new makerjs.paths.Line([0,0+markShift], [0+w, 0+markShift]));//W短线标注
  // makerjs.model.addCaption(myCornerAMakerW, TxtW, [0,0+markShiftThree], [0+w, 0+markShiftThree]);//W文字标注 
  // /*****************************************************************************/    
  // makerjs.model.addPath(myCornerAMakerH, new makerjs.paths.Line([0-markShiftTwo,0-h], [0-markShiftTwo, 0]));//H短线标注
  // makerjs.model.addCaption(myCornerAMakerH, TxtH, [0-markShiftFour,0-h], [0-markShiftFour, 0]);//H文字标注
  // /*****************************************************************************/ 
  // plate.models["Corner"]=myRectangleCornerA;
  // cutPlate.models["Corner"]=myRectangleCornerA;
  // markers.models["Corner"]=myRectangleCornerA;
  // markers.models["myCornerAMakerW"]=myCornerAMakerW;
  // markers.models["myCornerAMakerH"]=myCornerAMakerH;
  // markers.models["myCornerAMakerR"]=myCornerAMakerR;
  // /*****************************************************************************/
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerA"+myHolesNum]);
  // /*****************************************************************************/ 

  //   }else if(area==3){
  //     //画3、方角 C位置

  // //var myRectangleCornerA=new makerjs.models.RoundRectangle(w+5, h+5,r);
  // //var myRectangleCornerC=new makerjs.models.Rectangle(w+5, h+5);
  // var myRectangleCornerC=new makerjs.models.RoundRectangle(w+r, h+r,r);
  //   myRectangleCornerC.origin=[xc-w,yc-h];
  // ////标记3、方角 C位置
  // var myCornerCMakerW = new makerjs.models.Square(0); //孔的原点       
  // myCornerCMakerW.origin = [xc,yc];
  // var myCornerCMakerH = new makerjs.models.Square(0); //孔的原点       
  // myCornerCMakerH.origin = [xc,yc];
  // var myCornerCMakerR = new makerjs.models.Square(0); //孔的原点       
  // myCornerCMakerR.origin = [xc,yc];
  // /*****************************************************************************/
  // /*****************************************************************************/
  // makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0], [0, markShiftThree]));//Y轴加长标线
  // makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([-w,0], [-w,0+markShiftFour]));//Y轴方向短线
  // makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  // makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0-h], [0+markShiftThree, 0-h]));//X轴方向短线

  // /*****************************************************************************/
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerCMakerW, new makerjs.paths.Line([0,0+markShift], [0-w, 0+markShift]));//W短线标注
  // makerjs.model.addCaption(myCornerCMakerW, TxtW, [0-w,0+markShiftThree], [0, 0+markShiftThree]);//W文字标注 

  // /*****************************************************************************/  
  // makerjs.model.addPath(myCornerCMakerR, new makerjs.paths.Line([-w+markShiftThree,-h+markShiftThree], [-w+r/2, -h+r/2]));//R短线标注
  // makerjs.model.addCaption(myCornerCMakerR, TxtR, [-w+markShift, -h+5+markShiftFour], [-w+markShiftFour+r,-h+5+markShiftFour]);//R文字标注 
  // /*****************************************************************************/   
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerCMakerH, new makerjs.paths.Line([0+markShiftTwo,0-h], [0+markShiftTwo, 0]));//H短线标注
  // makerjs.model.addCaption(myCornerCMakerH, TxtH, [0+markShiftFour,0-h], [0+markShiftFour, 0]);//H文字标注 

  // /*****************************************************************************/  
  // /*****************************************************************************/ 
  // plate.models["myRectangleCornerC"+myHolesNum]=myRectangleCornerC;
  // cutPlate.models["myRectangleCornerC"+myHolesNum]=myRectangleCornerC;
  // markers.models["myRectangleCornerC"+myHolesNum]=myRectangleCornerC;
  // markers.models["myCornerCMakerW"+myHolesNum]=myCornerCMakerW;
  // markers.models["myCornerCMakerH"+myHolesNum]=myCornerCMakerH;
  // markers.models["myCornerCMakerR"+myHolesNum]=myCornerCMakerR;
  // /*****************************************************************************/
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerC"+myHolesNum])
  // /*****************************************************************************/ 

  //   }else if(area==7){

  // //画3、方角 G位置
  // //var myRectangleCornerG=new makerjs.models.Rectangle(w+5, h+5);

  // var myRectangleCornerG=new makerjs.models.RoundRectangle(w+r, h+r,r);
  //   myRectangleCornerG.origin=[xg-r,yg-r];
  // ////标记3、方角 C位置
  // var myCornerGMakerW = new makerjs.models.Square(0); //孔的原点       
  // myCornerGMakerW.origin = [xg,yg];
  // var myCornerGMakerH = new makerjs.models.Square(0); //孔的原点       
  // myCornerGMakerH.origin = [xg,yg];
  // var myCornerGMakerR = new makerjs.models.Square(0); //孔的原点       
  // myCornerGMakerR.origin = [xg,yg];
  // /*****************************************************************************/
  // /*****************************************************************************/
  // makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0], [0, -markShiftThree]));//Y轴加长标线
  // makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([w,0], [w,0-markShiftFour]));//Y轴方向短线
  // makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0], [0-markShiftThree, 0]));//X轴加长标线
  // makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0+h], [0-markShiftThree, 0+h]));//X轴方向短线
  // /*****************************************************************************/
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerGMakerW, new makerjs.paths.Line([0,0-markShiftTwo], [0+w, 0-markShiftTwo]));//W短线标注
  // makerjs.model.addCaption(myCornerGMakerW, TxtW, [0,0-markShiftFour], [0+w, 0-markShiftFour]);//W文字标注 
  // /*****************************************************************************/ 
  // makerjs.model.addPath(myCornerGMakerR, new makerjs.paths.Line([w-markShiftThree,h-markShiftThree], [w-r/2, h-r/2]));//R短线标注
  // makerjs.model.addCaption(myCornerGMakerR, TxtR, [w-markShiftFour-r,h-5-markShiftFour], [w-markShift, h-5-markShiftFour]);//R文字标注  
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerGMakerH, new makerjs.paths.Line([0-markShiftTwo,0], [0-markShiftTwo, h]));//H短线标注
  // makerjs.model.addCaption(myCornerGMakerH, TxtH, [0-markShiftFour,0], [0-markShiftFour, h]);//H文字标注 
  // /*****************************************************************************/  
  // /*****************************************************************************/ 
  // /*****************************************************************************/
  // plate.models["myRectangleCornerG"+myHolesNum]=myRectangleCornerG;
  // cutPlate.models["myRectangleCornerG"+myHolesNum]=myRectangleCornerG;
  // markers.models["myRectangleCornerG"+myHolesNum]=myRectangleCornerG;
  // markers.models["myCornerGMakerW"+myHolesNum]=myCornerGMakerW;
  // markers.models["myCornerGMakerH"+myHolesNum]=myCornerGMakerH;
  // markers.models["myCornerGMakerR"+myHolesNum]=myCornerGMakerR;
  // /*****************************************************************************/
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerG"+myHolesNum])

  // console.log("markers==",markers);
  // /*****************************************************************************/       
  //   }else if(area==9){
  //    //画3、方角 I位置

  // var myRectangleCornerI=new makerjs.models.RoundRectangle(w+r, h+r,r);
  // //var myRectangleCornerI=new makerjs.models.Rectangle(w+5, h+5);
  // myRectangleCornerI.origin=[xi-w,yi-r];
  // ////标记3、方角 C位置
  // var myCornerIMakerW = new makerjs.models.Square(0); //孔的原点       
  // myCornerIMakerW.origin = [xi,yi];
  // var myCornerIMakerH = new makerjs.models.Square(0); //孔的原点       
  // myCornerIMakerH.origin = [xi,yi];
  // var myCornerIMakerR = new makerjs.models.Square(0); //孔的原点       
  // myCornerIMakerR.origin = [xi,yi];
  // /*****************************************************************************/
  // /*****************************************************************************/
  // makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0,0], [0, 0-markShiftThree]));//Y轴加长标线
  // makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0-w,0], [0-w,0-markShiftFour]));//Y轴方向短线
  // makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0,0], [0+markShiftThree, 0]));//X轴加长标线
  // makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0,0+h], [0+markShiftThree, 0+h]));//X轴方向短线
  // /*****************************************************************************/
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerIMakerW, new makerjs.paths.Line([0-w,0-markShiftTwo], [0, 0-markShiftTwo]));//W短线标注
  // makerjs.model.addCaption(myCornerIMakerW, TxtW, [0-w,0-markShiftFour], [0, 0-markShiftFour]);//W文字标注
  // /*****************************************************************************/  
  // makerjs.model.addPath(myCornerIMakerR, new makerjs.paths.Line([-w+markShiftThree,h-markShiftThree], [-w+r/2, h-r/2]));//R短线标注
  // makerjs.model.addCaption(myCornerIMakerR, TxtR, [-w+markShift, h-5-markShiftFour], [-w+markShiftFour+r,h-5-markShiftFour]);//R文字标注  
  // /*****************************************************************************/   
  // makerjs.model.addPath(myCornerIMakerH, new makerjs.paths.Line([0+markShiftTwo,0], [0+markShiftTwo, h]));//H短线标注
  // makerjs.model.addCaption(myCornerIMakerH, TxtH, [0+markShiftFour,0], [0+markShiftFour, h]);//H文字标注 
  // /*****************************************************************************/  
  // /*****************************************************************************/
  // plate.models["myRectangleCornerI"+myHolesNum]=myRectangleCornerI;
  // cutPlate.models["myRectangleCornerI"+myHolesNum]=myRectangleCornerI;
  // markers.models["myRectangleCornerI"+myHolesNum]=myRectangleCornerI;
  // markers.models["myCornerIMakerW"+myHolesNum]=myCornerIMakerW;
  // markers.models["myCornerIMakerH"+myHolesNum]=myCornerIMakerH;
  // markers.models["myCornerIMakerR"+myHolesNum]=myCornerIMakerR;
  // /*****************************************************************************/
  // makerjs.model.combineSubtraction(markers.models.myRect, markers.models["myRectangleCornerI"+myHolesNum])

  // console.log("markers==",markers);
  // /*****************************************************************************/
  //   }

  myHolesNum++;
  return { plate, cutPlate, markers, myHolesNum }
};


export default Corner;