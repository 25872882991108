//13、吊轮孔列 
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangWheelHoleCol(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
   h= H*1*myZoom,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     /*
     d = D*1,
     h= H*1,
       x0 = X*1,
       y0 = Y*1;
       */
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(x0!==0){          
     var TxtX=X;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记吊轮孔列 A位置孔上
          var myHwhcHolesAup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var myHwhcHolesMarkAup = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkAup.origin = [xa, ya];      
          var myHwhcHolesXMarkAup = new makerjs.models.Square(0);      
          myHwhcHolesXMarkAup.origin =[xa, ya];      
          var myHwhcHolesYMarkAup = new makerjs.models.Square(0);      
          myHwhcHolesYMarkAup.origin = [xa, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkAup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesXMarkAup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(myHwhcHolesXMarkAup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHwhcHolesYMarkAup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkAup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //标记吊轮孔列 A位置孔下
     var myHwhcHolesAdn= new makerjs.models.Holes(d/2, [[xa+h, ya]]);
          var myHwhcHolesMarkAdn = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkAdn.origin = [xa+h, ya];       
          var myHwhcHolesYMarkAdn = new makerjs.models.Square(0);      
          myHwhcHolesYMarkAdn.origin = [xa+h, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkAdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkAdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesYMarkAdn, new makerjs.paths.Line([-h,0], [0, 0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkAdn, TxtH, [-h,0+markShift], [0, 0+markShift]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHwhcHolesAup"+myHolesNum]=myHwhcHolesAup;
    cutPlate.models["myHwhcHolesAup"+myHolesNum]=myHwhcHolesAup;
    markers.models["myHwhcHolesAup"+myHolesNum]=myHwhcHolesAup;
    markers.models["myHwhcHolesMarkAup"+myHolesNum]=myHwhcHolesMarkAup;
    markers.models["myHwhcHolesXMarkAup"+myHolesNum]=myHwhcHolesXMarkAup;
    markers.models["myHwhcHolesYMarkAup"+myHolesNum]=myHwhcHolesYMarkAup;
    /*****************************************************************************/
    plate.models["myHwhcHolesAdn"+myHolesNum]=myHwhcHolesAdn;
    cutPlate.models["myHwhcHolesAdn"+myHolesNum]=myHwhcHolesAdn;
    markers.models["myHwhcHolesAdn"+myHolesNum]=myHwhcHolesAdn;
    markers.models["myHwhcHolesMarkAdn"+myHolesNum]=myHwhcHolesMarkAdn;
    markers.models["myHwhcHolesYMarkAdn"+myHolesNum]=myHwhcHolesYMarkAdn;
    /*****************************************************************************/ 
       
    }else if(area==4){
    //画孔 D位置孔
          //标记吊轮孔列D位置孔上
          var myHwhcHolesDup= new makerjs.models.Holes(d/2, [[xd, yd]]);
          var myHwhcHolesMarkDup = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkDup.origin = [xd, yd];      
          var myHwhcHolesXMarkDup = new makerjs.models.Square(0);      
          myHwhcHolesXMarkDup.origin =[xd, yd];      
          var myHwhcHolesYMarkDup = new makerjs.models.Square(0);      
          myHwhcHolesYMarkDup.origin = [xd, yd];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkDup, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkDup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkDup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/    
    makerjs.model.addPath(myHwhcHolesYMarkDup, new makerjs.paths.Line([-x0,0], [0, 0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkDup, TxtX, [-x0,0+markShift], [0, 0+markShift]);//Y文字标注
    /*****************************************************************************/ 
     //标记吊轮孔列 D位置孔下
     var myHwhcHolesDdn= new makerjs.models.Holes(d/2, [[xd+h, yd]]);
          var myHwhcHolesMarkDdn = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkDdn.origin = [xd+h, yd];       
          var myHwhcHolesYMarkDdn = new makerjs.models.Square(0);      
          myHwhcHolesYMarkDdn.origin = [xd+h, yd];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkDdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkDdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesYMarkDdn, new makerjs.paths.Line([-h,0], [0, 0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkDdn, TxtH, [-h,0+markShift], [0, 0+markShift]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHwhcHolesDup"+myHolesNum]=myHwhcHolesDup;
    cutPlate.models["myHwhcHolesDup"+myHolesNum]=myHwhcHolesDup;
    markers.models["myHwhcHolesDup"+myHolesNum]=myHwhcHolesDup;
    markers.models["myHwhcHolesMarkDup"+myHolesNum]=myHwhcHolesMarkDup;
    markers.models["myHwhcHolesXMarkDup"+myHolesNum]=myHwhcHolesXMarkDup;
    markers.models["myHwhcHolesYMarkDup"+myHolesNum]=myHwhcHolesYMarkDup;
    /*****************************************************************************/
    plate.models["myHwhcHolesDdn"+myHolesNum]=myHwhcHolesDdn;
    cutPlate.models["myHwhcHolesDdn"+myHolesNum]=myHwhcHolesDdn;
    markers.models["myHwhcHolesDdn"+myHolesNum]=myHwhcHolesDdn;
    markers.models["myHwhcHolesMarkDdn"+myHolesNum]=myHwhcHolesMarkDdn;
    markers.models["myHwhcHolesYMarkDdn"+myHolesNum]=myHwhcHolesYMarkDdn;
    /*****************************************************************************/ 
      
    }else if(area==7){
    //画孔 G位置孔
          //标记吊轮孔列G位置孔上
          var myHwhcHolesGup= new makerjs.models.Holes(d/2, [[xg, yg]]);
          var myHwhcHolesMarkGup = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkGup.origin = [xg, yg];      
          var myHwhcHolesXMarkGup = new makerjs.models.Square(0);      
          myHwhcHolesXMarkGup.origin =[xg, yg];      
          var myHwhcHolesYMarkGup = new makerjs.models.Square(0);      
          myHwhcHolesYMarkGup.origin = [xg, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkGup, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkGup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myHwhcHolesMarkGup, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftThree, -y0]));//X轴加长标线
    makerjs.model.addPath(myHwhcHolesMarkGup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkGup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkGup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesXMarkGup, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(myHwhcHolesXMarkGup, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myHwhcHolesYMarkGup, new makerjs.paths.Line([-x0-markShift,-y0], [-x0-markShift, 0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkGup, TxtY, [-x0-markShiftThree,-y0], [-x0-markShiftThree, 0]);//Y文字标注
    /*****************************************************************************/ 
     //标记吊轮孔列 G位置孔下
     var myHwhcHolesGdn= new makerjs.models.Holes(d/2, [[xg+h, yg]]);
          var myHwhcHolesMarkGdn = new makerjs.models.Square(0); //孔的原点       
          myHwhcHolesMarkGdn.origin = [xg+h, yg];       
          var myHwhcHolesYMarkGdn = new makerjs.models.Square(0);      
          myHwhcHolesYMarkGdn.origin = [xg+h, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkGdn, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myHwhcHolesMarkGdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myHwhcHolesMarkGdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myHwhcHolesYMarkGdn, new makerjs.paths.Line([-h,0], [0, 0]));//Y短标线
    makerjs.model.addCaption(myHwhcHolesYMarkGdn, TxtH, [-h,0+markShift], [0, 0+markShift]);//Y文字标注
    /*****************************************************************************/ 
    plate.models["myHwhcHolesGup"+myHolesNum]=myHwhcHolesGup;
    cutPlate.models["myHwhcHolesGup"+myHolesNum]=myHwhcHolesGup;
    markers.models["myHwhcHolesGup"+myHolesNum]=myHwhcHolesGup;
    markers.models["myHwhcHolesMarkGup"+myHolesNum]=myHwhcHolesMarkGup;
    markers.models["myHwhcHolesXMarkGup"+myHolesNum]=myHwhcHolesXMarkGup;
    markers.models["myHwhcHolesYMarkGup"+myHolesNum]=myHwhcHolesYMarkGup;
    /*****************************************************************************/
    plate.models["myHwhcHolesGdn"+myHolesNum]=myHwhcHolesGdn;
    cutPlate.models["myHwhcHolesGdn"+myHolesNum]=myHwhcHolesGdn;
    markers.models["myHwhcHolesGdn"+myHolesNum]=myHwhcHolesGdn;
    markers.models["myHwhcHolesMarkGdn"+myHolesNum]=myHwhcHolesMarkGdn;
    markers.models["myHwhcHolesYMarkGdn"+myHolesNum]=myHwhcHolesYMarkGdn;
    /*****************************************************************************/ 
      
    }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HangWheelHoleCol;