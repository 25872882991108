//角7、地弹簧3
import makerjs from "makerjs";
import RectangleMark from "./RectangleMark";
//获取角的字符串判断是哪个角
function getStrs(area, strs) {
  console.log("是否调用此函数getStrs（）")
  var arrA = [];
  var TA = strs[0].slice(14);
  TA = TA + area;
  arrA = Array.from(TA);
  console.log("是否调用此函数getStrs()arrA==", arrA)
  arrA = arrA.sort();
  arrA = arrA.join("");
  console.log("是否调用此函数getStrs()areaPoint arrA==", arrA)
  return arrA;
};
// 矩形等去角 连线函数
function creatFloorSpring3(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1, xawx2 = param.xawx2, xawy2 = param.xawy2, xawx3 = param.xawx3, xawy3 = param.xawy3,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1, xcwx2 = param.xcwx2, xcwy2 = param.xcwy2, xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2, xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2, xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,
    s = param.s,d = param.d, r = param.r, r1 = param.r1, w = param.w, w1 = param.w1, w2 = param.w2, h = param.h, h1 = param.h1;
  if (area == "A") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
      }
    };
    //   var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xi, yi]]);
  } else if (area == "C") {
    var myFloorSpring3 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineC": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi]]);
  } else if (area == "G") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        // "RithgLineG": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "I") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineI": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineI": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineI": new makerjs.paths.Line([xa, ya], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AC") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "TopRithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineA": new makerjs.paths.Line([xi, yi], [xg, yg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi]]);
  } else if (area == "AG") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineA": new makerjs.paths.Line([xc, yc], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "AI") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xc, yc], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "CG") {
    var myFloorSpring3 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xi, yi], [xgwx, xgwy]]);
  } else if (area == "CI") {
    var myFloorSpring3 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xg, yg], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xa, ya], [xcwx, xcwy], [xchx, xchy], [xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "GI") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineG": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineG": new makerjs.paths.Line([xa, ya], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    // var myFillet = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xc, yc], [xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACG") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xi, yi]),
        // "BottomLineG": new makerjs.paths.Line([xi, yi], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xi, yi], [xgwx, xgwy]]);
  } else if (area == "ACI") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xg, yg], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "BottomLineI0": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xg, xg]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xg, yg], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy]]);
  } else if (area == "AGI") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xc, yc]),
        // "RithgLineI": new makerjs.paths.Line([xc, yc], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };

    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xc, yc],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "CGI") {
    var myFloorSpring3 = {
      paths: {
        "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "RightLineC": new makerjs.paths.Line([xgwx1, xgwy1], [xa, ya]),
        // "TopLeftLineC": new makerjs.paths.Line([xa, ya], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xa, ya], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  } else if (area == "ACGI") {
    var myFloorSpring3 = {
      paths: {
        "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    var myFloorSpring3Cut = {
      paths: {
        // "LeftLineA": new makerjs.paths.Line([xgwx1, xgwy1], [xawx1, xawy1]),
        "TopLeftLineA": new makerjs.paths.Line([xawx1, xawy1], [xawx2, xawy2]),
        "TopLeftArcA": new makerjs.paths.Arc([xawx2, xawy2], [xawx3, xawy3], r1, false, false),//是否大弧,是否顺时针
        // "TopRightLineA": new makerjs.paths.Line([xawx3, xawy3], [xcwx3, xcwy3]),
        "TopRightArcC": new makerjs.paths.Arc([xcwx3, xcwy3], [xcwx2, xcwy2], r1, false, false),//是否大弧,是否顺时针
        "RithgLineC0": new makerjs.paths.Line([xcwx2, xcwy2], [xcwx1, xcwy1]),
        // "RithgLineC": new makerjs.paths.Line([xcwx1, xcwy1], [xiwx1, xiwy1]),
        "BottomLineI": new makerjs.paths.Line([xiwx1, xiwy1], [xiwx2, xiwy2]),
        "BottomRigthArcI": new makerjs.paths.Arc([xiwx2, xiwy2], [xiwx3, xiwy3], r1, false, false),//是否大弧,是否顺时针
        // "BottomLeftLineI": new makerjs.paths.Line([xiwx3, xiwy3], [xgwx3, xgwy3]),
        "BottomLeftArcG": new makerjs.paths.Arc([xgwx3, xgwy3], [xgwx2, xgwy2], r1, false, false),//是否大弧,是否顺时针
        "BottomLeftLineG": new makerjs.paths.Line([xgwx2, xgwy2], [xgwx1, xgwy1]),
        "RightHolesA": new makerjs.paths.Circle([xa + w1, ya - h1], r),
        "RightHolesC": new makerjs.paths.Circle([xc - w1, yc - h1], r),
        "RightHolesG": new makerjs.paths.Circle([xg + w1, yg + h1], r),
        "RightHolesI": new makerjs.paths.Circle([xi - w1, yi + h1], r),
      }
    };
    // var myCorner = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return [myFloorSpring3,myFloorSpring3Cut];
};
// 矩形等去角标记 连线函数
function creatFloorSpring3Mark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var HH = outParam.H, WW = outParam.W,  D=inputParam.D,R=inputParam.R,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,
    markShiftTwo = fixedParam.markShiftTwo * 1, myZoom = zoom * 1,
    // // rd = OffsetParam.rMark * 1,
    //   Aa=OffsetParam.Aa*1,Aaa=OffsetParam.Aaa*1,Ab=OffsetParam.Ab*1,Ahd=OffsetParam.Ahd*1,
    //   Ca=OffsetParam.a*1,Caa=OffsetParam.Caa*1,Cb=OffsetParam.Cb*1,Chd=OffsetParam.Chd*1,
    //   Ga=OffsetParam.Ga*1,Gaa=OffsetParam.Gaa*1,Gb=OffsetParam.Gb*1,Ghd=OffsetParam.Ghd*1,
    //   Ia=OffsetParam.Ia*1,Iaa=OffsetParam.Iaa*1,Ib=OffsetParam.Ib*1,Ihd=OffsetParam.Ihd*1,
    //   ihd=OffsetParam.ihd*1,iwd=OffsetParam.iwd*1,iwdd=OffsetParam.iwdd*1,
    xa = param.xa, ya = param.ya, xawx1 = param.xawx1, xawy1 = param.xawy1, xawx2 = param.xawx2, xawy2 = param.xawy2, xawx3 = param.xawx3, xawy3 = param.xawy3,
    xc = param.xc, yc = param.yc, xcwx1 = param.xcwx1, xcwy1 = param.xcwy1, xcwx2 = param.xcwx2, xcwy2 = param.xcwy2, xcwx3 = param.xcwx3, xcwy3 = param.xcwy3,
    xg = param.xg, yg = param.yg, xgwx1 = param.xgwx1, xgwy1 = param.xgwy1, xgwx2 = param.xgwx2, xgwy2 = param.xgwy2, xgwx3 = param.xgwx3, xgwy3 = param.xgwy3,
    xi = param.xi, yi = param.yi, xiwx1 = param.xiwx1, xiwy1 = param.xiwy1, xiwx2 = param.xiwx2, xiwy2 = param.xiwy2, xiwx3 = param.xiwx3, xiwy3 = param.xiwy3,
    s = param.s,d = param.d,r = param.r, r1 = param.r1, r2 = param.r2, w = param.w, w1 = param.w1, w2 = param.w2, h = param.h, h1 = param.h1,
    markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
    markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

    w1 = W1*1*myZoom,
   w2 = W2*1*myZoom,
   h = H*1*myZoom,
   h1 = H1*1*myZoom,
   r1 = R*1*myZoom,
  // d = D*1*myZoom,
  // r = d/2,
   a=0,//圆心坐标[a,b]
   b=0;//圆心坐标[a,b]  
    //  x0 = X*1*myZoom,
    //  y0 = Y*1*myZoom;
           
     if(d!==0){
      var TxtD="∮"+D;
     }
     if(r1!==0){
      var TxtR1="R="+R;
     }
     if(w1!==0){
      var TxtW1=W1;
     }
     if(w2!==0){
      var TxtW2=W2;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(h1!==0){
      var TxtH1=H1;
     }
    //  if(x0!==0){          
    //  var TxtX=X;
    //  }
    //  if(y0!==0){          
    //   var TxtY=Y;
    //  }

  if (area == "A") {
    
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    var markers = {
      myFsAMakerW1A,
      myFsAMakerW2A,
      myFsAMakerHA,
      myFsAMakerH1A,
      myFsAMakerR1A,
      myFsAMakerDA,
    }

  } else if (area == "C") {
    
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    var markers = {
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
    }
  } else if (area == "G") {
   
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+markShiftThree], [xg+w2,yg+h+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1,[xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    var markers = {
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
    }

  } else if (area == "I") {
    
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
    var markers = {
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,
    }

  } else if (area == "AC") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
   
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
    }

  } else if (area == "AG") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
 
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
   
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya], [xa - markShiftFour, ya - h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg + h], [xg - markShiftFour, yg]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
   
    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */

    }
  } else if (area == "AI") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
   
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
  
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "CG") {

    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    /******************************************************************** */
    var markers = {
      
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
    }
  } else if (area == "CI") {
 
    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
  
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
      
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "GI") {
  
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
       
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "ACG") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
   
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */

    }
  } else if (area == "ACI") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
   
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/


    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "AGI") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
  
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "CGI") {
 
  
    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }
  } else if (area == "ACGI") {
    var myFsAMakerW1A = {
      paths: {
        "W1ALine0": new makerjs.paths.Line([xa+w1,ya], [xa+w1,ya-h1-d-markShiftThree]),
        "W1ALine1": new makerjs.paths.Line([xa,ya-h-d-markShift], [xa+w1,ya-h-d-markShift]),//左上角
        // "W1ALine2": new makerjs.paths.Line([xa + w1, ya - h1], [xa + w1, ya - h1 - d - markShiftThree]),//左上角
      }
    };
    var myFsAMakerW2A = {
      paths: {
        "W2ALine0": new makerjs.paths.Line([xa+w2,ya], [xa+w2,ya-h-markShiftFour]),
        "W2ALine1": new makerjs.paths.Line([xa,ya-h-markShiftTwo], [xa+w2,ya-h-markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHA = {

      paths: {
        "HALine0": new makerjs.paths.Line([xa, ya], [xa - markShiftFour, ya]),
        "HALine1": new makerjs.paths.Line([xa,ya-h], [xa-markShiftFour, ya-h]),//左上角
        "HALine2": new makerjs.paths.Line([xa - markShiftTwo, ya], [xa - markShiftTwo, ya - h]),//左上角
      }
    };
    var myFsAMakerH1A = {
      paths: {
        "H1ALine0": new makerjs.paths.Line([xa + w1 - r, ya - h1], [xa + w2 + r + markShiftFour, ya - h1]),
        "H1ALine1": new makerjs.paths.Line([xa+w1+markShiftTwo,ya], [xa+w1+markShiftTwo,ya-h1]),//左上角
      }
    };
    var myFsAMakerR1A = {
      paths: {
        "R1ALine0": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w2,ya+r1-h]),//左上角
        "R1ALine1": new makerjs.paths.Line([xa+w,ya+r1-h], [xa+w+(d/2),ya-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDA = {
      paths: {
        "DALine1": new makerjs.paths.Line([xa+w1,ya-h1], [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************CCCCC */
    var myFsAMakerW1C = {
      paths: {
        "W1CLine0": new makerjs.paths.Line([xc-w1,yc], [xc-w1,yc-h1-d-markShiftThree]),
        "W1CLine1": new makerjs.paths.Line([xc,yc-h-d-markShift], [xc-w1,yc-h-d-markShift]),//左上角
      }
    };
    var myFsAMakerW2C = {
      paths: {
        "W2CLine0": new makerjs.paths.Line([xc,yc-h-markShiftTwo], [xc-w2,yc-h-markShiftTwo]),
        "W2CLine1": new makerjs.paths.Line([xc-w2,yc], [xc-w2,yc-h1-markShiftThree]),//左上角
      }
    };
    var myFsAMakerHC = {
      paths: {
        "HCLine0": new makerjs.paths.Line([xc, yc], [xc + markShiftFour, yc]),
        "HCLine1": new makerjs.paths.Line([xc, yc - h], [xc + markShiftFour, yc - h]),//左上角
        "HCLine2": new makerjs.paths.Line([xc + markShiftTwo, yc], [xc + markShiftTwo, yc - h]),//左上角
      }
    };
    var myFsAMakerH1C = {
      paths: {
        "H1CLine0": new makerjs.paths.Line([xc-w1+r,yc-h1], [xc-w2-r-markShiftFour, yc-h1]),
        "H1CLine1": new makerjs.paths.Line([xc-w1-markShiftTwo,yc], [xc-w1-markShiftTwo,yc-h1]),//左上角
      }
    };
    var myFsAMakerR1C = {
      paths: {
      "R1CLine0": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w2,yc+r1-h]),//左上角
      "R1CLine1": new makerjs.paths.Line([xc-w,yc+r1-h], [xc-w-(d/2),yc-h+r1-s]),//左上角
      }
    };
    var myFsAMakerDC = {
      paths: {
        "DCLine1": new makerjs.paths.Line([xc-w1,yc-h1], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]),//左上角
      }
    };
    /*******************************GGGGGG */
    var myFsAMakerW1G = {
      paths: {
        "W1GLine0": new makerjs.paths.Line([xg+w1,yg], [xg+w1,yg+h1+d+markShiftThree]),
        "W1GLine1": new makerjs.paths.Line([xg,yg+h+d+markShift], [xg+w1,yg+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2G = {
      paths: {
        "W2GLine0": new makerjs.paths.Line([xg+w2,yg], [xg+w2,yg+h1+markShiftThree]),
        "W2GLine1": new makerjs.paths.Line([xg,yg+h+markShiftTwo], [xg+w2,yg+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHG = {
      paths: {
        "HGLine0": new makerjs.paths.Line([xg, yg], [xg - markShiftFour, yg]),
        "HGLine1": new makerjs.paths.Line([xg, yg + h], [xg - markShiftFour, yg + h]),//左上角
        "HGLine2": new makerjs.paths.Line([xg - markShiftTwo, yg], [xg - markShiftTwo, yg + h]),//左上角
      }
    };
    var myFsAMakerH1G = {
      paths: {
        "H1GLine0": new makerjs.paths.Line([xg+w1-r,yg+h1], [xg+w2+r+markShiftFour, yg+h1]),
        "H1GLine1": new makerjs.paths.Line([xg+w1+markShiftTwo,yg], [xg+w1+markShiftTwo,yg+h1]),//左上角
      }
    };

    var myFsAMakerR1G = {
      paths: {
      "R1GLine0": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w2,yg-r1+h]),//左上角
      "R1GLine1": new makerjs.paths.Line([xg+w,yg-r1+h], [xg+w+(d/2),yg+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDG = {
      paths: {
        "DGLine1": new makerjs.paths.Line([xg+w1,yg+h1], [xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    }; 
    /*******************************IIIIIIIIIIIII */
    var myFsAMakerW1I = {
      paths: {
        "W1ILine0": new makerjs.paths.Line([xi-w1,yi], [xi-w1,yi+h1+d+markShiftThree]),
        "W1ILine1": new makerjs.paths.Line([xi,yi+h+d+markShift], [xi-w1,yi+h+d+markShift]),//左上角
      }
    };
    var myFsAMakerW2I = {
      paths: {
        "W2ILine0": new makerjs.paths.Line([xi-w2,yi], [xi-w2,yi+h1+markShiftThree]),
        "W2ILine1": new makerjs.paths.Line([xi,yi+h+markShiftTwo], [xi-w2,yi+h+markShiftTwo]),//左上角
      }
    };
    var myFsAMakerHI = {
      paths: {
        "HILine0": new makerjs.paths.Line([xi, yi], [xi + markShiftFour, yi]),
        "HILine1": new makerjs.paths.Line([xi, yi + h], [xi + markShiftFour, yi + h]),//左上角
        "HILine2": new makerjs.paths.Line([xi + markShiftTwo, yi], [xi + markShiftTwo, yi + h]),//左上角
      }
    };
    var myFsAMakerH1I = {
      paths: {
        "H1ILine0": new makerjs.paths.Line([xi-w1+r,yi +h1], [xi-w2-r-markShiftFour, yi +h1]),
        "H1ILine1": new makerjs.paths.Line([xi-w1-markShiftTwo,yi], [xi-w1-markShiftTwo,yi +h1]),//左上角
      }
    };

    var myFsAMakerR1I = {
      paths: {
        "R1ILine0": new makerjs.paths.Line([xi-w,yi -r1+h], [xi-w2,yi-r1+h]),//左上角
        "R1ILine1": new makerjs.paths.Line([xi-w,yi-r1+h], [xi-w-(d/2),yi+h-r1+s]),//左上角
      }
    };
    var myFsAMakerDI = {
      paths: {
        "DILine1": new makerjs.paths.Line([xi-w1,yi+h1], [xi-w1-markShift-markHolesOneD,yi+h1+d+markShiftFour+markHolesOneD]),//左上角
      }
    };  
    /********************************************** */
    /**********************************************AAAAAAAAAAAAAAA */
   
    makerjs.model.addCaption(myFsAMakerW1A, TxtW1, [xa,ya-h-d-markShiftThree], [xa+w1,ya-h-d-markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2A, TxtW2, [xa,ya-h-r-markShiftThree], [xa+w2,ya-h-r-markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHA, TxtH, [xa - markShiftFour, ya - h], [xa - markShiftFour, ya]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1A, TxtH1, [xa+w1+markShiftFour,ya-h1], [xa+w1+markShiftFour,ya]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1A, TxtR1,  [xa+w+markShiftTwo,ya+r1-h+markShift], [xa+w+markShiftFour,ya+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDA, TxtD, [xa+w1+markShift+markHolesOneD,ya-h1-d-markShiftFour-markHolesOneD],[xa+w1+markHolesOneD+markShiftFour,ya-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
    
    /***************************************CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC*/
   
    makerjs.model.addCaption(myFsAMakerW1C, TxtW1, [xc - w1, yc - h - d - markShiftThree], [xc, yc - h - d - markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2C, TxtW2,  [xc-w2,yc-h-r-markShiftThree], [xc,yc-h-r-markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHC, TxtH, [xc + markShiftFour, yc - h], [xc + markShiftFour, yc]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1C, TxtH1, [xc-w1-markShiftFour,yc-h1], [xc-w1-markShiftFour,yc]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1C, TxtR1, [xc-w-markShiftFour,yc+r1-h+markShift],[xc-w-markShiftTwo,yc+r1-h+markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDC, TxtD, [xc-w1-markHolesOneD-markShiftFour,yc-h1-d-markShiftFour-markHolesOneD], [xc-w1-markShift-markHolesOneD,yc-h1-d-markShiftFour-markHolesOneD]);//D文字标注  
      

    /***************************************GGGGGGGGGGGGGGGGGGGGGGGGGGGGGG*/

   
    makerjs.model.addCaption(myFsAMakerW1G, TxtW1, [xg,yg+h+d+markShiftThree], [xg+w1,yg+h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2G, TxtW2, [xg,yg+h+r+markShiftThree], [xg+w2,yg+h+r+markShiftThree]);//W2文字标注
    makerjs.model.addCaption(myFsAMakerHG, TxtH, [xg - markShiftFour, yg], [xg - markShiftFour, yg + h]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1G, TxtH1, [xg+w1+markShiftFour,yg], [xg+w1+markShiftFour,yg+h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1G, TxtR1, [xg+w+markShiftTwo,yg-r1+h-markShift], [xg+w+markShiftFour,yg-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDG, TxtD,[xg+w1+markShift+markHolesOneD,yg+h1+d+markShiftFour+markHolesOneD],[xg+w1+markHolesOneD+markShiftFour,yg+h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   

    /***************************************IIIIIIIIIIIIIIIIIIIIIIIIIIIII*/
   
    makerjs.model.addCaption(myFsAMakerW1I, TxtW1, [xi-w1,yi + h+d+markShiftThree], [xi,yi + h+d+markShiftThree]);//W1文字标注 
    makerjs.model.addCaption(myFsAMakerW2I, TxtW2,[xi-w2,yi + h+r+markShiftThree], [xi,yi + h+r+markShiftThree]);//W1文字标注
    makerjs.model.addCaption(myFsAMakerHI, TxtH, [xi + markShiftFour, yi + h], [xi + markShiftFour, yi]);//H文字标注
    makerjs.model.addCaption(myFsAMakerH1I, TxtH1, [xi-w1-markShiftFour,yi], [xi-w1-markShiftFour,yi + h1]);//H1文字标注
    makerjs.model.addCaption(myFsAMakerR1I, TxtR1, [xi-w-markShiftFour,yi -r1+h-markShift],[xi-w-markShiftTwo,yi-r1+h-markShift]);//R1文字标注
    makerjs.model.addCaption(myFsAMakerDI, TxtD,[xi-w1-markHolesOneD-markShiftFour,yi + h1+d+markShiftFour+markHolesOneD], [xi-w1-markShift-markHolesOneD,yi + h1+d+markShiftFour+markHolesOneD]);//D文字标注  
   
    /******************************************************************** */
    var markers = {
        myFsAMakerW1A,
        myFsAMakerW2A,
        myFsAMakerHA,
        myFsAMakerH1A,
        myFsAMakerR1A,
        myFsAMakerDA,
      
      /**C */
      myFsAMakerW1C,
      myFsAMakerW2C,
      myFsAMakerHC,
      myFsAMakerH1C,
      myFsAMakerR1C,
      myFsAMakerDC,
      /**G */
      myFsAMakerW1G,
      myFsAMakerW2G,
      myFsAMakerHG,
      myFsAMakerH1G,
      myFsAMakerR1G,
      myFsAMakerDG,
      /**I */
      myFsAMakerW1I,
      myFsAMakerW2I,
      myFsAMakerHI,
      myFsAMakerH1I,
      myFsAMakerR1I,
      myFsAMakerDI,

    }


    // var myFilletMark = new makerjs.models.ConnectTheDots(true, [[xghx, xghy], [xahx, xahy], [xawx, xawy], [xcwx, xcwy], [xchx, xchy],[xihx, xihy], [xiwx, xiwy], [xgwx, xgwy]]);
  }
  return { markers };
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function FloorSpring3(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
    var D=inputParam.D,R=inputParam.R,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
    plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,


     
      w1 = W1 * 1 * myZoom,
      w2 = W2 * 1 * myZoom,
      h = H * 1 * myZoom,
      h1 = H1 * 1 * myZoom,
      r1 = R * 1 * myZoom,
      d = D * 1 * myZoom,
      r = d / 2,
      a = 0,//圆心坐标[a,b]
      b = 0;//圆心坐标[a,b]  
    x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;


    if (d !== 0) {
      var TxtD = "∮" + D;
    }
    if (r1 !== 0) {
      var TxtR1 = "R=" + R;
    }
    if (w1 !== 0) {
      var TxtW1 = W1;
    }
    if (w2 !== 0) {
      var TxtW2 = W2;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (h1 !== 0) {
      var TxtH1 = H1;
    }
  } else {
    var D=inputParam.D,R=inputParam.R,W1=inputParam.W1,W2=inputParam.W2,H=inputParam.H,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
    plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,frame = outParam.shapeName,
      glassW = outParam.glassW, glassH = outParam.glassH,
      markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
      markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

      w = W * 1 * myZoom,
      w1 = W1 * 1 * myZoom,
      w2 = W2 * 1 * myZoom,
      h = H * 1 * myZoom,
      h1 = H1 * 1 * myZoom,
      r1 = R * 1 * myZoom,
      d = D * 1 * myZoom,
      r = d / 2,
      a = 0,//圆心坐标[a,b]
      b = 0,//圆心坐标[a,b]  
      x0 = X * 1 * myZoom,
      y0 = Y * 1 * myZoom;


    if (d !== 0) {
      var TxtD = "∮" + D;
    }
    if (r1 !== 0) {
      var TxtR1 = "R=" + R;
    }
    if (w !== 0) {
      var TxtW = W;
    }
    if (w1 !== 0) {
      var TxtW1 = W1;
    }
    if (w2 !== 0) {
      var TxtW2 = W2;
    }
    if (h !== 0) {
      var TxtH = H;
    }
    if (h1 !== 0) {
      var TxtH1 = H1;
    }
    if (x0 !== 0) {
      var TxtX = X;
    }
    if (y0 !== 0) {
      var TxtY = Y;
    }
  }
  if (frame == "Rectangle") {
    console.log("frame==undefined||||*****")

    //在A区域 计算孔的位置       
    var xa = x0, ya = glassH * 1 - y0,
      //C区域
      xc = glassW * 1 - x0, yc = glassH * 1 - y0,
      //在G区域 计算孔的位置
      xg = x0, yg = y0,
      //在I区域 计算孔的位置
      xi = glassW * 1 - x0, yi = y0;

      var d=Math.sqrt((r1*r1)-(r1-h)*(r1-h));
      var w=w2*1-d*1;
      console.log("测试相关点距离D",d);
    var s=Math.sqrt((r1*r1)-(d/2)*(d/2));
    console.log("测试相关点距离S",s);
      
      var xawx1 = xa, xawy1 = ya - h,//A位置角1坐标
      xawx2 = xa + w, xawy2 = ya - h,//A位置角2坐标
      xawx3 = xa + w2, xawy3 = ya,//A位置角3坐标

      xcwx1 = xc, xcwy1 = yc - h,//C位置角1坐标
      xcwx2 = xc - w, xcwy2 = yc - h,//C位置角2坐标
      xcwx3 = xc - w2, xcwy3 = yc,//C位置角3坐标
   

      xgwx1 = xg, xgwy1 = yg + h,//G位置角1坐标
      xgwx2 = xg + w, xgwy2 = yg + h,//G位置角2坐标
      xgwx3 = xg + w2, xgwy3 = yg,//G位置角3坐标

   

      xiwx1 = xi, xiwy1 = yi + h,//I位置角1坐标
      xiwx2 = xi - w, xiwy2 = yi + h,//I位置角2坐标
      xiwx3 = xi - w2, xiwy3 = yi;//I位置角3坐标
     var OffsetParam={};
  } else if (frame == "IsoscelesTrapezoid") {
    
  } else if (frame == "RightAngledTrapezoid") {
   
  } else if (frame == "OtherTrapezoidsA") {
    
  } else if (frame == "OtherTrapezoidsB") {
   
  } else if (frame == "ParallelogramA") {
   

  } else if (frame == "ParallelogramB") {

  }

  // 坐标参数
  var paramCoordinate = { s,d, w, w1, w2, h, h1, r, r1, xa, ya, xawx1, xawy1, xawx2, xawy2, xawx3, xawy3, xc, yc, xcwx1, xcwy1, xcwx2, xcwy2, xcwx3, xcwy3, xg, yg, xgwx1, xgwy1, xgwx2, xgwy2, xgwx3, xgwy3, xi, yi, xiwx1, xiwy1, xiwx2, xiwy2, xiwx3, xiwy3 };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//角7、地弹簧3 ------", allKeys)
  if (allKeys[0] == frame) {
    allKeys.shift();//去掉数组第一个元素
  }
  console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
  if (allKeys.length == 0) {
    switch (area) {
      case "1": areaPoint = "A"; break;
      case "3": areaPoint = "C"; break;
      case "7": areaPoint = "G"; break;
      case "9": areaPoint = "I"; break;
    }
  } else {
    switch (area) {
      case "1": areaPoint = getStrs("A", allKeys); break;
      case "3": areaPoint = getStrs("C", allKeys); break;
      case "7": areaPoint = getStrs("G", allKeys); break;
      case "9": areaPoint = getStrs("I", allKeys); break;
    }
  }
  /**
  * 画图
  * 
  */
  var ObjName = "myFloorSpring3" + areaPoint;
  var ObjNameM = "myFloorSpring3" + areaPoint + "Mark";//外框标记
  var ObjNameMFloorSpring3 = "myFloorSpring3" + areaPoint + "Mark";//角标记
  var ObjNameO = "myFloorSpring3" + areaPoint + "Obj";
  ObjName = creatFloorSpring3(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMFloorSpring3 = creatFloorSpring3Mark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMFloorSpring3===|||||||||角标注", ObjNameMFloorSpring3);
  ObjNameO = { ObjName:ObjName[0] };
  ObjNameM = RectangleMark(outParam, model, fixedParam, zoom, frame);//外框标记
  cutPlate.models = {};
  plate.models = {};
  markers.models = {};
  cutPlate.models["myFloorSpring3" + areaPoint] = ObjName[1];
  plate.models["myFloorSpring3" + areaPoint] = ObjName[0];
  console.log("ObjName.myFloorSpring3Cut===|||||||||是否有所有标注", ObjName.myFloorSpring3Cut);
  console.log("ObjName.myFloorSpring3===|||||||||是否有所有标注", ObjName.myFloorSpring3);
  console.log("ObjNameO===|||||||||是否有所有标注", ObjNameO);
  console.log("ObjNameM.markers.models===|||||||||是否有所有标注", ObjNameM.markers.models);
  console.log("ObjNameMCorner.markers===|||||||||是否有所有标注", ObjNameMFloorSpring3.markers);
  console.log("ObjName.myFloorSpring3===|||||||||是否有所有标注", ObjName.myFloorSpring3);
  markers.models = { ...ObjNameM.markers.models, ...ObjNameO, ...ObjNameMFloorSpring3.markers };
  console.log("markers===|||||||||是否有所有标注", markers);


myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};
export default FloorSpring3;