//9、相对吊轮孔
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RelSuWheelHole(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
 //画孔 A位置孔
      //标记相对吊轮孔 A位置孔左
      var myRswhHolesALeft= new makerjs.models.Holes(d/2, [[xa, ya]]);
      var myRswhHolesMarkALeft = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkALeft.origin = [xa, ya];      
      var myRswhHolesXMarkALeft = new makerjs.models.Square(0);      
      myRswhHolesXMarkALeft.origin =[xa, ya];      
      var myRswhHolesYMarkALeft = new makerjs.models.Square(0);      
      myRswhHolesYMarkALeft.origin = [xa, ya];  
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkALeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkALeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkALeft, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkALeft, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myRswhHolesYMarkALeft, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
makerjs.model.addCaption(myRswhHolesYMarkALeft, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
/*****************************************************************************/ 
 //画孔 A位置孔
      //标记相对吊轮孔 A位置孔中
      var myRswhHolesAMid= new makerjs.models.Holes(d/2, [[xa, ya-w]]);
      var myRswhHolesMarkAMid = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkAMid.origin = [xa, ya-w];      
      var myRswhHolesXMarkAMid = new makerjs.models.Square(0);      
      myRswhHolesXMarkAMid.origin =[xa, ya-w];   
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkAMid, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkAMid, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkAMid, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkAMid, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkAMid, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, w]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkAMid, TxtW, [-x0-markShiftThree,0], [-x0-markShiftThree, w]);//X文字标注
/*****************************************************************************/ 
 //画孔 A位置孔
      //标记双固定孔 A位置孔右
      var myRswhHolesARight= new makerjs.models.Holes(d/2, [[xa, ya-w*2]]);
      //标记孔
      var myRswhHolesMarkARight = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkARight.origin = [xa, ya-w*2];      
      var myRswhHolesXMarkARight = new makerjs.models.Square(0);      
      myRswhHolesXMarkARight.origin =[xa, ya-w*2]; 
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkARight, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkARight, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkARight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkARight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkARight, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, w]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkARight, TxtW,[-x0-markShiftThree,0], [-x0-markShiftThree, w]);//X文字标注
/*****************************************************************************/
//G位置
//画孔 G位置孔
      //标记相对吊轮孔G位置孔右
      var myRswhHolesGRight= new makerjs.models.Holes(d/2, [[xg, yg]]);
      var myRswhHolesMarkGRight = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkGRight.origin = [xg, yg];      
      var myRswhHolesXMarkGRight = new makerjs.models.Square(0);      
      myRswhHolesXMarkGRight.origin =[xg, yg];      
      var myRswhHolesYMarkGRight = new makerjs.models.Square(0);      
      myRswhHolesYMarkGRight.origin = [xg, yg]; 
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkGRight, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftTwo]));//Y轴加长标线
makerjs.model.addPath(myRswhHolesMarkGRight, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkGRight, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkGRight, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftThree, -y0]));//X轴加长标线
makerjs.model.addPath(myRswhHolesMarkGRight, new makerjs.paths.Line([0,0], [0, -y0-markShiftTwo]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkGRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkGRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkGRight, new makerjs.paths.Line([0,-y0-markShift], [-x0, -y0-markShift]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkGRight, TxtX, [-x0,-y0-markShiftTwo], [0, -y0-markShiftTwo]);//X文字标注
/*****************************************************************************/     
makerjs.model.addPath(myRswhHolesYMarkGRight, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
makerjs.model.addCaption(myRswhHolesYMarkGRight, TxtY, [-x0-markShiftThree, -y0], [-x0-markShiftThree,0]);//Y文字标注
/*****************************************************************************/ 
 //画孔 G位置孔
      //标记相对吊轮孔 G位置孔中
      var myRswhHolesGMid= new makerjs.models.Holes(d/2, [[xg, yg+w]]);
      var myRswhHolesMarkGMid = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkGMid.origin = [xg, yg+w];      
      var myRswhHolesXMarkGMid = new makerjs.models.Square(0);      
      myRswhHolesXMarkGMid.origin =[xg, yg+w];   
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkGMid, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
makerjs.model.addPath(myRswhHolesMarkGMid, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkGMid, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkGMid, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkGMid, new makerjs.paths.Line([-x0-markShiftTwo, -w],[-x0-markShiftTwo,0]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkGMid, TxtW, [-x0-markShiftThree, -w], [-x0-markShiftThree,0]);//X文字标注
/*****************************************************************************/     
/*****************************************************************************/ 
/*****************************************************************************/ 
 //画孔 G位置孔
      //标记双固定孔 G位置孔左
      var myRswhHolesGLeft= new makerjs.models.Holes(d/2, [[xg, yg+w*2]]);
      //标记孔
      var myRswhHolesMarkGLeft = new makerjs.models.Square(0); //孔的原点       
      myRswhHolesMarkGLeft.origin = [xg, yg+w*2];      
      var myRswhHolesXMarkGLeft = new makerjs.models.Square(0);      
      myRswhHolesXMarkGLeft.origin =[xg, yg+w*2]; 
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkGLeft, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发Y方向竖向标线
/*****************************************************************************/ 
makerjs.model.addPath(myRswhHolesMarkGLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
makerjs.model.addCaption(myRswhHolesMarkGLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
/*****************************************************************************/   
makerjs.model.addPath(myRswhHolesXMarkGLeft, new makerjs.paths.Line([-x0-markShiftTwo,-w], [-x0-markShiftTwo, 0]));//X短标线
makerjs.model.addCaption(myRswhHolesXMarkGLeft, TxtW, [-x0-markShiftThree,-w], [-x0-markShiftThree, 0]);//X文字标注
/*****************************************************************************/ 
//*****************************A位置 */
plate.models["myRswhHolesALeft"+myHolesNum]=myRswhHolesALeft;
cutPlate.models["myRswhHolesALeft"+myHolesNum]=myRswhHolesALeft;
markers.models["myRswhHolesALeft"+myHolesNum]=myRswhHolesALeft;
markers.models["myRswhHolesMarkALeft"+myHolesNum]=myRswhHolesMarkALeft;
markers.models["myRswhHolesXMarkALeft"+myHolesNum]=myRswhHolesXMarkALeft;
markers.models["myRswhHolesYMarkALeft"+myHolesNum]=myRswhHolesYMarkALeft;
/*****************************************************************************/
plate.models["myRswhHolesAMid"+myHolesNum]=myRswhHolesAMid;
cutPlate.models["myRswhHolesAMid"+myHolesNum]=myRswhHolesAMid;
markers.models["myRswhHolesAMid"+myHolesNum]=myRswhHolesAMid;
markers.models["myRswhHolesMarkAMid"+myHolesNum]=myRswhHolesMarkAMid;
markers.models["myRswhHolesXMarkAMid"+myHolesNum]=myRswhHolesXMarkAMid;
/*****************************************************************************/ 
plate.models["myRswhHolesARight"+myHolesNum]=myRswhHolesARight;
cutPlate.models["myRswhHolesARight"+myHolesNum]=myRswhHolesARight;
markers.models["myRswhHolesARight"+myHolesNum]=myRswhHolesARight;
markers.models["myRswhHolesMarkARight"+myHolesNum]=myRswhHolesMarkARight;
markers.models["myRswhHolesXMarkARight"+myHolesNum]=myRswhHolesXMarkARight;
/*****************************************************************************/
//*****************************G位置 */
plate.models["myRswhHolesGRight"+myHolesNum]=myRswhHolesGRight;
cutPlate.models["myRswhHolesGRight"+myHolesNum]=myRswhHolesGRight;
markers.models["myRswhHolesGRight"+myHolesNum]=myRswhHolesGRight;
markers.models["myRswhHolesMarkGRight"+myHolesNum]=myRswhHolesMarkGRight;
markers.models["myRswhHolesXMarkGRight"+myHolesNum]=myRswhHolesXMarkGRight;
markers.models["myRswhHolesYMarkGRight"+myHolesNum]=myRswhHolesYMarkGRight;
/*****************************************************************************/
plate.models["myRswhHolesGMid"+myHolesNum]=myRswhHolesGMid;
cutPlate.models["myRswhHolesGMid"+myHolesNum]=myRswhHolesGMid;
markers.models["myRswhHolesGMid"+myHolesNum]=myRswhHolesGMid;
markers.models["myRswhHolesMarkGMid"+myHolesNum]=myRswhHolesMarkGMid;
markers.models["myRswhHolesXMarkGMid"+myHolesNum]=myRswhHolesXMarkGMid;
/*****************************************************************************/ 
plate.models["myRswhHolesGLeft"+myHolesNum]=myRswhHolesGLeft;
cutPlate.models["myRswhHolesGLeft"+myHolesNum]=myRswhHolesGLeft;
markers.models["myRswhHolesGLeft"+myHolesNum]=myRswhHolesGLeft;
markers.models["myRswhHolesMarkGLeft"+myHolesNum]=myRswhHolesMarkGLeft;
markers.models["myRswhHolesXMarkGLeft"+myHolesNum]=myRswhHolesXMarkGLeft;
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default RelSuWheelHole;