//8、双固定孔
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function DoubleFixHoles(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom; 
        /*
        d = D*1,
        w= W*1,
          x0 = X*1,
          y0 = Y*1;
          */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记双固定孔 A位置孔左
          var myDfhHolesALeft= new makerjs.models.Holes(d/2, [[xa, ya]]);
          //标记孔
          var myDfhHolesMarkALeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkALeft.origin = [xa, ya];      
          var myDfhHolesXMarkALeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkALeft.origin =[xa, ya];      
          var myDfhHolesYMarkALeft = new makerjs.models.Square(0);      
          myDfhHolesYMarkALeft.origin = [xa, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkALeft, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesMarkALeft, new makerjs.paths.Line([0,0], [0, -markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkALeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkALeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkALeft, new makerjs.paths.Line([0,0], [-x0, 0]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkALeft, TxtX, [-x0,-markShiftTwo], [0, -markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesYMarkALeft, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesYMarkALeft, TxtY, [-markShiftTwo,0], [-markShiftTwo, y0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 A位置孔
          //标记双固定孔 A位置孔右
          var myDfhHolesARight= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          //标记孔
          var myDfhHolesMarkARight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkARight.origin = [xa+w, ya];      
          var myDfhHolesXMarkARight = new makerjs.models.Square(0);      
          myDfhHolesXMarkARight.origin =[xa+w, ya]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkARight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkARight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkARight, new makerjs.paths.Line([0,0], [-w, 0]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkARight, TxtW, [-w,+markShiftTwo], [0, +markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesALeft"+myHolesNum]=myDfhHolesALeft;
    cutPlate.models["myDfhHolesALeft"+myHolesNum]=myDfhHolesALeft;
    markers.models["myDfhHolesALeft"+myHolesNum]=myDfhHolesALeft;
    markers.models["myDfhHolesMarkALeft"+myHolesNum]=myDfhHolesMarkALeft;
    markers.models["myDfhHolesXMarkALeft"+myHolesNum]=myDfhHolesXMarkALeft;
    markers.models["myDfhHolesYMarkALeft"+myHolesNum]=myDfhHolesYMarkALeft;
    /*****************************************************************************/
    plate.models["myDfhHolesARight"+myHolesNum]=myDfhHolesARight;
    cutPlate.models["myDfhHolesARight"+myHolesNum]=myDfhHolesARight;
    markers.models["myDfhHolesARight"+myHolesNum]=myDfhHolesARight;
    markers.models["myDfhHolesMarkARight"+myHolesNum]=myDfhHolesMarkARight;
    markers.models["myDfhHolesXMarkARight"+myHolesNum]=myDfhHolesXMarkARight;
    /*****************************************************************************/ 
   
    }else if(area==2){
    //画孔 B位置孔
          //标记双固定孔 B位置孔左
          var myDfhHolesBLeft= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
          //标记孔
          var myDfhHolesMarkBLeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkBLeft.origin = [xb-w/2, yb];      
          var myDfhHolesXMarkBLeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkBLeft.origin =[xb-w/2, yb];      
          var myDfhHolesYMarkBLeft = new makerjs.models.Square(0);      
          myDfhHolesYMarkBLeft.origin = [xb-w/2, yb];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkBLeft, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkBLeft, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(myDfhHolesMarkBLeft, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkBLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkBLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/      
    makerjs.model.addPath(myDfhHolesYMarkBLeft, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesYMarkBLeft, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 A位置孔
          //标记双固定孔 A位置孔右
          var myDfhHolesBRight= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
          //标记孔
          var myDfhHolesMarkBRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkBRight.origin = [xb+w/2, yb];      
          var myDfhHolesXMarkBRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkBRight.origin =[xb+w/2, yb]; 
    /*****************************************************************************/ 
   makerjs.model.addPath(myDfhHolesMarkBRight, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkBRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkBRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkBRight, new makerjs.paths.Line([0,+markShift], [-w, +markShift]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkBRight, TxtW, [-w,+markShiftTwo], [0, +markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesBLeft"+myHolesNum]=myDfhHolesBLeft;
    cutPlate.models["myDfhHolesBLeft"+myHolesNum]=myDfhHolesBLeft;
    markers.models["myDfhHolesBLeft"+myHolesNum]=myDfhHolesBLeft;
    markers.models["myDfhHolesMarkBLeft"+myHolesNum]=myDfhHolesMarkBLeft;
    markers.models["myDfhHolesXMarkBLeft"+myHolesNum]=myDfhHolesXMarkBLeft;
    markers.models["myDfhHolesYMarkBLeft"+myHolesNum]=myDfhHolesYMarkBLeft;
    /*****************************************************************************/
    plate.models["myDfhHolesBRight"+myHolesNum]=myDfhHolesBRight;
    cutPlate.models["myDfhHolesBRight"+myHolesNum]=myDfhHolesBRight;
    markers.models["myDfhHolesBRight"+myHolesNum]=myDfhHolesBRight;
    markers.models["myDfhHolesMarkBRight"+myHolesNum]=myDfhHolesMarkBRight;
    markers.models["myDfhHolesXMarkBRight"+myHolesNum]=myDfhHolesXMarkBRight;
    /*****************************************************************************/ 
  
    }else if(area==3){
    //画孔 C位置孔
          //标记双固定孔 C位置孔右
          var myDfhHolesCRight= new makerjs.models.Holes(d/2, [[xc, yc]]);
          //标记孔
          var myDfhHolesMarkCRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkCRight.origin = [xc, yc];      
          var myDfhHolesXMarkCRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkCRight.origin =[xc, yc];      
          var myDfhHolesYMarkCRight = new makerjs.models.Square(0);      
          myDfhHolesYMarkCRight.origin = [xc, yc];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkCRight, new makerjs.paths.Line([0,0], [0, +markShiftThree]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkCRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkCRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkCRight, new makerjs.paths.Line([0,0], [x0, 0]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkCRight, TxtX, [0, +markShiftTwo], [x0,+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesYMarkCRight, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesYMarkCRight, TxtY, [+markShiftThree,0], [+markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 C位置孔
          //标记双固定孔 C位置孔左
          var myDfhHolesCLeft= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
          //标记孔
          var myDfhHolesMarkCLeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkCLeft.origin = [xc-w, yc];      
          var myDfhHolesXMarkCLeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkCLeft.origin =[xc-w, yc]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkCLeft, new makerjs.paths.Line([0,0], [0, +markShiftThree]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkCLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkCLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkCLeft, new makerjs.paths.Line([0,+markShift], [w, +markShift]));//W短标线
    makerjs.model.addCaption(myDfhHolesXMarkCLeft, TxtW, [0, +markShiftTwo], [w,+markShiftTwo]);//W文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesCRight"+myHolesNum]=myDfhHolesCRight;
    cutPlate.models["myDfhHolesCRight"+myHolesNum]=myDfhHolesCRight;
    markers.models["myDfhHolesCRight"+myHolesNum]=myDfhHolesCRight;
    markers.models["myDfhHolesMarkCRight"+myHolesNum]=myDfhHolesMarkCRight;
    markers.models["myDfhHolesXMarkCRight"+myHolesNum]=myDfhHolesXMarkCRight;
    markers.models["myDfhHolesYMarkCRight"+myHolesNum]=myDfhHolesYMarkCRight;
    /*****************************************************************************/
    plate.models["myDfhHolesCLeft"+myHolesNum]=myDfhHolesCLeft;
    cutPlate.models["myDfhHolesCLeft"+myHolesNum]=myDfhHolesCLeft;
    markers.models["myDfhHolesCLeft"+myHolesNum]=myDfhHolesCLeft;
    markers.models["myDfhHolesMarkCLeft"+myHolesNum]=myDfhHolesMarkCLeft;
    markers.models["myDfhHolesXMarkCLeft"+myHolesNum]=myDfhHolesXMarkCLeft;
    /*****************************************************************************/ 
   
    }else if(area==4){
    //画孔 D位置孔
          //标记双固定孔 D位置孔左
          var myDfhHolesDLeft= new makerjs.models.Holes(d/2, [[xd, yd]]);
          //标记孔
          var myDfhHolesMarkDLeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkDLeft.origin = [xd, yd];      
          var myDfhHolesXMarkDLeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkDLeft.origin =[xd, yd];      
          var myDfhHolesYMarkDLeft = new makerjs.models.Square(0);      
          myDfhHolesYMarkDLeft.origin = [xd, yd];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkDLeft, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkDLeft, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkDLeft, new makerjs.paths.Line([-x0,ye], [-x0, ye]));//X轴加长标线
    makerjs.model.addPath(myDfhHolesMarkDLeft, new makerjs.paths.Line([0,0], [0, +markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkDLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkDLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkDLeft, TxtX, [-x0,+markShiftTwo], [0, +markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    /*****************************************************************************/ 
     //画孔 D位置孔
          //标记双固定孔 D位置孔右
          var myDfhHolesDRight= new makerjs.models.Holes(d/2, [[xd+w, yd]]);
          //标记孔
          var myDfhHolesMarkDRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkDRight.origin = [xd+w, yd];      
          var myDfhHolesXMarkDRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkDRight.origin =[xd+w, yd]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkDRight, new makerjs.paths.Line([0,0], [0, +markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkDRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkDRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkDRight, TxtW, [-w,+markShiftFour], [0, +markShiftFour]);//W文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesDLeft"+myHolesNum]=myDfhHolesDLeft;
    cutPlate.models["myDfhHolesDLeft"+myHolesNum]=myDfhHolesDLeft;
    markers.models["myDfhHolesDLeft"+myHolesNum]=myDfhHolesDLeft;
    markers.models["myDfhHolesMarkDLeft"+myHolesNum]=myDfhHolesMarkDLeft;
    markers.models["myDfhHolesXMarkDLeft"+myHolesNum]=myDfhHolesXMarkDLeft;
    markers.models["myDfhHolesYMarkDLeft"+myHolesNum]=myDfhHolesYMarkDLeft;
    /*****************************************************************************/
    plate.models["myDfhHolesDRight"+myHolesNum]=myDfhHolesDRight;
    cutPlate.models["myDfhHolesDRight"+myHolesNum]=myDfhHolesDRight;
    markers.models["myDfhHolesDRight"+myHolesNum]=myDfhHolesDRight;
    markers.models["myDfhHolesMarkDRight"+myHolesNum]=myDfhHolesMarkDRight;
    markers.models["myDfhHolesXMarkDRight"+myHolesNum]=myDfhHolesXMarkDRight;
    /*****************************************************************************/ 
   
    }else if(area==5){
    //画孔 E位置孔
          //标记双固定孔 E位置孔左
          var myDfhHolesELeft= new makerjs.models.Holes(d/2, [[xe-w/2, ye]]);
          //标记孔
          var myDfhHolesMarkELeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkELeft.origin = [xe-w/2, ye];      
          var myDfhHolesXMarkELeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkELeft.origin =[xe-w/2, ye];      
          var myDfhHolesYMarkELeft = new makerjs.models.Square(0);      
          myDfhHolesYMarkELeft.origin = [xe-w/2, ye];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkELeft, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkELeft, new makerjs.paths.Line([0,0], [0, +markShiftThree]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkELeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkELeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkELeft, new makerjs.paths.Line([0,ye+markShift], [-x0, ye+markShift]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkELeft, TxtX, [-x0,ye+markShiftTwo], [0, ye+markShiftTwo]);//X文字标注
    /*****************************************************************************/  
     //画孔 E位置孔
          //标记双固定孔 E位置孔右
          var myDfhHolesERight= new makerjs.models.Holes(d/2, [[xe+w/2, ye]]);
          //标记孔
          var myDfhHolesMarkERight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkERight.origin = [xe+w/2, ye];      
          var myDfhHolesXMarkERight = new makerjs.models.Square(0);      
          myDfhHolesXMarkERight.origin =[xe+w/2, ye]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkERight, new makerjs.paths.Line([0,0], [0, +markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkERight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkERight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkERight, TxtW, [-w,+markShiftTwo], [0, +markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesELeft"+myHolesNum]=myDfhHolesELeft;
    cutPlate.models["myDfhHolesELeft"+myHolesNum]=myDfhHolesELeft;
    markers.models["myDfhHolesELeft"+myHolesNum]=myDfhHolesELeft;
    markers.models["myDfhHolesMarkELeft"+myHolesNum]=myDfhHolesMarkELeft;
    markers.models["myDfhHolesXMarkELeft"+myHolesNum]=myDfhHolesXMarkELeft;
    markers.models["myDfhHolesYMarkELeft"+myHolesNum]=myDfhHolesYMarkELeft;
    /*****************************************************************************/
    plate.models["myDfhHolesERight"+myHolesNum]=myDfhHolesERight;
    cutPlate.models["myDfhHolesERight"+myHolesNum]=myDfhHolesERight;
    markers.models["myDfhHolesERight"+myHolesNum]=myDfhHolesERight;
    markers.models["myDfhHolesMarkERight"+myHolesNum]=myDfhHolesMarkERight;
    markers.models["myDfhHolesXMarkERight"+myHolesNum]=myDfhHolesXMarkERight;
    /*****************************************************************************/ 
    
    }else if(area==6){
    //画孔 F位置孔
          //标记双固定孔 F位置孔右
          var myDfhHolesFRight= new makerjs.models.Holes(d/2, [[xf, yf]]);
          //标记孔
          var myDfhHolesMarkFRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkFRight.origin = [xf, yf];      
          var myDfhHolesXMarkFRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkFRight.origin =[xf, yf];      
          var myDfhHolesYMarkFRight = new makerjs.models.Square(0);      
          myDfhHolesYMarkFRight.origin = [xf, yf];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkFRight, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkFRight, new makerjs.paths.Line([0,0], [0, +markShiftThree]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkFRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkFRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkFRight, TxtX, [0, +markShiftTwo], [x0,+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    /*****************************************************************************/ 
     //画孔 F位置孔
          //标记双固定孔 F位置孔左
          var myDfhHolesFLeft= new makerjs.models.Holes(d/2, [[xf-w, yf]]);
          //标记孔
          var myDfhHolesMarkFLeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkFLeft.origin = [xf-w, yf];      
          var myDfhHolesXMarkFLeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkFLeft.origin =[xf-w, yf]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkFLeft, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向竖向标线
    makerjs.model.addPath(myDfhHolesMarkFLeft, new makerjs.paths.Line([0,0], [0, +markShiftThree]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkFLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkFLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkFLeft, TxtW, [0, +markShiftTwo], [w,+markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesFRight"+myHolesNum]=myDfhHolesFRight;
    cutPlate.models["myDfhHolesFRight"+myHolesNum]=myDfhHolesFRight;
    markers.models["myDfhHolesFRight"+myHolesNum]=myDfhHolesFRight;
    markers.models["myDfhHolesMarkFRight"+myHolesNum]=myDfhHolesMarkFRight;
    markers.models["myDfhHolesXMarkFRight"+myHolesNum]=myDfhHolesXMarkFRight;
    markers.models["myDfhHolesYMarkFRight"+myHolesNum]=myDfhHolesYMarkFRight;
    /*****************************************************************************/
    plate.models["myDfhHolesFLeft"+myHolesNum]=myDfhHolesFLeft;
    cutPlate.models["myDfhHolesFLeft"+myHolesNum]=myDfhHolesFLeft;
    markers.models["myDfhHolesFLeft"+myHolesNum]=myDfhHolesFLeft;
    markers.models["myDfhHolesMarkFLeft"+myHolesNum]=myDfhHolesMarkFLeft;
    markers.models["myDfhHolesXMarkFLeft"+myHolesNum]=myDfhHolesXMarkFLeft;
    /*****************************************************************************/ 
   
    }else if(area==7){
    //画孔 G位置孔
          //标记双固定孔 G位置孔左
          var myDfhHolesGLeft= new makerjs.models.Holes(d/2, [[xg, yg]]);
          //标记孔
          var myDfhHolesMarkGLeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkGLeft.origin = [xg, yg];      
          var myDfhHolesXMarkGLeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkGLeft.origin =[xg, yg];      
          var myDfhHolesYMarkGLeft = new makerjs.models.Square(0);      
          myDfhHolesYMarkGLeft.origin = [xg, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkGLeft, new makerjs.paths.Line([0,0], [-x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkGLeft, new makerjs.paths.Line([0,0], [0, markShiftFour]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(myDfhHolesMarkGLeft, new makerjs.paths.Line([0,0], [0, -markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkGLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkGLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkGLeft, TxtX, [-x0,-markShiftTwo], [0, -markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesYMarkGLeft, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesYMarkGLeft, TxtY, [-markShift, -y0], [-markShift,0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 G位置孔
          //标记双固定孔 G位置孔右
          var myDfhHolesGRight= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
          //标记孔
          var myDfhHolesMarkGRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkGRight.origin = [xg+w, yg];      
          var myDfhHolesXMarkGRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkGRight.origin =[xg+w, yg]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkGRight, new makerjs.paths.Line([0,0], [0,markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkGRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkGRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkGRight, new makerjs.paths.Line([0,markShift], [-w, markShift]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkGRight, TxtW, [-w,markShiftThree], [0, markShiftThree]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesGLeft"+myHolesNum]=myDfhHolesGLeft;
    cutPlate.models["myDfhHolesGLeft"+myHolesNum]=myDfhHolesGLeft;
    markers.models["myDfhHolesGLeft"+myHolesNum]=myDfhHolesGLeft;
    markers.models["myDfhHolesMarkGLeft"+myHolesNum]=myDfhHolesMarkGLeft;
    markers.models["myDfhHolesXMarkGLeft"+myHolesNum]=myDfhHolesXMarkGLeft;
    markers.models["myDfhHolesYMarkGLeft"+myHolesNum]=myDfhHolesYMarkGLeft;
    /*****************************************************************************/
    plate.models["myDfhHolesGRight"+myHolesNum]=myDfhHolesGRight;
    cutPlate.models["myDfhHolesGRight"+myHolesNum]=myDfhHolesGRight;
    markers.models["myDfhHolesGRight"+myHolesNum]=myDfhHolesGRight;
    markers.models["myDfhHolesMarkGRight"+myHolesNum]=myDfhHolesMarkGRight;
    markers.models["myDfhHolesXMarkGRight"+myHolesNum]=myDfhHolesXMarkGRight;
    /*****************************************************************************/ 
   
    }else if(area==8){
    //画孔 H位置孔
          //标记双固定孔 H位置孔左
          var myDfhHolesHLeft= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
          //标记孔
          var myDfhHolesMarkHLeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkHLeft.origin = [xh-w/2, yh];      
          var myDfhHolesXMarkHLeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkHLeft.origin =[xh-w/2, yh];      
          var myDfhHolesYMarkHLeft = new makerjs.models.Square(0);      
          myDfhHolesYMarkHLeft.origin = [xh-w/2, yh];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkHLeft, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkHLeft, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线
    makerjs.model.addPath(myDfhHolesMarkHLeft, new makerjs.paths.Line([0,0], [0, d+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkHLeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkHLeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkHLeft, new makerjs.paths.Line([0,-y0-markShift], [-x0, -y0-markShift]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkHLeft, TxtX, [-x0,-y0-markShiftTwo], [0, -y0-markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesYMarkHLeft, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesYMarkHLeft, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 H位置孔
          //标记双固定孔 H位置孔右
          var myDfhHolesHRight= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
          //标记孔
          var myDfhHolesMarkHRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkHRight.origin = [xh+w/2, yh];      
          var myDfhHolesXMarkHRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkHRight.origin =[xh+w/2, yh]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkHRight, new makerjs.paths.Line([0,0], [0, d+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkHRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkHRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkHRight, new makerjs.paths.Line([0,d+markShift], [-w, d+markShift]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkHRight, TxtW, [-w,d+markShiftFour], [0, d+markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesHLeft"+myHolesNum]=myDfhHolesHLeft;
    cutPlate.models["myDfhHolesHLeft"+myHolesNum]=myDfhHolesHLeft;
    markers.models["myDfhHolesHLeft"+myHolesNum]=myDfhHolesHLeft;
    markers.models["myDfhHolesMarkHLeft"+myHolesNum]=myDfhHolesMarkHLeft;
    markers.models["myDfhHolesXMarkHLeft"+myHolesNum]=myDfhHolesXMarkHLeft;
    markers.models["myDfhHolesYMarkHLeft"+myHolesNum]=myDfhHolesYMarkHLeft;
    /*****************************************************************************/
    plate.models["myDfhHolesHRight"+myHolesNum]=myDfhHolesHRight;
    cutPlate.models["myDfhHolesHRight"+myHolesNum]=myDfhHolesHRight;
    markers.models["myDfhHolesHRight"+myHolesNum]=myDfhHolesHRight;
    markers.models["myDfhHolesMarkHRight"+myHolesNum]=myDfhHolesMarkHRight;
    markers.models["myDfhHolesXMarkHRight"+myHolesNum]=myDfhHolesXMarkHRight;
    /*****************************************************************************/ 
   
    }else if(area==9){
    //画孔 I位置孔
          //标记双固定孔 I位置孔右
          var myDfhHolesIRight= new makerjs.models.Holes(d/2, [[xi, yi]]);
          //标记孔
          var myDfhHolesMarkIRight = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkIRight.origin = [xi, yi];      
          var myDfhHolesXMarkIRight = new makerjs.models.Square(0);      
          myDfhHolesXMarkIRight.origin =[xi, yi];      
          var myDfhHolesYMarkIRight = new makerjs.models.Square(0);      
          myDfhHolesYMarkIRight.origin = [xi, yi];  
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkIRight, new makerjs.paths.Line([-markShiftFour,0], [x0, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(myDfhHolesMarkIRight, new makerjs.paths.Line([0,0], [0, markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkIRight, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkIRight, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addCaption(myDfhHolesXMarkIRight, TxtX, [0, -markShiftTwo], [x0,-markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(myDfhHolesYMarkIRight, new makerjs.paths.Line([-markShiftTwo,0], [-markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(myDfhHolesYMarkIRight, TxtY, [-markShiftFour, -y0], [-markShiftFour,0]);//Y文字标注
    /*****************************************************************************/ 
     //画孔 I位置孔
          //标记双固定孔 I位置孔左
          var myDfhHolesILeft= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
          //标记孔
          var myDfhHolesMarkILeft = new makerjs.models.Square(0); //孔的原点       
          myDfhHolesMarkILeft.origin = [xi-w, yi];      
          var myDfhHolesXMarkILeft = new makerjs.models.Square(0);      
          myDfhHolesXMarkILeft.origin =[xi-w, yi]; 
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkILeft, new makerjs.paths.Line([0,0], [0, markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(myDfhHolesMarkILeft, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(myDfhHolesMarkILeft, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(myDfhHolesXMarkILeft, new makerjs.paths.Line([0,markShiftTwo], [w, markShiftTwo]));//X短标线
    makerjs.model.addCaption(myDfhHolesXMarkILeft, TxtW, [0, markShiftFour], [w,markShiftFour]);//X文字标注
    /*****************************************************************************/  
    /*****************************************************************************/ 
    plate.models["myDfhHolesIRight"+myHolesNum]=myDfhHolesIRight;
    cutPlate.models["myDfhHolesIRight"+myHolesNum]=myDfhHolesIRight;
    markers.models["myDfhHolesIRight"+myHolesNum]=myDfhHolesIRight;
    markers.models["myDfhHolesMarkIRight"+myHolesNum]=myDfhHolesMarkIRight;
    markers.models["myDfhHolesXMarkIRight"+myHolesNum]=myDfhHolesXMarkIRight;
    markers.models["myDfhHolesYMarkIRight"+myHolesNum]=myDfhHolesYMarkIRight;
    /*****************************************************************************/
    plate.models["myDfhHolesILeft"+myHolesNum]=myDfhHolesILeft;
    cutPlate.models["myDfhHolesILeft"+myHolesNum]=myDfhHolesILeft;
    markers.models["myDfhHolesILeft"+myHolesNum]=myDfhHolesILeft;
    markers.models["myDfhHolesMarkILeft"+myHolesNum]=myDfhHolesMarkILeft;
    markers.models["myDfhHolesXMarkILeft"+myHolesNum]=myDfhHolesXMarkILeft;
    /*****************************************************************************/ 
   
    } 

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default DoubleFixHoles;