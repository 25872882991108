//缺6、铜夹
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyCopperClipGap(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xb = param.xb, 
  yb = param.yb, xc = param.xc, 
  yc = param.yc, xg = param.xg, yg = param.yg, 
  xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,  
  x = param.x0, r = param.r, rd = param.rd,w = param.w,h1 = param.h1, h = param.h,d = param.d; 
  
  if (area == "A") {
    var myCopperClipGap = {
      paths: {
            "LeftLine": new makerjs.paths.Line([xa, ya], [xa, ya-h]),
            "BottomLine": new makerjs.paths.Line([xa, ya-h], [xa+w, ya-h]),
            "RightLine": new makerjs.paths.Line([xa+w, ya-h], [xa+w, ya]),
            "Holes":new makerjs.paths.Circle([xa+w/2,ya-h1], d/2),
          }
    };
    
    var fillet1 = makerjs.path.fillet(myCopperClipGap.paths.LeftLine, myCopperClipGap.paths.BottomLine, r);
    var fillet2 = makerjs.path.fillet(myCopperClipGap.paths.BottomLine, myCopperClipGap.paths.RightLine, r);
myCopperClipGap.paths.fillet1 = fillet1;
myCopperClipGap.paths.fillet2 = fillet2;
  } else if (area == "B") {
      var myCopperClipGap = {
            paths: {
                  "LeftLine": new makerjs.paths.Line([xb, yb], [xb, yb-h]),
                  "BottomLine": new makerjs.paths.Line([xb, yb-h], [xb+w, yb-h]),
                  "RightLine": new makerjs.paths.Line([xb+w, yb-h], [xb+w, yb]),
                  "Holes":new makerjs.paths.Circle([xb+w/2,yb-h1], d/2),
                }
          };
          
          var fillet1 = makerjs.path.fillet(myCopperClipGap.paths.LeftLine, myCopperClipGap.paths.BottomLine, r);
          var fillet2 = makerjs.path.fillet(myCopperClipGap.paths.BottomLine, myCopperClipGap.paths.RightLine, r);
      myCopperClipGap.paths.fillet1 = fillet1;
      myCopperClipGap.paths.fillet2 = fillet2;
  } else if (area == "C") {
      var myCopperClipGap = {
            paths: {
                  "LeftLine": new makerjs.paths.Line([xc-w, yc], [xc-w, yc-h]),
                  "BottomLine": new makerjs.paths.Line([xc-w, yc-h], [xc, yc-h]),
                  "RightLine": new makerjs.paths.Line([xc, ya-h], [xc, ya]),
                  "Holes":new makerjs.paths.Circle([xc-w/2,yc-h1], d/2),
                }
          };
          
          var fillet1 = makerjs.path.fillet(myCopperClipGap.paths.LeftLine, myCopperClipGap.paths.BottomLine, r);
          var fillet2 = makerjs.path.fillet(myCopperClipGap.paths.BottomLine, myCopperClipGap.paths.RightLine, r);
      myCopperClipGap.paths.fillet1 = fillet1;
      myCopperClipGap.paths.fillet2 = fillet2;
  } else if (area == "G") {
      var myCopperClipGap = {
            paths: {
                  "LeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+h]),
                  "BottomLine": new makerjs.paths.Line([xg, yg+h], [xg+w, yg+h]),
                  "RightLine": new makerjs.paths.Line([xg+w, yg+h], [xg+w, yg]),
                  "Holes":new makerjs.paths.Circle([xg+w/2,yg+h1], d/2),
                }
          };
          
          var fillet1 = makerjs.path.fillet(myCopperClipGap.paths.LeftLine, myCopperClipGap.paths.BottomLine, r);
          var fillet2 = makerjs.path.fillet(myCopperClipGap.paths.BottomLine, myCopperClipGap.paths.RightLine, r);
      myCopperClipGap.paths.fillet1 = fillet1;
      myCopperClipGap.paths.fillet2 = fillet2;
  } else if (area == "H") {
      var myCopperClipGap = {
            paths: {
                  "LeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+h]),
                  "BottomLine": new makerjs.paths.Line([xh, yh+h], [xh+w, yh+h]),
                  "RightLine": new makerjs.paths.Line([xh+w, yh+h], [xh+w, yh]),
                  "Holes":new makerjs.paths.Circle([xh+w/2,yh+h1], d/2),
                }
          };
          
          var fillet1 = makerjs.path.fillet(myCopperClipGap.paths.LeftLine, myCopperClipGap.paths.BottomLine, r);
          var fillet2 = makerjs.path.fillet(myCopperClipGap.paths.BottomLine, myCopperClipGap.paths.RightLine, r);
      myCopperClipGap.paths.fillet1 = fillet1;
      myCopperClipGap.paths.fillet2 = fillet2;
  } else if (area == "I") {
   var myCopperClipGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xi-w, yi], [xi-w, yi+h]),
        "BottomLine": new makerjs.paths.Line([xi-w, yi+h], [xi, yi+h]),
        "RightLine": new makerjs.paths.Line([xi, yi+h], [xi, yi]),        
        "Holes":new makerjs.paths.Circle([xi-w/2,yi+h1], d/2),
      }
};
var fillet1 = makerjs.path.fillet(myCopperClipGap.paths.LeftLine, myCopperClipGap.paths.BottomLine, r);
var fillet2 = makerjs.path.fillet(myCopperClipGap.paths.BottomLine, myCopperClipGap.paths.RightLine, r);
myCopperClipGap.paths.fillet1 = fillet1;
myCopperClipGap.paths.fillet2 = fillet2;
  }
  return myCopperClipGap;
};
// 矩形等去角标记 连线函数      
function creatmyCopperClipGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var R=inputParam.R,W=inputParam.W,H1=inputParam.H1,H=inputParam.H,D=inputParam.D,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
  xa = param.xa, ya = param.ya, xb = param.xb, 
  yb = param.yb, xc = param.xc, 
  yc = param.yc, xg = param.xg, yg = param.yg, 
  xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
  x = param.x0, r = param.r, rd = param.rd,w = param.w,h1 = param.h1, h = param.h,d = param.d,
//   x0,rd,r,w,w1,h,d 
        glassW = outParam.glassW, glassH = outParam.glassH,
       markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        w= W*1*myZoom,
      h= H*1*myZoom,
      d= D*1*myZoom,
      h1= H1*1*myZoom,
      r=R*1*myZoom,
      rd=r/Math.sqrt(2),
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        if(d!==0){
         var TxtD="∮"+d;
        }
        if(r!==0){
         var TxtR="R="+R;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }

  if (area == "A") {
      var myCopperClipAMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xa+0,ya+0+markShift], [xa+w, ya+0+markShift]),
                  "WALine1": new makerjs.paths.Line([xa+w,ya+0], [xa+w, ya+markShiftFour]),
            }
      };
      var myCopperClipAMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xa+w-r,ya-h+r], [xa+w-r+rd,ya-h+r-rd]),
                  }
      };
      var myCopperClipAMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xa+0,ya+0], [xa+0, ya+markShiftFour]),
                  "XALine1": new makerjs.paths.Line([xa-x0,ya+0], [xa-x0, ya+markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xa-x0,ya+0+markShiftTwo], [xa+0, ya+0+markShiftTwo]),
            }
      };
      var myCopperClipAMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xa+0,ya-h], [xa+0-markShiftFour, ya-h]),
               "HALine1": new makerjs.paths.Line([xa+0-markShift,ya-h], [xa+0-markShift, ya+0]),
              // "WALine2": new makerjs.paths.Line([xa, ya+ markShiftTwo], [xa+w, ya + markShiftTwo]),
            }
      };
      var myCopperClipAMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xa+w,ya-h1], [xa+w+markShiftFour+markShift, ya-h1]),
               "H1ALine1":new makerjs.paths.Line([xa+w+markShiftFour,ya-h1], [xa+w+markShiftFour, ya+0]),
              // "W1ALine2": new makerjs.paths.Line([xa, ya+ markShiftTwo], [xa+w, ya + markShiftTwo]),
            }
      };
      var myCopperClipAMakerD = {
            paths: {
               "DALine0": new makerjs.paths.Line([xa+w/2,ya-h1], [xa+w/2+0+d, ya+0-h1-d]),
              
            }
      };
        makerjs.model.addCaption(myCopperClipAMakerW, TxtW, [xa+0,ya+0+markShiftThree], [xa+w, ya+0+markShiftThree]);//W文字标注
        makerjs.model.addCaption(myCopperClipAMakerX, TxtX, [xa-x0,ya+0+markShiftFour], [xa+0, ya+0+markShiftFour]);//X文字标注
        makerjs.model.addCaption(myCopperClipAMakerR, TxtR,  [xa+w-r+markHolesOneD,ya-h+r+r-markHolesOneD], [xa+w-r+rd+r+markHolesOneD, ya-h+r-rd-r-markHolesOneD]);//R文字标注  
        makerjs.model.addCaption(myCopperClipAMakerH, TxtH, [xa+0-markShiftFour,ya-h], [xa+0-markShiftFour, ya+0]);//H文字标注    
        makerjs.model.addCaption(myCopperClipAMakerH1, TxtH1,[xa+w+markShiftFour+markShift,ya-h1], [xa+w+markShiftFour+markShift, ya+0]);//H1文字标注    
        makerjs.model.addCaption(myCopperClipAMakerD, TxtD, [xa+w/2,ya-h1],[xa+w/2+0+d+markHolesOneD, ya-h1+0-d-markHolesOneD]);//D文字标注  
        var markers = [myCopperClipAMakerH,myCopperClipAMakerX,myCopperClipAMakerR,myCopperClipAMakerW,myCopperClipAMakerH1,myCopperClipAMakerD]
  } else if (area == "B") {
      var myCopperClipBMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xb+0,yb+0+markShift], [xb+w, yb+0+markShift]),
                  "WALine1": new makerjs.paths.Line([xb+w,yb+0], [xb+w, yb+markShiftFour]),
            }
      };
      var myCopperClipBMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xb+w-r,yb-h+r], [xb+w-r+rd,yb-h+r-rd]),
                  }
      };
      var myCopperClipBMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xb+0,yb+0], [xb+0, yb+markShiftFour]),
                  "XALine1": new makerjs.paths.Line([xb-x0,yb+0], [xb-x0, yb+markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xb-x0,yb+0+markShiftTwo], [xb+0, yb+0+markShiftTwo]),
            }
      };
      var myCopperClipBMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xb+0,yb-h], [xb+0-markShiftFour, yb-h]),
               "HALine1": new makerjs.paths.Line([xb+0-markShift,yb-h], [xb+0-markShift, yb+0]),
              // "WALine2": new makerjs.paths.Line([xb, yb+ markShiftTwo], [xb+w, yb + markShiftTwo]),
            }
      };
      var myCopperClipBMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xb+w,yb-h1], [xb+w+markShiftFour+markShift, yb-h1]),
               "H1ALine1":new makerjs.paths.Line([xb+w+markShiftFour,yb-h1], [xb+w+markShiftFour, yb+0]),
              // "W1ALine2": new makerjs.paths.Line([xb, yb+ markShiftTwo], [xb+w, yb + markShiftTwo]),
            }
      };
      var myCopperClipBMakerD = {
            paths: {
               "DALine0": new makerjs.paths.Line([xb+w/2,yb-h1], [xb+w/2+0+d, yb+0-h1-d]),
              
            }
      };
        makerjs.model.addCaption(myCopperClipBMakerW, TxtW, [xb+0,yb+0+markShiftThree], [xb+w, yb+0+markShiftThree]);//W文字标注
        makerjs.model.addCaption(myCopperClipBMakerX, TxtX, [xb-x0,yb+0+markShiftFour], [xb+0, yb+0+markShiftFour]);//X文字标注
        makerjs.model.addCaption(myCopperClipBMakerR, TxtR,  [xb+w-r+markHolesOneD,yb-h+r+r-markHolesOneD], [xb+w-r+rd+r+markHolesOneD, yb-h+r-rd-r-markHolesOneD]);//R文字标注  
        makerjs.model.addCaption(myCopperClipBMakerH, TxtH, [xb+0-markShiftFour,yb-h], [xb+0-markShiftFour, yb+0]);//H文字标注    
        makerjs.model.addCaption(myCopperClipBMakerH1, TxtH1,[xb+w+markShiftFour+markShift,yb-h1], [xb+w+markShiftFour+markShift, yb+0]);//H1文字标注    
        makerjs.model.addCaption(myCopperClipBMakerD, TxtD, [xb+w/2+d,yb-h1-d],[xb+w/2+0+d+markHolesOneD, yb-h1+0-d-markHolesOneD]);//D文字标注  
        var markers = [myCopperClipBMakerH,myCopperClipBMakerX,myCopperClipBMakerR,myCopperClipBMakerW,myCopperClipBMakerH1,myCopperClipBMakerD]
  } else if (area == "C") {
      var myCopperClipCMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xc-0,yc+0+markShift], [xc-w, yc+0+markShift]),
                  "WALine1": new makerjs.paths.Line([xc-w,yc+0], [xc-w, yc+markShiftFour]),
            }
      };
      var myCopperClipCMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xc-w+r,yc-h+r], [xc-w+r-rd,yc-h+r-rd]),
                  }
      };
      var myCopperClipCMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xc-0,yc+0], [xc-0, yc+markShiftFour]),
                  "XALine1": new makerjs.paths.Line([xc+x0,yc+0], [xc+x0, yc+markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xc+x0,yc+0+markShiftTwo], [xc-0, yc+0+markShiftTwo]),
            }
      };
      var myCopperClipCMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xc,yc-h], [xc+markShiftFour, yc-h]),
               "HALine1": new makerjs.paths.Line([xc+markShift,yc-h], [xc+markShift, yc+0]),
              // "WALine2": new makerjs.paths.Line([xc, yc+ markShiftTwo], [xc+w, yc + markShiftTwo]),
            }
      };
      var myCopperClipCMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xc-w,yc-h1], [xc-w-markShiftFour-markShift, yc-h1]),
               "H1ALine1":new makerjs.paths.Line([xc-w-markShiftFour,yc-h1], [xc-w-markShiftFour, yc+0]),
              // "W1ALine2": new makerjs.paths.Line([xc, yc+ markShiftTwo], [xc+w, yc + markShiftTwo]),
            }
      };
      var myCopperClipCMakerD = {
            paths: {
               "DALine0": new makerjs.paths.Line([xc-w/2,yc-h1], [xc-w/2+d, yc+0-h1-d]),
              
            }
      };
        makerjs.model.addCaption(myCopperClipCMakerW, TxtW, [xc-w, yc+0+markShiftThree], [xc,yc+0+markShiftThree]);//W文字标注
        makerjs.model.addCaption(myCopperClipCMakerX, TxtX, [xc, yc+0+markShiftFour], [xc+x0,yc+0+markShiftFour]);//X文字标注
        makerjs.model.addCaption(myCopperClipCMakerR, TxtR, [xc-w+r-rd-r-markHolesOneD, yc-h+r-rd-r-markHolesOneD],[xc-w+r-markHolesOneD,yc-h+r+r-markHolesOneD]);//R文字标注  
        makerjs.model.addCaption(myCopperClipCMakerH, TxtH, [xc+markShiftFour,yc-h], [xc+markShiftFour, yc+0]);//H文字标注    
        makerjs.model.addCaption(myCopperClipCMakerH1, TxtH1,[xc-w-markShiftFour-markShift,yc-h1], [xc-w-markShiftFour-markShift, yc+0]);//H1文字标注    
        makerjs.model.addCaption(myCopperClipCMakerD, TxtD, [xc-w/2+d,yc-h1-d],[xc-w/2+d+markHolesOneD, yc-h1+0-d-markHolesOneD]);//D文字标注  
  var markers = [myCopperClipCMakerH,myCopperClipCMakerX,myCopperClipCMakerR,myCopperClipCMakerW,myCopperClipCMakerH1,myCopperClipCMakerD]
  } else if (area == "G") {
      var myCopperClipGMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xg,yg-markShiftTwo], [xg+w, yg-markShiftTwo]),
                  "WALine1": new makerjs.paths.Line([xg+w,yg], [xg+w, yg-markShiftFour]),
            }
      };
      var myCopperClipGMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xg+w-r,yg+h-r], [xg+w-r+rd,yg+h-r+rd]),
                  }
      };
      var myCopperClipGMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xg,yg], [xg, yg-markShiftFour]),
                  "XALine1": new makerjs.paths.Line([xg-x0,yg], [xg-x0, yg-markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xg-x0,yg-markShiftTwo], [xg, yg-markShiftTwo]),
            }
      };
      var myCopperClipGMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xg,yg+h], [xg-markShiftFour, yg+h]),
               "HALine1": new makerjs.paths.Line([xg-markShift,yg+h], [xg-markShift, yg]),
            }
      };
      var myCopperClipGMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xg+w,yg+h1], [xg+w+markShiftFour+markShift, yg+h1]),
               "H1ALine1":new makerjs.paths.Line([xg+w+markShiftFour,yg+h1], [xg+w+markShiftFour, yg]),
            }
      };
      var myCopperClipGMakerD = {
            paths: {
               "DALine0": new makerjs.paths.Line([xg+w/2,yg+h1], [xg+w/2+d, yg+h1+d]),
              
            }
      };
        makerjs.model.addCaption(myCopperClipGMakerW, TxtW, [xg,yg-markShiftFour], [xg+w, yg-markShiftFour]);//W文字标注
        makerjs.model.addCaption(myCopperClipGMakerX, TxtX, [xg-x0,yg-markShiftFour], [xg, yg-markShiftFour]);//X文字标注
        makerjs.model.addCaption(myCopperClipGMakerR, TxtR,  [xg+w-r+markHolesOneD,yg+h-r-r+markHolesOneD], [xg+w-r+rd+r+markHolesOneD, yg+h-r+rd+r+markHolesOneD]);//R文字标注  
        makerjs.model.addCaption(myCopperClipGMakerH, TxtH, [xg-markShiftFour, yg], [xg-markShiftFour,yg+h]);//H文字标注    
        makerjs.model.addCaption(myCopperClipGMakerH1, TxtH1, [xg+w+markShiftFour+markShift, yg],[xg+w+markShiftFour+markShift,yg+h1]);//H1文字标注    
        makerjs.model.addCaption(myCopperClipGMakerD, TxtD, [xg+w/2,yg+h1],[xg+w/2+d+markHolesOneD, yg+h1+d+markHolesOneD]);//D文字标注  
        var markers = [myCopperClipGMakerH,myCopperClipGMakerX,myCopperClipGMakerR,myCopperClipGMakerW,myCopperClipGMakerH1,myCopperClipGMakerD]
  } else if (area == "H") {
      var myCopperClipHMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xh+0,yh+markShift], [xh+w, yh+markShift]),
                  //"WALine1": new makerjs.paths.Line([xh+w,yh], [xh+w, yh-markShiftFour]),
            }
      };
      var myCopperClipHMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xh+w-r,yh+h-r], [xh+w-r+rd,yh+h-r+rd]),
                  }
      };
      var myCopperClipHMakerX = {
            paths: {
                  // "XALine0": new makerjs.paths.Line([xh+0,yh], [xh+0, yh-markShiftFour]),
                  // "XALine1": new makerjs.paths.Line([xh-x0,yh], [xh-x0, yh-markShiftFour]),
                  // "XALine2": new makerjs.paths.Line([xh-x0,yh-markShiftTwo], [xh+0, yh-markShiftTwo]),
            }
      };
      var myCopperClipHMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xh+0,yh+h], [xh+0-markShiftFour, yh+h]),
               "HALine1": new makerjs.paths.Line([xh+0-markShift,yh+h], [xh+0-markShift, yh+0]),
            }
      };
      var myCopperClipHMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xh+w,yh+h1], [xh+w+markShiftFour+markShift, yh+h1]),
               "H1ALine1":new makerjs.paths.Line([xh+w+markShiftFour,yh+h1], [xh+w+markShiftFour, yh+0]),
            }
      };
      var myCopperClipHMakerD = {
            paths: {
               "DALine0": new makerjs.paths.Line([xh+w/2,yh+h1], [xh+w/2+0+d, yh+h1+d]),
              
            }
      };
        makerjs.model.addCaption(myCopperClipHMakerW, TxtW, [xh+0,yh+markShiftThree], [xh+w, yh+markShiftThree]);//W文字标注
        makerjs.model.addCaption(myCopperClipHMakerX, TxtX, [xh-x0,yh-markShiftFour], [xh, yh-markShiftFour]);//X文字标注
        makerjs.model.addCaption(myCopperClipHMakerR, TxtR,  [xh+w-r+markHolesOneD,yh+h-r-r+markHolesOneD], [xh+w-r+rd+r+markHolesOneD, yh+h-r+rd+r+markHolesOneD]);//R文字标注  
        makerjs.model.addCaption(myCopperClipHMakerH, TxtH, [xh-markShiftFour, yh], [xh+0-markShiftFour,yh+h]);//H文字标注    
        makerjs.model.addCaption(myCopperClipHMakerH1, TxtH1, [xh+w+markShiftFour+markShift, yh],[xh+w+markShiftFour+markShift,yh+h1]);//H1文字标注    
        makerjs.model.addCaption(myCopperClipHMakerD, TxtD, [xh+w/2,yh+h1],[xh+w/2+0+d+markHolesOneD, yh+h1+d+markHolesOneD]);//D文字标注  
        var markers = [myCopperClipHMakerH,myCopperClipHMakerX,myCopperClipHMakerR,myCopperClipHMakerW,myCopperClipHMakerH1,myCopperClipHMakerD]
  } else if (area == "I") {
      var myCopperClipIMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xi,yi-markShiftTwo], [xi-w, yi-markShiftTwo]),
                  "WALine1": new makerjs.paths.Line([xi-w,yi], [xi-w, yi-markShiftFour]),
            }
      };
      var myCopperClipIMakerR = {
            paths: {
                   "RALine0": new makerjs.paths.Line([xi-w+r,yi+h-r], [xi-w+r-rd,yi+h-r+rd]),
                  }
      };
      var myCopperClipIMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xi-0,yi], [xi-0, yi-markShiftFour]),
                  "XALine1": new makerjs.paths.Line([xi+x0,yi], [xi+x0, yi-markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xi+x0,yi-markShiftTwo], [xi-0, yi-markShiftTwo]),
            }
      };
      var myCopperClipIMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xi,yi+h], [xi+markShiftFour, yi+h]),
               "HALine1": new makerjs.paths.Line([xi+markShift,yi+h], [xi+markShift, yi]),
            }
      };
      var myCopperClipIMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xi-w,yi+h1], [xi-w-markShiftFour-markShift, yi+h1]),
               "H1ALine1":new makerjs.paths.Line([xi-w-markShiftFour,yi+h1], [xi-w-markShiftFour, yi]),
            }
      };
      var myCopperClipIMakerD = {
            paths: {
               "DALine0": new makerjs.paths.Line([xi-w/2,yi+h1], [xi-w/2+d, yi+h1+d]),
              
            }
      };
        makerjs.model.addCaption(myCopperClipIMakerW, TxtW, [xi-w, yi-markShiftFour], [xi,yi-markShiftFour]);//W文字标注
        makerjs.model.addCaption(myCopperClipIMakerX, TxtX, [xi, yi-markShiftFour], [xi+x0,yi-markShiftFour]);//X文字标注
        makerjs.model.addCaption(myCopperClipIMakerR, TxtR, [xi-w+r-rd-r-markHolesOneD, yi+h-r+rd+r+markHolesOneD],[xi-w+r-markHolesOneD,yi+h-r-r+markHolesOneD]);//R文字标注  
        makerjs.model.addCaption(myCopperClipIMakerH, TxtH, [xi+markShiftFour, yi], [xi+markShiftFour,yi+h]);//H文字标注    
        makerjs.model.addCaption(myCopperClipIMakerH1, TxtH1, [xi-w-markShiftFour-markShift, yi],[xi-w-markShiftFour-markShift,yi+h1]);//H1文字标注    
        makerjs.model.addCaption(myCopperClipIMakerD, TxtD, [xi-w/2+d,yi+h1+d],[xi-w/2+d+markHolesOneD, yi+h1+d+markHolesOneD]);//D文字标注  
  var markers = [myCopperClipIMakerH,myCopperClipIMakerX,myCopperClipIMakerR,myCopperClipIMakerW,myCopperClipIMakerH1,myCopperClipIMakerD]
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function CopperClipGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){

      var frame = outParam.shapeName;
      console.log("outParam==", outParam)
      console.log("model==", model)
      console.log("inputParam==", inputParam)
      console.log("outParam.shapeName==////////", outParam.shapeName)
      if (typeof (frame) == "undefined") {
            var R=inputParam.R,W=inputParam.W,H1=inputParam.H1,H=inputParam.H,D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
            plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  w= W*1*myZoom,
                  h= H*1*myZoom,
                  d= D*1*myZoom,
                  h1= H1*1*myZoom,
                  r=R*1*myZoom,
                  rd=r/Math.sqrt(2),
                    x0 = X*1*myZoom,
                    y0 = Y*1*myZoom;
                    /*
                    
               w= W*1,
                  h= H*1,
                  d= D*1,
                  w1= W1*1,
                  r=R*1,
                  rd=r/Math.sqrt(2),
                    x0 = X*1,
                    y0 = Y*1;
                    */
                    if(d!==0){
                     var TxtD="∮"+d;
                    }
                    if(r!==0){
                     var TxtR="R="+R;
                    }
                    if(h!==0){
                     var TxtH=H;
                    }
                    if(w!==0){
                     var TxtW=W;
                    }
                    if(h1!==0){
                     var TxtH1=H1;
                    }
                    if(x0!==0){          
                    var TxtX=X;
                    }
                    if(y0!==0){          
                     var TxtY=Y;
                    }
      } else {
            var R=inputParam.R,W=inputParam.W,H1=inputParam.H1,H=inputParam.H,D=inputParam.D,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
            plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  w= W*1*myZoom,
                  h= H*1*myZoom,
                  d= D*1*myZoom,
                  h1= H1*1*myZoom,
                  r=R*1*myZoom,
                  rd=r/Math.sqrt(2),
                    x0 = X*1*myZoom,
                    y0 = Y*1*myZoom;
                    if(d!==0){
                     var TxtD="∮"+d;
                    }
                    if(r!==0){
                     var TxtR="R="+R;
                    }
                    if(h!==0){
                     var TxtH=H;
                    }
                    if(w!==0){
                     var TxtW=W;
                    }
                    if(h1!==0){
                     var TxtH1=H1;
                    }
                    if(x0!==0){          
                    var TxtX=X;
                    }
                    if(y0!==0){          
                     var TxtY=Y;
                    }
      }
      if (frame == "Rectangle") {
            console.log("frame==undefined||||*****")
            //在A区域 计算孔的位置       
            var xa = x0, ya = glassH * 1 - y0,
                  //B区域//只有Y标注没有X标注 
                  xb = glassW * 1 / 2, yb = glassH * 1 - y0,
                  //C区域
                  xc = glassW * 1 - x0, yc = glassH * 1 - y0,
                  //在G区域 计算孔的位置
                  xg = x0, yg = y0,
                  //在H区域 计算孔的位置
                  //只标注Y
                  xh = glassW * 1 / 2, yh = y0,
                  //在I区域 计算孔的位置
                  xi = glassW * 1 - x0, yi = y0;
            var OffsetParam = {};
      } else if (frame == "IsoscelesTrapezoid") {
    
      } else if (frame == "RightAngledTrapezoid") {d
    
      } else if (frame == "OtherTrapezoidsA") {
    
      } else if (frame == "OtherTrapezoidsB") {
    
      } else if (frame == "ParallelogramA") {
    
    
      } else if (frame == "ParallelogramB") {
    
      }
      // 坐标参数
      var paramCoordinate = { x0,rd,r,w,h1,h,d,xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
      const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
      var areaPoint = "";
      console.log("//角7、地弹簧3 ------", allKeys)
      if (allKeys[0] == frame) {
            allKeys.shift();//去掉数组第一个元素
      }
      console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
      switch (area) {
            case "1": areaPoint = "A"; break;
            case "2": areaPoint = "B"; break;
            case "3": areaPoint = "C"; break;
            case "7": areaPoint = "G"; break;
            case "8": areaPoint = "H"; break;
            case "9": areaPoint = "I"; break;
      }
      /**
      * 画图
      * 
      */
      var ObjName = "myCopperClipGap" + areaPoint;
      var ObjNameMmyCopperClipGap = "myCopperClipGap" + areaPoint + "Mark";//角标记
      ObjName = creatmyCopperClipGap(areaPoint, paramCoordinate);//图形
      console.log("ObjName===|||||||||", ObjName);
      ObjNameMmyCopperClipGap = creatmyCopperClipGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
      console.log("ObjNameMmyCopperClipGap===|||||||||角标注", ObjNameMmyCopperClipGap);
      console.log("cutPlate.models====************", cutPlate.models),
            console.log("plate.models====**************", plate.models),
            console.log("markers.models====*************", markers.models),
         // myCopperClipAMakerH,myCopperClipAMakerX,myCopperClipAMakerR,myCopperClipAMakerW,myCopperClipAMakerH1,myCopperClipAMakerD
            cutPlate.models["myCopperClipGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myCopperClipGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myCopperClipGapH" + areaPoint + myHolesNum] = ObjNameMmyCopperClipGap[0];
      plate.models["myCopperClipGapX" + areaPoint + myHolesNum] = ObjNameMmyCopperClipGap[1];
      plate.models["myCopperClipGapR" + areaPoint + myHolesNum] = ObjNameMmyCopperClipGap[2];
      plate.models["myCopperClipGapW" + areaPoint + myHolesNum] = ObjNameMmyCopperClipGap[3];
      plate.models["myCopperClipGapH1" + areaPoint + myHolesNum] = ObjNameMmyCopperClipGap[4];
      plate.models["myCopperClipGapD" + areaPoint + myHolesNum] = ObjNameMmyCopperClipGap[5];
      markers.models["myCopperClipGap" + areaPoint + myHolesNum] = ObjName;
      markers.models["myCopperClipGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyCopperClipGap[0];
      markers.models["myCopperClipGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyCopperClipGap[1];
      markers.models["myCopperClipGapR" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyCopperClipGap[2];
      markers.models["myCopperClipGapW" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyCopperClipGap[3];
      markers.models["myCopperClipGapH1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyCopperClipGap[4];
      markers.models["myCopperClipGapD" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyCopperClipGap[5];

myHolesNum++;
return{plate,cutPlate,markers, myHolesNum}
};


export default CopperClipGap;