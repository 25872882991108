//缺7、长条夹  
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyLongClipGap(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xb = param.xb, 
  yb = param.yb, xc = param.xc, 
  yc = param.yc, xg = param.xg, yg = param.yg, 
  xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi, 
  x = param.x0, r = param.r, d = param.d,r1 = param.r1,d1 = param.d1, h = param.h,h1 = param.h1; 
  
  if (area == "A") {
    var myLongClipGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xa, ya], [xa, ya-h]),
        "BottomArc": new makerjs.paths.Arc([xa, ya-h], [xa+d, ya-h],r,false,false),
        "RightLine": new makerjs.paths.Line([xa+d, ya-h], [xa+d, ya]),
        "BottomCircle": new makerjs.paths.Circle([xa+d/2, ya-h1],r1),
          }
    };
  } else if (area == "B") {
      var myLongClipGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xb, yb], [xb, yb-h]),
              "BottomArc": new makerjs.paths.Arc([xb, yb-h], [xb+d, yb-h],r,false,false),
              "RightLine": new makerjs.paths.Line([xb+d, yb-h], [xb+d, yb]),
              "BottomCircle": new makerjs.paths.Circle([xb+d/2, yb-h1],r1),
                }
          };
  } else if (area == "C") {
      var myLongClipGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xc, yc], [xc, yc-h]),
              "BottomArc": new makerjs.paths.Arc([xc, yc-h], [xc+d, yc-h],r,false,false),
              "RightLine": new makerjs.paths.Line([xc+d, yc-h], [xc+d, yc]),
              "BottomCircle": new makerjs.paths.Circle([xc+d/2, yc-h1],r1),
                }
          };
  } else if (area == "G") {
      var myLongClipGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xg, yg], [xg, yg+h]),
              "TopArc": new makerjs.paths.Arc([xg, yg+h], [xg+d, yg+h],r,false,true),
              "RightLine": new makerjs.paths.Line([xg+d, yg+h], [xg+d, yg]),
              "TopCircle": new makerjs.paths.Circle([xg+d/2, yg+h1],r1),
                }
          };
  } else if (area == "H") {
      var myLongClipGap = {
            paths: {
              "LeftLine": new makerjs.paths.Line([xh, yh], [xh, yh+h]),
              "TopArc": new makerjs.paths.Arc([xh, yh+h], [xh+d, yh+h],r,false,true),
              "RightLine": new makerjs.paths.Line([xh+d, yh+h], [xh+d, yh]),
              "TopCircle": new makerjs.paths.Circle([xh+d/2, yh+h1],r1),
                }
          };
  } else if (area == "I") {
   var myLongClipGap = {
      paths: {
        "LeftLine": new makerjs.paths.Line([xi, yi], [xi, yi+h]),
        "TopArc": new makerjs.paths.Arc([xi, yi+h], [xi+d, yi+h],r,false,true),
        "RightLine": new makerjs.paths.Line([xi+d, yi+h], [xi+d, yi]),
        "TopCircle": new makerjs.paths.Circle([xi+d/2, yi+h1],r1),
      }
};
  }
  return myLongClipGap;
};
// 矩形等去角标记 连线函数      
function creatmyLongClipGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var D=inputParam.D,H=inputParam.H,D1=inputParam.D1,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
  xa = param.xa, ya = param.ya, xb = param.xb, 
  yb = param.yb, xc = param.xc, 
  yc = param.yc, xg = param.xg, yg = param.yg, 
  xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
  x = param.x0, r = param.r, d = param.d,r1 = param.r1,d1 = param.d1, h = param.h,h1 = param.h1,
//   x0,rd,r,w,w1,h,d 
        glassW = outParam.glassW, glassH = outParam.glassH,
       markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        d = D*1*myZoom,
        r = d/2,
           d1 = D1*1*myZoom,
           r1 = d1/2,
           h= H*1*myZoom,
           h1= H1*1*myZoom,
             x0 = X*1*myZoom,
             y0 = Y*1*myZoom;
             if(d!==0){
              var TxtD=D;
             }
             if(d1!==0){
              var TxtD1="∮"+D1;
             }
             if(h!==0){
              var TxtH=H;
             }
             if(h1!==0){
              var TxtH1=H1;
             }
             if(x0!==0){          
             var TxtX=X;
             }
             if(y0!==0){          
              var TxtY=Y;
             }

  if (area == "A") {
      var myLcgGapAMakerD = {
            paths: {
                  "DALine0": new makerjs.paths.Line([xa+0,ya+0+markShift], [xa+d, ya+0+markShift]),
                  "DALine1": new makerjs.paths.Line([xa+d,ya+0], [xa+d, ya+markShiftFour]),
            }
      };
      var myLcgGapAMakerX = {
            paths: {
                  "XALine0": new makerjs.paths.Line([xa+0,ya+0], [xa+0, ya+markShiftFour]),
                  "XALine1": new makerjs.paths.Line([xa-x0,ya+0], [xa-x0, ya+markShiftFour]),
                  "XALine2": new makerjs.paths.Line([xa-x0,ya+0+markShiftTwo], [xa+0, ya+0+markShiftTwo]),
            }
      };
      var myLcgGapAMakerH = {
            paths: {
               "HALine0": new makerjs.paths.Line([xa+0,ya-h], [xa+0-markShiftFour, ya-h]),
               "HALine1": new makerjs.paths.Line([xa+0-markShift,ya-h], [xa+0-markShift, ya+0]),
              // "WALine2": new makerjs.paths.Line([xa, ya+ markShiftTwo], [xa+w, ya + markShiftTwo]),
            }
      };
      var myLcgGapAMakerH1 = {
            paths: {
               "H1ALine0": new makerjs.paths.Line([xa+d,ya-h1], [xa+d+markShiftFour+markShift, ya-h1]),
               "H1ALine1":new makerjs.paths.Line([xa+d+markShiftFour,ya-h1], [xa+d+markShiftFour, ya+0]),
              // "W1ALine2": new makerjs.paths.Line([xa, ya+ markShiftTwo], [xa+w, ya + markShiftTwo]),
            }
      };
      var myLcgGapAMakerD1 = {
            paths: {
               "D1ALine0": new makerjs.paths.Line([xa+d/2,ya-h1], [xa+d/2+0+d1, ya+0-h1-d1]),
              
            }
      };
        makerjs.model.addCaption(myLcgGapAMakerD, TxtD, [xa+0,ya+0+markShiftThree], [xa+d, ya+0+markShiftThree]);//W文字标注
        makerjs.model.addCaption(myLcgGapAMakerX, TxtX, [xa-x0,ya+0+markShiftFour], [xa+0, ya+0+markShiftFour]);//X文字标注
       makerjs.model.addCaption(myLcgGapAMakerH, TxtH, [xa+0-markShiftFour,ya-h], [xa+0-markShiftFour, ya+0]);//H文字标注    
        makerjs.model.addCaption(myLcgGapAMakerH1, TxtH1,[xa+d+markShiftFour+markShift,ya-h1], [xa+d+markShiftFour+markShift, ya+0]);//H1文字标注    
        makerjs.model.addCaption(myLcgGapAMakerD1, TxtD1, [xa+d/2,ya-h1],[xa+d/2+0+d1+markHolesOneD, ya-h1+0-d1-markHolesOneD]);//D文字标注  
        var markers = [myLcgGapAMakerH,myLcgGapAMakerX,myLcgGapAMakerD,myLcgGapAMakerH1,myLcgGapAMakerD1]
  } else if (area == "B") {
    var myLcgGapBMakerD = {
      paths: {
            "DALine0": new makerjs.paths.Line([xb+0,yb+0+markShift], [xb+d, yb+0+markShift]),
            "DALine1": new makerjs.paths.Line([xb+d,yb+0], [xb+d, yb+markShiftFour]),
      }
};
var myLcgGapBMakerX = {
      paths: {
            "XALine0": new makerjs.paths.Line([xb+0,yb+0], [xb+0, yb+markShiftFour]),
            "XALine1": new makerjs.paths.Line([xb-x0,yb+0], [xb-x0, yb+markShiftFour]),
            "XALine2": new makerjs.paths.Line([xb-x0,yb+0+markShiftTwo], [xb+0, yb+0+markShiftTwo]),
      }
};
var myLcgGapBMakerH = {
      paths: {
         "HALine0": new makerjs.paths.Line([xb+0,yb-h], [xb+0-markShiftFour, yb-h]),
         "HALine1": new makerjs.paths.Line([xb+0-markShift,yb-h], [xb+0-markShift, yb+0]),
        // "WALine2": new makerjs.paths.Line([xb, yb+ markShiftTwo], [xb+w, yb + markShiftTwo]),
      }
};
var myLcgGapBMakerH1 = {
      paths: {
         "H1ALine0": new makerjs.paths.Line([xb+d,yb-h1], [xb+d+markShiftFour+markShift, yb-h1]),
         "H1ALine1":new makerjs.paths.Line([xb+d+markShiftFour,yb-h1], [xb+d+markShiftFour, yb+0]),
        // "W1ALine2": new makerjs.paths.Line([xb, yb+ markShiftTwo], [xb+w, yb + markShiftTwo]),
      }
};
var myLcgGapBMakerD1 = {
      paths: {
         "D1ALine0": new makerjs.paths.Line([xb+d/2,yb-h1], [xb+d/2+0+d1, yb+0-h1-d1]),
        
      }
};
  makerjs.model.addCaption(myLcgGapBMakerD, TxtD, [xb+0,yb+0+markShiftThree], [xb+d, yb+0+markShiftThree]);//W文字标注
  makerjs.model.addCaption(myLcgGapBMakerX, TxtX, [xb-x0,yb+0+markShiftFour], [xb+0, yb+0+markShiftFour]);//X文字标注
 makerjs.model.addCaption(myLcgGapBMakerH, TxtH, [xb+0-markShiftFour,yb-h], [xb+0-markShiftFour, yb+0]);//H文字标注    
  makerjs.model.addCaption(myLcgGapBMakerH1, TxtH1,[xb+d+markShiftFour+markShift,yb-h1], [xb+d+markShiftFour+markShift, yb+0]);//H1文字标注    
  makerjs.model.addCaption(myLcgGapBMakerD1, TxtD1, [xb+d/2,yb-h1],[xb+d/2+0+d1+markHolesOneD, yb-h1+0-d1-markHolesOneD]);//D文字标注  
  var markers = [myLcgGapBMakerH,myLcgGapBMakerX,myLcgGapBMakerD,myLcgGapBMakerH1,myLcgGapBMakerD1]
  } else if (area == "C") {
    var myLcgGapCMakerD = {
      paths: {
            "DALine0": new makerjs.paths.Line([xc,yc+0+markShift], [xc-d, yc+0+markShift]),
            "DALine1": new makerjs.paths.Line([xc-d,yc+0], [xc-d, yc+markShiftFour]),
      }
};
var myLcgGapCMakerX = {
      paths: {
            "XALine0": new makerjs.paths.Line([xc+0,yc+0], [xc+0, yc+markShiftFour]),
            "XALine1": new makerjs.paths.Line([xc+x0,yc+0], [xc+x0, yc+markShiftFour]),
            "XALine2": new makerjs.paths.Line([xc+x0,yc+0+markShiftTwo], [xc+0, yc+0+markShiftTwo]),
      }
};
var myLcgGapCMakerH = {
      paths: {
         "HALine0": new makerjs.paths.Line([xc+0,yc-h], [xc-markShiftFour, yc-h]),
         "HALine1": new makerjs.paths.Line([xc-markShift,yc-h], [xc-markShift, yc+0]),
      }
};
var myLcgGapCMakerH1 = {
      paths: {
         "H1ALine0": new makerjs.paths.Line([xc+d,yc-h1], [xc+d+markShiftFour+markShift, yc-h1]),
         "H1ALine1":new makerjs.paths.Line([xc+d+markShiftFour,yc-h1], [xc+d+markShiftFour, yc+0]),
      }
};
var myLcgGapCMakerD1 = {
      paths: {
         "D1ALine0": new makerjs.paths.Line([xc+d/2,yc-h1], [xc+d/2+d1, yc+0-h1-d1]),
        
      }
};
  makerjs.model.addCaption(myLcgGapCMakerD, TxtD, [xc-d, yc+0+markShiftThree], [xc,yc+0+markShiftThree]);//W文字标注
  makerjs.model.addCaption(myLcgGapCMakerX, TxtX, [xc, yc+0+markShiftFour], [xc+x0,yc+0+markShiftFour]);//X文字标注
 makerjs.model.addCaption(myLcgGapCMakerH, TxtH, [xc-markShiftFour,yc-h], [xc-markShiftFour, yc+0]);//H文字标注    
  makerjs.model.addCaption(myLcgGapCMakerH1, TxtH1,[xc+d+markShiftFour+markShift,yc-h1], [xc+d+markShiftFour+markShift, yc+0]);//H1文字标注    
  makerjs.model.addCaption(myLcgGapCMakerD1, TxtD1, [xc+d/2,yc-h1],[xc+d/2+d1+markHolesOneD, yc-h1+0-d1-markHolesOneD]);//D文字标注  
  var markers = [myLcgGapCMakerH,myLcgGapCMakerX,myLcgGapCMakerD,myLcgGapCMakerH1,myLcgGapCMakerD1]
  } else if (area == "G") {
    var myLcgGapGMakerD = {
      paths: {
            "DALine0": new makerjs.paths.Line([xg,yg-markShiftTwo], [xg+d, yg-markShiftTwo]),
            "DALine1": new makerjs.paths.Line([xg+d,yg], [xg+d, yg-markShiftFour]),
      }
};
var myLcgGapGMakerX = {
      paths: {
            "XALine0": new makerjs.paths.Line([xg,yg], [xg, yg-markShiftFour]),
            "XALine1": new makerjs.paths.Line([xg-x0,yg], [xg-x0, yg-markShiftFour]),
            "XALine2": new makerjs.paths.Line([xg-x0,yg-markShiftTwo], [xg, yg-markShiftTwo]),
      }
};
var myLcgGapGMakerH = {
      paths: {
         "HALine0": new makerjs.paths.Line([xg+0,yg+h], [xg+0-markShiftFour, yg+h]),
         "HALine1": new makerjs.paths.Line([xg+0-markShift,yg+h], [xg+0-markShift, yg+0]),
      }
};
var myLcgGapGMakerH1 = {
      paths: {
         "H1ALine0": new makerjs.paths.Line([xg+d,yg+h1], [xg+d+markShiftFour+markShift, yg+h1]),
         "H1ALine1":new makerjs.paths.Line([xg+d+markShiftFour,yg+h1], [xg+d+markShiftFour, yg+0]),
      }
};
var myLcgGapGMakerD1 = {
      paths: {
         "D1ALine0": new makerjs.paths.Line([xg+d/2,yg+h1], [xg+d/2+0+d1, yg+h1+d1]),
        
      }
};
  makerjs.model.addCaption(myLcgGapGMakerD, TxtD, [xg+0,yg-markShiftFour], [xg+d, yg-markShiftFour]);//W文字标注
  makerjs.model.addCaption(myLcgGapGMakerX, TxtX, [xg-x0,yg-markShiftFour], [xg+0, yg-markShiftFour]);//X文字标注
 makerjs.model.addCaption(myLcgGapGMakerH, TxtH, [xg+0-markShiftFour, yg+0], [xg+0-markShiftFour,yg+h]);//H文字标注    
  makerjs.model.addCaption(myLcgGapGMakerH1, TxtH1, [xg+d+markShiftFour+markShift, yg+0],[xg+d+markShiftFour+markShift,yg+h1]);//H1文字标注    
  makerjs.model.addCaption(myLcgGapGMakerD1, TxtD1, [xg+d/2,yg+h1],[xg+d/2+0+d1+markHolesOneD, yg+h1+d1+markHolesOneD]);//D文字标注  
  var markers = [myLcgGapGMakerH,myLcgGapGMakerX,myLcgGapGMakerD,myLcgGapGMakerH1,myLcgGapGMakerD1]
  } else if (area == "H") {
    var myLcgGapHMakerD = {
      paths: {
            "DALine0": new makerjs.paths.Line([xh,yh+markShift], [xh+d, yh+markShift]),
            // "DALine1": new makerjs.paths.Line([xh+d,yh], [xh+d, yh-markShiftFour]),
      }
};
var myLcgGapHMakerX = {
      paths: {
            // "XALine0": new makerjs.paths.Line([xh,yh], [xh, yh-markShiftFour]),
            // "XALine1": new makerjs.paths.Line([xh-x0,yh], [xh-x0, yh-markShiftFour]),
            // "XALine2": new makerjs.paths.Line([xh-x0,yh-markShiftTwo], [xh, yh-markShiftTwo]),
      }
};
var myLcgGapHMakerH = {
      paths: {
         "HALine0": new makerjs.paths.Line([xh+0,yh+h], [xh+0-markShiftFour, yh+h]),
         "HALine1": new makerjs.paths.Line([xh+0-markShift,yh+h], [xh+0-markShift, yh+0]),
      }
};
var myLcgGapHMakerH1 = {
      paths: {
         "H1ALine0": new makerjs.paths.Line([xh+d,yh+h1], [xh+d+markShiftFour+markShift, yh+h1]),
         "H1ALine1":new makerjs.paths.Line([xh+d+markShiftFour,yh+h1], [xh+d+markShiftFour, yh+0]),
      }
};
var myLcgGapHMakerD1 = {
      paths: {
         "D1ALine0": new makerjs.paths.Line([xh+d/2,yh+h1], [xh+d/2+0+d1, yh+h1+d1]),
        
      }
};
  makerjs.model.addCaption(myLcgGapHMakerD, TxtD, [xh+0,yh+markShiftThree], [xh+d, yh+markShiftThree]);//W文字标注
  makerjs.model.addCaption(myLcgGapHMakerX, TxtX, [xh-x0,yh-markShiftFour], [xh+0, yh-markShiftFour]);//X文字标注
 makerjs.model.addCaption(myLcgGapHMakerH, TxtH, [xh+0-markShiftFour, yh+0], [xh+0-markShiftFour,yh+h]);//H文字标注    
  makerjs.model.addCaption(myLcgGapHMakerH1, TxtH1, [xh+d+markShiftFour+markShift, yh+0],[xh+d+markShiftFour+markShift,yh+h1]);//H1文字标注    
  makerjs.model.addCaption(myLcgGapHMakerD1, TxtD1, [xh+d/2,yh+h1],[xh+d/2+0+d1+markHolesOneD, yh+h1+d1+markHolesOneD]);//D文字标注  
  var markers = [myLcgGapHMakerH,myLcgGapHMakerX,myLcgGapHMakerD,myLcgGapHMakerH1,myLcgGapHMakerD1]
  } else if (area == "I") {
    var myLcgGapIMakerD = {
      paths: {
            "DALine0": new makerjs.paths.Line([xi,yi-markShiftTwo], [xi-d, yi-markShiftTwo]),
            "DALine1": new makerjs.paths.Line([xi-d,yi+0], [xi-d, yi-markShiftFour]),
      }
};
var myLcgGapIMakerX = {
      paths: {
            "XALine0": new makerjs.paths.Line([xi+0,yi+0], [xi+0, yi-markShiftFour]),
            "XALine1": new makerjs.paths.Line([xi+x0,yi+0], [xi+x0, yi-markShiftFour]),
            "XALine2": new makerjs.paths.Line([xi+x0,yi-markShiftTwo], [xi+0, yi-markShiftTwo]),
      }
};
var myLcgGapIMakerH = {
      paths: {
         "HALine0": new makerjs.paths.Line([xi+0,yi+h], [xi-markShiftFour, yi+h]),
         "HALine1": new makerjs.paths.Line([xi-markShift,yi+h], [xi-markShift, yi+0]),
      }
};
var myLcgGapIMakerH1 = {
      paths: {
         "H1ALine0": new makerjs.paths.Line([xi+d,yi+h1], [xi+d+markShiftFour+markShift, yi+h1]),
         "H1ALine1":new makerjs.paths.Line([xi+d+markShiftFour,yi+h1], [xi+d+markShiftFour, yi+0]),
      }
};
var myLcgGapIMakerD1 = {
      paths: {
         "D1ALine0": new makerjs.paths.Line([xi+d/2,yi+h1], [xi+d/2+d1, yi+h1+d1]),
        
      }
};
  makerjs.model.addCaption(myLcgGapIMakerD, TxtD, [xi-d, yi-markShiftFour], [xi,yi-markShiftFour]);//W文字标注
  makerjs.model.addCaption(myLcgGapIMakerX, TxtX, [xi, yi-markShiftFour], [xi+x0,yi-markShiftFour]);//X文字标注
 makerjs.model.addCaption(myLcgGapIMakerH, TxtH, [xi-markShiftFour, yi+0], [xi-markShiftFour,yi+h]);//H文字标注    
  makerjs.model.addCaption(myLcgGapIMakerH1, TxtH1, [xi+d+markShiftFour+markShift, yi+0],[xi+d+markShiftFour+markShift,yi+h1]);//H1文字标注    
  makerjs.model.addCaption(myLcgGapIMakerD1, TxtD1, [xi+d/2,yi+h1],[xi+d/2+d1+markHolesOneD, yi+h1+d1+markHolesOneD]);//D文字标注  
  var markers = [myLcgGapIMakerH,myLcgGapIMakerX,myLcgGapIMakerD,myLcgGapIMakerH1,myLcgGapIMakerD1]
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function LongClipGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){

  var frame = outParam.shapeName;
  console.log("outParam==", outParam)
  console.log("model==", model)
  console.log("inputParam==", inputParam)
  console.log("outParam.shapeName==////////", outParam.shapeName)
  if (typeof (frame) == "undefined") {
        var D=inputParam.D,H=inputParam.H,D1=inputParam.D1,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
              glassW = outParam.glassW, glassH = outParam.glassH,
              markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
              markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
              d = D*1*myZoom,
              r = d/2,
                 d1 = D1*1*myZoom,
                 r1 = d1/2,
                 h= H*1*myZoom,
                 h1= H1*1*myZoom,
                   x0 = X*1*myZoom,
                   y0 = Y*1*myZoom;
                   if(d!==0){
                    var TxtD="∮"+D;
                   }
                   if(d1!==0){
                    var TxtD1="∮"+D1;
                   }
                   if(h!==0){
                    var TxtH=H;
                   }
                   if(h1!==0){
                    var TxtH1=H1;
                   }
                   if(x0!==0){          
                   var TxtX=X;
                   }
                   if(y0!==0){          
                    var TxtY=Y;
                   }
  } else {
        var D=inputParam.D,H=inputParam.H,D1=inputParam.D1,H1=inputParam.H1,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
        plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
              glassW = outParam.glassW, glassH = outParam.glassH,
              markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
              markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
              d = D*1*myZoom,
              r = d/2,
                 d1 = D1*1*myZoom,
                 r1 = d1/2,
                 h= H*1*myZoom,
                 h1= H1*1*myZoom,
                   x0 = X*1*myZoom,
                   y0 = Y*1*myZoom;
                   if(d!==0){
                    var TxtD="∮"+D;
                   }
                   if(d1!==0){
                    var TxtD1="∮"+D1;
                   }
                   if(h!==0){
                    var TxtH=H;
                   }
                   if(h1!==0){
                    var TxtH1=H1;
                   }
                   if(x0!==0){          
                   var TxtX=X;
                   }
                   if(y0!==0){          
                    var TxtY=Y;
                   }
  }
  if (frame == "Rectangle") {
        console.log("frame==undefined||||*****")
        //在A区域 计算孔的位置       
        var xa = x0, ya = glassH * 1 - y0,
              //B区域//只有Y标注没有X标注 
              xb = glassW * 1 / 2, yb = glassH * 1 - y0,
              //C区域
              xc = glassW * 1 - x0, yc = glassH * 1 - y0,
              //在G区域 计算孔的位置
              xg = x0, yg = y0,
              //在H区域 计算孔的位置
              //只标注Y
              xh = glassW * 1 / 2, yh = y0,
              //在I区域 计算孔的位置
              xi = glassW * 1 - x0, yi = y0;
        var OffsetParam = {};
  } else if (frame == "IsoscelesTrapezoid") {

  } else if (frame == "RightAngledTrapezoid") {d

  } else if (frame == "OtherTrapezoidsA") {

  } else if (frame == "OtherTrapezoidsB") {

  } else if (frame == "ParallelogramA") {


  } else if (frame == "ParallelogramB") {

  }
  // 坐标参数
  var paramCoordinate = { x0,d,r,d1,r1,h,h1,xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
  const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
  var areaPoint = "";
  console.log("//角7、地弹簧3 ------", allKeys)
  if (allKeys[0] == frame) {
        allKeys.shift();//去掉数组第一个元素
  }
  console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
  switch (area) {
        case "1": areaPoint = "A"; break;
        case "2": areaPoint = "B"; break;
        case "3": areaPoint = "C"; break;
        case "7": areaPoint = "G"; break;
        case "8": areaPoint = "H"; break;
        case "9": areaPoint = "I"; break;
  }
  /**
  * 画图
  * 
  */
  var ObjName = "myLongClipGap" + areaPoint;
  var ObjNameMmyLongClipGap = "myLongClipGap" + areaPoint + "Mark";//角标记
  ObjName = creatmyLongClipGap(areaPoint, paramCoordinate);//图形
  console.log("ObjName===|||||||||", ObjName);
  ObjNameMmyLongClipGap = creatmyLongClipGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
  console.log("ObjNameMmyLongClipGap===|||||||||角标注", ObjNameMmyLongClipGap);
  console.log("cutPlate.models====************", cutPlate.models),
        console.log("plate.models====**************", plate.models),
        console.log("markers.models====*************", markers.models),
     // myLcgGapAMakerH,myLcgGapAMakerX,myLcgGapAMakerD,myLcgGapAMakerH1,myLcgGapAMakerD1
        cutPlate.models["myLongClipGap" + areaPoint + myHolesNum] = ObjName;
  plate.models["myLongClipGap" + areaPoint + myHolesNum] = ObjName;
  plate.models["myLongClipGapH" + areaPoint + myHolesNum] = ObjNameMmyLongClipGap[0];
  plate.models["myLongClipGapX" + areaPoint + myHolesNum] = ObjNameMmyLongClipGap[1];
  plate.models["myLongClipGapD" + areaPoint + myHolesNum] = ObjNameMmyLongClipGap[2];
  plate.models["myLongClipGapH1" + areaPoint + myHolesNum] = ObjNameMmyLongClipGap[3];
  plate.models["myLongClipGapD1" + areaPoint + myHolesNum] = ObjNameMmyLongClipGap[4];
  markers.models["myLongClipGap" + areaPoint + myHolesNum] = ObjName;
  markers.models["myLongClipGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyLongClipGap[0];
  markers.models["myLongClipGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyLongClipGap[1];
  markers.models["myLongClipGapD" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyLongClipGap[2];
  markers.models["myLongClipGapH1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyLongClipGap[3];
  markers.models["myLongClipGapD1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyLongClipGap[4];

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default LongClipGap;