//11、旋转锁孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RotatLockHole(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
        //标记旋转锁孔 A位置孔左上
        var myRlhHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
        //标记孔
        var myRlhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkALeftup.origin = [xa, ya];      
        var myRlhHolesXMarkALeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkALeftup.origin =[xa, ya];      
        var myRlhHolesYMarkALeftup = new makerjs.models.Square(0);      
        myRlhHolesYMarkALeftup.origin = [xa, ya];  
  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  //画孔 A位置孔
        //标记旋转锁孔 A位置孔右上
        var myRlhHolesARightup= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
        var myRlhHolesMarkARightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkARightup.origin = [xa+w, ya];      
        var myRlhHolesXMarkARightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkARightup.origin =[xa+w, ya]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkARightup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkARightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkARightup, new makerjs.paths.Line([0,y0+markShift], [-w, y0+markShift]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkARightup, TxtW, [-w,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
  /*****************************************************************************/  
  /*****************************************************************************/ 
   //标记旋转锁孔 A位置孔左下
   var myRlhHolesALeftdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
        var myRlhHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkALeftdn.origin = [xa, ya-h];   
        var myRlhHolesYMarkALeftdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkALeftdn.origin = [xa, ya-h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkALeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, h]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkALeftdn, TxtH, [-x0-markShiftThree,0], [-x0-markShiftThree, h]);//Y文字标注
  /*****************************************************************************/ 
  plate.models["myRlhHolesALeftup"+myHolesNum]=myRlhHolesALeftup;
  cutPlate.models["myRlhHolesALeftup"+myHolesNum]=myRlhHolesALeftup;
  markers.models["myRlhHolesALeftup"+myHolesNum]=myRlhHolesALeftup;
  markers.models["myRlhHolesMarkALeftup"+myHolesNum]=myRlhHolesMarkALeftup;
  markers.models["myRlhHolesXMarkALeftup"+myHolesNum]=myRlhHolesXMarkALeftup;
  markers.models["myRlhHolesYMarkALeftup"+myHolesNum]=myRlhHolesYMarkALeftup;
  /*****************************************************************************/
  plate.models["myRlhHolesALeftdn"+myHolesNum]=myRlhHolesALeftdn;
  cutPlate.models["myRlhHolesALeftdn"+myHolesNum]=myRlhHolesALeftdn;
  markers.models["myRlhHolesALeftdn"+myHolesNum]=myRlhHolesALeftdn;
  markers.models["myRlhHolesMarkALeftdn"+myHolesNum]=myRlhHolesMarkALeftdn;
  markers.models["myRlhHolesYMarkALeftdn"+myHolesNum]=myRlhHolesYMarkALeftdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesARightup"+myHolesNum]=myRlhHolesARightup;
  cutPlate.models["myRlhHolesARightup"+myHolesNum]=myRlhHolesARightup;
  markers.models["myRlhHolesARightup"+myHolesNum]=myRlhHolesARightup;
  markers.models["myRlhHolesMarkARightup"+myHolesNum]=myRlhHolesMarkARightup;
  markers.models["myRlhHolesXMarkARightup"+myHolesNum]=myRlhHolesXMarkARightup;
  /*****************************************************************************/ 
  
  } else if(area==2){
   //画孔 B位置孔
        //标记旋转锁孔 B位置孔左上
        var myRlhHolesBLeftup= new makerjs.models.Holes(d/2, [[xb, yb]]);
        //标记孔
        var myRlhHolesMarkBLeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkBLeftup.origin = [xb, yb];      
        var myRlhHolesXMarkBLeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkBLeftup.origin =[xb, yb];      
        var myRlhHolesYMarkBLeftup = new makerjs.models.Square(0);      
        myRlhHolesYMarkBLeftup.origin = [xb, yb];  
  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkBLeftup, new makerjs.paths.Line([xb,0], [xb+markShiftFour, 0]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkBLeftup, new makerjs.paths.Line([0,y0], [xb+markShiftFour, y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/    
  makerjs.model.addPath(myRlhHolesYMarkBLeftup, new makerjs.paths.Line([xb+markShift,0], [xb+markShift, y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkBLeftup, TxtY, [xb+markShiftThree,0], [xb+markShiftThree, y0]);//Y文字标注
  /*****************************************************************************/ 
  //画孔 B位置孔
        //标记旋转锁孔 B位置孔右上
        var myRlhHolesBRightup= new makerjs.models.Holes(d/2, [[xb+w, yb]]);
        var myRlhHolesMarkBRightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkBRightup.origin = [xb+w, yb];      
        var myRlhHolesXMarkBRightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkBRightup.origin =[xb+w, yb]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkBRightup, new makerjs.paths.Line([0,0], [xb-w+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
  //makerjs.model.addPath(myRlhHolesMarkBRightup, new makerjs.paths.Line([0,0], [0, yd+markShiftTwo]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkBRightup, new makerjs.paths.Line([0,0], [-w, 0]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkBRightup, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
  /*****************************************************************************/ 
   //标记旋转锁孔 B位置孔左下
   var myRlhHolesBLeftdn= new makerjs.models.Holes(d/2, [[xb, yb-h]]);
        var myRlhHolesMarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkBLeftdn.origin = [xb, yb-h];   
        var myRlhHolesYMarkBLeftdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkBLeftdn.origin = [xb, yb-h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [xb+markShiftFour, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkBLeftdn, new makerjs.paths.Line([xb+markShift,0], [xb+markShift, h]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkBLeftdn, TxtH, [xb+markShiftThree,0], [xb+markShiftThree, h]);//Y文字标注
  /*****************************************************************************/ 
  plate.models["myRlhHolesBLeftup"+myHolesNum]=myRlhHolesBLeftup;
  cutPlate.models["myRlhHolesBLeftup"+myHolesNum]=myRlhHolesBLeftup;
  markers.models["myRlhHolesBLeftup"+myHolesNum]=myRlhHolesBLeftup;
  markers.models["myRlhHolesMarkBLeftup"+myHolesNum]=myRlhHolesMarkBLeftup;
  markers.models["myRlhHolesXMarkBLeftup"+myHolesNum]=myRlhHolesXMarkBLeftup;
  markers.models["myRlhHolesYMarkBLeftup"+myHolesNum]=myRlhHolesYMarkBLeftup;
  /*****************************************************************************/
  plate.models["myRlhHolesBLeftdn"+myHolesNum]=myRlhHolesBLeftdn;
  cutPlate.models["myRlhHolesBLeftdn"+myHolesNum]=myRlhHolesBLeftdn;
  markers.models["myRlhHolesBLeftdn"+myHolesNum]=myRlhHolesBLeftdn;
  markers.models["myRlhHolesMarkBLeftdn"+myHolesNum]=myRlhHolesMarkBLeftdn;
  markers.models["myRlhHolesYMarkBLeftdn"+myHolesNum]=myRlhHolesYMarkBLeftdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesBRightup"+myHolesNum]=myRlhHolesBRightup;
  cutPlate.models["myRlhHolesBRightup"+myHolesNum]=myRlhHolesBRightup;
  markers.models["myRlhHolesBRightup"+myHolesNum]=myRlhHolesBRightup;
  markers.models["myRlhHolesMarkBRightup"+myHolesNum]=myRlhHolesMarkBRightup;
  markers.models["myRlhHolesXMarkBRightup"+myHolesNum]=myRlhHolesXMarkBRightup;
  /*****************************************************************************/ 
     
  } else if(area==7){
  //画孔 G位置孔
        //标记旋转锁孔 G位置孔右上
        var myRlhHolesGRightup= new makerjs.models.Holes(d/2, [[xg, yg]]);
        //标记孔
        var myRlhHolesMarkGRightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkGRightup.origin = [xg, yg];      
        var myRlhHolesXMarkGRightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkGRightup.origin =[xg, yg];      
        var myRlhHolesYMarkGRightup = new makerjs.models.Square(0);      
        myRlhHolesYMarkGRightup.origin = [xg, yg];  
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkGRightup, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkGRightup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkGRightup, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftThree, -y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkGRightup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkGRightup, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkGRightup, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkGRightup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkGRightup, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
  /*****************************************************************************/ 
  //画孔 G位置孔
        //标记旋转锁孔 G位置孔左上
        var myRlhHolesGLeftup= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
        var myRlhHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkGLeftup.origin = [xg, yg+h];      
        var myRlhHolesXMarkGLeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkGLeftup.origin =[xg, yg+h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkGLeftup, new makerjs.paths.Line([-x0-markShiftTwo,-h], [-x0-markShiftTwo,0]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkGLeftup, TxtH,[-x0-markShiftFour,-h], [-x0-markShiftFour,0]);//X文字标注
  /*****************************************************************************/ 
   //标记旋转锁孔 G位置孔右下
   var myRlhHolesGRightdn= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
        var myRlhHolesMarkGRightdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkGRightdn.origin = [xg+w, yg];   
        var myRlhHolesYMarkGRightdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkGRightdn.origin = [xg+w, yg]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftThree]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [-w-markShiftTwo,0]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkGRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkGRightdn, new makerjs.paths.Line([-w,-y0-markShiftTwo], [0,-y0-markShiftTwo]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkGRightdn, TxtW, [-w,-y0-markShiftFour], [0,-y0-markShiftFour]);//Y文字标注
  /*****************************************************************************/ 
  plate.models["myRlhHolesGRightup"+myHolesNum]=myRlhHolesGRightup;
  cutPlate.models["myRlhHolesGRightup"+myHolesNum]=myRlhHolesGRightup;
  markers.models["myRlhHolesGRightup"+myHolesNum]=myRlhHolesGRightup;
  markers.models["myRlhHolesMarkGRightup"+myHolesNum]=myRlhHolesMarkGRightup;
  markers.models["myRlhHolesXMarkGRightup"+myHolesNum]=myRlhHolesXMarkGRightup;
  markers.models["myRlhHolesYMarkGRightup"+myHolesNum]=myRlhHolesYMarkGRightup;
  /*****************************************************************************/
  plate.models["myRlhHolesGRightdn"+myHolesNum]=myRlhHolesGRightdn;
  cutPlate.models["myRlhHolesGRightdn"+myHolesNum]=myRlhHolesGRightdn;
  markers.models["myRlhHolesGRightdn"+myHolesNum]=myRlhHolesGRightdn;
  markers.models["myRlhHolesMarkGRightdn"+myHolesNum]=myRlhHolesMarkGRightdn;
  markers.models["myRlhHolesYMarkGRightdn"+myHolesNum]=myRlhHolesYMarkGRightdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesGLeftup"+myHolesNum]=myRlhHolesGLeftup;
  cutPlate.models["myRlhHolesGLeftup"+myHolesNum]=myRlhHolesGLeftup;
  markers.models["myRlhHolesGLeftup"+myHolesNum]=myRlhHolesGLeftup;
  markers.models["myRlhHolesMarkGLeftup"+myHolesNum]=myRlhHolesMarkGLeftup;
  markers.models["myRlhHolesXMarkGLeftup"+myHolesNum]=myRlhHolesXMarkGLeftup;
  /*****************************************************************************/ 
     
  } else if(area==8){
    //画孔 H位置孔
        //标记旋转锁孔 H位置孔右上
        var myRlhHolesHRightup= new makerjs.models.Holes(d/2, [[xh, yh]]);
        //标记孔
        var myRlhHolesMarkHRightup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkHRightup.origin = [xh, yh];      
        var myRlhHolesXMarkHRightup = new makerjs.models.Square(0);      
        myRlhHolesXMarkHRightup.origin =[xh, yh];      
        var myRlhHolesYMarkHRightup = new makerjs.models.Square(0);      
        myRlhHolesYMarkHRightup.origin = [xh, yh];  
  /*****************************************************************************/ 

  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([xh,0], [xh+markShiftFour, 0]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([0,-y0], [xh+markShiftFour, -y0]));//X轴加长标线
  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发Y方向竖向标线


  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([xh,0], [xh+markShiftFour, 0]));//Y轴加长标线
  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([0,-y0], [xh+markShiftFour, -y0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/    
  makerjs.model.addPath(myRlhHolesYMarkHRightup, new makerjs.paths.Line([xh+markShift,0], [xh+markShift, -y0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkHRightup, TxtY, [xh+markShiftThree,0], [xh+markShiftThree, -y0]);//Y文字标注
  /*****************************************************************************/ 
  //画孔 H位置孔
        //标记旋转锁孔 H位置孔左上
        var myRlhHolesHLeftup= new makerjs.models.Holes(d/2, [[xh+w, yh]]);
        var myRlhHolesMarkHLeftup = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkHLeftup.origin = [xh+w, yh];      
        var myRlhHolesXMarkHLeftup = new makerjs.models.Square(0);      
        myRlhHolesXMarkHLeftup.origin =[xh+w, yh]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkHLeftup, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkHLeftup, new makerjs.paths.Line([0,0], [xb-w+markShiftFour,0]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/   
  makerjs.model.addPath(myRlhHolesXMarkHLeftup, new makerjs.paths.Line([-w,0], [0, 0]));//X短标线
  makerjs.model.addCaption(myRlhHolesXMarkHLeftup, TxtW, [-w,0+markShift], [0, 0+markShift]);//X文字标注
  /*****************************************************************************/ 
   //标记旋转锁孔 H位置孔右下
   var myRlhHolesHRightdn= new makerjs.models.Holes(d/2, [[xh, yh+h]]);
        var myRlhHolesMarkHRightdn = new makerjs.models.Square(0); //孔的原点       
        myRlhHolesMarkHRightdn.origin = [xh, yh+h];   
        var myRlhHolesYMarkHRightdn = new makerjs.models.Square(0);      
        myRlhHolesYMarkHRightdn.origin = [xh, yh+h]; 
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [xb+markShiftThree, 0]));//从孔圆心出发X方向横向标线
  makerjs.model.addPath(myRlhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [0, -h]));//从孔圆心出发Y方向竖向标线
  /*****************************************************************************/ 
  makerjs.model.addPath(myRlhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
  makerjs.model.addCaption(myRlhHolesMarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
  /*****************************************************************************/  
  /*****************************************************************************/     
  makerjs.model.addPath(myRlhHolesYMarkHRightdn, new makerjs.paths.Line([xb+markShift, -h],[xb+markShift,0]));//Y短标线
  makerjs.model.addCaption(myRlhHolesYMarkHRightdn, TxtH, [xb+markShiftThree,0], [xb+markShiftThree, -h]);//Y文字标注
  /*****************************************************************************/ 
  /*****************************************************************************/ 
  plate.models["myRlhHolesHRightup"+myHolesNum]=myRlhHolesHRightup;
  cutPlate.models["myRlhHolesHRightup"+myHolesNum]=myRlhHolesHRightup;
  markers.models["myRlhHolesHRightup"+myHolesNum]=myRlhHolesHRightup;
  markers.models["myRlhHolesMarkHRightup"+myHolesNum]=myRlhHolesMarkHRightup;
  markers.models["myRlhHolesXMarkHRightup"+myHolesNum]=myRlhHolesXMarkHRightup;
  markers.models["myRlhHolesYMarkHRightup"+myHolesNum]=myRlhHolesYMarkHRightup;
  /*****************************************************************************/
  plate.models["myRlhHolesHRightdn"+myHolesNum]=myRlhHolesHRightdn;
  cutPlate.models["myRlhHolesHRightdn"+myHolesNum]=myRlhHolesHRightdn;
  markers.models["myRlhHolesHRightdn"+myHolesNum]=myRlhHolesHRightdn;
  markers.models["myRlhHolesMarkHRightdn"+myHolesNum]=myRlhHolesMarkHRightdn;
  markers.models["myRlhHolesYMarkHRightdn"+myHolesNum]=myRlhHolesYMarkHRightdn;
  /*****************************************************************************/ 
  plate.models["myRlhHolesHLeftup"+myHolesNum]=myRlhHolesHLeftup;
  cutPlate.models["myRlhHolesHLeftup"+myHolesNum]=myRlhHolesHLeftup;
  markers.models["myRlhHolesHLeftup"+myHolesNum]=myRlhHolesHLeftup;
  markers.models["myRlhHolesMarkHLeftup"+myHolesNum]=myRlhHolesMarkHLeftup;
  markers.models["myRlhHolesXMarkHLeftup"+myHolesNum]=myRlhHolesXMarkHLeftup;
  /*****************************************************************************/ 
      
  }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default RotatLockHole;