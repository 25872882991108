//6、晾物孔
import makerjs from "makerjs";
// inputParam,model,this.myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function HangHole(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H1=inputParam.H1,H2=inputParam.H2,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
   d = D*1*myZoom,
   w= W*1*myZoom,
   h1= H1*1*myZoom,
   h2= H2*1*myZoom,
   x0 = X*1*myZoom,
   y0 = Y*1*myZoom;

/*
   d = D*1,
 w= W*1,
      h1= H1*1,
      h2= H2*1,
        x0 = X*1,
        y0 = Y*1;
        */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(h1!==0){
         var TxtH1=H1;
        }
        if(h2!==0){
         var TxtH2=H2;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
         //标记晾物孔 A位置孔左下
         var myHhHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
         //标记孔
         var myHhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkALeftup.origin = [xa, ya];      
         var myKhHolesXMarkALeftup = new makerjs.models.Square(0);      
         myKhHolesXMarkALeftup.origin =[xa, ya];      
         var myKhHolesYMarkALeftup = new makerjs.models.Square(0);      
         myKhHolesYMarkALeftup.origin = [xa, ya]; 
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
   /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   //画孔 A位置孔
         //标记晾物孔 A位置孔左上
         var myHhHolesARightup= new makerjs.models.Holes(d/2, [[xa, ya-w]]);
         var myHhHolesMarkARightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkARightup.origin = [xa, ya-w];      
         var myHhHolesXMarkARightup = new makerjs.models.Square(0);      
         myHhHolesXMarkARightup.origin =[xa, ya-w];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkARightup, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发Y方向竖向标线             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkARightup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo,w]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkARightup, TxtW, [-x0-markShiftThree,0], [-x0-markShiftThree,w]);//X文字标注
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔中下
    var myHhHolesALeftmd= new makerjs.models.Holes(d/2, [[xa+h1, ya]]);
         //标记孔
         var myHhHolesMarkALeftmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkALeftmd.origin = [xa+h1, ya];     
         var myHhHolesYMarkALeftmd = new makerjs.models.Square(0);      
         myHhHolesYMarkALeftmd.origin = [xa+h1, ya];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkALeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addCaption(myHhHolesYMarkALeftmd, TxtH1, [-h1,markShiftTwo], [0, markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔中上
    var myKhHolesARightmd= new makerjs.models.Holes(d/2, [[xa+h1, ya-w]]);
         //标记孔
         var myKhHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkARightmd.origin = [xa+h1, ya-w];      
         var myKhHolesXMarkARightmd = new makerjs.models.Square(0);      
         myKhHolesXMarkARightmd.origin =[xa+h1, ya-w];      
         var myKhHolesYMarkARightmd = new makerjs.models.Square(0);      
         myKhHolesYMarkARightmd.origin = [xa+h1, ya-w];  
   /*****************************************************************************/ 
 makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([-h1,0], [0, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkARightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkARightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔右下
    var myKhHolesALeftdn= new makerjs.models.Holes(d/2, [[xa+h1+h2, ya]]);
         //标记孔
         var myKhHolesMarkALeftdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkALeftdn.origin = [xa+h1+h2, ya];     
         var myKhHolesYMarkALeftdn = new makerjs.models.Square(0);      
         myKhHolesYMarkALeftdn.origin = [xa+h1+h2, ya];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/ 
   makerjs.model.addCaption(myKhHolesYMarkALeftdn, TxtH2, [-h2,markShiftTwo], [0, markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
    //标记晾物孔 A位置孔右上
    var myHhHolesARightdn= new makerjs.models.Holes(d/2, [[xa+h1+h2, ya-w]]);
         //标记孔
         var myHhHolesMarkARightdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkARightdn.origin = [xa+h1+h2, ya-w];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightdn, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkARightdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发Y方向竖向标线          
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/ 
   plate.models["myHhHolesALeftup"+myHolesNum]=myHhHolesALeftup;
   cutPlate.models["myHhHolesALeftup"+myHolesNum]=myHhHolesALeftup;
   markers.models["myHhHolesALeftup"+myHolesNum]=myHhHolesALeftup;
   markers.models["myHhHolesMarkALeftup"+myHolesNum]=myHhHolesMarkALeftup;
   markers.models["myKhHolesXMarkALeftup"+myHolesNum]=myKhHolesXMarkALeftup;
   markers.models["myKhHolesYMarkALeftup"+myHolesNum]=myKhHolesYMarkALeftup;
   /*****************************************************************************/
   plate.models["myHhHolesALeftmd"+myHolesNum]=myHhHolesALeftmd;
   cutPlate.models["myHhHolesALeftmd"+myHolesNum]=myHhHolesALeftmd;
   markers.models["myHhHolesALeftmd"+myHolesNum]=myHhHolesALeftmd;
   markers.models["myHhHolesMarkALeftmd"+myHolesNum]=myHhHolesMarkALeftmd;
   markers.models["myHhHolesYMarkALeftmd"+myHolesNum]=myHhHolesYMarkALeftmd;
   /*****************************************************************************/ 
   plate.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
   cutPlate.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
   markers.models["myKhHolesALeftdn"+myHolesNum]=myKhHolesALeftdn;
   markers.models["myKhHolesMarkALeftdn"+myHolesNum]=myKhHolesMarkALeftdn;
   markers.models["myKhHolesYMarkALeftdn"+myHolesNum]=myKhHolesYMarkALeftdn;
   /*****************************************************************************/ 
   plate.models["myHhHolesARightup"+myHolesNum]=myHhHolesARightup;
   cutPlate.models["myHhHolesARightup"+myHolesNum]=myHhHolesARightup;
   markers.models["myHhHolesARightup"+myHolesNum]=myHhHolesARightup;
   markers.models["myHhHolesMarkARightup"+myHolesNum]=myHhHolesMarkARightup;
   markers.models["myHhHolesXMarkARightup"+myHolesNum]=myHhHolesXMarkARightup;
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   plate.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
   cutPlate.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
   markers.models["myKhHolesARightmd"+myHolesNum]=myKhHolesARightmd;
   markers.models["myKhHolesMarkARightmd"+myHolesNum]=myKhHolesMarkARightmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesARightdn"+myHolesNum]=myHhHolesARightdn;
   cutPlate.models["myHhHolesARightdn"+myHolesNum]=myHhHolesARightdn;
   markers.models["myHhHolesARightdn"+myHolesNum]=myHhHolesARightdn;
   markers.models["myHhHolesMarkARightdn"+myHolesNum]=myHhHolesMarkARightdn;
   /*****************************************************************************/ 
  
   }else if(area==2){
   //画孔 B位置孔
         //标记晾物孔 B位置孔左上
         var myHhHolesBLeftup= new makerjs.models.Holes(d/2, [[xb-h1, yb]]);
         //标记孔
         var myHhHolesMarkBLeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkBLeftup.origin = [xb-h1, yb];      
         var myKhHolesXMarkBLeftup = new makerjs.models.Square(0);      
         myKhHolesXMarkBLeftup.origin =[xb-h1, yb];      
         var myKhHolesYMarkBLeftup = new makerjs.models.Square(0);      
         myKhHolesYMarkBLeftup.origin = [xb-h1, yb];     
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBLeftup, new makerjs.paths.Line([-xe+h1-markShiftFour,0,], [0,0]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkBLeftup, new makerjs.paths.Line([-xe+h1-markShiftFour,y0,], [-xe+h1,y0]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [ h1,0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkBLeftup, new makerjs.paths.Line([-xe+h1-markShiftTwo,0], [-xe+h1-markShiftTwo,y0]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkBLeftup, TxtY, [-xe+h1-markShiftFour,0], [-xe+h1-markShiftFour,y0]);//X文字标注
   /*****************************************************************************/  
   //画孔 B位置孔
         //标记晾物孔 B位置孔右上
         var myHhHolesBRightup= new makerjs.models.Holes(d/2, [[xb-h1, yb-w]]);
         //标记孔
         var myHhHolesMarkBRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkBRightup.origin = [xb-h1, yb-w];      
         var myHhHolesXMarkBRightup = new makerjs.models.Square(0);      
         myHhHolesXMarkBRightup.origin =[xb-h1, yb-w];     
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBRightup, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkBRightup, new makerjs.paths.Line([0,0], [h1,0]));//从孔圆心出发Y方向竖向标线             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkBRightup, new makerjs.paths.Line([0,0], [0,w]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkBRightup, TxtW, [-markShiftTwo,0], [-markShiftTwo,w]);//X文字标注
   /*****************************************************************************/    
    //标记晾物孔 B位置孔左中
    var myHhHolesBLeftmd= new makerjs.models.Holes(d/2, [[xb, yb]]);
         //标记孔
         var myHhHolesMarkBLeftmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkBLeftmd.origin = [xb, yb];     
         var myHhHolesYMarkBLeftmd = new makerjs.models.Square(0);      
         myHhHolesYMarkBLeftmd.origin = [xb, yb];     
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [0,y0]));//从孔圆心出发X方向横向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkBLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/     
   makerjs.model.addPath(myHhHolesYMarkBLeftmd, new makerjs.paths.Line([-h1,0], [0,0]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkBLeftmd, TxtH1, [-h1,+markShiftTwo], [0,+markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
    //标记晾物孔B位置孔右中
    var myKhHolesBRightmd= new makerjs.models.Holes(d/2, [[xb, yb-w]]);
         //标记孔
         var myKhHolesMarkBRightmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkBRightmd.origin = [xb, yb-w];      
         var myKhHolesXMarkBRightmd = new makerjs.models.Square(0);      
         myKhHolesXMarkBRightmd.origin =[xb, yb-w];      
         var myKhHolesYMarkBRightmd = new makerjs.models.Square(0);      
         myKhHolesYMarkBRightmd.origin = [xb, yb-w];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkBRightmd, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线             
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkBRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkBRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注   
   /*****************************************************************************/    
    //标记晾物孔 B位置孔左下
    var myKhHolesBLeftdn= new makerjs.models.Holes(d/2, [[xb+h2, yb]]);
         //标记孔
         var myKhHolesMarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkBLeftdn.origin = [xb+h2, yb];     
         var myKhHolesYMarkBLeftdn = new makerjs.models.Square(0);      
         myKhHolesYMarkBLeftdn.origin = [xb+h2, yb];     
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [-h2,0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesYMarkBLeftdn, new makerjs.paths.Line([-h2,0], [0,0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkBLeftdn, TxtH2, [-h2,+markShiftTwo], [0,+markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/    
    //标记晾物孔 B位置孔右下
    var myHhHolesBRightdn= new makerjs.models.Holes(d/2, [[xb+h2, yb-w]]);
         //标记孔
         var myHhHolesMarkBRightdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkBRightdn.origin = [xb+h2, yb-w];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBRightdn, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkBRightdn, new makerjs.paths.Line([0,0], [-h2,0]));//从孔圆心出发Y方向竖向标线          
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkBRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkBRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/ 
   plate.models["myHhHolesBLeftup"+myHolesNum]=myHhHolesBLeftup;
   cutPlate.models["myHhHolesBLeftup"+myHolesNum]=myHhHolesBLeftup;
   markers.models["myHhHolesBLeftup"+myHolesNum]=myHhHolesBLeftup;
   markers.models["myHhHolesMarkBLeftup"+myHolesNum]=myHhHolesMarkBLeftup;
   markers.models["myKhHolesXMarkBLeftup"+myHolesNum]=myKhHolesXMarkBLeftup;
   markers.models["myKhHolesYMarkBLeftup"+myHolesNum]=myKhHolesYMarkBLeftup;
   /*****************************************************************************/
   plate.models["myHhHolesBLeftmd"+myHolesNum]=myHhHolesBLeftmd;
   cutPlate.models["myHhHolesBLeftmd"+myHolesNum]=myHhHolesBLeftmd;
   markers.models["myHhHolesBLeftmd"+myHolesNum]=myHhHolesBLeftmd;
   markers.models["myHhHolesMarkBLeftmd"+myHolesNum]=myHhHolesMarkBLeftmd;
   markers.models["myHhHolesYMarkBLeftmd"+myHolesNum]=myHhHolesYMarkBLeftmd;
   /*****************************************************************************/ 
   plate.models["myKhHolesBLeftdn"+myHolesNum]=myKhHolesBLeftdn;
   cutPlate.models["myKhHolesBLeftdn"+myHolesNum]=myKhHolesBLeftdn;
   markers.models["myKhHolesBLeftdn"+myHolesNum]=myKhHolesBLeftdn;
   markers.models["myKhHolesMarkBLeftdn"+myHolesNum]=myKhHolesMarkBLeftdn;
   markers.models["myKhHolesYMarkBLeftdn"+myHolesNum]=myKhHolesYMarkBLeftdn;
   /*****************************************************************************/ 
   plate.models["myHhHolesBRightup"+myHolesNum]=myHhHolesBRightup;
   cutPlate.models["myHhHolesBRightup"+myHolesNum]=myHhHolesBRightup;
   markers.models["myHhHolesBRightup"+myHolesNum]=myHhHolesBRightup;
   markers.models["myHhHolesMarkBRightup"+myHolesNum]=myHhHolesMarkBRightup;
   markers.models["myHhHolesXMarkBRightup"+myHolesNum]=myHhHolesXMarkBRightup;
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   plate.models["myKhHolesBRightmd"+myHolesNum]=myKhHolesBRightmd;
   cutPlate.models["myKhHolesBRightmd"+myHolesNum]=myKhHolesBRightmd;
   markers.models["myKhHolesBRightmd"+myHolesNum]=myKhHolesBRightmd;
   markers.models["myKhHolesMarkBRightmd"+myHolesNum]=myKhHolesMarkBRightmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesBRightdn"+myHolesNum]=myHhHolesBRightdn;
   cutPlate.models["myHhHolesBRightdn"+myHolesNum]=myHhHolesBRightdn;
   markers.models["myHhHolesBRightdn"+myHolesNum]=myHhHolesBRightdn;
   markers.models["myHhHolesMarkBRightdn"+myHolesNum]=myHhHolesMarkBRightdn;
   /*****************************************************************************/ 
   } else if(area==3){
     //画孔 C位置孔
         //标记晾物孔 C位置孔右上
         var myHhHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
         //标记孔
         var myHhHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCRightup.origin = [xc, yc];      
         var myKhHolesXMarkCRightup = new makerjs.models.Square(0);      
         myKhHolesXMarkCRightup.origin =[xc, yc];      
         var myKhHolesYMarkCRightup = new makerjs.models.Square(0);      
         myKhHolesYMarkCRightup.origin = [xc, yc];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftTwo]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
   // // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkCRightup, TxtX, [0, y0+markShiftTwo], [x0,y0+markShiftTwo]);//X文字标注
   // /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkCRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkCRightup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔 C位置孔
         //标记晾物孔 C位置孔左上
         var myHhHolesCLeftup= new makerjs.models.Holes(d/2, [[xc, yc-w]]);
         //标记孔
         var myHhHolesMarkCLeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCLeftup.origin = [xc, yc-w];      
         var myHhHolesXMarkCLeftup = new makerjs.models.Square(0);      
         myHhHolesXMarkCLeftup.origin =[xc, yc-w];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkCLeftup, new makerjs.paths.Line([0,0], [0, w]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkCLeftup, TxtW, [0-markShiftTwo, 0], [0-markShiftTwo,w]);//X文字标注
   // /*****************************************************************************/  
    //标记晾物孔 C位置孔右中
    var myHhHolesCRightmd= new makerjs.models.Holes(d/2, [[xc-h1, yc]]);
         //标记孔
         var myHhHolesMarkCRightmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCRightmd.origin = [xc-h1, yc];     
         var myHhHolesYMarkCRightmd = new makerjs.models.Square(0);      
         myHhHolesYMarkCRightmd.origin = [xc-h1, yc];     
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [h1,0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/      
   makerjs.model.addPath(myHhHolesYMarkCRightmd, new makerjs.paths.Line([0,0], [h1, 0]));//Y短标线
   makerjs.model.addCaption(myHhHolesYMarkCRightmd, TxtH1, [0,0+markShiftTwo], [h1, 0+markShiftTwo]);//Y文字标注
   // /*****************************************************************************/    
    //标记晾物孔 C位置孔左中
    var myKhHolesCLeftmd= new makerjs.models.Holes(d/2, [[xc-h1, yc-w]]);
         //标记孔
         var myKhHolesMarkCLeftmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkCLeftmd.origin = [xc-h1, yc-w];      
         var myKhHolesXMarkCLeftmd = new makerjs.models.Square(0);      
         myKhHolesXMarkCLeftmd.origin =[xc-h1, yc-w];      
         var myKhHolesYMarkCLeftmd = new makerjs.models.Square(0);      
         myKhHolesYMarkCLeftmd.origin = [xc-h1, yc-w];  
    // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [h1, 0]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkCLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注   
   /*****************************************************************************/    
    //标记晾物孔C位置孔右下
    var myKhHolesCRightdn= new makerjs.models.Holes(d/2, [[xc-h1-h2, yc]]);
         //标记孔
         var myKhHolesMarkCRightdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkCRightdn.origin = [xc-h1-h2, yc];        
         var myKhHolesYMarkCRightdn = new makerjs.models.Square(0);      
         myKhHolesYMarkCRightdn.origin = [xc-h1-h2, yc];     
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [h2, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/       
   makerjs.model.addPath(myKhHolesYMarkCRightdn, new makerjs.paths.Line([0,0], [h2, 0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkCRightdn, TxtH2,[0,0+markShiftTwo], [h2, 0+markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/    
    //标记晾物孔C位置孔左下
    var myHhHolesCLeftdn= new makerjs.models.Holes(d/2, [[xc-h1-h2, yc-w]]);
         //标记孔
         var myHhHolesMarkCLeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkCLeftdn.origin =[xc-h1-h2, yc-w];      
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [0, w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [h2, 0]));//从孔圆心出发Y方向竖向标线             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkCLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkCLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   plate.models["myHhHolesCRightup"+myHolesNum]=myHhHolesCRightup;
   cutPlate.models["myHhHolesCRightup"+myHolesNum]=myHhHolesCRightup;
   markers.models["myHhHolesCRightup"+myHolesNum]=myHhHolesCRightup;
   markers.models["myHhHolesMarkCRightup"+myHolesNum]=myHhHolesMarkCRightup;
   markers.models["myKhHolesXMarkCRightup"+myHolesNum]=myKhHolesXMarkCRightup;
   markers.models["myKhHolesYMarkCRightup"+myHolesNum]=myKhHolesYMarkCRightup;
   // /*****************************************************************************/
   plate.models["myHhHolesCRightmd"+myHolesNum]=myHhHolesCRightmd;
   cutPlate.models["myHhHolesCRightmd"+myHolesNum]=myHhHolesCRightmd;
   markers.models["myHhHolesCRightmd"+myHolesNum]=myHhHolesCRightmd;
   markers.models["myHhHolesMarkCRightmd"+myHolesNum]=myHhHolesMarkCRightmd;
   markers.models["myHhHolesYMarkCRightmd"+myHolesNum]=myHhHolesYMarkCRightmd;   
   /*****************************************************************************/ 
   plate.models["myKhHolesCRightdn"+myHolesNum]=myKhHolesCRightdn;
   cutPlate.models["myKhHolesCRightdn"+myHolesNum]=myKhHolesCRightdn;
   markers.models["myKhHolesCRightdn"+myHolesNum]=myKhHolesCRightdn;
   markers.models["myKhHolesMarkCRighttdn"+myHolesNum]=myKhHolesMarkCRightdn;
   markers.models["myKhHolesYMarkCRightdn"+myHolesNum]=myKhHolesYMarkCRightdn;   
   /*****************************************************************************/ 
   plate.models["myHhHolesCLeftup"+myHolesNum]=myHhHolesCLeftup;
   cutPlate.models["myHhHolesCLeftup"+myHolesNum]=myHhHolesCLeftup;
   markers.models["myHhHolesCLeftup"+myHolesNum]=myHhHolesCLeftup;
   markers.models["myHhHolesMarkCLeftup"+myHolesNum]=myHhHolesMarkCLeftup;
   markers.models["myHhHolesXMarkCLeftup"+myHolesNum]=myHhHolesXMarkCLeftup;
   /*****************************************************************************/ 
   plate.models["myKhHolesCLeftmd"+myHolesNum]=myKhHolesCLeftmd;
   cutPlate.models["myKhHolesCLeftmd"+myHolesNum]=myKhHolesCLeftmd;
   markers.models["myKhHolesCLeftmd"+myHolesNum]=myKhHolesCLeftmd;
   markers.models["myKhHolesMarkCLeftmd"+myHolesNum]=myKhHolesMarkCLeftmd;   
   /*****************************************************************************/ 
   plate.models["myHhHolesCLeftdn"+myHolesNum]=myHhHolesCLeftdn;
   cutPlate.models["myHhHolesCLeftdn"+myHolesNum]=myHhHolesCLeftdn;
   markers.models["myHhHolesCLeftdn"+myHolesNum]=myHhHolesCLeftdn;
   markers.models["myHhHolesMarkCLeftdn"+myHolesNum]=myHhHolesMarkCLeftdn;
   /*****************************************************************************/ 
   } else if(area==7){
    //画孔 G位置孔
         //标记晾物孔 G位置孔左下
         var myHhHolesGLeftdn= new makerjs.models.Holes(d/2, [[xg, yg]]);
         //标记孔
         var myHhHolesMarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGLeftdn.origin = [xg, yg];      
         var myKhHolesXMarkGLeftdn = new makerjs.models.Square(0);      
         myKhHolesXMarkGLeftdn.origin =[xg, yg];      
         var myKhHolesYMarkGLeftdn = new makerjs.models.Square(0);      
         myKhHolesYMarkGLeftdn.origin = [xg, yg];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([-x0,-y0], [-x0-markShiftFour, -y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftdn, new makerjs.paths.Line([0,0], [d, d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkGLeftdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkGLeftdn, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
   /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkGLeftdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkGLeftdn, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔 G位置孔
         //标记晾物孔 G位置孔右下
         var myHhHolesGRightdn= new makerjs.models.Holes(d/2, [[xg, yg+w]]);
         //标记孔
         var myHhHolesMarkGRightdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGRightdn.origin = [xg, yg+w];      
         var myHhHolesXMarkGRightdn = new makerjs.models.Square(0);      
         myHhHolesXMarkGRightdn.origin =[xg, yg+w]; 
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [0,-w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [-x0-markShiftFour,0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightdn, new makerjs.paths.Line([0,0], [d, d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGRightdn, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkGRightdn, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo,0-w]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkGRightdn, TxtW, [-x0-markShiftFour,-w], [0-x0-markShiftFour,0]);//Y文字标注
   /*****************************************************************************/  
   /*****************************************************************************/ 
    //标记晾物孔 G位置孔左中
    var myHhHolesGLeftmd= new makerjs.models.Holes(d/2, [[xg+h1, yg]]);
         //标记孔
         var myHhHolesMarkGLeftmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGLeftmd.origin = [xg+h1, yg];     
         var myHhHolesYMarkGLeftmd = new makerjs.models.Square(0);      
         myHhHolesYMarkGLeftmd.origin = [xg+h1, yg];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发X方向横向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [d, d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGLeftmd, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
   /*****************************************************************************/  
   makerjs.model.addCaption(myHhHolesYMarkGLeftmd, TxtH1, [-h1, -markShiftTwo], [0,-markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
    //标记晾物孔 G位置孔右中
    var myKhHolesGRightmd= new makerjs.models.Holes(d/2, [[xg+h1, yg+w]]);
         //标记孔
         var myKhHolesMarkGRightmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkGRightmd.origin = [xg+h1, yg+w];      
         var myKhHolesXMarkGRightmd = new makerjs.models.Square(0);      
         myKhHolesXMarkGRightmd.origin =[xg+h1, yg+w];      
         var myKhHolesYMarkGRightmd = new makerjs.models.Square(0);      
         myKhHolesYMarkGRightmd.origin = [xg+h1, yg+w];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发Y方向竖向标线          
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [d, d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkGRightmd, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
   // /*****************************************************************************/ 
    //标记晾物孔 G位置孔左上
    var myKhHolesGLeftup= new makerjs.models.Holes(d/2, [[xg+h1+h2, yg]]);
         //标记孔
         var myKhHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkGLeftup.origin = [xg+h1+h2, yg]; 
         var myKhHolesYMarkGLeftup = new makerjs.models.Square(0);      
         myKhHolesYMarkGLeftup.origin = [xg+h1+h2, yg];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发X方向横向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   /*****************************************************************************/   
   makerjs.model.addCaption(myKhHolesYMarkGLeftup, TxtH2, [-h2, -markShiftTwo], [0,-markShiftTwo]);//Y文字标注
   // /*****************************************************************************/ 
    //标记晾物孔 G位置孔右上
    var myHhHolesGRightup= new makerjs.models.Holes(d/2, [[xg+h1+h2, yg+w]]);
         //标记孔
         var myHhHolesMarkGRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkGRightup.origin = [xg+h1+h2, yg+w]; 
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightup, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkGRightup, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/  
   plate.models["myHhHolesGLeftdn"+myHolesNum]=myHhHolesGLeftdn;
   cutPlate.models["myHhHolesGLeftdn"+myHolesNum]=myHhHolesGLeftdn;
   markers.models["myHhHolesGLeftdn"+myHolesNum]=myHhHolesGLeftdn;
   markers.models["myHhHolesMarkGLeftdn"+myHolesNum]=myHhHolesMarkGLeftdn;
   markers.models["myKhHolesXMarkGLeftdn"+myHolesNum]=myKhHolesXMarkGLeftdn;
   markers.models["myKhHolesYMarkGLeftdn"+myHolesNum]=myKhHolesYMarkGLeftdn;
   /*****************************************************************************/
   plate.models["myHhHolesGLeftmd"+myHolesNum]=myHhHolesGLeftmd;
   cutPlate.models["myHhHolesGLeftmd"+myHolesNum]=myHhHolesGLeftmd;
   markers.models["myHhHolesGLeftmd"+myHolesNum]=myHhHolesGLeftmd;
   markers.models["myHhHolesMarkGLeftmd"+myHolesNum]=myHhHolesMarkGLeftmd;
   markers.models["myHhHolesYMarkGLeftmd"+myHolesNum]=myHhHolesYMarkGLeftmd;
   // /*****************************************************************************/ 
   plate.models["myKhHolesGLeftup"+myHolesNum]=myKhHolesGLeftup;
   cutPlate.models["myKhHolesGLeftup"+myHolesNum]=myKhHolesGLeftup;
   markers.models["myKhHolesGLeftup"+myHolesNum]=myKhHolesGLeftup;
   markers.models["myKhHolesMarkGLeftup"+myHolesNum]=myKhHolesMarkGLeftup;
   markers.models["myKhHolesYMarkGLeftup"+myHolesNum]=myKhHolesYMarkGLeftup;
   // /*****************************************************************************/ 
   plate.models["myHhHolesGRightdn"+myHolesNum]=myHhHolesGRightdn;
   cutPlate.models["myHhHolesGRightdn"+myHolesNum]=myHhHolesGRightdn;
   markers.models["myHhHolesGRightdn"+myHolesNum]=myHhHolesGRightdn;
   markers.models["myHhHolesMarkGRightdn"+myHolesNum]=myHhHolesMarkGRightdn;
   markers.models["myHhHolesXMarkGRightdn"+myHolesNum]=myHhHolesXMarkGRightdn;
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   plate.models["myKhHolesGRightmd"+myHolesNum]=myKhHolesGRightmd;
   cutPlate.models["myKhHolesGRightmd"+myHolesNum]=myKhHolesGRightmd;
   markers.models["myKhHolesGRightmd"+myHolesNum]=myKhHolesGRightmd;
   markers.models["myKhHolesMarkGRightmd"+myHolesNum]=myKhHolesMarkGRightmd;
   // /*****************************************************************************/ 
   plate.models["myHhHolesGRightup"+myHolesNum]=myHhHolesGRightup;
   cutPlate.models["myHhHolesGRightup"+myHolesNum]=myHhHolesGRightup;
   markers.models["myHhHolesGRightup"+myHolesNum]=myHhHolesGRightup;
   markers.models["myHhHolesMarkGRightup"+myHolesNum]=myHhHolesMarkGRightup;
   /*****************************************************************************/  
   } else if(area==8){
    //画孔 H位置孔
         //标记晾物孔 H位置孔右上
         var myHhHolesHRightup= new makerjs.models.Holes(d/2, [[xh-h1, yh]]);
         //标记孔
         var myHhHolesMarkHRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkHRightup.origin = [xh-h1, yh];      
         var myKhHolesXMarkHRightup = new makerjs.models.Square(0);      
         myKhHolesXMarkHRightup.origin =[xh-h1, yh];      
         var myKhHolesYMarkHRightup = new makerjs.models.Square(0);      
         myKhHolesYMarkHRightup.origin = [xh-h1, yh];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//Y轴加长标线
  makerjs.model.addPath(myHhHolesMarkHRightup, new makerjs.paths.Line([0,0], [-xe+h1, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkHRightup, new makerjs.paths.Line([-xe+h1,-y0], [-xe+h1-markShiftFour, -y0]));//从孔圆心出发Y方向竖向标线
   makerjs.model.addPath(myHhHolesMarkHRightup, new makerjs.paths.Line([-xe+h1,0], [-xe+h1-markShiftFour, 0]));//从孔圆心出发Y方向竖向标线
   // // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkHRightup, new makerjs.paths.Line([-xe+h1-markShiftTwo,0], [-xe+h1-markShiftTwo,-y0]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkHRightup, TxtY, [-xe+h1-markShiftFour,-y0], [-xe+h1-markShiftFour,0]);//X文字标注
   // /*****************************************************************************/    
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔 H位置孔
         //标记晾物孔 H位置孔左上
         var myHhHolesHLeftup= new makerjs.models.Holes(d/2, [[xh-h1, yh+w]]);
         //标记孔
         var myHhHolesMarkHLeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkHLeftup.origin = [xh-h1, yh+w];      
         var myHhHolesXMarkHLeftup = new makerjs.models.Square(0);      
         myHhHolesXMarkHLeftup.origin =[xh-h1, yh+w];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHLeftup, new makerjs.paths.Line([0,0], [0, 0-w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkHLeftup, new makerjs.paths.Line([-xe+h1,0], [0,0]));//从孔圆心出发Y方向竖向标线
   makerjs.model.addPath(myHhHolesMarkHLeftup, new makerjs.paths.Line([-xe+h1,0], [-xe+h1-markShiftFour,0]));//从孔圆心出发Y方向竖向标线
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkHLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkHLeftup, new makerjs.paths.Line([-xe+h1-markShiftTwo,0], [-xe+h1-markShiftTwo,-w]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkHLeftup, TxtW, [-xe+h1-markShiftFour,-w], [-xe+h1-markShiftFour,0]);//X文字标注
   // /*****************************************************************************/  
   // /*****************************************************************************/  
   
    //标记晾物孔 H位置孔右中
    var myHhHolesHRightmd= new makerjs.models.Holes(d/2, [[xh, yh]]);
         //标记孔
         var myHhHolesMarkHRightmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkHRightmd.origin = [xh, yh];     
         var myHhHolesYMarkHRightmd = new makerjs.models.Square(0);      
         myHhHolesYMarkHRightmd.origin = [xh, yh];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHRightmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkHRightmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkHRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/      
   makerjs.model.addPath(myHhHolesYMarkHRightmd, new makerjs.paths.Line( [0,0], [-h1, 0]));//H短标线
   makerjs.model.addCaption(myHhHolesYMarkHRightmd, TxtH1, [-h1, 0+markShiftTwo], [0,markShiftTwo]);//H文字标注
   // /*****************************************************************************/ 
   
    //标记晾物孔 H位置孔左中
    var myKhHolesHLeftmd= new makerjs.models.Holes(d/2, [[xh, yh+w]]);
         //标记孔
         var myKhHolesMarkHLeftmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkHLeftmd.origin = [xh, yh+w];      
         var myKhHolesXMarkHLeftmd = new makerjs.models.Square(0);      
         myKhHolesXMarkHLeftmd.origin =[xh, yh+w];      
         var myKhHolesYMarkHLeftmd = new makerjs.models.Square(0);      
         myKhHolesYMarkHLeftmd.origin = [xh, yh+w];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [-h1, 0]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkHLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   
   /*****************************************************************************/ 
   
    //标记晾物孔H位置孔右下
    var myKhHolesHRightdn= new makerjs.models.Holes(d/2, [[xh+h2, yh]]);
         //标记孔
         var myKhHolesMarkHRightdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkHRightdn.origin = [xh+h2, yh];        
         var myKhHolesYMarkHRightdn = new makerjs.models.Square(0);      
         myKhHolesYMarkHRightdn.origin = [xh+h2, yh];  
   
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/    
   makerjs.model.addCaption(myKhHolesYMarkHRightdn, TxtH2, [-h2,markShiftTwo], [0,markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   
    //标记晾物孔H位置孔左下
    var myHhHolesHLeftdn= new makerjs.models.Holes(d/2, [[xh+h2, yh+w]]);
         //标记孔
         var myHhHolesMarkHLeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkHLeftdn.origin = [xh+h2, yh+w];   
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHLeftdn, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkHLeftdn, new makerjs.paths.Line([0,0], [-h2, 0]));//从孔圆心出发Y方向竖向标线
             
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkHLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkHLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   plate.models["myHhHolesHRightup"+myHolesNum]=myHhHolesHRightup;
   cutPlate.models["myHhHolesHRightup"+myHolesNum]=myHhHolesHRightup;
   markers.models["myHhHolesHRightup"+myHolesNum]=myHhHolesHRightup;
   markers.models["myHhHolesMarkHRightup"+myHolesNum]=myHhHolesMarkHRightup;
   markers.models["myKhHolesXMarkHRightup"+myHolesNum]=myKhHolesXMarkHRightup;
   markers.models["myKhHolesYMarkHRightup"+myHolesNum]=myKhHolesYMarkHRightup;
   // /*****************************************************************************/
   plate.models["myHhHolesHRightmd"+myHolesNum]=myHhHolesHRightmd;
   cutPlate.models["myHhHolesHRightmd"+myHolesNum]=myHhHolesHRightmd;
   markers.models["myHhHolesHRightmd"+myHolesNum]=myHhHolesHRightmd;
   markers.models["myHhHolesMarkHRightmd"+myHolesNum]=myHhHolesMarkHRightmd;
   markers.models["myHhHolesYMarkHRightmd"+myHolesNum]=myHhHolesYMarkHRightmd;
   
   /*****************************************************************************/ 
   plate.models["myKhHolesHRightdn"+myHolesNum]=myKhHolesHRightdn;
   cutPlate.models["myKhHolesHRightdn"+myHolesNum]=myKhHolesHRightdn;
   markers.models["myKhHolesHRightdn"+myHolesNum]=myKhHolesHRightdn;
   markers.models["myKhHolesMarkHRighttdn"+myHolesNum]=myKhHolesMarkHRightdn;
   markers.models["myKhHolesYMarkHRightdn"+myHolesNum]=myKhHolesYMarkHRightdn;
   
   /*****************************************************************************/ 
   plate.models["myHhHolesHLeftup"+myHolesNum]=myHhHolesHLeftup;
   cutPlate.models["myHhHolesHLeftup"+myHolesNum]=myHhHolesHLeftup;
   markers.models["myHhHolesHLeftup"+myHolesNum]=myHhHolesHLeftup;
   markers.models["myHhHolesMarkHLeftup"+myHolesNum]=myHhHolesMarkHLeftup;
   markers.models["myHhHolesXMarkHLeftup"+myHolesNum]=myHhHolesXMarkHLeftup;
   /*****************************************************************************/ 
   plate.models["myKhHolesHLeftmd"+myHolesNum]=myKhHolesHLeftmd;
   cutPlate.models["myKhHolesHLeftmd"+myHolesNum]=myKhHolesHLeftmd;
   markers.models["myKhHolesHLeftmd"+myHolesNum]=myKhHolesHLeftmd;
   markers.models["myKhHolesMarkHLeftmd"+myHolesNum]=myKhHolesMarkHLeftmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesHLeftdn"+myHolesNum]=myHhHolesHLeftdn;
   cutPlate.models["myHhHolesHLeftdn"+myHolesNum]=myHhHolesHLeftdn;
   markers.models["myHhHolesHLeftdn"+myHolesNum]=myHhHolesHLeftdn;
   markers.models["myHhHolesMarkHLeftdn"+myHolesNum]=myHhHolesMarkHLeftdn;
   /*****************************************************************************/   
   } else if(area==9){
     //画孔 I位置孔
         //标记晾物孔 I位置孔右上
         var myHhHolesIRightup= new makerjs.models.Holes(d/2, [[xi, yi]]);
         //标记孔
         var myHhHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkIRightup.origin = [xi, yi];      
         var myKhHolesXMarkIRightup = new makerjs.models.Square(0);      
         myKhHolesXMarkIRightup.origin =[xi, yi];      
         var myKhHolesYMarkIRightup = new makerjs.models.Square(0);      
         myKhHolesYMarkIRightup.origin = [xi, yi];  
   
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//Y轴加长标线
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0], [x0+markShiftThree, -y0]));//X轴加长标线
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
   // // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   // /*****************************************************************************/   
   makerjs.model.addPath(myKhHolesXMarkIRightup, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
   makerjs.model.addCaption(myKhHolesXMarkIRightup, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
   // /*****************************************************************************/     
   makerjs.model.addPath(myKhHolesYMarkIRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
   makerjs.model.addCaption(myKhHolesYMarkIRightup, TxtY, [x0+markShiftThree, -y0], [x0+markShiftThree,0]);//Y文字标注
   // /*****************************************************************************/ 
   // /*****************************************************************************/ 
   //画孔I位置孔
         //标记晾物孔 I位置孔左上
         var myHhHolesILeftup= new makerjs.models.Holes(d/2, [[xi, yi+w]]);
         //标记孔
         var myHhHolesMarkILeftup = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkILeftup.origin = [xi, yi+w];      
         var myHhHolesXMarkILeftup = new makerjs.models.Square(0);      
         myHhHolesXMarkILeftup.origin =[xi, yi+w]; 
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftup, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkILeftup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发Y方向竖向标线
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkILeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   makerjs.model.addPath(myHhHolesXMarkILeftup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift,-w]));//X短标线
   makerjs.model.addCaption(myHhHolesXMarkILeftup, TxtW, [x0+markShiftThree,-w], [x0+markShiftThree,0]);//X文字标注
   // /*****************************************************************************/  
   // /*****************************************************************************/ 
    //标记晾物孔 I位置孔右中
    var myHhHolesIRightmd= new makerjs.models.Holes(d/2, [[xi-h1, yi]]);
         //标记孔
         var myHhHolesMarkIRightmd = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkIRightmd.origin = [xi-h1, yi];     
         var myHhHolesYMarkIRightmd = new makerjs.models.Square(0);      
         myHhHolesYMarkIRightmd.origin = [xi-h1, yi];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [h1, 0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [0,-y0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkIRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/     
   makerjs.model.addCaption(myHhHolesYMarkIRightmd, TxtH1, [0,markShiftTwo], [h1, markShiftTwo]);//Y文字标注
   // /*****************************************************************************/ 
    //标记晾物孔 I位置孔左中
    var myKhHolesILeftmd= new makerjs.models.Holes(d/2, [[xi-h1, yi+w]]);
         //标记孔
         var myKhHolesMarkILeftmd = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkILeftmd.origin = [xi-h1, yi+w];      
         var myKhHolesXMarkILeftmd = new makerjs.models.Square(0);      
         myKhHolesXMarkILeftmd.origin =[xi-h1, yi+w];      
         var myKhHolesYMarkILeftmd = new makerjs.models.Square(0);      
         myKhHolesYMarkILeftmd.origin = [xi-h1, yi+w];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [h1, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkILeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/
    //标记晾物孔I位置孔右下
    var myKhHolesIRightdn= new makerjs.models.Holes(d/2, [[xi-h1-h2, yi]]);
         //标记孔
         var myKhHolesMarkIRightdn = new makerjs.models.Square(0); //孔的原点       
         myKhHolesMarkIRightdn.origin = [xi-h1-h2, yi];        
         var myKhHolesYMarkIRightdn = new makerjs.models.Square(0);      
         myKhHolesYMarkIRightdn.origin = [xi-h1-h2, yi];  
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myKhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [h2, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myKhHolesMarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myKhHolesMarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/       
   makerjs.model.addCaption(myKhHolesYMarkIRightdn, TxtH2, [0,markShiftTwo], [h2, markShiftTwo]);//Y文字标注
   /*****************************************************************************/ 
   /*****************************************************************************/
    //标记晾物孔I位置孔左下
    var myHhHolesILeftdn= new makerjs.models.Holes(d/2, [[xi-h1-h2, yi+w]]);
         //标记孔
         var myHhHolesMarkILeftdn = new makerjs.models.Square(0); //孔的原点       
         myHhHolesMarkILeftdn.origin = [xi-h1-h2, yi+w];  
   // /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [0, -w]));//从孔圆心出发X方向横向标线
   makerjs.model.addPath(myHhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [h2, 0]));//从孔圆心出发Y方向竖向标线
   /*****************************************************************************/ 
   makerjs.model.addPath(myHhHolesMarkILeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
   makerjs.model.addCaption(myHhHolesMarkILeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
   /*****************************************************************************/   
   plate.models["myHhHolesIRightup"+myHolesNum]=myHhHolesIRightup;
   cutPlate.models["myHhHolesIRightup"+myHolesNum]=myHhHolesIRightup;
   markers.models["myHhHolesIRightup"+myHolesNum]=myHhHolesIRightup;
   markers.models["myHhHolesMarkIRightup"+myHolesNum]=myHhHolesMarkIRightup;
   markers.models["myKhHolesXMarkIRightup"+myHolesNum]=myKhHolesXMarkIRightup;
   markers.models["myKhHolesYMarkIRightup"+myHolesNum]=myKhHolesYMarkIRightup;
   // /*****************************************************************************/
   plate.models["myHhHolesIRightmd"+myHolesNum]=myHhHolesIRightmd;
   cutPlate.models["myHhHolesIRightmd"+myHolesNum]=myHhHolesIRightmd;
   markers.models["myHhHolesIRightmd"+myHolesNum]=myHhHolesIRightmd;
   markers.models["myHhHolesMarkIRightmd"+myHolesNum]=myHhHolesMarkIRightmd;
   markers.models["myHhHolesYMarkIRightmd"+myHolesNum]=myHhHolesYMarkIRightmd;
   /*****************************************************************************/ 
   plate.models["myKhHolesIRightdn"+myHolesNum]=myKhHolesIRightdn;
   cutPlate.models["myKhHolesIRightdn"+myHolesNum]=myKhHolesIRightdn;
   markers.models["myKhHolesIRightdn"+myHolesNum]=myKhHolesIRightdn;
   markers.models["myKhHolesMarkIRightdn"+myHolesNum]=myKhHolesMarkIRightdn;
   markers.models["myKhHolesYMarkIRightdn"+myHolesNum]=myKhHolesYMarkIRightdn;
   /*****************************************************************************/ 
   plate.models["myHhHolesILeftup"+myHolesNum]=myHhHolesILeftup;
   cutPlate.models["myHhHolesILeftup"+myHolesNum]=myHhHolesILeftup;
   markers.models["myHhHolesILeftup"+myHolesNum]=myHhHolesILeftup;
   markers.models["myHhHolesMarkILeftup"+myHolesNum]=myHhHolesMarkILeftup;
   markers.models["myHhHolesXMarkILeftup"+myHolesNum]=myHhHolesXMarkILeftup;
   /*****************************************************************************/ 
   /*****************************************************************************/ 
   plate.models["myKhHolesILeftmd"+myHolesNum]=myKhHolesILeftmd;
   cutPlate.models["myKhHolesILeftmd"+myHolesNum]=myKhHolesILeftmd;
   markers.models["myKhHolesILeftmd"+myHolesNum]=myKhHolesILeftmd;
   markers.models["myKhHolesMarkILeftmd"+myHolesNum]=myKhHolesMarkILeftmd;
   /*****************************************************************************/ 
   plate.models["myHhHolesILeftdn"+myHolesNum]=myHhHolesILeftdn;
   cutPlate.models["myHhHolesILeftdn"+myHolesNum]=myHhHolesILeftdn;
   markers.models["myHhHolesILeftdn"+myHolesNum]=myHhHolesILeftdn;
   markers.models["myHhHolesMarkILeftdn"+myHolesNum]=myHhHolesMarkILeftdn;
   /*****************************************************************************/  
   }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default HangHole;