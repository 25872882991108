//缺1.2、蝴蝶
import makerjs from "makerjs";

// 矩形等去角 连线函数
function creatmyButterflyGap2(area, param) {
   console.log("是否调用此函数creatOneCorner（）");
   console.log("是否调用此函数creatOneCorner（）param=", param);
   var xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
         x = param.x0, r = param.r, w = param.w,w1 = param.w1, h = param.h, h1 = param.h1;
        var lx=Math.sqrt(r*r-(h1-h)*(h1-h)),ly=Math.sqrt(r*r-((w1-w)/2)*((w1-w)/2)),ax=(w1-w)/2,ay=h1-h,height=h-ly,width=w-lx*2;//确定两个圆弧坐标
        var angleA = Math.asin(ax /r)*180/Math.PI,angleB = Math.asin(ay /r)*180/Math.PI; //对边比斜边
        console.log("测试坐标X轴偏移lx==",lx);
        console.log("测试坐标X轴偏移ly==",ly);
        console.log("测试坐标X轴偏移ax==",ax);
        console.log("测试坐标X轴偏移ay==",ay);
        console.log("测试坐标X轴偏移width==",width);
        console.log("测试坐标X轴偏移height==",height);
        console.log("测试坐标X轴偏移r==",r);
        console.log("测试坐标X轴偏移angleA==",angleA);
        console.log("测试坐标X轴偏移angleB==",angleB);
   
   if (area == "A") {
         var myButterflyGap2 = {
               paths: {
                     "Left": new makerjs.paths.Line([xa, ya], [xa, ya-height]),
                     "BottomLeft": new makerjs.paths.Arc([xa+ax, ya-h], r, angleA+90, -angleB),
                     'Bottom': new makerjs.paths.Line([xa+ax+lx, ya-h1], [xa+ax+lx+width, ya-h1]),
                     "BottomRight": new makerjs.paths.Arc([xa+ax+w, ya-h], r, 180+angleB, 90-angleA),
                     "Right": new makerjs.paths.Line([xa+w1, ya-height], [xa+w1, ya]),
               }
         };
   } else if (area == "B") {
         var myButterflyGap2 = {
               paths: {
                  "Left": new makerjs.paths.Line([xb, yb], [xb, yb-height]),
                  "BottomLeft": new makerjs.paths.Arc([xb+ax, yb-h], r, angleA+90, -angleB),
                  'Bottom': new makerjs.paths.Line([xb+ax+lx, yb-h1], [xb+ax+lx+width, yb-h1]),
                  "BottomRight": new makerjs.paths.Arc([xb+ax+w, yb-h], r, 180+angleB, 90-angleA),
                  "Right": new makerjs.paths.Line([xb+w1, yb-height], [xb+w1, yb]),
               }
         };
   } else if (area == "C") {
         var myButterflyGap2 = {
               paths: {
                  "Left": new makerjs.paths.Line([xc-w1, yc], [xc-w1, yc-height]),
                  "BottomLeft": new makerjs.paths.Arc([xc-w1+ax, yc-h], r, angleA+90, -angleB),
                  'Bottom': new makerjs.paths.Line([xc-ax-lx, yc-h1], [xc-ax-lx-width, yc-h1]),
                  "BottomRight": new makerjs.paths.Arc([xc-ax, yc-h], r, 180+angleB, 90-angleA),
                  "Right": new makerjs.paths.Line([xc, yc-height], [xc, yc]),
               }
         };
   } else if (area == "G") {
      var myButterflyGap2 = {
            paths: {
                  "Left": new makerjs.paths.Line([xg, yg], [xg, yg+height]),
                  "BottomLeft": new makerjs.paths.Arc([xg+ax, yg+h], r, angleB, 270-angleA),
                  'Bottom': new makerjs.paths.Line([xg+ax+lx, yg+h1], [xg+ax+lx+width, yg+h1]),
                  "BottomRight": new makerjs.paths.Arc([xg+ax+w, yg+h], r, 270+angleA, 180-angleB),
                  "Right": new makerjs.paths.Line([xg+w1, yg+height], [xg+w1, yg]),
            }
      };
   } else if (area == "H") {
      var myButterflyGap2 = {
            paths: {
                  "Left": new makerjs.paths.Line([xh, yh], [xh, yh+height]),
                  "BottomLeft": new makerjs.paths.Arc([xh+ax, yh+h], r, angleB, 270-angleA),
                  'Bottom': new makerjs.paths.Line([xh+ax+lx, yh+h1], [xh+ax+lx+width, yh+h1]),
                  "BottomRight": new makerjs.paths.Arc([xh+ax+w, yh+h], r, 270+angleA, 180-angleB),
                  "Right": new makerjs.paths.Line([xh+w1, yh+height], [xh+w1, yh]),
            }
      };
   } else if (area == "I") {
      var myButterflyGap2 = {
            paths: {
                  "Left": new makerjs.paths.Line([xi-w1, yi], [xi-w1, yi+height]),
                  "BottomLeft": new makerjs.paths.Arc([xi-w1+ax, yi+h], r, angleB, 270-angleA),
                  'Bottom': new makerjs.paths.Line([xi-ax-lx, yi+h1], [xi-ax-lx-width, yi+h1]),
                  "BottomRight": new makerjs.paths.Arc([xi-ax, yi+h], r, 270+angleA, 180-angleB),
                  "Right": new makerjs.paths.Line([xi, yi+height], [xi, yi]),
            }
      };
   }
   return myButterflyGap2;
};
// 矩形等去角标记 连线函数      
function creatmyButterflyGap2Mark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
   console.log("是否调用此函数creatCornerMark()");
   console.log("是否调用此函数param", param);
   console.log("是否调用此函数outParam", outParam);
   console.log("是否调用此函数inputParam", inputParam);
   console.log("是否调用此函数OffsetParam", OffsetParam);
   var R = inputParam.R, W = inputParam.W, W1 = inputParam.W1, H = inputParam.H, H1 = inputParam.H1, X = inputParam.X, Y = inputParam.Y, myZoom = zoom * 1,
         glassW = outParam.glassW, glassH = outParam.glassH,
         xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, 
         xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
         x = param.x0, radius = param.r, width = param.w, height = param.h,
         markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
         markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
         h = H * 1 * myZoom,
               w = W * 1 * myZoom,
               h1 = H1 * 1 * myZoom,
               w1 = W1 * 1 * myZoom,
               r = R * 1 * myZoom,
               dd = r / Math.sqrt(2),
               hd = h1 - h,
               wd = (w1 - w) / 2,
               y0 = Y * 1 * myZoom,
               x0 = X * 1 * myZoom;
            if (r !== 0) {
               var TxtR = "R=" + R;
            }
            if (h !== 0) {
               var TxtH = H;
            }
            if (w !== 0) {
               var TxtW = W;
            }
            if (h1 !== 0) {
               var TxtH1 = H1;
            }
            if (w1 !== 0) {
               var TxtW1 = W1;
            }
            if (y0 !== 0) {
               var TxtY = Y;
            }
            if (x0 !== 0) {
               var TxtX = X;
            }

   if (area == "A") {
      var myBfg2GapAMakerW = {
            paths: {
                  "WALine0": new makerjs.paths.Line([xa+wd,ya-h], [xa+wd,ya-h-markShiftFour]),
                  "WALine1": new makerjs.paths.Line([xa+w+wd,ya-h], [xa+w+wd,ya-h-markShiftFour]),
                  "WALine2": new makerjs.paths.Line([xa+wd,ya-h-markShiftTwo], [xa+wd+w, ya-h-markShiftTwo]),
            }
      };
      var myBfg2GapAMakerW1 = {
            paths: {
                  "WA1Line0": new makerjs.paths.Line([xa, ya - markShiftTwo], [xa + w1, ya - markShiftTwo]),
            }
      };
      var myBfg2GapAMakerH = {
            paths: {
                  "HALine0": new makerjs.paths.Line([xa - markShift, ya], [xa - markShift, ya - h]),
                  "HALine2": new makerjs.paths.Line([xa - markShiftFour, ya - h], [xa, ya - h]),
            }
      };
      var myBfg2GapAMakerH1 = {
            paths: {
                  "H1ALine0": new makerjs.paths.Line([xa+w1+ markShiftFour, ya], [xa+w1+markShiftFour, ya - h1]),
                  "H1ALine2": new makerjs.paths.Line([xa+w1-wd-r, ya - h1], [xa+w1+ markShiftFour*2, ya - h1]),
            }
      };
         var myBfg2GapAMakerX = {
               paths: {
                     "XALine0": new makerjs.paths.Line([xa - x0, ya + markShift], [xa, ya + markShift]),
                     "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                     "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
               }
         };
         var myBfg2GapAMakerR = {
               paths: {
                     "DALine0": new makerjs.paths.Line([xa + w, ya - h], [xa + w + dd + markHolesOneD, ya - h - dd - markHolesOneD]),
               }
         };
         makerjs.model.addCaption(myBfg2GapAMakerW, TxtW,[xa+wd,ya-h-markShiftFour], [xa+wd+w, ya-h-markShiftFour]);//W文字标注
         makerjs.model.addCaption(myBfg2GapAMakerW1, TxtW1, [xa, ya - markShiftFour], [xa + w1, ya - markShiftFour]);//W1文字标注 
         makerjs.model.addCaption(myBfg2GapAMakerH, TxtH, [xa - markShiftThree, ya - h], [xa - markShiftThree, ya]);//H文字标注
         makerjs.model.addCaption(myBfg2GapAMakerH1, TxtH1, [xa +w1+ markShiftFour+markShiftTwo, ya - h1], [xa +w1+ markShiftFour+markShiftTwo, ya]);//H文字标注
         makerjs.model.addCaption(myBfg2GapAMakerX, TxtX, [xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]);//R1文字标注
         makerjs.model.addCaption(myBfg2GapAMakerR, TxtR, [xa + w + dd + markHolesOneD, ya - h - dd - markHolesOneD], [xa + w + dd + markHolesOneD+markShiftTwo*2, ya - h - dd - markHolesOneD]);//D文字标注  
         var markers = [myBfg2GapAMakerW,myBfg2GapAMakerW1, myBfg2GapAMakerH,myBfg2GapAMakerH1, myBfg2GapAMakerX, myBfg2GapAMakerR]
   } else if (area == "B") {
      var myBfg2GapBMakerW = {
            paths: {
                  "WBLine0": new makerjs.paths.Line([xb+wd,yb-h], [xb+wd,yb-h-markShiftFour]),
                  "WBLine1": new makerjs.paths.Line([xb+w+wd,yb-h], [xb+w+wd,yb-h-markShiftFour]),
                  "WBLine2": new makerjs.paths.Line([xb+wd,yb-h-markShiftTwo], [xb+wd+w, yb-h-markShiftTwo]),
            }
      };w
      var myBfg2GapBMakerW1 = {
            paths: {
                  "W1BLine0": new makerjs.paths.Line([xb, yb - markShiftTwo], [xb + w1, yb - markShiftTwo]),
            }
      };
      var myBfg2GapBMakerH = {
            paths: {
                  "HBLine0": new makerjs.paths.Line([xb - markShift, yb], [xb - markShift, yb - h]),
                  "HBLine2": new makerjs.paths.Line([xb - markShiftFour, yb - h], [xb, yb - h]),
            }
      };
      var myBfg2GapBMakerH1 = {
            paths: {
                  "H1BLine0": new makerjs.paths.Line([xb+w1+ markShiftFour, yb], [xb+w1+markShiftFour, yb - h1]),
                  "H1BLine2": new makerjs.paths.Line([xb+w1-wd-r, yb - h1], [xb+w1+ markShiftFour*2, yb - h1]),
            }
      };
         var myBfg2GapBMakerX = {
               paths: {
                  //    "XALine0": new makerjs.paths.Line([xb - x0, yb + markShift], [xb, yb + markShift]),
                  //    "XALine1": new makerjs.paths.Line([xb - x0, yb], [xb - x0, yb + markShiftFour]),
                  //    "XALine2": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
               }
         };
         var myBfg2GapBMakerR = {
               paths: {
                     "RBLine0": new makerjs.paths.Line([xb + w, yb - h], [xb + w + dd + markHolesOneD, yb - h - dd - markHolesOneD]),
               }
         };
         makerjs.model.addCaption(myBfg2GapBMakerW, TxtW,[xb+wd,yb-h-markShiftFour], [xb+wd+w, yb-h-markShiftFour]);//W文字标注
         makerjs.model.addCaption(myBfg2GapBMakerW1, TxtW1, [xb, yb - markShiftFour], [xb + w1, yb - markShiftFour]);//W1文字标注 
         makerjs.model.addCaption(myBfg2GapBMakerH, TxtH, [xb - markShiftThree, yb - h], [xb - markShiftThree, yb]);//H文字标注
         makerjs.model.addCaption(myBfg2GapBMakerH1, TxtH1, [xb +w1+ markShiftFour+markShiftTwo, yb - h1], [xb +w1+ markShiftFour+markShiftTwo, yb]);//H文字标注
         makerjs.model.addCaption(myBfg2GapBMakerX, TxtX, [xb - x0, yb + markShiftTwo], [xb, yb + markShiftTwo]);//R1文字标注
         makerjs.model.addCaption(myBfg2GapBMakerR, TxtR, [xb + w + dd + markHolesOneD, yb - h - dd - markHolesOneD], [xb + w + dd + markHolesOneD+markShiftTwo*2, yb - h - dd - markHolesOneD]);//D文字标注  
         var markers = [myBfg2GapBMakerW,myBfg2GapBMakerW1, myBfg2GapBMakerH,myBfg2GapBMakerH1, myBfg2GapBMakerX, myBfg2GapBMakerR]
   } else if (area == "C") {
      var myBfg2GapCMakerW = {
            paths: {
                  "WCLine0": new makerjs.paths.Line([xc-wd,yc-h], [xc-wd,yc-h-markShiftFour]),
                  "WCLine1": new makerjs.paths.Line([xc-w,yc-h], [xc-w,yc-h-markShiftFour]),
                  "WCLine2": new makerjs.paths.Line([xc-w, yc-markShiftTwo-h],[xc-wd,yc-markShiftTwo-h]),
            }
      };
      var myBfg2GapCMakerW1 = {
            paths: {
                  "WC1Line0": new makerjs.paths.Line([xc- w1, yc - markShiftTwo], [xc , yc - markShiftTwo]),
            }
      };
      var myBfg2GapCMakerH = {
            paths: {
                  "HCLine0": new makerjs.paths.Line([xc + markShift, yc], [xc + markShift, yc - h]),
                  "HCLine2": new makerjs.paths.Line([xc + markShiftFour, yc - h], [xc, yc - h]),
            }
      };
      var myBfg2GapCMakerH1 = {
            paths: {
                  "H1CLine0": new makerjs.paths.Line([xc-w1- markShiftFour, yc], [xc-w1-markShiftFour, yc - h1]),
                  "H1CLine2": new makerjs.paths.Line([xc-w1+wd+r, yc - h1], [xc-w1- markShiftFour*2, yc - h1]),
            }
      };
         var myBfg2GapCMakerX = {
               paths: {
                     "XCLine0": new makerjs.paths.Line([xc, yc + markShift], [xc + x0, yc + markShift]),
                     "XCLine1": new makerjs.paths.Line([xc + x0, yc], [xc + x0, yc + markShiftFour]),
                     "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
               }
         };
         var myBfg2GapCMakerR = {
               paths: {
                     "RCLine0": new makerjs.paths.Line([xc - w, yc - h], [xc - w - dd - markHolesOneD, yc - h - dd - markHolesOneD]),
               }
         };
         makerjs.model.addCaption(myBfg2GapCMakerW, TxtW, [xc-w, yc-markShiftFour-h],[xc-wd,yc-markShiftFour-h]);//W文字标注
         makerjs.model.addCaption(myBfg2GapCMakerW1, TxtW1, [xc-w1, yc - markShiftFour], [xc , yc - markShiftFour]);//W1文字标注 
         makerjs.model.addCaption(myBfg2GapCMakerH, TxtH, [xc + markShiftThree, yc - h], [xc + markShiftThree, yc]);//H文字标注
         makerjs.model.addCaption(myBfg2GapCMakerH1, TxtH1, [xc-w1- markShiftFour-markShiftTwo, yc - h1], [xc-w1- markShiftFour-markShiftTwo, yc]);//H文字标注
         makerjs.model.addCaption(myBfg2GapCMakerX, TxtX, [xc , yc + markShiftTwo], [xc+ x0, yc + markShiftTwo]);//R1文字标注
         makerjs.model.addCaption(myBfg2GapCMakerR, TxtR, [xc- w - dd - markHolesOneD-markShiftTwo*2, yc - h - dd - markHolesOneD], [xc - w - dd - markHolesOneD, yc - h - dd - markHolesOneD]);//D文字标注  
         var markers = [myBfg2GapCMakerW,myBfg2GapCMakerW1, myBfg2GapCMakerH,myBfg2GapCMakerH1, myBfg2GapCMakerX, myBfg2GapCMakerR]
   } else if (area == "G") {
      var myBfg2GapGMakerW = {
            paths: {
                  "WGLine0": new makerjs.paths.Line([xg+wd,yg+h], [xg+wd,yg+h+markShiftFour]),
                  "WGLine1": new makerjs.paths.Line([xg+w+wd,yg+h], [xg+w+wd,yg+h+markShiftFour]),
                  "WGLine2": new makerjs.paths.Line([xg+wd,yg+h+markShiftTwo], [xg+wd+w, yg+h+markShiftTwo]),
            }
      };w
      var myBfg2GapGMakerW1 = {
            paths: {
                  "WG1Line0": new makerjs.paths.Line([xg, yg + markShiftTwo], [xg + w1, yg + markShiftTwo]),
            }
      };
      var myBfg2GapGMakerH = {
            paths: {
                  "HGLine0": new makerjs.paths.Line([xg - markShift, yg], [xg - markShift, yg + h]),
                  "HGLine2": new makerjs.paths.Line([xg - markShiftFour, yg + h], [xg, yg + h]),
            }
      };
      var myBfg2GapGMakerH1 = {
            paths: {
                  "H1GLine0": new makerjs.paths.Line([xg+w1+ markShiftFour, yg], [xg+w1+markShiftFour, yg + h1]),
                  "H1GLine2": new makerjs.paths.Line([xg+w1-wd-r, yg + h1], [xg+w1+ markShiftFour*2, yg + h1]),
            }
      };
         var myBfg2GapGMakerX = {
               paths: {
                     "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
                     "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
                     "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
               }
         };
         var myBfg2GapGMakerR = {
               paths: {
                     "DGLine0": new makerjs.paths.Line([xg + w, yg + h], [xg + w + dd + markHolesOneD, yg+ h + dd + markHolesOneD]),
               }
         };
         makerjs.model.addCaption(myBfg2GapGMakerW, TxtW,[xg+wd,yg+h+markShiftFour], [xg+wd+w, yg+h+markShiftFour]);//W文字标注
         makerjs.model.addCaption(myBfg2GapGMakerW1, TxtW1, [xg, yg+markShiftFour], [xg + w1, yg + markShiftFour]);//W1文字标注 
         makerjs.model.addCaption(myBfg2GapGMakerH, TxtH, [xg - markShiftThree, yg], [xg - markShiftThree, yg+ h]);//H文字标注
         makerjs.model.addCaption(myBfg2GapGMakerH1, TxtH1, [xg +w1+ markShiftFour+markShiftTwo, yg], [xg +w1+ markShiftFour+markShiftTwo, yg + h1]);//H文字标注
         makerjs.model.addCaption(myBfg2GapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//R1文字标注
         makerjs.model.addCaption(myBfg2GapGMakerR, TxtR, [xg + w + dd + markHolesOneD, yg + h + dd + markHolesOneD], [xg + w + dd + markHolesOneD+markShiftTwo*2, yg + h + dd + markHolesOneD]);//D文字标注  
         var markers = [myBfg2GapGMakerW,myBfg2GapGMakerW1, myBfg2GapGMakerH,myBfg2GapGMakerH1, myBfg2GapGMakerX, myBfg2GapGMakerR]
   } else if (area == "H") {
      var myBfg2GapHMakerW = {
            paths: {
                  "WHLine0": new makerjs.paths.Line([xh+wd,yh+h], [xh+wd,yh+h+markShiftFour]),
                  "WHLine1": new makerjs.paths.Line([xh+w+wd,yh+h], [xh+w+wd,yh+h+markShiftFour]),
                  "WHLine2": new makerjs.paths.Line([xh+wd,yh+h+markShiftTwo], [xh+wd+w, yh+h+markShiftTwo]),
            }
      };w
      var myBfg2GapHMakerW1 = {
            paths: {
                  "WH1Line0": new makerjs.paths.Line([xh, yh + markShiftTwo], [xh + w1, yh + markShiftTwo]),
            }
      };
      var myBfg2GapHMakerH = {
            paths: {
                  "HHLine0": new makerjs.paths.Line([xh - markShift, yh], [xh - markShift, yh + h]),
                  "HHLine2": new makerjs.paths.Line([xh - markShiftFour, yh + h], [xh, yh + h]),
            }
      };
      var myBfg2GapHMakerH1 = {
            paths: {
                  "H1HLine0": new makerjs.paths.Line([xh+w1+ markShiftFour, yh], [xh+w1+markShiftFour, yh + h1]),
                  "H1HLine2": new makerjs.paths.Line([xh+w1-wd-r, yh + h1], [xh+w1+ markShiftFour*2, yh + h1]),
            }
      };
         var myBfg2GapHMakerX = {
               paths: {
                  //    "XHLine0": new makerjs.paths.Line([xh - x0, yh - markShiftTwo], [xh, yh - markShiftTwo]),
                  //    "XHLine1": new makerjs.paths.Line([xh - x0, yh], [xh - x0, yh - markShiftFour]),
                  //    "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour]),
               }
         };
         var myBfg2GapHMakerR = {
               paths: {
                     "DHLine0": new makerjs.paths.Line([xh + w, yh + h], [xh + w + dd + markHolesOneD, yh+ h + dd + markHolesOneD]),
               }
         };
         makerjs.model.addCaption(myBfg2GapHMakerW, TxtW,[xh+wd,yh+h+markShiftFour], [xh+wd+w, yh+h+markShiftFour]);//W文字标注
         makerjs.model.addCaption(myBfg2GapHMakerW1, TxtW1, [xh, yh+markShiftFour], [xh + w1, yh + markShiftFour]);//W1文字标注 
         makerjs.model.addCaption(myBfg2GapHMakerH, TxtH, [xh - markShiftThree, yh], [xh - markShiftThree, yh+ h]);//H文字标注
         makerjs.model.addCaption(myBfg2GapHMakerH1, TxtH1, [xh +w1+ markShiftFour+markShiftTwo, yh], [xh +w1+ markShiftFour+markShiftTwo, yh + h1]);//H文字标注
         makerjs.model.addCaption(myBfg2GapHMakerX, TxtX, [xh - x0, yh - markShiftFour], [xh, yh - markShiftFour]);//R1文字标注
         makerjs.model.addCaption(myBfg2GapHMakerR, TxtR, [xh + w + dd + markHolesOneD, yh + h + dd + markHolesOneD], [xh + w + dd + markHolesOneD+markShiftTwo*2, yh + h + dd + markHolesOneD]);//D文字标注  
         var markers = [myBfg2GapHMakerW,myBfg2GapHMakerW1, myBfg2GapHMakerH,myBfg2GapHMakerH1, myBfg2GapHMakerX, myBfg2GapHMakerR]
   } else if (area == "I") {
      var myBfg2GapIMakerW = {
            paths: {
                  "WILine0": new makerjs.paths.Line([xi-wd,yi+h], [xi-wd,yi+h+markShiftFour]),
                  "WILine1": new makerjs.paths.Line([xi-w,yi+h], [xi-w,yi+h+markShiftFour]),
                  "WILine2": new makerjs.paths.Line([xi-w, yi+markShiftTwo+h],[xi-wd,yi+markShiftTwo+h]),
            }
      };
      var myBfg2GapIMakerW1 = {
            paths: {
                  "WI1Line0": new makerjs.paths.Line([xi- w1, yi+ markShiftTwo], [xi , yi+ markShiftTwo]),
            }
      };
      var myBfg2GapIMakerH = {
            paths: {
                  "HILine0": new makerjs.paths.Line([xi + markShift, yi], [xi + markShift, yi+h]),
                  "HILine2": new makerjs.paths.Line([xi + markShiftFour, yi+h], [xi, yi+h]),
            }
      };
      var myBfg2GapIMakerH1 = {
            paths: {
                  "H1ILine0": new makerjs.paths.Line([xi-w1- markShiftFour, yi], [xi-w1-markShiftFour, yi+h1]),
                  "H1ILine2": new makerjs.paths.Line([xi-w1+wd+r, yi+h1], [xi-w1- markShiftFour*2, yi+ h1]),
            }
      };
         var myBfg2GapIMakerX = {
               paths: {
                     "XILine0": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi + x0, yi - markShiftTwo]),
                     "XILine1": new makerjs.paths.Line([xi + x0, yi], [xi + x0, yi - markShiftFour]),
                     "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
               }
         };
         var myBfg2GapIMakerR = {
               paths: {
                     "RILine0": new makerjs.paths.Line([xi - w, yi + h], [xi - w - dd - markHolesOneD, yi + h + dd + markHolesOneD]),
               }
         };
         makerjs.model.addCaption(myBfg2GapIMakerW, TxtW, [xi-w, yi+markShiftFour+h],[xi-wd,yi+markShiftFour+h]);//W文字标注
         makerjs.model.addCaption(myBfg2GapIMakerW1, TxtW1, [xi-w1, yi + markShiftFour], [xi , yi + markShiftFour]);//W1文字标注 
         makerjs.model.addCaption(myBfg2GapIMakerH, TxtH, [xi + markShiftThree, yi + h], [xi + markShiftThree, yi]);//H文字标注
         makerjs.model.addCaption(myBfg2GapIMakerH1, TxtH1, [xi-w1- markShiftFour-markShiftTwo, yi+ h1], [xi-w1- markShiftFour-markShiftTwo, yi]);//H文字标注
         makerjs.model.addCaption(myBfg2GapIMakerX, TxtX, [xi , yi - markShiftFour], [xi+ x0, yi - markShiftFour]);//R1文字标注
         makerjs.model.addCaption(myBfg2GapIMakerR, TxtR, [xi- w - dd - markHolesOneD-markShiftTwo*2, yi+ h + dd + markHolesOneD], [xi - w - dd - markHolesOneD, yi+ h+ dd+ markHolesOneD]);//D文字标注  
         var markers = [myBfg2GapIMakerW,myBfg2GapIMakerW1, myBfg2GapIMakerH,myBfg2GapIMakerH1, myBfg2GapIMakerX, myBfg2GapIMakerR]
   }
   console.log("markers||||||||||||||||||=", markers)
   return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap2(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {  
   var frame = outParam.shapeName;
   console.log("outParam==", outParam)
   console.log("model==", model)
   console.log("inputParam==", inputParam)
   console.log("outParam.shapeName==////////", outParam.shapeName)
   if (typeof (frame) == "undefined") {
         var R = inputParam.R, W = inputParam.W, W1 = inputParam.W1, H = inputParam.H, H1 = inputParam.H1, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
         plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
               glassW = outParam.glassW, glassH = outParam.glassH,
               markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
               markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
               h = H * 1 * myZoom,
               w = W * 1 * myZoom,
               h1 = H1 * 1 * myZoom,
               w1 = W1 * 1 * myZoom,
               r = R * 1 * myZoom,
               dd = r / Math.sqrt(2),
               wd = w1 - w,
               hd = (h1 - h) / 2,
               y0 = Y * 1 * myZoom,
               x0 = X * 1 * myZoom;
            if (r !== 0) {
               var TxtR = "R=" + R;
            }
            if (h !== 0) {
               var TxtH = H;
            }
            if (w !== 0) {
               var TxtW = W;
            }
            if (h1 !== 0) {
               var TxtH1 = H1;
            }
            if (w1 !== 0) {
               var TxtW1 = W1;
            }
            if (y0 !== 0) {
               var TxtY = Y;
            }
            if (x0 !== 0) {
               var TxtX = X;
            }
   } else {
         var R = inputParam.R, W = inputParam.W, W1 = inputParam.W1, H = inputParam.H, H1 = inputParam.H1, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
         plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
               glassW = outParam.glassW, glassH = outParam.glassH,
               markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
               markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

               glassW = outParam.glassW, glassH = outParam.glassH,
               markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
               markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
               h = H * 1 * myZoom,
               w = W * 1 * myZoom,
               h1 = H1 * 1 * myZoom,
               w1 = W1 * 1 * myZoom,
               r = R * 1 * myZoom,
               dd = r / Math.sqrt(2),
               wd = w1 - w,
               hd = (h1 - h) / 2,
               y0 = Y * 1 * myZoom,
               x0 = X * 1 * myZoom;
            if (r !== 0) {
               var TxtR = "R=" + R;
            }
            if (h !== 0) {
               var TxtH = H;
            }
            if (w !== 0) {
               var TxtW = W;
            }
            if (h1 !== 0) {
               var TxtH1 = H1;
            }
            if (w1 !== 0) {
               var TxtW1 = W1;
            }
            if (y0 !== 0) {
               var TxtY = Y;
            }
            if (x0 !== 0) {
               var TxtX = X;
            }
   }
   if (frame == "Rectangle") {
         console.log("frame==undefined||||*****")
         //在A区域 计算孔的位置       
         var xa = x0, ya = glassH * 1 - y0,
               //B区域//只有Y标注没有X标注 
               xb = glassW * 1 / 2, yb = glassH * 1 - y0,
               //C区域
               xc = glassW * 1 - x0, yc = glassH * 1 - y0,
               //在G区域 计算孔的位置
               xg = x0, yg = y0,
               //在H区域 计算孔的位置
               //只标注Y
               xh = glassW * 1 / 2, yh = y0,
               //在I区域 计算孔的位置
               xi = glassW * 1 - x0, yi = y0;
         var OffsetParam = {};
   } else if (frame == "IsoscelesTrapezoid") {

   } else if (frame == "RightAngledTrapezoid") {

   } else if (frame == "OtherTrapezoidsA") {

   } else if (frame == "OtherTrapezoidsB") {

   } else if (frame == "ParallelogramA") {


   } else if (frame == "ParallelogramB") {

   }
   // 坐标参数
   var paramCoordinate = { x0, r, h,h1, w,w1, xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
   const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
   var areaPoint = "";
   console.log("//角7、地弹簧3 ------", allKeys)
   if (allKeys[0] == frame) {
         allKeys.shift();//去掉数组第一个元素
   }
   console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
   switch (area) {
         case "1": areaPoint = "A"; break;
         case "2": areaPoint = "B"; break;
         case "3": areaPoint = "C"; break;
         case "7": areaPoint = "G"; break;
         case "8": areaPoint = "H"; break;
         case "9": areaPoint = "I"; break;
   }
   /**
   * 画图
   * 
   */
   var ObjName = "myButterflyGap2" + areaPoint;
   var ObjNameMmyButterflyGap = "myButterflyGap2" + areaPoint + "Mark";//角标记
   var ObjNameMmyButterflyGap = "myButterflyGap2" + areaPoint + "Mark";//角标记
   ObjName = creatmyButterflyGap2(areaPoint, paramCoordinate);//图形
   console.log("ObjName===|||||||||", ObjName);
   ObjNameMmyButterflyGap = creatmyButterflyGap2Mark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
   console.log("ObjNameMmyButterflyGap===|||||||||角标注", ObjNameMmyButterflyGap);
   console.log("cutPlate.models====************", cutPlate.models),
         console.log("plate.models====**************", plate.models),
         console.log("markers.models====*************", markers.models),

      //    myBfg2GapAMakerW,myBfg2GapAMakerW1, myBfg2GapAMakerH,myBfg2GapAMakerH1, myBfg2GapAMakerX, myBfg2GapAMakerR
         cutPlate.models["myButterflyGap2" + areaPoint + myHolesNum] = ObjName;
   plate.models["myButterflyGap2" + areaPoint + myHolesNum] = ObjName;
   plate.models["myButterflyGap2W" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[0];
   plate.models["myButterflyGap2W1" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[1];
   plate.models["myButterflyGap2H" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[2];
   plate.models["myButterflyGap2H1" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[3];
   plate.models["myButterflyGap2X" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[4];
   plate.models["myButterflyGap2R" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[5];
   markers.models["myButterflyGap2" + areaPoint + myHolesNum] = ObjName;
   markers.models["myButterflyGap2W" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[0];
   markers.models["myButterflyGap2W1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[1];
   markers.models["myButterflyGap2H" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[2];
   markers.models["myButterflyGap2H1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[3];
   markers.models["myButterflyGap2X" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[4];
   markers.models["myButterflyGap2R" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[5];

   myHolesNum++;
   return { plate, cutPlate, markers, myHolesNum }
};


export default ButterflyGap2;