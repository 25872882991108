//缺4、跑道缺
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyRunwayGap(area, param) {
      console.log("是否调用此函数creatOneCorner（）");
      console.log("是否调用此函数creatOneCorner（）param=", param);
      var xa = param.xa, ya = param.ya, xb = param.xb,
            yb = param.yb, xc = param.xc,
            yc = param.yc, xg = param.xg, yg = param.yg,
            xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
            x = param.x0, r = param.r, d = param.d, w = param.w, h = param.h, h1 = param.h1, w = param.w, w1 = param.w1, dd = param.dd,
            hh = param.hh, hd = param.hd, w0 = param.w0;
      if (area == "A") {
            var myRwgGap = {
                  paths: {
                        "LeftTopLine": new makerjs.paths.Line([xa, ya], [xa, ya - hh]),
                        "TopLeftArc": new makerjs.paths.Arc([xa, ya - hh], [xa - w0, ya - hd], r, false, false),
                        "LeftLine": new makerjs.paths.Line([xa - w0, ya - hd], [xa - w0, ya - h1]),
                        "BottomArc": new makerjs.paths.Arc([xa - w0, ya - h1], [xa - w0 + w, ya - h1], r, false, false),
                        "RightLine": new makerjs.paths.Line([xa - w0 + w, ya - h1], [xa - w0 + w, ya - hd]),
                        "TopRightArc": new makerjs.paths.Arc([xa - w0 + w, ya - hd], [xa + w1, ya - hh], r, false, false),
                        "TopRightLine": new makerjs.paths.Line([xa + w1, ya - hh], [xa + w1, ya])
                  }
            };
      } else if (area == "B") {
            var myRwgGap = {
                  paths: {
                        "LeftTopLine": new makerjs.paths.Line([xb, yb], [xb, yb - hh]),
                        "TopLeftArc": new makerjs.paths.Arc([xb, yb - hh], [xb - w0, yb - hd], r, false, false),
                        "LeftLine": new makerjs.paths.Line([xb - w0, yb - hd], [xb - w0, yb - h1]),
                        "BottomArc": new makerjs.paths.Arc([xb - w0, yb - h1], [xb - w0 + w, yb - h1], r, false, false),
                        "RightLine": new makerjs.paths.Line([xb - w0 + w, yb - h1], [xb - w0 + w, yb - hd]),
                        "TopRightArc": new makerjs.paths.Arc([xb - w0 + w, yb - hd], [xb + w1, yb - hh], r, false, false),
                        "TopRightLine": new makerjs.paths.Line([xb + w1, yb - hh], [xb + w1, yb])
                  }
            };
      } else if (area == "C") {
            var myRwgGap = {
                  paths: {
                        "LeftTopLine": new makerjs.paths.Line([xc - w1, yc],[xc - w1, yc - hh]),
                        "TopLeftArc": new makerjs.paths.Arc([xc - w1, yc - hh], [xc-w0 - w1, yc - hd], r, false, false),
                        "LeftLine": new makerjs.paths.Line([xc-w0 - w1, yc - hd], [xc-w0 - w1, yc - h1]),
                        "BottomArc": new makerjs.paths.Arc([xc-w0 - w1, yc - h1], [xc+w0, yc - h1], r, false, false),
                        "RightLine": new makerjs.paths.Line([xc+w0, yc - h1], [xc+w0, yc - hd]),
                        "TopRightArc": new makerjs.paths.Arc([xc+w0, yc - hd], [xc, yc - hh], r, false, false),
                        "TopRightLine": new makerjs.paths.Line([xc, yc - hh], [xc, yc])
                  }
            };
      } else if (area == "G") {
            var myRwgGap = {
                  paths: {
                        "LeftTopLine": new makerjs.paths.Line([xg, yg], [xg, yg + hh]),
                        "TopLeftArc": new makerjs.paths.Arc([xg, yg + hh], [xg - w0, yg + hd], r, false, true),
                        "LeftLine": new makerjs.paths.Line([xg - w0, yg + hd], [xg - w0, yg + h1]),
                        "BottomArc": new makerjs.paths.Arc([xg - w0, yg + h1], [xg - w0 + w, yg + h1], r, true, true),
                        "RightLine": new makerjs.paths.Line([xg - w0 + w, yg + h1], [xg - w0 + w, yg + hd]),
                        "TopRightArc": new makerjs.paths.Arc([xg - w0 + w, yg + hd], [xg + w1, yg +hh], r, false, true),
                        "TopRightLine": new makerjs.paths.Line([xg + w1, yg + hh], [xg + w1, yg])
                  }
            };
      } else if (area == "H") {
            var myRwgGap = {
                  paths: {
                        "LeftTopLine": new makerjs.paths.Line([xh, yh], [xh, yh + hh]),
                        "TopLeftArc": new makerjs.paths.Arc([xh, yh + hh], [xh - w0, yh + hd], r, false, true),
                        "LeftLine": new makerjs.paths.Line([xh - w0, yh + hd], [xh - w0, yh + h1]),
                        "BottomArc": new makerjs.paths.Arc([xh - w0, yh + h1], [xh - w0 + w, yh + h1], r, true, true),
                        "RightLine": new makerjs.paths.Line([xh - w0 + w, yh + h1], [xh - w0 + w, yh + hd]),
                        "TopRightArc": new makerjs.paths.Arc([xh - w0 + w, yh + hd], [xh + w1, yh +hh], r, false, true),
                        "TopRightLine": new makerjs.paths.Line([xh + w1, yh + hh], [xh + w1, yh])
                  }
            };
      } else if (area == "I") {
            var myRwgGap = {
                  paths: {
                        "LeftTopLine": new makerjs.paths.Line([xi - w1, yi],[xi - w1, yi + hh]),
                        "TopLeftArc": new makerjs.paths.Arc([xi - w1, yi + hh], [xi-w0 - w1, yi + hd], r, false, true),
                        "LeftLine": new makerjs.paths.Line([xi-w0 - w1, yi + hd], [xi-w0 - w1, yi + h1]),
                        "BottomArc": new makerjs.paths.Arc([xi-w0 - w1, yi + h1], [xi+w0, yi + h1], r, true, true),
                        "RightLine": new makerjs.paths.Line([xi+w0, yi + h1], [xi+w0, yi + hd]),
                        "TopRightArc": new makerjs.paths.Arc([xi+w0, yi + hd], [xi, yi + hh], r, false, true),
                        "TopRightLine": new makerjs.paths.Line([xi, yi + hh], [xi, yi])
                  }
            };
      }
      return myRwgGap;
};
// 矩形等去角标记 连线函数      
function creatmyRunwayGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
      console.log("是否调用此函数creatCornerMark()");
      console.log("是否调用此函数param", param);
      console.log("是否调用此函数outParam", outParam);
      console.log("是否调用此函数inputParam", inputParam);
      console.log("是否调用此函数OffsetParam", OffsetParam);
      var H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, X = inputParam.X, Y = inputParam.Y, myZoom = zoom * 1,
            xa = param.xa, ya = param.ya, xb = param.xb,
            yb = param.yb, xc = param.xc,
            yc = param.yc, xg = param.xg, yg = param.yg,
            xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
            x = param.x0, r = param.r, d = param.d, w = param.w, h = param.h, h1 = param.h1, w = param.w, w1 = param.w1, dd = param.dd,
            hh = param.hh, hd = param.hd, w0 = param.w0,
            glassW = outParam.glassW, glassH = outParam.glassH,
            markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
            markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
            h = H * 1 * myZoom,
            d = W * 1 * myZoom,
            h1 = H1 * 1 * myZoom,
            w1 = W1 * 1 * myZoom,
            w = W * 1 * myZoom,
            r = w / 2,
            dd = Math.sqrt(r * r - w1 / 2 * w1 / 2),
            hh = h1 - h - dd,
            hd = h1 - h,
            w0 = (w - w1) / 2,
            x0 = X * 1 * myZoom,
            y0 = Y * 1 * myZoom;

      if (h !== 0) {
            var TxtH = H;
      }
      if (h1 !== 0) {
            var TxtH1 = H1;
      }
      if (w !== 0) {
            var TxtW = W;
      }
      if (w1 !== 0) {
            var TxtW1 = W1;
      }
      if (x0 !== 0) {
            var TxtX = X;
      }
      if (area == "A") {
            var myRwgGapAMakerH = {
                  paths: {
                        "HALine0": new makerjs.paths.Line([xa - w0, ya - hd], [xa - w0 - markShiftFour, ya - hd]),
                        "HALine1": new makerjs.paths.Line([xa - w0, ya - h1], [xa - w0 - markShiftFour, ya - h1]),
                        "HALine2": new makerjs.paths.Line([xa - w0 - markShiftTwo, ya - hd], [xa - w0 - markShiftTwo, ya - h1]),
                  }
            };
            var myRwgGapAMakerH1 = {
                  paths: {
                        "H1ALine0": new makerjs.paths.Line([xa + w1 + w0 + markShiftTwo, ya - h1], [xa + w1 + w0 + markShiftTwo, ya]),
                        "H1ALine1": new makerjs.paths.Line([xa + w1 + w0, ya - h1], [xa + w1 + w0 + markShiftFour, ya - h1]),
                  }
            };
            var myRwgGapAMakerX = {
                  paths: {
                        "XALine0": new makerjs.paths.Line([xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]),
                        "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                        "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
                  }
            };
            var myRwgGapAMakerW1 = {
                  paths: {
                        "W1ALine0": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
                        "W1ALine1": new makerjs.paths.Line([xa + w1, ya], [xa + w1, ya + markShiftFour]),
                        "W1ALine2": new makerjs.paths.Line([xa, ya + markShiftTwo], [xa + w1, ya + markShiftTwo]),
                  }
            };
            var myRwgGapAMakerW = {
                  paths: {
                        "WALine0": new makerjs.paths.Line([xa - w0, ya - h1], [xa - w0, ya - h1 - markShiftFour * 2 - markShift]),
                        "WALine1": new makerjs.paths.Line([xa - w0 + w, ya - h1], [xa - w0 + w, ya - h1 - markShiftFour * 2 - markShift]),
                        "WALine2": new makerjs.paths.Line([xa - w0, ya - h1 - markShiftFour * 2], [xa - w0 + w, ya - h1 - markShiftFour * 2]),
                  }
            };
            makerjs.model.addCaption(myRwgGapAMakerH, TxtH, [xa - w0 - markShiftFour, ya - h1], [xa - w0 - markShiftFour, ya - hd]);//H文字标注
            makerjs.model.addCaption(myRwgGapAMakerH1, TxtH1, [xa + w1 + w0 + markShiftFour, ya - h1], [xa + w1 + w0 + markShiftFour, ya]);//H文字标注
            makerjs.model.addCaption(myRwgGapAMakerX, TxtX, [xa - x0, ya + markShiftFour], [xa, ya + markShiftFour]);//X文字标注 
            makerjs.model.addCaption(myRwgGapAMakerW1, TxtW1, [xa, ya + markShiftFour], [xa + w1, ya + markShiftFour]);//W1文字标注   
            makerjs.model.addCaption(myRwgGapAMakerW, TxtW, [xa - w0, ya - h1 - markShiftFour * 2 - markShift], [xa - w0 + w, ya - h1 - markShiftFour * 2 - markShift]);//W文字标注  
            var markers = [myRwgGapAMakerH, myRwgGapAMakerH1, myRwgGapAMakerX, myRwgGapAMakerW, myRwgGapAMakerW1]
      } else if (area == "B") {
            var myRwgGapBMakerH = {
                  paths: {
                        "HBLine0": new makerjs.paths.Line([xb - w0, yb - hd], [xb - w0 - markShiftFour, yb - hd]),
                        "HBLine1": new makerjs.paths.Line([xb - w0, yb - h1], [xb - w0 - markShiftFour, yb - h1]),
                        "HBLine2": new makerjs.paths.Line([xb - w0 - markShiftTwo, yb - hd], [xb - w0 - markShiftTwo, yb - h1]),
                  }
            };
            var myRwgGapBMakerH1 = {
                  paths: {
                        "H1BLine0": new makerjs.paths.Line([xb + w1 + w0 + markShiftTwo, yb - h1], [xb + w1 + w0 + markShiftTwo, yb]),
                        "H1BLine1": new makerjs.paths.Line([xb + w1 + w0, yb - h1], [xb + w1 + w0 + markShiftFour, yb - h1]),
                  }
            };
            var myRwgGapBMakerX = {
                  paths: {
                        "XBLine0": new makerjs.paths.Line([xb - x0, yb + markShiftTwo], [xb, yb + markShiftTwo]),
                        "XBLine1": new makerjs.paths.Line([xb - x0, yb], [xb - x0, yb + markShiftFour]),
                        "XBLine2": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
                  }
            };
            var myRwgGapBMakerW1 = {
                  paths: {
                        "W1BLine0": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
                        "W1BLine1": new makerjs.paths.Line([xb + w1, yb], [xb + w1, yb + markShiftFour]),
                        "W1BLine2": new makerjs.paths.Line([xb, yb + markShiftTwo], [xb + w1, yb + markShiftTwo]),
                  }
            };
            var myRwgGapBMakerW = {
                  paths: {
                        "WBLine0": new makerjs.paths.Line([xb - w0, yb - h1], [xb - w0, yb - h1 - markShiftFour * 2 - markShift]),
                        "WBLine1": new makerjs.paths.Line([xb - w0 + w, yb - h1], [xb - w0 + w, yb - h1 - markShiftFour * 2 - markShift]),
                        "WBLine2": new makerjs.paths.Line([xb - w0, yb - h1 - markShiftFour * 2], [xb - w0 + w, yb - h1 - markShiftFour * 2]),
                  }
            };
            makerjs.model.addCaption(myRwgGapBMakerH, TxtH, [xb - w0 - markShiftFour, yb - h1], [xb - w0 - markShiftFour, yb - hd]);//H文字标注
            makerjs.model.addCaption(myRwgGapBMakerH1, TxtH1, [xb + w1 + w0 + markShiftFour, yb - h1], [xb + w1 + w0 + markShiftFour, yb]);//H文字标注
            makerjs.model.addCaption(myRwgGapBMakerX, TxtX, [xb - x0, yb + markShiftFour], [xb, yb + markShiftFour]);//X文字标注 
            makerjs.model.addCaption(myRwgGapBMakerW1, TxtW1, [xb, yb + markShiftFour], [xb + w1, yb + markShiftFour]);//W1文字标注   
            makerjs.model.addCaption(myRwgGapBMakerW, TxtW, [xb - w0, yb - h1 - markShiftFour * 2 - markShift], [xb - w0 + w, yb - h1 - markShiftFour * 2 - markShift]);//W文字标注  
            var markers = [myRwgGapBMakerH, myRwgGapBMakerH1, myRwgGapBMakerX, myRwgGapBMakerW, myRwgGapBMakerW1]
      } else if (area == "C") {
            var myRwgGapCMakerH = {
                  paths: {
                        "HCLine0": new makerjs.paths.Line([xc + w0, yc - hd], [xc + w0 + markShiftFour, yc - hd]),
                        "HCLine1": new makerjs.paths.Line([xc + w0, yc - h1], [xc + w0 + markShiftFour, yc - h1]),
                        "HCLine2": new makerjs.paths.Line([xc + w0 + markShiftTwo, yc - hd], [xc + w0 + markShiftTwo, yc - h1]),
                  }
            };
            var myRwgGapCMakerH1 = {
                  paths: {
                        "H1CLine0": new makerjs.paths.Line([xc- w1 - w0 - markShiftTwo, yc - h1], [xc - w1 - w0 - markShiftTwo, yc]),
                        "H1CLine1": new makerjs.paths.Line([xc - w1 - w0, yc - h1], [xc - w1 - w0 - markShiftFour, yc - h1]),
                  }
            };
            var myRwgGapCMakerX = {
                  paths: {
                        "XCLine0": new makerjs.paths.Line([xc +x0, yc + markShiftTwo], [xc, yc + markShiftTwo]),
                        "XCLine1": new makerjs.paths.Line([xc + x0, yc], [xc + x0, yc + markShiftFour]),
                        "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
                  }
            };
            var myRwgGapCMakerW1 = {
                  paths: {
                        "W1CLine0": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
                        "W1CLine1": new makerjs.paths.Line([xc - w1, yc], [xc - w1, yc + markShiftFour]),
                        "W1CLine2": new makerjs.paths.Line([xc, yc + markShiftTwo], [xc - w1, yc + markShiftTwo]),
                  }
            };
            var myRwgGapCMakerW = {
                  paths: {
                        "WCLine0": new makerjs.paths.Line([xc + w0, yc - h1], [xc + w0, yc - h1 - markShiftFour * 2 - markShift]),
                        "WCLine1": new makerjs.paths.Line([xc + w0 - w, yc - h1], [xc+ w0 - w, yc - h1 - markShiftFour * 2 - markShift]),
                        "WCLine2": new makerjs.paths.Line([xc + w0, yc - h1 - markShiftFour * 2], [xc + w0 - w, yc - h1 - markShiftFour * 2]),
                  }
            };
            makerjs.model.addCaption(myRwgGapCMakerH, TxtH, [xc + w0 + markShiftFour, yc - h1], [xc + w0 + markShiftFour, yc - hd]);//H文字标注
            makerjs.model.addCaption(myRwgGapCMakerH1, TxtH1, [xc - w1 - w0 - markShiftFour, yc - h1], [xc- w1 - w0 - markShiftFour, yc]);//H文字标注
            makerjs.model.addCaption(myRwgGapCMakerX, TxtX, [xc, yc + markShiftFour], [xc + x0, yc + markShiftFour]);//X文字标注 
            makerjs.model.addCaption(myRwgGapCMakerW1, TxtW1, [xc - w1, yc + markShiftFour], [xc, yc + markShiftFour]);//W1文字标注   
            makerjs.model.addCaption(myRwgGapCMakerW, TxtW, [xc + w0 - w, yc - h1 - markShiftFour * 2 - markShift], [xc + w0, yc - h1 - markShiftFour * 2 - markShift]);//W文字标注  
            var markers = [myRwgGapCMakerH, myRwgGapCMakerH1, myRwgGapCMakerX, myRwgGapCMakerW, myRwgGapCMakerW1]
      } else if (area == "G") {
            var myRwgGapGMakerH = {
                  paths: {
                        "HGLine0": new makerjs.paths.Line([xg - w0, yg + hd], [xg - w0 - markShiftFour, yg + hd]),
                        "HGLine1": new makerjs.paths.Line([xg - w0, yg + h1], [xg - w0 - markShiftFour, yg + h1]),
                        "HGLine2": new makerjs.paths.Line([xg - w0 - markShiftTwo, yg + hd], [xg - w0 - markShiftTwo, yg + h1]),
                  }
            };
            var myRwgGapGMakerH1 = {
                  paths: {
                        "H1GLine0": new makerjs.paths.Line([xg + w1 + w0 + markShiftTwo, yg + h1], [xg + w1 + w0 + markShiftTwo, yg]),
                        "H1GLine1": new makerjs.paths.Line([xg + w1 + w0, yg + h1], [xg + w1 + w0 + markShiftFour, yg+ h1]),
                  }
            };
            var myRwgGapGMakerX = {
                  paths: {
                        "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
                        "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
                        "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
                  }
            };
            var myRwgGapGMakerW1 = {
                  paths: {
                        "W1GLine0": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
                        "W1GLine1": new makerjs.paths.Line([xg + w1, yg], [xg + w1, yg - markShiftFour]),
                        "W1GLine2": new makerjs.paths.Line([xg, yg - markShiftTwo], [xg + w1, yg - markShiftTwo]),
                  }
            };
            var myRwgGapGMakerW = {
                  paths: {
                        "WGLine0": new makerjs.paths.Line([xg - w0, yg + h1], [xg - w0, yg + h1 + markShiftFour * 2 + markShift]),
                        "WGLine1": new makerjs.paths.Line([xg - w0 + w, yg + h1], [xg - w0 + w, yg + h1 + markShiftFour * 2 + markShift]),
                        "WGLine2": new makerjs.paths.Line([xg - w0, yg + h1 + markShiftFour * 2], [xg - w0 + w, yg + h1 +markShiftFour * 2]),
                  }
            };
            makerjs.model.addCaption(myRwgGapGMakerH, TxtH, [xg - w0 - markShiftFour, yg + hd], [xg - w0 - markShiftFour, yg + h1]);//H文字标注
            makerjs.model.addCaption(myRwgGapGMakerH1, TxtH1, [xg + w1 + w0 + markShiftFour, yg], [xg + w1 + w0 + markShiftFour, yg + h1]);//H文字标注
            makerjs.model.addCaption(myRwgGapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//X文字标注 
            makerjs.model.addCaption(myRwgGapGMakerW1, TxtW1, [xg, yg - markShiftFour], [xg + w1, yg - markShiftFour]);//W1文字标注   
            makerjs.model.addCaption(myRwgGapGMakerW, TxtW, [xg - w0, yg + h1 + markShiftFour * 2 + markShift], [xg - w0 + w, yg + h1 + markShiftFour * 2 + markShift]);//W文字标注  
            var markers = [myRwgGapGMakerH, myRwgGapGMakerH1, myRwgGapGMakerX, myRwgGapGMakerW, myRwgGapGMakerW1]
      } else if (area == "H") {
            var myRwgGapHMakerH = {
                  paths: {
                        "HHLine0": new makerjs.paths.Line([xh - w0, yh + hd], [xh - w0 - markShiftFour, yh + hd]),
                        "HHLine1": new makerjs.paths.Line([xh - w0, yh + h1], [xh - w0 - markShiftFour, yh + h1]),
                        "HHLine2": new makerjs.paths.Line([xh - w0 - markShiftTwo, yh + hd], [xh - w0 - markShiftTwo, yh + h1]),
                  }
            };
            var myRwgGapHMakerH1 = {
                  paths: {
                        "H1HLine0": new makerjs.paths.Line([xh + w1 + w0 + markShiftTwo, yh + h1], [xh + w1 + w0 + markShiftTwo, yh]),
                        "H1HLine1": new makerjs.paths.Line([xh + w1 + w0, yh + h1], [xh + w1 + w0 + markShiftFour, yh+ h1]),
                  }
            };
            var myRwgGapHMakerX = {
                  paths: {
                        // "XHLine0": new makerjs.paths.Line([xh - x0, yh - markShiftTwo], [xh, yh - markShiftTwo]),
                        // "XHLine1": new makerjs.paths.Line([xh - x0, yh], [xh - x0, yh - markShiftFour]),
                        // "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour]),
                  }
            };
            var myRwgGapHMakerW1 = {
                  paths: {
                        "W1HLine0": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour*2-markShift]),
                        "W1HLine1": new makerjs.paths.Line([xh + w1, yh], [xh + w1, yh - markShiftFour*2-markShift]),
                        "W1HLine2": new makerjs.paths.Line([xh, yh -  markShiftFour * 2], [xh + w1, yh -  markShiftFour * 2]),
                  }
            };
            var myRwgGapHMakerW = {
                  paths: {
                        "WHLine0": new makerjs.paths.Line([xh - w0, yh + h1], [xh - w0, yh + h1 + markShiftFour * 2 + markShift]),
                        "WHLine1": new makerjs.paths.Line([xh - w0 + w, yh + h1], [xh - w0 + w, yh + h1 + markShiftFour * 2 + markShift]),
                        "WHLine2": new makerjs.paths.Line([xh - w0, yh + h1 + markShiftFour * 2], [xh - w0 + w, yh + h1 +markShiftFour * 2]),
                  }
            };
            makerjs.model.addCaption(myRwgGapHMakerH, TxtH, [xh - w0 - markShiftFour, yh + hd], [xh - w0 - markShiftFour, yh + h1]);//H文字标注
            makerjs.model.addCaption(myRwgGapHMakerH1, TxtH1, [xh + w1 + w0 + markShiftFour, yh], [xh + w1 + w0 + markShiftFour, yh + h1]);//H文字标注
            makerjs.model.addCaption(myRwgGapHMakerX, TxtX, [xh - x0, yh - markShiftFour], [xh, yh - markShiftFour]);//X文字标注 
            makerjs.model.addCaption(myRwgGapHMakerW1, TxtW1, [xh, yh - markShiftFour*2-markShift], [xh + w1, yh - markShiftFour*2-markShift]);//W1文字标注   
            makerjs.model.addCaption(myRwgGapHMakerW, TxtW, [xh - w0, yh + h1 + markShiftFour * 2 + markShift], [xh - w0 + w, yh + h1 + markShiftFour * 2 + markShift]);//W文字标注  
            var markers = [myRwgGapHMakerH, myRwgGapHMakerH1, myRwgGapHMakerX, myRwgGapHMakerW, myRwgGapHMakerW1]
      } else if (area == "I") {
            var myRwgGapIMakerH = {
                  paths: {
                        "HILine0": new makerjs.paths.Line([xi + w0, yi + hd], [xi + w0 + markShiftFour, yi + hd]),
                        "HILine1": new makerjs.paths.Line([xi + w0, yi + h1], [xi + w0 + markShiftFour, yi + h1]),
                        "HILine2": new makerjs.paths.Line([xi + w0 + markShiftTwo, yi + hd], [xi + w0 + markShiftTwo, yi + h1]),
                  }
            };
            var myRwgGapIMakerH1 = {
                  paths: {
                        "H1ILine0": new makerjs.paths.Line([xi- w1 - w0 - markShiftTwo, yi + h1], [xi - w1 - w0 - markShiftTwo, yi]),
                        "H1ILine1": new makerjs.paths.Line([xi - w1 - w0, yi + h1], [xi - w1 - w0 - markShiftFour, yi+ h1]),
                  }
            };
            var myRwgGapIMakerX = {
                  paths: {
                        "XILine0": new makerjs.paths.Line([xi +x0, yi - markShiftTwo], [xi, yi - markShiftTwo]),
                        "XILine1": new makerjs.paths.Line([xi + x0, yi], [xi + x0, yi - markShiftFour]),
                        "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
                  }
            };
            var myRwgGapIMakerW1 = {
                  paths: {
                        "W1ILine0": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
                        "W1ILine1": new makerjs.paths.Line([xi - w1, yi], [xi - w1, yi - markShiftFour]),
                        "W1ILine2": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi - w1, yi - markShiftTwo]),
                  }
            };
            var myRwgGapIMakerW = {
                  paths: {
                        "WILine0": new makerjs.paths.Line([xi + w0, yi + h1], [xi + w0, yi + h1 + markShiftFour * 2 + markShift]),
                        "WILine1": new makerjs.paths.Line([xi + w0 - w, yi + h1], [xi+ w0 - w, yi + h1 + markShiftFour * 2 + markShift]),
                        "WILine2": new makerjs.paths.Line([xi + w0, yi + h1 + markShiftFour * 2], [xi + w0 - w, yi + h1 + markShiftFour * 2]),
                  }
            };
            makerjs.model.addCaption(myRwgGapIMakerH, TxtH, [xi + w0 + markShiftFour, yi + hd], [xi + w0 + markShiftFour, yi + h1]);//H文字标注
            makerjs.model.addCaption(myRwgGapIMakerH1, TxtH1, [xi- w1 - w0 - markShiftFour, yi], [xi - w1 - w0 - markShiftFour, yi + h1]);//H文字标注
            makerjs.model.addCaption(myRwgGapIMakerX, TxtX, [xi, yi - markShiftFour], [xi + x0, yi - markShiftFour]);//X文字标注 
            makerjs.model.addCaption(myRwgGapIMakerW1, TxtW1, [xi - w1, yi - markShiftFour], [xi, yi - markShiftFour]);//W1文字标注   
            makerjs.model.addCaption(myRwgGapIMakerW, TxtW, [xi + w0 - w, yi + h1 + markShiftFour * 2 + markShift], [xi + w0, yi + h1 + markShiftFour * 2 + markShift]);//W文字标注  
            var markers = [myRwgGapIMakerH, myRwgGapIMakerH1, myRwgGapIMakerX, myRwgGapIMakerW, myRwgGapIMakerW1]
      }
      console.log("markers||||||||||||||||||=", markers)
      return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function RunwayGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
      var frame = outParam.shapeName;
      console.log("outParam==", outParam)
      console.log("model==", model)
      console.log("inputParam==", inputParam)
      console.log("outParam.shapeName==////////", outParam.shapeName)
      if (typeof (frame) == "undefined") {
            var H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
                  plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  d = W * 1 * myZoom,
                  h = H * 1 * myZoom,
            h1 = H1 * 1 * myZoom,
            w1 = W1 * 1 * myZoom,
            w = W * 1 * myZoom,
            r = w / 2,
            dd = Math.sqrt(r * r - w1 / 2 * w1 / 2),
            hh = h1 - h - dd,
            hd = h1 - h,
            w0 = (w - w1) / 2,
            x0 = X * 1 * myZoom,
            y0 = Y * 1 * myZoom;
        
      } else {
            var H = inputParam.H, H1 = inputParam.H1, W = inputParam.W, W1 = inputParam.W1, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
                  plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  d = W * 1 * myZoom,
                  h = H * 1 * myZoom,
            h1 = H1 * 1 * myZoom,
            w1 = W1 * 1 * myZoom,
            w = W * 1 * myZoom,
            r = w / 2,
            dd = Math.sqrt(r * r - w1 / 2 * w1 / 2),
            hh = h1 - h - dd,
            hd = h1 - h,
            w0 = (w - w1) / 2,
            x0 = X * 1 * myZoom,
            y0 = Y * 1 * myZoom;
           
      }
      if (frame == "Rectangle") {
            console.log("frame==undefined||||*****")
            //在A区域 计算孔的位置       
            var xa = x0, ya = glassH * 1 - y0,
                  //B区域//只有Y标注没有X标注 
                  xb = glassW * 1 / 2, yb = glassH * 1 - y0,
                  //C区域
                  xc = glassW * 1 - x0, yc = glassH * 1 - y0,
                  //在G区域 计算孔的位置
                  xg = x0, yg = y0,
                  //在H区域 计算孔的位置
                  //只标注Y
                  xh = glassW * 1 / 2, yh = y0,
                  //在I区域 计算孔的位置
                  xi = glassW * 1 - x0, yi = y0;
            var OffsetParam = {};
      } else if (frame == "IsoscelesTrapezoid") {

      } else if (frame == "RightAngledTrapezoid") {
           

      } else if (frame == "OtherTrapezoidsA") {

      } else if (frame == "OtherTrapezoidsB") {

      } else if (frame == "ParallelogramA") {


      } else if (frame == "ParallelogramB") {

      }
      // 坐标参数
      var paramCoordinate = { x0, d, h, h1, w1, w, r, dd, hh, hd, w0, xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
      const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
      var areaPoint = "";
      console.log("//角7、地弹簧3 ------", allKeys)
      if (allKeys[0] == frame) {
            allKeys.shift();//去掉数组第一个元素
      }
      console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
      switch (area) {
            case "1": areaPoint = "A"; break;
            case "2": areaPoint = "B"; break;
            case "3": areaPoint = "C"; break;
            case "7": areaPoint = "G"; break;
            case "8": areaPoint = "H"; break;
            case "9": areaPoint = "I"; break;
      }
      /**
      * 画图
      * 
      */
      var ObjName = "myRunwayGap" + areaPoint;
      var ObjNameMmyRunwayGap = "myRunwayGap" + areaPoint + "Mark";//角标记
      ObjName = creatmyRunwayGap(areaPoint, paramCoordinate);//图形
      console.log("ObjName===|||||||||", ObjName);
      ObjNameMmyRunwayGap = creatmyRunwayGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
      console.log("ObjNameMmyRunwayGap===|||||||||角标注", ObjNameMmyRunwayGap);
      console.log("cutPlate.models====************", cutPlate.models),
            console.log("plate.models====**************", plate.models),
            console.log("markers.models====*************", markers.models),
            // myRwgGapAMakerH, myRwgGapAMakerH1, myRwgGapAMakerX, myRwgGapAMakerW, myRwgGapAMakerW1
            cutPlate.models["myRunwayGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myRunwayGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myRunwayGapH" + areaPoint + myHolesNum] = ObjNameMmyRunwayGap[0];
      plate.models["myRunwayGapH1" + areaPoint + myHolesNum] = ObjNameMmyRunwayGap[1];
      plate.models["myRunwayGapX" + areaPoint + myHolesNum] = ObjNameMmyRunwayGap[2];
      plate.models["myRunwayGapW" + areaPoint + myHolesNum] = ObjNameMmyRunwayGap[3];
      plate.models["myRunwayGapW1" + areaPoint + myHolesNum] = ObjNameMmyRunwayGap[4];
      markers.models["myRunwayGap" + areaPoint + myHolesNum] = ObjName;
      markers.models["myRunwayGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRunwayGap[0];
      markers.models["myRunwayGapH1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRunwayGap[1];
      markers.models["myRunwayGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRunwayGap[2];
      markers.models["myRunwayGapW" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRunwayGap[3];
      markers.models["myRunwayGapW1" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyRunwayGap[4];

      myHolesNum++;
      return { plate, cutPlate, markers, myHolesNum }
};


export default RunwayGap;