//缺2、U型
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyUShapeGap(area, param) {
  console.log("是否调用此函数creatOneCorner（）");
  console.log("是否调用此函数creatOneCorner（）param=", param);
  var xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
  x = param.x0, r = param.r, d = param.d, h = param.h,hd=h-r;
           
  
  if (area == "A") {
    var myUShapeGap = {
          paths: {
                Left: new makerjs.paths.Line([xa, ya], [xa, ya-hd]),
                BottomArc: new makerjs.paths.Arc([xa, ya-hd],[xa+d, ya-hd], r, true,false),
                Right: new makerjs.paths.Line([xa+d, ya-hd], [xa+d, ya]),
          }
    };
console.log("myUShapeGap==",myUShapeGap);
  } else if (area == "B") {
      var myUShapeGap = {
            paths: {
                  Left: new makerjs.paths.Line([xb, yb], [xb, yb-hd]),
                  BottomArc: new makerjs.paths.Arc([xb, yb-hd],[xb+d, yb-hd], r, true,false),
                  Right: new makerjs.paths.Line([xb+d, yb-hd], [xb+d, yb]),
            }
      };
console.log("myUShapeGap==",myUShapeGap);
  } else if (area == "C") {
      var myUShapeGap = {
            paths: {
                  Left: new makerjs.paths.Line([xc-d, yc], [xc-d, yc-hd]),
                  BottomArc: new makerjs.paths.Arc([xc-d, yc-hd],[xc, yc-hd], r, true,false),
                  Right: new makerjs.paths.Line([xc, yc-hd], [xc, yc]),
            }
      };
console.log("myUShapeGap==",myUShapeGap);
  } else if (area == "G") {
      var myUShapeGap = {
            paths: {
                  Left: new makerjs.paths.Line([xg, yg], [xg, yg+hd]),
                  BottomArc: new makerjs.paths.Arc([xg, yg+hd],[xg+d, yg+hd], r, true,true),
                  Right: new makerjs.paths.Line([xg+d, yg+hd], [xg+d, yg]),
            }
      };
console.log("myUShapeGap==",myUShapeGap);
  } else if (area == "H") {
      var myUShapeGap = {
            paths: {
                  Left: new makerjs.paths.Line([xh, yh], [xh, yh+hd]),
                  BottomArc: new makerjs.paths.Arc([xh, yh+hd],[xh+d, yh+hd], r, true,true),
                  Right: new makerjs.paths.Line([xh+d, yh+hd], [xh+d, yh]),
            }
      };
console.log("myUShapeGap==",myUShapeGap);
  } else if (area == "I") {
      var myUShapeGap = {
            paths: {
                  Left: new makerjs.paths.Line([xi-d, yi], [xi-d, yi+hd]),
                  BottomArc: new makerjs.paths.Arc([xi-d, yi+hd],[xi, yi+hd], r, true,true),
                  Right: new makerjs.paths.Line([xi, yi+hd], [xi, yi]),
            }
      };
console.log("myUShapeGap==",myUShapeGap);
  }
  return myUShapeGap;
};
// 矩形等去角标记 连线函数      
function creatmyUShapeGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
  console.log("是否调用此函数creatCornerMark()");
  console.log("是否调用此函数param", param);
  console.log("是否调用此函数outParam", outParam);
  console.log("是否调用此函数inputParam", inputParam);
  console.log("是否调用此函数OffsetParam", OffsetParam);
  var D=inputParam.D,H=inputParam.H,X=inputParam.X,Y=inputParam.Y, myZoom = zoom * 1,
        xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
        x = param.x0, r = param.r, d = param.d, h = param.h,hd=h-r, 
        glassW = outParam.glassW, glassH = outParam.glassH,
       markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
        markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
        d = D*1*myZoom,
   h= H*1*myZoom,
   r=d/2,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     if(d!==0){
      var TxtD=TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
     if(x0!==0){          
      var TxtX=X;
     }

  if (area == "A") {
      var myUsgGapAMakerH = {
            paths: {
                  "HALine0": new makerjs.paths.Line([xa+d+markShift,ya], [xa+d+markShift,ya-h]),
                  "HALine1": new makerjs.paths.Line([xa+r,ya-h], [xa+d+markShiftFour,ya-h]),
            }
      };
      var myUsgGapAMakerD = {
            paths: {
                  "DALine0": new makerjs.paths.Line([xa,ya], [xa,ya+markShiftFour]),
                  "DALine1": new makerjs.paths.Line([xa+d,ya], [xa+d,ya+markShiftFour]),
                  "DALine2": new makerjs.paths.Line([xa,ya+markShiftTwo], [xa+d, ya+markShiftTwo]),
            }
      };
        var myUsgGapAMakerX = {
              paths: {
                    "XALine0": new makerjs.paths.Line([xa - x0, ya + markShift], [xa, ya + markShift]),
                    "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                    "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
              }
        };
        makerjs.model.addCaption(myUsgGapAMakerH, TxtH,[xa+d+markShiftFour,ya-h], [xa+d+markShiftFour,ya]);//H文字标注
        makerjs.model.addCaption(myUsgGapAMakerX, TxtX, [xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myUsgGapAMakerD, TxtD, [xa,ya+markShiftFour], [xa+d, ya+markShiftFour]);//D文字标注  
        var markers = [myUsgGapAMakerH,myUsgGapAMakerX,myUsgGapAMakerD]
  } else if (area == "B") {
      var myUsgGapBMakerH = {
            paths: {
                  "HBLine0": new makerjs.paths.Line([xb+d+markShift,yb], [xb+d+markShift,yb-h]),
                  "HBLine1": new makerjs.paths.Line([xb+r,yb-h], [xb+d+markShiftFour,yb-h]),
            }
      };
      var myUsgGapBMakerD = {
            paths: {
                  "DBLine0": new makerjs.paths.Line([xb,yb], [xb,yb+markShiftFour]),
                  "DBLine1": new makerjs.paths.Line([xb+d,yb], [xb+d,yb+markShiftFour]),
                  "DBLine2": new makerjs.paths.Line([xb,yb+markShiftTwo], [xb+d, yb+markShiftTwo]),
            }
      };
        var myUsgGapBMakerX = {
              paths: {
                  //   "XBLine0": new makerjs.paths.Line([xb - x0, yb + markShift], [xb, yb + markShift]),
                  //   "XBLine1": new makerjs.paths.Line([xb - x0, yb], [xb - x0, yb + markShiftFour]),
                  //   "XBLine2": new makerjs.paths.Line([xb, yb], [xb, yb + markShiftFour]),
              }
        };
        makerjs.model.addCaption(myUsgGapBMakerH, TxtH,[xb+d+markShiftFour,yb-h], [xb+d+markShiftFour,yb]);//H文字标注
        makerjs.model.addCaption(myUsgGapBMakerX, TxtX, [xb - x0, yb + markShiftTwo], [xb, yb + markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myUsgGapBMakerD, TxtD, [xb,yb+markShiftFour], [xb+d, yb+markShiftFour]);//D文字标注  
        var markers = [myUsgGapBMakerH,myUsgGapBMakerX,myUsgGapBMakerD]
  } else if (area == "C") {
      var myUsgGapCMakerH = {
            paths: {
                  "HCLine0": new makerjs.paths.Line([xc-d-markShift,yc], [xc-d-markShift,yc-h]),
                  "HCLine1": new makerjs.paths.Line([xc-r,yc-h], [xc-d-markShiftFour,yc-h]),
            }
      };
      var myUsgGapCMakerD = {
            paths: {
                  "DCLine0": new makerjs.paths.Line([xc,yc], [xc,yc+markShiftFour]),
                  "DCLine1": new makerjs.paths.Line([xc-d,yc], [xc-d,yc+markShiftFour]),
                  "DCLine2": new makerjs.paths.Line([xc-d,yc+markShiftTwo], [xc, yc+markShiftTwo]),
            }
      };
        var myUsgGapCMakerX = {
              paths: {
                    "XCLine0": new makerjs.paths.Line([xc + x0, yc + markShift], [xc, yc + markShift]),
                    "XCLine1": new makerjs.paths.Line([xc + x0, yc], [xc + x0, yc + markShiftFour]),
                    "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
              }
        };
        makerjs.model.addCaption(myUsgGapCMakerH, TxtH,[xc-d-markShiftFour,yc-h], [xc-d-markShiftFour,yc]);//H文字标注
        makerjs.model.addCaption(myUsgGapCMakerX, TxtX, [xc, yc + markShiftTwo], [xc+x0, yc + markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myUsgGapCMakerD, TxtD, [xc-d,yc+markShiftFour], [xc, yc+markShiftFour]);//D文字标注  
        var markers = [myUsgGapCMakerH,myUsgGapCMakerX,myUsgGapCMakerD]
  } else if (area == "G") {
      var myUsgGapGMakerH = {
            paths: {
                  "HGLine0": new makerjs.paths.Line([xg+d+markShift,yg], [xg+d+markShift,yg+h]),
                  "HGLine1": new makerjs.paths.Line([xg+r,yg+h], [xg+d+markShiftFour,yg+h]),
            }
      };
      var myUsgGapGMakerD = {
            paths: {
                  "DGLine0": new makerjs.paths.Line([xg,yg], [xg,yg-markShiftFour]),
                  "DGLine1": new makerjs.paths.Line([xg+d,yg], [xg+d,yg-markShiftFour]),
                  "DGLine2": new makerjs.paths.Line([xg,yg-markShiftTwo], [xg+d, yg-markShiftTwo]),
            }
      };
        var myUsgGapGMakerX = {
              paths: {
                    "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
                    "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
                    "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
              }
        };
        makerjs.model.addCaption(myUsgGapGMakerH, TxtH, [xg+d+markShiftFour,yg],[xg+d+markShiftFour,yg+h]);//H文字标注
        makerjs.model.addCaption(myUsgGapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//X文字标注
        makerjs.model.addCaption(myUsgGapGMakerD, TxtD, [xg,yg-markShiftFour], [xg+d, yg-markShiftFour]);//D文字标注  
   var markers = [myUsgGapGMakerH,myUsgGapGMakerX,  myUsgGapGMakerD]
  } else if (area == "H") {
      var myUsgGapHMakerH = {
            paths: {
                  "HHLine0": new makerjs.paths.Line([xh+d+markShift,yh], [xh+d+markShift,yh+h]),
                  "HHLine1": new makerjs.paths.Line([xh+r,yh+h], [xh+d+markShiftFour,yh+h]),
            }
      };
      var myUsgGapHMakerD = {
            paths: {
                  "DHLine0": new makerjs.paths.Line([xh,yh], [xh,yh-markShiftFour*2]),
                  "DHLine1": new makerjs.paths.Line([xh+d,yh], [xh+d,yh-markShiftFour*2]),
                  "DHLine2": new makerjs.paths.Line([xh,yh-markShiftFour-markShiftTwo], [xh+d, yh-markShiftFour-markShiftTwo]),
            }
      };
        var myUsgGapHMakerX = {
              paths: {
                  //   "XHLine0": new makerjs.paths.Line([xh - x0, yh - markShift], [xh, yh - markShift]),
                  //   "XHLine1": new makerjs.paths.Line([xh - x0, yh], [xh - x0, yh - markShiftFour]),
                  //   "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh - markShiftFour]),
              }
        };
        makerjs.model.addCaption(myUsgGapHMakerH, TxtH, [xh+d+markShiftFour,yh],[xh+d+markShiftFour,yh+h]);//H文字标注
        makerjs.model.addCaption(myUsgGapHMakerX, TxtX, [xh - x0, yh - markShiftTwo], [xh, yh - markShiftTwo]);//X文字标注
        makerjs.model.addCaption(myUsgGapHMakerD, TxtD, [xh,yh-markShiftFour*2], [xh+d, yh-markShiftFour*2]);//D文字标注  
   var markers = [myUsgGapHMakerH,myUsgGapHMakerX,  myUsgGapHMakerD]
  } else if (area == "I") {
      var myUsgGapIMakerH = {
            paths: {
                  "HILine0": new makerjs.paths.Line([xi-d-markShift,yi], [xi-d-markShift,yi+h]),
                  "HILine1": new makerjs.paths.Line([xi-r,yi+h], [xi-d-markShiftFour,yi+h]),
            }
      };
      var myUsgGapIMakerD = {
            paths: {
                  "DILine0": new makerjs.paths.Line([xi,yi], [xi,yi-markShiftFour]),
                  "DILine1": new makerjs.paths.Line([xi-d,yi], [xi-d,yi-markShiftFour]),
                  "DILine2": new makerjs.paths.Line([xi-d,yi-markShiftTwo], [xi, yi-markShiftTwo]),
            }
      };
        var myUsgGapIMakerX = {
              paths: {
                    "XILine0": new makerjs.paths.Line([xi + x0, yi - markShiftTwo], [xi, yi - markShiftTwo]),
                    "XILine1": new makerjs.paths.Line([xi + x0, yi], [xi + x0, yi - markShiftFour]),
                    "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
              }
        };
        makerjs.model.addCaption(myUsgGapIMakerH, TxtH, [xi-d-markShiftFour,yi],[xi-d-markShiftFour,yi+h]);//H文字标注
        makerjs.model.addCaption(myUsgGapIMakerX, TxtX, [xi, yi - markShiftFour], [xi+x0, yi - markShiftFour]);//X文字标注
        makerjs.model.addCaption(myUsgGapIMakerD, TxtD, [xi-d,yi-markShiftFour], [xi, yi-markShiftFour]);//D文字标注  
   var markers = [myUsgGapIMakerH,myUsgGapIMakerX,myUsgGapIMakerD]
  }
  console.log("markers||||||||||||||||||=", markers)
  return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function UShapeGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){

      var frame = outParam.shapeName;
      console.log("outParam==", outParam)
      console.log("model==", model)
      console.log("inputParam==", inputParam)
      console.log("outParam.shapeName==////////", outParam.shapeName)
      if (typeof (frame) == "undefined") {
            var D=inputParam.D,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
            plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  d = D*1*myZoom,
   h= H*1*myZoom,
   r=d/2,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     if(d!==0){
      var TxtD=TxtD="∮"+D;
     }
     if(h!==0){
      var TxtH=H;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
     if(x0!==0){          
      var TxtX=X;
     }
      } else {
            var D=inputParam.D,W=inputParam.W,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
            plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
                  d = D*1*myZoom,
   w= W*1*myZoom,
   r=d/2,
     x0 = X*1*myZoom,
     y0 = Y*1*myZoom;
     if(d!==0){
      var TxtD=TxtD="∮"+D;
     }
     if(w!==0){
      var TxtW=W;
     }
     if(y0!==0){          
      var TxtY=Y;
     }
     if(x0!==0){          
      var TxtX=X;
     }
      }
      if (frame == "Rectangle") {
            console.log("frame==undefined||||*****")
            //在A区域 计算孔的位置       
            var xa = x0, ya = glassH * 1 - y0,
                  //B区域//只有Y标注没有X标注 
                  xb = glassW * 1 / 2, yb = glassH * 1 - y0,
                  //C区域
                  xc = glassW * 1 - x0, yc = glassH * 1 - y0,
                  //在G区域 计算孔的位置
                  xg = x0, yg = y0,
                  //在H区域 计算孔的位置
                  //只标注Y
                  xh = glassW * 1 / 2, yh = y0,
                  //在I区域 计算孔的位置
                  xi = glassW * 1 - x0, yi = y0;
            var OffsetParam = {};
      } else if (frame == "IsoscelesTrapezoid") {
    
      } else if (frame == "RightAngledTrapezoid") {d
    
      } else if (frame == "OtherTrapezoidsA") {
    
      } else if (frame == "OtherTrapezoidsB") {
    
      } else if (frame == "ParallelogramA") {
    
    
      } else if (frame == "ParallelogramB") {
    
      }
      // 坐标参数
      var paramCoordinate = { x0,d,h,r,xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
      const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
      var areaPoint = "";
      console.log("//角7、地弹簧3 ------", allKeys)
      if (allKeys[0] == frame) {
            allKeys.shift();//去掉数组第一个元素
      }
      console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
      switch (area) {
            case "1": areaPoint = "A"; break;
            case "2": areaPoint = "B"; break;
            case "3": areaPoint = "C"; break;
            case "7": areaPoint = "G"; break;
            case "8": areaPoint = "H"; break;
            case "9": areaPoint = "I"; break;
      }
      /**
      * 画图
      * 
      */
      var ObjName = "myUShapeGap" + areaPoint;
      var ObjNameMmyUShapeGap = "myUShapeGap" + areaPoint + "Mark";//角标记
      ObjName = creatmyUShapeGap(areaPoint, paramCoordinate);//图形
      console.log("ObjName===|||||||||", ObjName);
      ObjNameMmyUShapeGap = creatmyUShapeGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
      console.log("ObjNameMmyUShapeGap===|||||||||角标注", ObjNameMmyUShapeGap);
      console.log("cutPlate.models====************", cutPlate.models),
            console.log("plate.models====**************", plate.models),
            console.log("markers.models====*************", markers.models),
         // myUsgGapAMakerH,myUsgGapAMakerX,myUsgGapAMakerD
            cutPlate.models["myUShapeGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myUShapeGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myUShapeGapH" + areaPoint + myHolesNum] = ObjNameMmyUShapeGap[0];
      plate.models["myUShapeGapX" + areaPoint + myHolesNum] = ObjNameMmyUShapeGap[1];
      plate.models["myUShapeGapD" + areaPoint + myHolesNum] = ObjNameMmyUShapeGap[2];
      markers.models["myUShapeGap" + areaPoint + myHolesNum] = ObjName;
      markers.models["myUShapeGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyUShapeGap[0];
      markers.models["myUShapeGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyUShapeGap[1];
      markers.models["myUShapeGapD" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyUShapeGap[2];
myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default UShapeGap;