//17、三孔2 A
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ThreeHoles(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom; 
        /*
        d = D*1,
        w= W*1,
        h= H*1,
          x0 = X*1,
          y0 = Y*1;
          */
       if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
      //画孔A位置孔
            //标记三孔 A位置孔右上
            var myThHoles2ARightup= new makerjs.models.Holes(d/2, [[xa, ya]]);
            var myThHoles2MarkARightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles2MarkARightup.origin = [xa, ya];      
            var myThHoles2XMarkARightup = new makerjs.models.Square(0);      
            myThHoles2XMarkARightup.origin =[xa, ya];      
            var myThHoles2WMarkARightup = new makerjs.models.Square(0);      
            myThHoles2WMarkARightup.origin =[xa, ya];       
            var myThHoles2HMarkARightup = new makerjs.models.Square(0);      
            myThHoles2HMarkARightup.origin =[xa, ya];        
            var myThHoles2YMarkARightup = new makerjs.models.Square(0);      
            myThHoles2YMarkARightup.origin =[xa, ya];         
            var myThHoles2XMarkARightup = new makerjs.models.Square(0);      
            myThHoles2XMarkARightup.origin =[xa, ya];   
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkARightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(myThHoles2MarkARightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
      makerjs.model.addPath(myThHoles2MarkARightup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
      makerjs.model.addPath(myThHoles2MarkARightup, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkARightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles2MarkARightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles2WMarkARightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
      makerjs.model.addCaption(myThHoles2WMarkARightup, TxtW,[0,0+markShift], [w, 0+markShift]);//W文字标注
      /*****************************************************************************/     
      makerjs.model.addPath(myThHoles2HMarkARightup, new makerjs.paths.Line([0,-h], [0, 0]));//H短标线
      makerjs.model.addCaption(myThHoles2HMarkARightup, TxtH,[0-markShift,-h], [0-markShift, 0]);//H文字标注 
      /*****************************************************************************/     
      makerjs.model.addPath(myThHoles2YMarkARightup, new makerjs.paths.Line([0,0], [0, y0]));//Y短标线
      makerjs.model.addCaption(myThHoles2YMarkARightup, TxtY,[0-markShift,0], [0-markShift, y0]);//Y文字标注 
      /*****************************************************************************/     
      makerjs.model.addPath(myThHoles2XMarkARightup, new makerjs.paths.Line([-x0,y0+markShift], [0, y0+markShift]));//X短标线
      makerjs.model.addCaption(myThHoles2XMarkARightup, TxtX,[-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注 
      /*****************************************************************************/ 
       //标记三孔A位置孔左下
       var myThHoles2ALeftdn= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
            var myThHoles2MarkALeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles2MarkALeftdn.origin = [xa+w, ya]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkALeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles2MarkALeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/ 
       //标记三孔 A位置孔右下
       var myThHoles2ARightdn= new makerjs.models.Holes(d/2, [[xa, ya-h]]);
            var myThHoles2MarkARightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles2MarkARightdn.origin = [xa, ya-h]; 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkARightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles2MarkARightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      plate.models["myThHoles2ALeftdn"+myHolesNum]=myThHoles2ALeftdn;
      cutPlate.models["myThHoles2ALeftdn"+myHolesNum]=myThHoles2ALeftdn;
      markers.models["myThHoles2ALeftdn"+myHolesNum]=myThHoles2ALeftdn;
      markers.models["myThHoles2MarkALeftdn"+myHolesNum]=myThHoles2MarkALeftdn;
      /*****************************************************************************/ 
      plate.models["myThHoles2ARightup"+myHolesNum]=myThHoles2ARightup;
      cutPlate.models["myThHoles2ARightup"+myHolesNum]=myThHoles2ARightup;
      markers.models["myThHoles2ARightup"+myHolesNum]=myThHoles2ARightup;
      markers.models["myThHoles2MarkARightup"+myHolesNum]=myThHoles2MarkARightup;
      markers.models["myThHoles2WMarkARightup"+myHolesNum]=myThHoles2WMarkARightup;
      markers.models["myThHoles2XMarkARightup"+myHolesNum]=myThHoles2XMarkARightup;
      markers.models["myThHoles2YMarkARightup"+myHolesNum]=myThHoles2YMarkARightup;
      markers.models["myThHoles2HMarkARightup"+myHolesNum]=myThHoles2HMarkARightup;
      /*****************************************************************************/ 
      plate.models["myThHoles2ARightdn"+myHolesNum]=myThHoles2ARightdn;
      cutPlate.models["myThHoles2ARightdn"+myHolesNum]=myThHoles2ARightdn;
      markers.models["myThHoles2ARightdn"+myHolesNum]=myThHoles2ARightdn;
      markers.models["myThHoles2MarkARightdn"+myHolesNum]=myThHoles2MarkARightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
          
      }else if(area==2){
            //画孔B位置孔
                  //标记三孔 B位置孔右上
                  var myThHoles2BRightup= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
                  var myThHoles2MarkBRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkBRightup.origin = [xb-w/2, yb];      
                  var myThHoles2XMarkBRightup = new makerjs.models.Square(0);      
                  myThHoles2XMarkBRightup.origin =[xb-w/2, yb];       
                  var myThHoles2HMarkBRightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkBRightup.origin =[xb-w/2, yb];        
                  var myThHoles2YMarkBRightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkBRightup.origin =[xb-w/2, yb];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkBRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkBRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2XMarkBRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkBRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2HMarkBRightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkBRightup, TxtH,[0-markShift,-h], [0-markShift, 0]);//X文字标注 
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2YMarkBRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
            makerjs.model.addCaption(myThHoles2YMarkBRightup, TxtY,[0-markShift,0], [0-markShift, y0]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔B位置孔左下
             var myThHoles2BLeftdn= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
                  var myThHoles2MarkBLeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkBLeftdn.origin = [xb+w/2, yb]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkBLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkBLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
             //标记三孔 B位置孔右下
             var myThHoles2BRightdn= new makerjs.models.Holes(d/2, [[xb-w/2, yb-h]]);
                  var myThHoles2MarkBRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkBRightdn.origin = [xb-w/2, yb-h]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkBRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkBRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2BLeftdn"+myHolesNum]=myThHoles2BLeftdn;
            cutPlate.models["myThHoles2BLeftdn"+myHolesNum]=myThHoles2BLeftdn;
            markers.models["myThHoles2BLeftdn"+myHolesNum]=myThHoles2BLeftdn;
            markers.models["myThHoles2MarkBLeftdn"+myHolesNum]=myThHoles2MarkBLeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2BRightup"+myHolesNum]=myThHoles2BRightup;
            cutPlate.models["myThHoles2BRightup"+myHolesNum]=myThHoles2BRightup;
            markers.models["myThHoles2BRightup"+myHolesNum]=myThHoles2BRightup;
            markers.models["myThHoles2MarkBRightup"+myHolesNum]=myThHoles2MarkBRightup;
            markers.models["myThHoles2XMarkBRightup"+myHolesNum]=myThHoles2XMarkBRightup;
            markers.models["myThHoles2YMarkBRightup"+myHolesNum]=myThHoles2YMarkBRightup;
            markers.models["myThHoles2HMarkBRightup"+myHolesNum]=myThHoles2HMarkBRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2BRightdn"+myHolesNum]=myThHoles2BRightdn;
            cutPlate.models["myThHoles2BRightdn"+myHolesNum]=myThHoles2BRightdn;
            markers.models["myThHoles2BRightdn"+myHolesNum]=myThHoles2BRightdn;
            markers.models["myThHoles2MarkBRightdn"+myHolesNum]=myThHoles2MarkBRightdn;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
                
      }else if(area==3){
            //画孔C位置孔
                  //标记三孔 C位置孔右上
                  var myThHoles2CRightup= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
                  var myThHoles2MarkCRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkCRightup.origin = [xc-w, yc];      
                  var myThHoles2XMarkCRightup = new makerjs.models.Square(0);      
                  myThHoles2XMarkCRightup.origin =[xc-w, yc];       
                  var myThHoles2HMarkCRightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkCRightup.origin =[xc-w, yc];        
                  var myThHoles2YMarkCRightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkCRightup.origin =[xc-w, yc];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkCRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkCRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2XMarkCRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkCRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2HMarkCRightup, new makerjs.paths.Line([w,-h], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkCRightup, TxtH,[w-markShift,-h], [w-markShift, 0]);//X文字标注 
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2YMarkCRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
            makerjs.model.addCaption(myThHoles2YMarkCRightup, TxtY,[0-markShift,0], [0-markShift, y0]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔C位置孔左下
             var myThHoles2CLeftdn= new makerjs.models.Holes(d/2, [[xc, yc]]);
                  var myThHoles2MarkCLeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkCLeftdn.origin = [xc, yc];        
                  var myThHoles2XMarkCLeftdn = new makerjs.models.Square(0);      
                  myThHoles2XMarkCLeftdn.origin =[xc, yc];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkCLeftdn, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkCLeftdn, new makerjs.paths.Line([0,y0], [0, y0+markShiftFour]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkCLeftdn, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkCLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkCLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2XMarkCLeftdn, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkCLeftdn, TxtX,[0,y0+markShiftThree], [x0, y0+markShiftThree]);//X文字标注 
            /*****************************************************************************/  
            /*****************************************************************************/ 
            /*****************************************************************************/ 
             //标记三孔 C位置孔右下
             var myThHoles2CRightdn= new makerjs.models.Holes(d/2, [[xc, yc-h]]);
                  var myThHoles2MarkCRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkCRightdn.origin = [xc, yc-h]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkCRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkCRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2CLeftdn"+myHolesNum]=myThHoles2CLeftdn;
            cutPlate.models["myThHoles2CLeftdn"+myHolesNum]=myThHoles2CLeftdn;
            markers.models["myThHoles2CLeftdn"+myHolesNum]=myThHoles2CLeftdn;
            markers.models["myThHoles2MarkCLeftdn"+myHolesNum]=myThHoles2MarkCLeftdn;
            markers.models["myThHoles2XMarkCLeftdn"+myHolesNum]=myThHoles2XMarkCLeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2CRightup"+myHolesNum]=myThHoles2CRightup;
            cutPlate.models["myThHoles2CRightup"+myHolesNum]=myThHoles2CRightup;
            markers.models["myThHoles2CRightup"+myHolesNum]=myThHoles2CRightup;
            markers.models["myThHoles2MarkCRightup"+myHolesNum]=myThHoles2MarkCRightup;
            markers.models["myThHoles2XMarkCRightup"+myHolesNum]=myThHoles2XMarkCRightup;
            markers.models["myThHoles2YMarkCRightup"+myHolesNum]=myThHoles2YMarkCRightup;
            markers.models["myThHoles2HMarkCRightup"+myHolesNum]=myThHoles2HMarkCRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2CRightdn"+myHolesNum]=myThHoles2CRightdn;
            cutPlate.models["myThHoles2CRightdn"+myHolesNum]=myThHoles2CRightdn;
            markers.models["myThHoles2CRightdn"+myHolesNum]=myThHoles2CRightdn;
            markers.models["myThHoles2MarkCRightdn"+myHolesNum]=myThHoles2MarkCRightdn;
            markers.models["myThHoles2MarkCRightdn"+myHolesNum]=myThHoles2MarkCRightdn;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
                
      }else if(area==4){
            //画孔D位置孔
                  //标记三孔 D位置孔右上
                  var myThHoles2DRightup= new makerjs.models.Holes(d/2, [[xd, yd-h/2]]);
                  var myThHoles2MarkDRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkDRightup.origin = [xd, yd-h/2];       
                  var myThHoles2WMarkDRightup = new makerjs.models.Square(0);      
                  myThHoles2WMarkDRightup.origin =[xd, yd-h/2];       
                  var myThHoles2HMarkDRightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkDRightup.origin =[xd, yd-h/2];        
                  var myThHoles2YMarkDRightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkDRightup.origin =[xd, yd-h/2];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkDRightup, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkDRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkDRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkDRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2WMarkDRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2WMarkDRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/    
            makerjs.model.addPath(myThHoles2HMarkDRightup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkDRightup, TxtH,[0-markShift,0], [0-markShift, h]);//X文字标注 
            /*****************************************************************************/  
             //标记三孔D位置孔左下
             var myThHoles2DLeftdn= new makerjs.models.Holes(d/2, [[xd, yd+h/2]]);
                  var myThHoles2MarkDLeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkDLeftdn.origin = [xd, yd+h/2];        
                  var myThHoles2XMarkDLeftdn = new makerjs.models.Square(0);      
                  myThHoles2XMarkDLeftdn.origin =[xd, yd+h/2];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([0,0], [0, yd-h/2]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([0,yd-h/2], [0, yd-h/2+markShiftFour]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([-x0,yd-h/2], [-x0, yd-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkDLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkDLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2XMarkDLeftdn, new makerjs.paths.Line([-x0,yd-h/2+markShift], [0, yd-h/2+markShift]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkDLeftdn, TxtX,[-x0,yd-h/2+markShiftThree], [0, yd-h/2+markShiftThree]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔 D位置孔右下
             var myThHoles2DRightdn= new makerjs.models.Holes(d/2, [[xd+w, yd-h/2]]);
                  var myThHoles2MarkDRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkDRightdn.origin = [xd+w, yd-h/2]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkDRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkDRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2DLeftdn"+myHolesNum]=myThHoles2DLeftdn;
            cutPlate.models["myThHoles2DLeftdn"+myHolesNum]=myThHoles2DLeftdn;
            markers.models["myThHoles2DLeftdn"+myHolesNum]=myThHoles2DLeftdn;
            markers.models["myThHoles2MarkDLeftdn"+myHolesNum]=myThHoles2MarkDLeftdn;
            markers.models["myThHoles2XMarkDLeftdn"+myHolesNum]=myThHoles2XMarkDLeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2DRightup"+myHolesNum]=myThHoles2DRightup;
            cutPlate.models["myThHoles2DRightup"+myHolesNum]=myThHoles2DRightup;
            markers.models["myThHoles2DRightup"+myHolesNum]=myThHoles2DRightup;
            markers.models["myThHoles2MarkDRightup"+myHolesNum]=myThHoles2MarkDRightup;
            markers.models["myThHoles2WMarkDRightup"+myHolesNum]=myThHoles2WMarkDRightup;
            markers.models["myThHoles2YMarkDRightup"+myHolesNum]=myThHoles2YMarkDRightup;
            markers.models["myThHoles2HMarkDRightup"+myHolesNum]=myThHoles2HMarkDRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2DRightdn"+myHolesNum]=myThHoles2DRightdn;
            cutPlate.models["myThHoles2DRightdn"+myHolesNum]=myThHoles2DRightdn;
            markers.models["myThHoles2DRightdn"+myHolesNum]=myThHoles2DRightdn;
            markers.models["myThHoles2MarkDRightdn"+myHolesNum]=myThHoles2MarkDRightdn;
            markers.models["myThHoles2MarkDRightdn"+myHolesNum]=myThHoles2MarkDRightdn;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
                
      }else if(area==5){
            //画孔E位置孔
                  //标记三孔 E位置孔右上
                  var myThHoles2ERightup= new makerjs.models.Holes(d/2, [[xe-w/2, ye-h/2]]);
                  var myThHoles2MarkERightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkERightup.origin = [xe-w/2, ye-h/2];    
                  var myThHoles2WMarkERightup = new makerjs.models.Square(0);      
                  myThHoles2WMarkERightup.origin =[xe-w/2, ye-h/2];       
                  var myThHoles2HMarkERightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkERightup.origin =[xe-w/2, ye-h/2];        
                  var myThHoles2YMarkERightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkERightup.origin =[xe-w/2, ye-h/2];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkERightup, new makerjs.paths.Line([0,0], [0, h]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkERightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkERightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkERightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2WMarkERightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2WMarkERightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/    
            makerjs.model.addPath(myThHoles2HMarkERightup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkERightup, TxtH,[0-markShift,0], [0-markShift, h]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔E位置孔左下
             var myThHoles2ELeftdn= new makerjs.models.Holes(d/2, [[xe-w/2, ye+h/2]]);
                  var myThHoles2MarkELeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkELeftdn.origin = [xe-w/2, ye+h/2];        
                  var myThHoles2XMarkELeftdn = new makerjs.models.Square(0);      
                  myThHoles2XMarkELeftdn.origin =[xe-w/2, ye+h/2];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkELeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkELeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2XMarkELeftdn, new makerjs.paths.Line([-x0,yd-h/2+markShift], [0, yd-h/2+markShift]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkELeftdn, TxtX,[-x0,yd-h/2+markShiftThree], [0, yd-h/2+markShiftThree]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔 E位置孔右下
             var myThHoles2ERightdn= new makerjs.models.Holes(d/2, [[xe+w/2, ye-h/2]]);
                  var myThHoles2MarkERightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkERightdn.origin = [xe+w/2, ye-h/2]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkERightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkERightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2ELeftdn"+myHolesNum]=myThHoles2ELeftdn;
            cutPlate.models["myThHoles2ELeftdn"+myHolesNum]=myThHoles2ELeftdn;
            markers.models["myThHoles2ELeftdn"+myHolesNum]=myThHoles2ELeftdn;
            markers.models["myThHoles2MarkELeftdn"+myHolesNum]=myThHoles2MarkELeftdn;
            markers.models["myThHoles2XMarkELeftdn"+myHolesNum]=myThHoles2XMarkELeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2ERightup"+myHolesNum]=myThHoles2ERightup;
            cutPlate.models["myThHoles2ERightup"+myHolesNum]=myThHoles2ERightup;
            markers.models["myThHoles2ERightup"+myHolesNum]=myThHoles2ERightup;
            markers.models["myThHoles2MarkERightup"+myHolesNum]=myThHoles2MarkERightup;
            markers.models["myThHoles2WMarkERightup"+myHolesNum]=myThHoles2WMarkERightup;
            markers.models["myThHoles2YMarkERightup"+myHolesNum]=myThHoles2YMarkERightup;
            markers.models["myThHoles2HMarkERightup"+myHolesNum]=myThHoles2HMarkERightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2ERightdn"+myHolesNum]=myThHoles2ERightdn;
            cutPlate.models["myThHoles2ERightdn"+myHolesNum]=myThHoles2ERightdn;
            markers.models["myThHoles2ERightdn"+myHolesNum]=myThHoles2ERightdn;
            markers.models["myThHoles2MarkERightdn"+myHolesNum]=myThHoles2MarkERightdn;
            markers.models["myThHoles2MarkERightdn"+myHolesNum]=myThHoles2MarkERightdn;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
                
      }else if(area==6){
            //画孔F位置孔
                  //标记三孔 F位置孔右上
                  var myThHoles2FRightup= new makerjs.models.Holes(d/2, [[xf, yf+h/2]]);
                  var myThHoles2MarkFRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkFRightup.origin = [xf, yf+h/2];      
                  var myThHoles2WMarkFRightup = new makerjs.models.Square(0);      
                  myThHoles2WMarkFRightup.origin =[xf, yf+h/2];       
                  var myThHoles2HMarkFRightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkFRightup.origin =[xf, yf+h/2];        
                  var myThHoles2YMarkFRightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkFRightup.origin =[xf, yf+h/2];   
            /*****************************************************************************/ 
            //makerjs.model.addPath(myThHoles2MarkFRightup, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
           // makerjs.model.addPath(myThHoles2MarkFRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkFRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkFRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2WMarkFRightup, new makerjs.paths.Line([-w,-h], [0, -h]));//X短标线
            makerjs.model.addCaption(myThHoles2WMarkFRightup, TxtW,[-w,-h+markShift], [0, -h+markShift]);//X文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2HMarkFRightup, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkFRightup, TxtH,[0-markShift,-h], [0-markShift, 0]);//X文字标注 
            /*****************************************************************************/     
           // makerjs.model.addPath(myThHoles2YMarkFRightup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
            //makerjs.model.addCaption(myThHoles2YMarkFRightup, TxtY,[0-markShift,0], [0-markShift, y0]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔F位置孔左下
             var myThHoles2FLeftdn= new makerjs.models.Holes(d/2, [[xf, yf-h/2]]);
                  var myThHoles2MarkFLeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkFLeftdn.origin = [xf, yf-h/2];        
                  var myThHoles2XMarkFLeftdn = new makerjs.models.Square(0);      
                  myThHoles2XMarkFLeftdn.origin =[xf, yf-h/2];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,0], [0, yf+h/2]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,0], [x0, 0]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,yf+h/2], [0, yf+h/2+markShiftFour]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([x0,yf+h/2], [x0, yf+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkFLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkFLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2XMarkFLeftdn, new makerjs.paths.Line([0,yf+h/2+markShift], [x0, yf+h/2+markShift]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkFLeftdn, TxtX,[0,yf+h/2+markShiftThree], [x0, yf+h/2+markShiftThree]);//X文字标注 
            /*****************************************************************************/  
            /*****************************************************************************/ 
            /*****************************************************************************/ 
             //标记三孔 F位置孔右下
             var myThHoles2FRightdn= new makerjs.models.Holes(d/2, [[xf-w, yf-h/2]]);
                  var myThHoles2MarkFRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkFRightdn.origin = [xf-w, yf-h/2]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkFRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkFRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2FLeftdn"+myHolesNum]=myThHoles2FLeftdn;
            cutPlate.models["myThHoles2FLeftdn"+myHolesNum]=myThHoles2FLeftdn;
            markers.models["myThHoles2FLeftdn"+myHolesNum]=myThHoles2FLeftdn;
            markers.models["myThHoles2MarkFLeftdn"+myHolesNum]=myThHoles2MarkFLeftdn;
            markers.models["myThHoles2XMarkFLeftdn"+myHolesNum]=myThHoles2XMarkFLeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2FRightup"+myHolesNum]=myThHoles2FRightup;
            cutPlate.models["myThHoles2FRightup"+myHolesNum]=myThHoles2FRightup;
            markers.models["myThHoles2FRightup"+myHolesNum]=myThHoles2FRightup;
            markers.models["myThHoles2MarkFRightup"+myHolesNum]=myThHoles2MarkFRightup;
            markers.models["myThHoles2WMarkFRightup"+myHolesNum]=myThHoles2WMarkFRightup;
            markers.models["myThHoles2YMarkFRightup"+myHolesNum]=myThHoles2YMarkFRightup;
            markers.models["myThHoles2HMarkFRightup"+myHolesNum]=myThHoles2HMarkFRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2FRightdn"+myHolesNum]=myThHoles2FRightdn;
            cutPlate.models["myThHoles2FRightdn"+myHolesNum]=myThHoles2FRightdn;
            markers.models["myThHoles2FRightdn"+myHolesNum]=myThHoles2FRightdn;
            markers.models["myThHoles2MarkFRightdn"+myHolesNum]=myThHoles2MarkFRightdn;
            markers.models["myThHoles2MarkFRightdn"+myHolesNum]=myThHoles2MarkFRightdn;
            /*****************************************************************************/ 
            /*****************************************************************************/ 
                
      }else if(area==7){
         //画孔G位置孔
            //标记三孔 G位置孔右上
            var myThHoles2GRightup= new makerjs.models.Holes(d/2, [[xg, yg]]);
            var myThHoles2MarkGRightup = new makerjs.models.Square(0); //孔的原点       
            myThHoles2MarkGRightup.origin = [xg, yg];      
            var myThHoles2XMarkGRightup = new makerjs.models.Square(0);      
            myThHoles2XMarkGRightup.origin =[xg, yg];      
            var myThHoles2WMarkGRightup = new makerjs.models.Square(0);      
            myThHoles2WMarkGRightup.origin =[xg, yg];       
            var myThHoles2HMarkGRightup = new makerjs.models.Square(0);      
            myThHoles2HMarkGRightup.origin =[xg, yg];        
            var myThHoles2YMarkGRightup = new makerjs.models.Square(0);      
            myThHoles2YMarkGRightup.origin =[xg, yg];         
            var myThHoles2XMarkGRightup = new makerjs.models.Square(0);      
            myThHoles2XMarkGRightup.origin =[xg, yg];   
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkGRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
      //makerjs.model.addPath(myThHoles2MarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
      makerjs.model.addPath(myThHoles2MarkGRightup, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
      makerjs.model.addPath(myThHoles2MarkGRightup, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkGRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles2MarkGRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(myThHoles2WMarkGRightup, new makerjs.paths.Line([0,0], [w, 0]));//W短标线
      makerjs.model.addCaption(myThHoles2WMarkGRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//W文字标注
      /*****************************************************************************/     
      makerjs.model.addPath(myThHoles2HMarkGRightup, new makerjs.paths.Line([0,0], [0, h]));//H短标线
      makerjs.model.addCaption(myThHoles2HMarkGRightup, TxtH,[0-markShift,0], [0-markShift, h]);//H文字标注 
      /*****************************************************************************/     
      makerjs.model.addPath(myThHoles2YMarkGRightup, new makerjs.paths.Line([0,0], [0, -y0]));//Y短标线
      makerjs.model.addCaption(myThHoles2YMarkGRightup, TxtY,[0-markShift,-y0], [0-markShift, 0]);//Y文字标注 
      /*****************************************************************************/     
      makerjs.model.addPath(myThHoles2XMarkGRightup, new makerjs.paths.Line([-x0,-y0-markShiftTwo], [0, -y0-markShiftTwo]));//X短标线
      makerjs.model.addCaption(myThHoles2XMarkGRightup, TxtX,[-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注 
      /*****************************************************************************/ 
       //标记三孔G位置孔左下
       var myThHoles2GLeftdn= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
            var myThHoles2MarkGLeftdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles2MarkGLeftdn.origin = [xg+w, yg]; 
      /*****************************************************************************/ 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkGLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles2MarkGLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/ 
      /*****************************************************************************/ 
       //标记三孔 G位置孔右下
       var myThHoles2GRightdn= new makerjs.models.Holes(d/2, [[xg, yg+h]]);
            var myThHoles2MarkGRightdn = new makerjs.models.Square(0); //孔的原点       
            myThHoles2MarkGRightdn.origin = [xg, yg+h]; 
      /*****************************************************************************/ 
      makerjs.model.addPath(myThHoles2MarkGRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(myThHoles2MarkGRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/  
      /*****************************************************************************/
      plate.models["myThHoles2GLeftdn"+myHolesNum]=myThHoles2GLeftdn;
      cutPlate.models["myThHoles2GLeftdn"+myHolesNum]=myThHoles2GLeftdn;
      markers.models["myThHoles2GLeftdn"+myHolesNum]=myThHoles2GLeftdn;
      markers.models["myThHoles2MarkGLeftdn"+myHolesNum]=myThHoles2MarkGLeftdn;
      /*****************************************************************************/ 
      plate.models["myThHoles2GRightup"+myHolesNum]=myThHoles2GRightup;
      cutPlate.models["myThHoles2GRightup"+myHolesNum]=myThHoles2GRightup;
      markers.models["myThHoles2GRightup"+myHolesNum]=myThHoles2GRightup;
      markers.models["myThHoles2MarkGRightup"+myHolesNum]=myThHoles2MarkGRightup;
      markers.models["myThHoles2WMarkGRightup"+myHolesNum]=myThHoles2WMarkGRightup;
      markers.models["myThHoles2XMarkGRightup"+myHolesNum]=myThHoles2XMarkGRightup;
      markers.models["myThHoles2YMarkGRightup"+myHolesNum]=myThHoles2YMarkGRightup;
      markers.models["myThHoles2HMarkGRightup"+myHolesNum]=myThHoles2HMarkGRightup;
      /*****************************************************************************/ 
      plate.models["myThHoles2GRightdn"+myHolesNum]=myThHoles2GRightdn;
      cutPlate.models["myThHoles2GRightdn"+myHolesNum]=myThHoles2GRightdn;
      markers.models["myThHoles2GRightdn"+myHolesNum]=myThHoles2GRightdn;
      markers.models["myThHoles2MarkGRightdn"+myHolesNum]=myThHoles2MarkGRightdn;
      /*****************************************************************************/ 
      /*****************************************************************************/ 
                
      }else if(area==8){
            //画孔H位置孔
                  //标记三孔 H位置孔右上
                  var myThHoles2HRightup= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
                  var myThHoles2MarkHRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkHRightup.origin = [xh-w/2, yh];      
                  var myThHoles2XMarkHRightup = new makerjs.models.Square(0);      
                  myThHoles2XMarkHRightup.origin =[xh-w/2, yh];      
                  var myThHoles2HMarkHRightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkHRightup.origin =[xh-w/2, yh];       
                  var myThHoles2YMarkHRightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkHRightup.origin =[xh-w/2, yh];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
            //makerjs.model.addPath(myThHoles2MarkHRightup, new makerjs.paths.Line([0,0], [0, yb-h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2XMarkHRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkHRightup, TxtW, [0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2HMarkHRightup, new makerjs.paths.Line([0,0], [0, h]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkHRightup, TxtH, [0-markShift,0], [0-markShift, h]);//X文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2YMarkHRightup, new makerjs.paths.Line([0,-y0], [0, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2YMarkHRightup, TxtY, [0-markShift,-y0], [0-markShift, 0]);//X文字标注
            /*****************************************************************************/  
            /*****************************************************************************/ 
             //标记三孔H位置孔右下
             var myThHoles2HRightdn= new makerjs.models.Holes(d/2, [[xh-w/2, yh+h]]);
                  var myThHoles2MarkHRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkHRightdn.origin = [xh-w/2, yh+h];
            /*****************************************************************************/ 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkHRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkHRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/ 
             //标记三孔 H位置孔左下
             var myThHoles2HLeftdn= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
                  var myThHoles2MarkHLeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkHLeftdn.origin = [xh+w/2, yh]; 
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkHLeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkHLeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2HRightdn"+myHolesNum]=myThHoles2HRightdn;
            cutPlate.models["myThHoles2HRightdn"+myHolesNum]=myThHoles2HRightdn;
            markers.models["myThHoles2HRightdn"+myHolesNum]=myThHoles2HRightdn;
            markers.models["myThHoles2MarkHRightdn"+myHolesNum]=myThHoles2MarkHRightdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2HRightup"+myHolesNum]=myThHoles2HRightup;
            cutPlate.models["myThHoles2HRightup"+myHolesNum]=myThHoles2HRightup;
            markers.models["myThHoles2HRightup"+myHolesNum]=myThHoles2HRightup;
            markers.models["myThHoles2MarkHRightup"+myHolesNum]=myThHoles2MarkHRightup;
            markers.models["myThHoles2XMarkHRightup"+myHolesNum]=myThHoles2XMarkHRightup;
            markers.models["myThHoles2HMarkHRightup"+myHolesNum]=myThHoles2HMarkHRightup;
            markers.models["myThHoles2YMarkHRightup"+myHolesNum]=myThHoles2YMarkHRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2HLeftdn"+myHolesNum]=myThHoles2HLeftdn;
            cutPlate.models["myThHoles2HLeftdn"+myHolesNum]=myThHoles2HLeftdn;
            markers.models["myThHoles2HLeftdn"+myHolesNum]=myThHoles2HLeftdn;
            markers.models["myThHoles2MarkHLeftdn"+myHolesNum]=myThHoles2MarkHLeftdn;
            /*****************************************************************************/ 
           
      }else if(area==9){
        //画孔I位置孔
                  //标记三孔 I位置孔右上
                  var myThHoles2IRightup= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
                  var myThHoles2MarkIRightup = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkIRightup.origin = [xi-w, yi];      
                  var myThHoles2XMarkIRightup = new makerjs.models.Square(0);      
                  myThHoles2XMarkIRightup.origin =[xi-w, yi];       
                  var myThHoles2HMarkIRightup = new makerjs.models.Square(0);      
                  myThHoles2HMarkIRightup.origin =[xi-w, yi];        
                  var myThHoles2YMarkIRightup = new makerjs.models.Square(0);      
                  myThHoles2YMarkIRightup.origin =[xi-w, yi];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkIRightup, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkIRightup, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkIRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkIRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/   
            makerjs.model.addPath(myThHoles2XMarkIRightup, new makerjs.paths.Line([0,0], [w, 0]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkIRightup, TxtW,[0,0+markShift], [w, 0+markShift]);//X文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2HMarkIRightup, new makerjs.paths.Line([w,0], [w, h]));//X短标线
            makerjs.model.addCaption(myThHoles2HMarkIRightup, TxtH,[w-markShift,0], [w-markShift, h]);//X文字标注 
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2YMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
            makerjs.model.addCaption(myThHoles2YMarkIRightup, TxtY,[0-markShift,-y0], [0-markShift, 0]);//X文字标注 
            /*****************************************************************************/ 
             //标记三孔I位置孔左下
             var myThHoles2ILeftdn= new makerjs.models.Holes(d/2, [[xi, yi]]);
                  var myThHoles2MarkILeftdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkILeftdn.origin = [xi, yi];        
                  var myThHoles2XMarkILeftdn = new makerjs.models.Square(0);      
                  myThHoles2XMarkILeftdn.origin =[xi, yi];   
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkILeftdn, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkILeftdn, new makerjs.paths.Line([0,-y0], [0, -y0-markShiftFour]));//从孔圆心出发X方向横向标线
            makerjs.model.addPath(myThHoles2MarkILeftdn, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkILeftdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkILeftdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/     
            makerjs.model.addPath(myThHoles2XMarkILeftdn, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
            makerjs.model.addCaption(myThHoles2XMarkILeftdn, TxtX,[0,-y0-markShiftFour], [x0, -y0-markShiftFour]);//X文字标注 
            /*****************************************************************************/  
             //标记三孔 I位置孔右下
             var myThHoles2IRightdn= new makerjs.models.Holes(d/2, [[xi, yi+h]]);
                  var myThHoles2MarkIRightdn = new makerjs.models.Square(0); //孔的原点       
                  myThHoles2MarkIRightdn.origin = [xi, yi+h]; 
            /*****************************************************************************/ 
            //makerjs.model.addPath(myThHoles2MarkIRightdn, new makerjs.paths.Line([0,0], [d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
            //makerjs.model.addPath(myThHoles2MarkIRightdn, new makerjs.paths.Line([0,0], [0, yb+h/2+markShiftFour]));//从孔圆心出发Y方向竖向标线
            /*****************************************************************************/ 
            makerjs.model.addPath(myThHoles2MarkIRightdn, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
            makerjs.model.addCaption(myThHoles2MarkIRightdn, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
            /*****************************************************************************/  
            /*****************************************************************************/
            plate.models["myThHoles2ILeftdn"+myHolesNum]=myThHoles2ILeftdn;
            cutPlate.models["myThHoles2ILeftdn"+myHolesNum]=myThHoles2ILeftdn;
            markers.models["myThHoles2ILeftdn"+myHolesNum]=myThHoles2ILeftdn;
            markers.models["myThHoles2MarkILeftdn"+myHolesNum]=myThHoles2MarkILeftdn;
            markers.models["myThHoles2XMarkILeftdn"+myHolesNum]=myThHoles2XMarkILeftdn;
            /*****************************************************************************/ 
            plate.models["myThHoles2IRightup"+myHolesNum]=myThHoles2IRightup;
            cutPlate.models["myThHoles2IRightup"+myHolesNum]=myThHoles2IRightup;
            markers.models["myThHoles2IRightup"+myHolesNum]=myThHoles2IRightup;
            markers.models["myThHoles2MarkIRightup"+myHolesNum]=myThHoles2MarkIRightup;
            markers.models["myThHoles2XMarkIRightup"+myHolesNum]=myThHoles2XMarkIRightup;
            markers.models["myThHoles2YMarkIRightup"+myHolesNum]=myThHoles2YMarkIRightup;
            markers.models["myThHoles2HMarkIRightup"+myHolesNum]=myThHoles2HMarkIRightup;
            /*****************************************************************************/ 
            plate.models["myThHoles2IRightdn"+myHolesNum]=myThHoles2IRightdn;
            cutPlate.models["myThHoles2IRightdn"+myHolesNum]=myThHoles2IRightdn;
            markers.models["myThHoles2IRightdn"+myHolesNum]=myThHoles2IRightdn;
            markers.models["myThHoles2MarkIRightdn"+myHolesNum]=myThHoles2MarkIRightdn;
            markers.models["myThHoles2MarkIRightdn"+myHolesNum]=myThHoles2MarkIRightdn;
                 
      }
/*****************************************************************************/ 

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default ThreeHoles;