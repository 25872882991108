//缺1、蝴蝶
import makerjs from "makerjs";
// 矩形等去角 连线函数
function creatmyButterflyGap(area, param) {
      console.log("是否调用此函数creatOneCorner（）");
      console.log("是否调用此函数creatOneCorner（）param=", param);
      var xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
            x = param.x0, radius = param.r, width = param.w, height = param.h;
      // var ax = radius / Math.SQRT2, ay = radius / Math.SQRT2,
      //       lx = ax * 2, ly = ax * 2,
      //       apexes = [225, 315, 0, 135];
      // var ButterflyGapTop = {
      //       paths: {
      //             "TopRight": new makerjs.paths.Arc([width - ax, height - ay], radius, apexes[2] - 90, apexes[2] + 90),
      //             "TopLeft": new makerjs.paths.Arc([ax, height - ay], radius, apexes[3] - 90, apexes[3] + 90),
      //             'Top': new makerjs.paths.Line([lx, height], [width - lx, height]),
      //             "Left": new makerjs.paths.Line([0, ly], [0, height - ly]),
      //             "Right": new makerjs.paths.Line([width, ly], [width, height - ly]),

      //       }
      // };
      // var ButterflyGapBottom = {
      //       paths: {
      //             "Left": new makerjs.paths.Line([0, ly], [0, height - ly]),
      //             "Right": new makerjs.paths.Line([width, ly], [width, height - ly]),
      //             'Bottom': new makerjs.paths.Line([lx, 0], [width - lx, 0]),
      //             "BottomLeft": new makerjs.paths.Arc([ax, ay], radius, apexes[0] - 90, apexes[0] + 90),
      //             "BottomRight": new makerjs.paths.Arc([width - ax, ay], radius, apexes[1] - 90, apexes[1] + 90),

      //       }
      // };
      // var Rect = {
      //       paths: {
      //             "LineLeft": new makerjs.paths.Line([xg, yg], [xa, ya]),
      //             "LineTop": new makerjs.paths.Line([xa, ya], [xc, yc]),
      //             "LineRight": new makerjs.paths.Line([xc, yc], [xi, yi]),
      //             "LineBottom": new makerjs.paths.Line([xi, yi], [xg, yg]),
      //       }
      // };
      //   var ButterflyGapAll = {
      //       paths:{
      //             "TopRight":new makerjs.paths.Arc([width - ax, height - ay], radius, apexes[2] - 90, apexes[2] + 90),
      //             "TopLeft": new makerjs.paths.Arc([ax, height - ay], radius, apexes[3] - 90, apexes[3] + 90),
      //             'Top': new makerjs.paths.Line([lx, height], [width - lx, height]),
      //             "Left":new makerjs.paths.Line([0, ly], [0, height - ly]),
      //                     "Right":new makerjs.paths.Line([width, ly], [width, height - ly]),
      //                     'Bottom':new makerjs.paths.Line([lx, 0], [width - lx, 0]),
      //                     "BottomLeft": new makerjs.paths.Arc([ax, ay], radius, apexes[0] - 90, apexes[0] + 90),
      //                     "BottomRight": new makerjs.paths.Arc([width - ax, ay], radius, apexes[1] - 90, apexes[1] + 90),
      //       }
      //     };                      



      if (area == "A") {
            var myButterflyGap = {

                  paths: {
                        "Left": new makerjs.paths.Line([xa, ya], [xa, ya - height + radius]),
                        "BottomLeft": new makerjs.paths.Arc([xa, ya - height], radius, 90, 360),
                        'Bottom': new makerjs.paths.Line([xa + radius, ya - height], [xa + width - radius, ya - height]),
                        "BottomRight": new makerjs.paths.Arc([xa + width, ya - height], radius, 180, 90),
                        "Right": new makerjs.paths.Line([xa + width, ya - height + radius], [xa + width, ya]),
                  }
            };
      } else if (area == "B") {
            var myButterflyGap = {
                  paths: {
                        "Left": new makerjs.paths.Line([xb, yb], [xb, yb - height + radius]),
                        "BottomLeft": new makerjs.paths.Arc([xb, yb - height], radius, 90, 360),
                        'Bottom': new makerjs.paths.Line([xb + radius, yb - height], [xb + width - radius, yb - height]),
                        "BottomRight": new makerjs.paths.Arc([xb + width, yb - height], radius, 180, 90),
                        "Right": new makerjs.paths.Line([xb + width, yb - height + radius], [xb + width, yb]),
                  }
            };
      } else if (area == "C") {
            var myButterflyGap = {
                  paths: {
                        "Left": new makerjs.paths.Line([xc - width, yc], [xc - width, yc - height + radius]),
                        "BottomLeft": new makerjs.paths.Arc([xc - width, yc - height], radius, 90, 360),
                        'Bottom': new makerjs.paths.Line([xc - width + radius, yc - height], [xc - radius, yc - height]),
                        "BottomRight": new makerjs.paths.Arc([xc, yc - height], radius, 180, 90),
                        "Right": new makerjs.paths.Line([xc, yc - height + radius], [xc, yc]),
                  }
            };
      } else if (area == "G") {
            var myButterflyGap = {
                  paths: {
                        "Left": new makerjs.paths.Line([xg, yg], [xg, yg + height - radius]),
                        "TopLeft": new makerjs.paths.Arc([xg, yg + height - radius], [xg + radius, yg + height], radius, true, true),
                        'Top': new makerjs.paths.Line([xg + radius, yg + height], [xg + width - radius, yg + height]),
                        "TopRight": new makerjs.paths.Arc([xg + width - radius, yg + height], [xg + width, yg + height - radius], radius, true, true),
                        "Right": new makerjs.paths.Line([xg + width, yg + height - radius], [xg + width, yg]),
                  }
            };
      } else if (area == "H") {
            var myButterflyGap = {
                  paths: {
                        "Left": new makerjs.paths.Line([xh, yh], [xh, yh + height - radius]),
                        "TopLeft": new makerjs.paths.Arc([xh, yh + height - radius], [xh + radius, yh + height], radius, true, true),
                        'Top': new makerjs.paths.Line([xh + radius, yh + height], [xh + width - radius, yh + height]),
                        "TopRight": new makerjs.paths.Arc([xh + width - radius, yh + height], [xh + width, yh + height - radius], radius, true, true),
                        "Right": new makerjs.paths.Line([xh + width, yh + height - radius], [xh + width, yh]),
                  }
            };
      } else if (area == "I") {
            var myButterflyGap = {
                  paths: {
                        "Left": new makerjs.paths.Line([xi - width, yi], [xi - width, yi + height - radius]),
                        "TopLeft": new makerjs.paths.Arc([xi - width, yi + height - radius], [xi - width + radius, yi + height], radius, true, true),
                        'Top': new makerjs.paths.Line([xi - width + radius, yi + height], [xi - radius, yi + height]),
                        "TopRight": new makerjs.paths.Arc([xi - radius, yi + height], [xi, yi + height - radius], radius, true, true),
                        "Right": new makerjs.paths.Line([xi, yi + height - radius], [xi, yi]),
                  }
            };
      }
      return myButterflyGap;
};
// 矩形等去角标记 连线函数      
function creatmyButterflyGapMark(outParam, area, param, inputParam, fixedParam, OffsetParam, zoom) {
      console.log("是否调用此函数creatCornerMark()");
      console.log("是否调用此函数param", param);
      console.log("是否调用此函数outParam", outParam);
      console.log("是否调用此函数inputParam", inputParam);
      console.log("是否调用此函数OffsetParam", OffsetParam);
      var HH = outParam.H, WW = outParam.W, R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, myZoom = zoom * 1,
            glassW = outParam.glassW, glassH = outParam.glassH,
            xa = param.xa, ya = param.ya, xb = param.xb, yb = param.yb, xc = param.xc, yc = param.yc, xg = param.xg, yg = param.yg, xh = param.xh, yh = param.yh, xi = param.xi, yi = param.yi,
            x = param.x0, radius = param.r, width = param.w, height = param.h,
            markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
            markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,
            h = H * 1 * myZoom,
            w = W * 1 * myZoom,
            d = R * 2 * myZoom,
            r = d / 2,
            dd = (d / 2) / Math.sqrt(2),
            x0 = X * 1 * myZoom,
            y0 = Y * 1 * myZoom;
      if (d !== 0) {
            var TxtD = "R" + R;
      }
      if (h !== 0) {
            var TxtH = H;
      }
      if (w !== 0) {
            var TxtW = W;
      }
      if (x0 !== 0) {
            var TxtX = X;
      }
      if (y0 !== 0) {
            var TxtY = Y;
      }

      if (area == "A") {
            var myBfgGapAMakerW = {
                  paths: {
                        "WALine0": new makerjs.paths.Line([xa, ya - markShiftTwo], [xa + w, ya - markShiftTwo]),
                  }
            };
            var myBfgGapAMakerH = {
                  paths: {
                        "HALine0": new makerjs.paths.Line([xa - markShift, ya], [xa - markShift, ya - h]),
                        "HALine2": new makerjs.paths.Line([xa - markShiftFour, ya - h], [xa, ya - h]),
                  }
            };
            var myBfgGapAMakerX = {
                  paths: {
                        "XALine0": new makerjs.paths.Line([xa - x0, ya + markShift], [xa, ya + markShift]),
                        "XALine1": new makerjs.paths.Line([xa - x0, ya], [xa - x0, ya + markShiftFour]),
                        "XALine2": new makerjs.paths.Line([xa, ya], [xa, ya + markShiftFour]),
                  }
            };
            var myBfgGapAMakerD = {
                  paths: {
                        "DALine0": new makerjs.paths.Line([xa + w, ya - h], [xa + w + dd + markHolesOneD, ya - h - dd - markHolesOneD]),
                  }
            };
            makerjs.model.addCaption(myBfgGapAMakerW, TxtW, [xa, ya - markShiftFour], [xa + w, ya - markShiftFour]);//W文字标注 
            makerjs.model.addCaption(myBfgGapAMakerH, TxtH, [xa - markShiftThree, ya - h], [xa - markShiftThree, ya]);//H文字标注
            makerjs.model.addCaption(myBfgGapAMakerX, TxtX, [xa - x0, ya + markShiftTwo], [xa, ya + markShiftTwo]);//R1文字标注
            makerjs.model.addCaption(myBfgGapAMakerD, TxtD, [xa + w + markShift, ya - h], [xa + w + markShift + dd + markHolesOneD, ya - h - dd - markHolesOneD]);//D文字标注  
            var markers = [myBfgGapAMakerW, myBfgGapAMakerH, myBfgGapAMakerX, myBfgGapAMakerD]
      } else if (area == "B") {
            var myBfgGapBMakerW = {
                  paths: {
                        "WBLine0": new makerjs.paths.Line([xb, yb - markShiftTwo], [xb + w, yb - markShiftTwo]),
                  }
            };
            var myBfgGapBMakerH = {
                  paths: {
                        "HBLine0": new makerjs.paths.Line([xb - markShift, yb], [xb - markShift, yb - h]),
                        "HBLine2": new makerjs.paths.Line([xb - markShiftFour, yb - h], [xb, yb - h]),
                  }
            };
            var myBfgGapBMakerX = {
                  paths: {
                        // "XBLine0": new makerjs.paths.Line([xb-x0, yb + markShift], [xb, yb + markShift]),
                        // "XBLine1": new makerjs.paths.Line([xb-x0, yb], [xb-x0, yb+markShiftFour]),
                        // "XBLine2": new makerjs.paths.Line([xb, yb], [xb, yb+markShiftFour]),
                  }
            };
            var myBfgGapBMakerD = {
                  paths: {
                        "DBLine0": new makerjs.paths.Line([xb + w, yb - h], [xb + w + dd + markHolesOneD, yb - h - dd - markHolesOneD]),
                  }
            };
            makerjs.model.addCaption(myBfgGapBMakerW, TxtW, [xb, yb - markShiftFour], [xb + w, yb - markShiftFour]);//W文字标注 
            makerjs.model.addCaption(myBfgGapBMakerH, TxtH, [xb - markShiftThree, yb - h], [xb - markShiftThree, yb]);//H文字标注
            makerjs.model.addCaption(myBfgGapBMakerX, TxtX, [xb - x0, yb + markShiftTwo], [xa, yb + markShiftTwo]);//R1文字标注
            makerjs.model.addCaption(myBfgGapBMakerD, TxtD, [xb + w + markShift, yb - h], [xb + w + markShift + dd + markHolesOneD, yb - h - dd - markHolesOneD]);//D文字标注  
            var markers = [myBfgGapBMakerW, myBfgGapBMakerH, myBfgGapBMakerX, myBfgGapBMakerD]
      } else if (area == "C") {
            var myBfgGapCMakerW = {
                  paths: {
                        "WCLine0": new makerjs.paths.Line([xc - w, yc - markShiftTwo], [xc, yc - markShiftTwo]),
                  }
            };
            var myBfgGapCMakerH = {
                  paths: {
                        "HCLine0": new makerjs.paths.Line([xc - w - markShift, yc], [xc - w - markShift, yc - h]),
                        "HCLine2": new makerjs.paths.Line([xc - w - markShiftFour, yc - h], [xc - w, yc - h]),
                  }
            };
            var myBfgGapCMakerX = {
                  paths: {
                        "XCLine0": new makerjs.paths.Line([xc, yc + markShift], [xc + x0, yc + markShift]),
                        "XCLine1": new makerjs.paths.Line([xc + x0, yc], [xc + x0, yc + markShiftFour]),
                        "XCLine2": new makerjs.paths.Line([xc, yc], [xc, yc + markShiftFour]),
                  }
            };
            var myBfgGapCMakerD = {
                  paths: {
                        "DCLine0": new makerjs.paths.Line([xc, yc - h], [xc + dd + markHolesOneD, yc - h - dd - markHolesOneD]),
                  }
            };
            makerjs.model.addCaption(myBfgGapCMakerW, TxtW, [xc - w, yc - markShiftFour], [xc, yc - markShiftFour]);//W文字标注 
            makerjs.model.addCaption(myBfgGapCMakerH, TxtH, [xc - w - markShiftThree, yc - h], [xc - w - markShiftThree, yc]);//H文字标注
            makerjs.model.addCaption(myBfgGapCMakerX, TxtX, [xc, yc + markShiftTwo], [xc + x0, yc + markShiftTwo]);//R1文字标注
            makerjs.model.addCaption(myBfgGapCMakerD, TxtD, [xc + markShift, yc - h], [xc + markShift + dd + markHolesOneD, yc - h - dd - markHolesOneD]);//D文字标注  
            var markers = [myBfgGapCMakerW, myBfgGapCMakerH, myBfgGapCMakerX, myBfgGapCMakerD]
      } else if (area == "G") {
            var myBfgGapGMakerW = {
                  paths: {
                        "WGLine0": new makerjs.paths.Line([xg, yg + markShiftTwo], [xg + w, yg + markShiftTwo]),
                  }
            };
            var myBfgGapGMakerH = {
                  paths: {
                        "HGLine0": new makerjs.paths.Line([xg - markShift, yg], [xg - markShift, yg + h]),
                        "HGLine2": new makerjs.paths.Line([xg - markShiftFour, yg + h], [xg, yg + h]),
                  }
            };
            var myBfgGapGMakerX = {
                  paths: {
                        "XGLine0": new makerjs.paths.Line([xg - x0, yg - markShiftTwo], [xg, yg - markShiftTwo]),
                        "XGLine1": new makerjs.paths.Line([xg - x0, yg], [xg - x0, yg - markShiftFour]),
                        "XGLine2": new makerjs.paths.Line([xg, yg], [xg, yg - markShiftFour]),
                  }
            };
            var myBfgGapGMakerD = {
                  paths: {
                        "DGLine0": new makerjs.paths.Line([xg + w, yg + h], [xg + w + dd + markHolesOneD, yg + h - dd - markHolesOneD]),
                  }
            };
            makerjs.model.addCaption(myBfgGapGMakerW, TxtW, [xg, yg + markShiftFour], [xg + w, yg + markShiftFour]);//W文字标注 
            makerjs.model.addCaption(myBfgGapGMakerH, TxtH, [xg - markShiftThree, yg], [xg - markShiftThree, yg + h]);//H文字标注
            makerjs.model.addCaption(myBfgGapGMakerX, TxtX, [xg - x0, yg - markShiftFour], [xg, yg - markShiftFour]);//R1文字标注
            makerjs.model.addCaption(myBfgGapGMakerD, TxtD, [xg + w + markShift, yg + h], [xg + w + markShift + dd + markHolesOneD, yg + h - dd - markHolesOneD]);//D文字标注  
            var markers = [myBfgGapGMakerW, myBfgGapGMakerH, myBfgGapGMakerX, myBfgGapGMakerD]
      } else if (area == "H") {
            var myBfgGapHMakerW = {
                  paths: {
                        "WHLine0": new makerjs.paths.Line([xh, yh + markShiftTwo], [xh + w, yh + markShiftTwo]),
                  }
            };
            var myBfgGapHMakerH = {
                  paths: {
                        "HHLine0": new makerjs.paths.Line([xh - markShift, yh], [xh - markShift, yh + h]),
                        "HHLine2": new makerjs.paths.Line([xh - markShiftFour, yh + h], [xh, yh + h]),
                  }
            };
            var myBfgGapHMakerX = {
                  paths: {
                        // "XHLine0": new makerjs.paths.Line([xh-x0, yh - markShiftTwo], [xh, yh - markShiftTwo]),
                        // "XHLine1": new makerjs.paths.Line([xh-x0, yh], [xh-x0, yh-markShiftFour]),
                        // "XHLine2": new makerjs.paths.Line([xh, yh], [xh, yh-markShiftFour]),
                  }
            };
            var myBfgGapHMakerD = {
                  paths: {
                        "DHLine0": new makerjs.paths.Line([xh + w, yh + h], [xh + w + dd + markHolesOneD, yh + h - dd - markHolesOneD]),
                  }
            };
            makerjs.model.addCaption(myBfgGapHMakerW, TxtW, [xh, yh + markShiftFour], [xh + w, yh + markShiftFour]);//W文字标注 
            makerjs.model.addCaption(myBfgGapHMakerH, TxtH, [xh - markShiftThree, yh], [xh - markShiftThree, yh + h]);//H文字标注
            makerjs.model.addCaption(myBfgGapHMakerX, TxtX, [xh - x0, yh - markShiftFour], [xh, yh - markShiftFour]);//R1文字标注
            makerjs.model.addCaption(myBfgGapHMakerD, TxtD, [xh + w + markShift, yh + h], [xh + w + markShift + dd + markHolesOneD, yh + h - dd - markHolesOneD]);//D文字标注  
            var markers = [myBfgGapHMakerW, myBfgGapHMakerH, myBfgGapHMakerX, myBfgGapHMakerD]
      } else if (area == "I") {
            var myBfgGapIMakerW = {
                  paths: {
                        "WILine0": new makerjs.paths.Line([xi - w, yi + markShiftTwo], [xi, yi + markShiftTwo]),
                  }
            };
            var myBfgGapIMakerH = {
                  paths: {
                        "HILine0": new makerjs.paths.Line([xi - w - markShift, yi], [xi - w - markShift, yi + h]),
                        "HILine2": new makerjs.paths.Line([xi - w - markShiftFour, yi + h], [xi - w, yi + h]),
                  }
            };
            var myBfgGapIMakerX = {
                  paths: {
                        "XILine0": new makerjs.paths.Line([xi, yi - markShiftTwo], [xi + x0, yi - markShiftTwo]),
                        "XILine1": new makerjs.paths.Line([xi + x0, yi], [xi + x0, yi - markShiftFour]),
                        "XILine2": new makerjs.paths.Line([xi, yi], [xi, yi - markShiftFour]),
                  }
            };
            var myBfgGapIMakerD = {
                  paths: {
                        "DILine0": new makerjs.paths.Line([xi, yi + h], [xi + dd + markHolesOneD, yi + h - dd - markHolesOneD]),
                  }
            };
            makerjs.model.addCaption(myBfgGapIMakerW, TxtW, [xi - w, yi + markShiftFour], [xi, yi + markShiftFour]);//W文字标注 
            makerjs.model.addCaption(myBfgGapIMakerH, TxtH, [xi - w - markShiftThree, yi], [xi - w - markShiftThree, yi + h]);//H文字标注
            makerjs.model.addCaption(myBfgGapIMakerX, TxtX, [xi, yi - markShiftFour], [xi + x0, yi - markShiftFour]);//R1文字标注
            makerjs.model.addCaption(myBfgGapIMakerD, TxtD, [xi + markShift, yi + h], [xi + markShift + dd + markHolesOneD, yi + h - dd - markHolesOneD]);//D文字标注  
            var markers = [myBfgGapIMakerW, myBfgGapIMakerH, myBfgGapIMakerX, myBfgGapIMakerD]
      }
      console.log("markers||||||||||||||||||=", markers)
      return markers;
};
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function ButterflyGap(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut) {
      var frame = outParam.shapeName;
      console.log("outParam==", outParam)
      console.log("model==", model)
      console.log("inputParam==", inputParam)
      console.log("outParam.shapeName==////////", outParam.shapeName)
      if (typeof (frame) == "undefined") {
            var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
                  plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = "Rectangle",
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

                  h = H * 1 * myZoom,
                  w = W * 1 * myZoom,
                  d = R * 2 * myZoom,
                  r = R * 1 * myZoom,
                  dd = (d / 2) / Math.sqrt(2),
                  x0 = X * 1 * myZoom,
                  y0 = Y * 1 * myZoom;

            if (d !== 0) {
                  var TxtD = "R" + R;
            }
            if (h !== 0) {
                  var TxtH = H;
            }
            if (w !== 0) {
                  var TxtW = W;
            }
            if (x0 !== 0) {
                  var TxtX = X;
            }
            if (y0 !== 0) {
                  var TxtY = Y;
            }
      } else {
            var R = inputParam.R, W = inputParam.W, H = inputParam.H, X = inputParam.X, Y = inputParam.Y, area = inputParam.area, myZoom = zoom * 1, myθCut = θCut, myrCut = rCut,
                  plate = model.plate, cutPlate = model.cutPlate, markers = model.markers, frame = outParam.shapeName,
                  glassW = outParam.glassW, glassH = outParam.glassH,
                  markShift = fixedParam.markShift, markShiftTwo = fixedParam.markShiftTwo,
                  markShiftThree = fixedParam.markShiftThree, markShiftFour = fixedParam.markShiftFour, markHolesOneD = fixedParam.markHolesOneD,

                  h = H * 1 * myZoom,
                  w = W * 1 * myZoom,
                  d = R * 2 * myZoom,
                  r = d / 2,
                  dd = (d / 2) / Math.sqrt(2),
                  x0 = X * 1 * myZoom,
                  y0 = Y * 1 * myZoom;
            /*
            h = H*1,
               w= W*1,
               d= R*2,
               r=d/2,
               dd=(d/2)/Math.sqrt(2),
                 x0 = X*1,
                 y0 = Y*1;
                 */
            if (d !== 0) {
                  var TxtD = "R" + R;
            }
            if (h !== 0) {
                  var TxtH = H;
            }
            if (w !== 0) {
                  var TxtW = W;
            }
            if (x0 !== 0) {
                  var TxtX = X;
            }
            if (y0 !== 0) {
                  var TxtY = Y;
            }
      }
      if (frame == "Rectangle") {
            console.log("frame==undefined||||*****")
            //在A区域 计算孔的位置       
            var xa = x0, ya = glassH * 1 - y0,
                  //B区域//只有Y标注没有X标注 
                  xb = glassW * 1 / 2, yb = glassH * 1 - y0,
                  //C区域
                  xc = glassW * 1 - x0, yc = glassH * 1 - y0,
                  //在G区域 计算孔的位置
                  xg = x0, yg = y0,
                  //在H区域 计算孔的位置
                  //只标注Y
                  xh = glassW * 1 / 2, yh = y0,
                  //在I区域 计算孔的位置
                  xi = glassW * 1 - x0, yi = y0;
            var OffsetParam = {};
      } else if (frame == "IsoscelesTrapezoid") {

      } else if (frame == "RightAngledTrapezoid") {

      } else if (frame == "OtherTrapezoidsA") {

      } else if (frame == "OtherTrapezoidsB") {

      } else if (frame == "ParallelogramA") {


      } else if (frame == "ParallelogramB") {

      }
      // 坐标参数
      var paramCoordinate = { x0, r, h, w, xa, ya, xb, yb, xc, yc, xg, yg, xh, yh, xi, yi };
      const allKeys = Object.getOwnPropertyNames(JSON.parse(JSON.stringify(model.plate.models)));
      var areaPoint = "";

      console.log("//角7、地弹簧3 ------", allKeys)
      if (allKeys[0] == frame) {
            allKeys.shift();//去掉数组第一个元素
      }
      console.log("//角7、地弹簧3 位置去掉数组第一个元素------", allKeys)
      switch (area) {
            case "1": areaPoint = "A"; break;
            case "2": areaPoint = "B"; break;
            case "3": areaPoint = "C"; break;
            case "7": areaPoint = "G"; break;
            case "8": areaPoint = "H"; break;
            case "9": areaPoint = "I"; break;
      }
      /**
      * 画图
      * 
      */
      var ObjName = "myButterflyGap" + areaPoint;
      var ObjNameMmyButterflyGap = "myButterflyGap" + areaPoint + "Mark";//角标记
      var ObjNameMmyButterflyGap = "myButterflyGap" + areaPoint + "Mark";//角标记
      ObjName = creatmyButterflyGap(areaPoint, paramCoordinate);//图形
      console.log("ObjName===|||||||||", ObjName);
      ObjNameMmyButterflyGap = creatmyButterflyGapMark(outParam, areaPoint, paramCoordinate, inputParam, fixedParam, OffsetParam, zoom);//图形
      console.log("ObjNameMmyButterflyGap===|||||||||角标注", ObjNameMmyButterflyGap);
      console.log("cutPlate.models====************", cutPlate.models),
            console.log("plate.models====**************", plate.models),
            console.log("markers.models====*************", markers.models),
            cutPlate.models["myButterflyGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myButterflyGap" + areaPoint + myHolesNum] = ObjName;
      plate.models["myButterflyGapW" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[0];
      plate.models["myButterflyGapH" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[1];
      plate.models["myButterflyGapX" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[2];
      plate.models["myButterflyGapD" + areaPoint + myHolesNum] = ObjNameMmyButterflyGap[3];
      markers.models["myButterflyGap" + areaPoint + myHolesNum] = ObjName;
      markers.models["myButterflyGapW" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[0];
      markers.models["myButterflyGapH" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[1];
      markers.models["myButterflyGapX" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[2];
      markers.models["myButterflyGapD" + areaPoint + myHolesNum + "Mark"] = ObjNameMmyButterflyGap[3];
      myHolesNum++;
      return { plate, cutPlate, markers, myHolesNum }
};


export default ButterflyGap;