//14、7字拉手孔
import makerjs from "makerjs";
// inputParam,model,myHolesNum,outParam,fixedParam
// (D,X,Y,area,plate,markers,myHolesNum,glassW,glassH,markShift,markShiftTwo,markShiftThree,markShiftFour,markHolesOneD)
function Shaped7HandleHole(inputParam, model, myHolesNum, outParam, fixedParam, zoom, θCut, rCut){
var D=inputParam.D,W=inputParam.W,H=inputParam.H,X=inputParam.X,Y=inputParam.Y,area=inputParam.area,myZoom=zoom*1, myθCut = θCut, myrCut = rCut,
plate = model.plate, cutPlate = model.cutPlate, markers = model.markers,
glassW=outParam.glassW,glassH=outParam.glassH,
   markShift=fixedParam.markShift,markShiftTwo=fixedParam.markShiftTwo,
   markShiftThree=fixedParam.markShiftThree,markShiftFour=fixedParam.markShiftFour,markHolesOneD=fixedParam.markHolesOneD,
 
   d = D*1*myZoom,
      w= W*1*myZoom,
      h= H*1*myZoom,
        x0 = X*1*myZoom,
        y0 = Y*1*myZoom;
        /*
        d = D*1,
           w= W*1,
           h= H*1,
             x0 = X*1,
             y0 = Y*1;
             */
        if(d!==0){
         var TxtD="∮"+D;
        }
        if(h!==0){
         var TxtH=H;
        }
        if(w!==0){
         var TxtW=W;
        }
        if(x0!==0){          
        var TxtX=X;
        }
        if(y0!==0){          
         var TxtY=Y;
        }
  //在A区域 计算孔的位置       
  var xa=x0,ya=glassH * 1-y0,
  //B区域//只有Y标注没有X标注 
  xb=glassW * 1/2,yb=glassH * 1-y0,
  //C区域
  xc=glassW * 1-x0,yc=glassH * 1-y0,
  //D区域
  //只有X标注没有Y标注 
  xd=x0,yd=glassH * 1/2,
  //E区域
  //只有X标注没有Y标注 
  xe=glassW * 1/2,ye=glassH * 1/2,
  //F区域
  //只有X标注没有Y标注 
  xf=glassW * 1-x0,yf=glassH * 1/2,
  //在G区域 计算孔的位置
  xg=x0,yg=y0,
  //在H区域 计算孔的位置
  //只标注Y
  xh=glassW*1/2,yh=y0,
  //在I区域 计算孔的位置
  xi=glassW*1-x0,yi=y0;
  if(area==1){
    //画孔 A位置孔
          //标记7字拉手孔 A位置孔左上
          var my7ShhHolesALeftup= new makerjs.models.Holes(d/2, [[xa, ya]]);
          var my7ShhHolesMarkALeftup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkALeftup.origin = [xa, ya];      
          var my7ShhHolesXMarkALeftup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkALeftup.origin =[xa, ya];      
          var my7ShhHolesYMarkALeftup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkALeftup.origin = [xa, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0, y0+markShiftTwo]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([-x0,y0], [-x0-markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([0,0], [0, y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkALeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkALeftup, new makerjs.paths.Line([0,y0+markShift], [-x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkALeftup, TxtX, [-x0,y0+markShiftTwo], [0, y0+markShiftTwo]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkALeftup, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkALeftup, TxtY, [-x0-markShiftThree,0], [-x0-markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //标记7字拉手孔 A位置孔左中
     var my7ShhHolesALeftmd= new makerjs.models.Holes(d/2, [[xa+w, ya]]);
          var my7ShhHolesMarkALeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkALeftmd.origin = [xa+w, ya];       
          var my7ShhHolesYMarkALeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkALeftmd.origin = [xa+w, ya];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [0, h+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkALeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkALeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
     //标记7字拉手孔 A位置孔右中
     var my7ShhHolesARightmd= new makerjs.models.Holes(d/2, [[xa+w, ya-h]]);
          var my7ShhHolesMarkARightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkARightmd.origin = [xa+w, ya-h];      
          var my7ShhHolesXMarkARightmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkARightmd.origin =[xa+w, ya-h];      
          var my7ShhHolesYMarkARightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkARightmd.origin = [xa+w, ya-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkARightmd, new makerjs.paths.Line([0,0], [-w-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkARightmd, new makerjs.paths.Line([0,0], [0, h+y0+markShiftTwo]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkARightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkARightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesYMarkARightmd, new makerjs.paths.Line([-x0-markShiftTwo-w,0], [-x0-markShiftTwo-w, h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkARightmd, TxtH, [-x0-markShiftThree-w,0], [-x0-markShiftThree-w, h]);//Y文字标注

    makerjs.model.addPath(my7ShhHolesXMarkARightmd, new makerjs.paths.Line([0,y0+h+markShift], [-w, y0+h+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkARightmd, TxtW, [-w,y0+h+markShiftTwo], [0, y0+h+markShiftTwo]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesALeftup"+myHolesNum]=my7ShhHolesALeftup;
    cutPlate.models["my7ShhHolesALeftup"+myHolesNum]=my7ShhHolesALeftup;
    markers.models["my7ShhHolesALeftup"+myHolesNum]=my7ShhHolesALeftup;
    markers.models["my7ShhHolesMarkALeftup"+myHolesNum]=my7ShhHolesMarkALeftup;
    markers.models["my7ShhHolesXMarkALeftup"+myHolesNum]=my7ShhHolesXMarkALeftup;
    markers.models["my7ShhHolesYMarkALeftup"+myHolesNum]=my7ShhHolesYMarkALeftup;
    /*****************************************************************************/
    plate.models["my7ShhHolesALeftmd"+myHolesNum]=my7ShhHolesALeftmd;
    cutPlate.models["my7ShhHolesALeftmd"+myHolesNum]=my7ShhHolesALeftmd;
    markers.models["my7ShhHolesALeftmd"+myHolesNum]=my7ShhHolesALeftmd;
    markers.models["my7ShhHolesMarkALeftmd"+myHolesNum]=my7ShhHolesMarkALeftmd;
    markers.models["my7ShhHolesYMarkALeftmd"+myHolesNum]=my7ShhHolesYMarkALeftmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesARightmd"+myHolesNum]=my7ShhHolesARightmd;
    cutPlate.models["my7ShhHolesARightmd"+myHolesNum]=my7ShhHolesARightmd;
    markers.models["my7ShhHolesARightmd"+myHolesNum]=my7ShhHolesARightmd;
    markers.models["my7ShhHolesMarkARightmd"+myHolesNum]=my7ShhHolesMarkARightmd;
    markers.models["my7ShhHolesYMarkARightmd"+myHolesNum]=my7ShhHolesYMarkARightmd;
    markers.models["my7ShhHolesXMarkARightmd"+myHolesNum]=my7ShhHolesXMarkARightmd;
    /*****************************************************************************/ 
    
    }else if(area==2){
      //画孔 B位置孔
            //标记7字拉手孔 B位置孔左上
            var my7ShhHolesBLeftup= new makerjs.models.Holes(d/2, [[xb-w/2, yb]]);
            var my7ShhHolesMarkBLeftup = new makerjs.models.Square(0); //孔的原点       
            my7ShhHolesMarkBLeftup.origin = [xb-w/2, yb];      
            var my7ShhHolesXMarkBLeftup = new makerjs.models.Square(0);      
            my7ShhHolesXMarkBLeftup.origin =[xb-w/2, yb];      
            var my7ShhHolesYMarkBLeftup = new makerjs.models.Square(0);      
            my7ShhHolesYMarkBLeftup.origin = [xb-w/2, yb];  
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkBLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(my7ShhHolesMarkBLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(my7ShhHolesXMarkBLeftup, new makerjs.paths.Line([0,0], [0, y0]));//X短标线
      makerjs.model.addCaption(my7ShhHolesXMarkBLeftup, TxtY,[0+markShift,0], [0+markShift, y0]);//X文字标注
      /*****************************************************************************/ 
       //标记7字拉手孔 B位置孔左中
       var my7ShhHolesBLeftmd= new makerjs.models.Holes(d/2, [[xb+w/2, yb]]);
            var my7ShhHolesMarkBLeftmd = new makerjs.models.Square(0); //孔的原点       
            my7ShhHolesMarkBLeftmd.origin = [xb+w/2, yb];       
            var my7ShhHolesYMarkBLeftmd = new makerjs.models.Square(0);      
            my7ShhHolesYMarkBLeftmd.origin = [xb+w/2, yb];  
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [0, y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(my7ShhHolesMarkBLeftmd, new makerjs.paths.Line([-w,0], [0, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkBLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(my7ShhHolesMarkBLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(my7ShhHolesYMarkBLeftmd, new makerjs.paths.Line([-w,0], [0, 0]));//Y短标线
      makerjs.model.addCaption(my7ShhHolesYMarkBLeftmd, TxtW, [-w,0+markShift], [0, 0+markShift]);//Y文字标注
      /*****************************************************************************/ 
       //标记7字拉手孔B位置孔右中
       var my7ShhHolesBRightmd= new makerjs.models.Holes(d/2, [[xb+w/2, yb-h]]);
            var my7ShhHolesMarkBRightmd = new makerjs.models.Square(0); //孔的原点       
            my7ShhHolesMarkBRightmd.origin = [xb+w/2, yb-h];      
            var my7ShhHolesXMarkBRightmd = new makerjs.models.Square(0);      
            my7ShhHolesXMarkBRightmd.origin =[xb+w/2, yb-h];      
            var my7ShhHolesYMarkBRightmd = new makerjs.models.Square(0);      
            my7ShhHolesYMarkBRightmd.origin = [xb+w/2, yb-h];  
      /*****************************************************************************/
      makerjs.model.addPath(my7ShhHolesMarkBRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(my7ShhHolesMarkBRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(my7ShhHolesXMarkBRightmd, new makerjs.paths.Line([0,h], [0, 0]));//X短标线
      makerjs.model.addCaption(my7ShhHolesXMarkBRightmd, TxtH, [0-markShift,0], [0-markShift, h]);//X文字标注
      /*****************************************************************************/  
      plate.models["my7ShhHolesBLeftup"+myHolesNum]=my7ShhHolesBLeftup;
      cutPlate.models["my7ShhHolesBLeftup"+myHolesNum]=my7ShhHolesBLeftup;
      markers.models["my7ShhHolesBLeftup"+myHolesNum]=my7ShhHolesBLeftup;
      markers.models["my7ShhHolesMarkBLeftup"+myHolesNum]=my7ShhHolesMarkBLeftup;
      markers.models["my7ShhHolesXMarkBLeftup"+myHolesNum]=my7ShhHolesXMarkBLeftup;
      markers.models["my7ShhHolesYMarkBLeftup"+myHolesNum]=my7ShhHolesYMarkBLeftup;
      /*****************************************************************************/
      plate.models["my7ShhHolesBLeftmd"+myHolesNum]=my7ShhHolesBLeftmd;
      cutPlate.models["my7ShhHolesBLeftmd"+myHolesNum]=my7ShhHolesBLeftmd;
      markers.models["my7ShhHolesBLeftmd"+myHolesNum]=my7ShhHolesBLeftmd;
      markers.models["my7ShhHolesMarkBLeftmd"+myHolesNum]=my7ShhHolesMarkBLeftmd;
      markers.models["my7ShhHolesYMarkBLeftmd"+myHolesNum]=my7ShhHolesYMarkBLeftmd;
      /*****************************************************************************/ 
      plate.models["my7ShhHolesBRightmd"+myHolesNum]=my7ShhHolesBRightmd;
      cutPlate.models["my7ShhHolesBRightmd"+myHolesNum]=my7ShhHolesBRightmd;
      markers.models["my7ShhHolesBRightmd"+myHolesNum]=my7ShhHolesBRightmd;
      markers.models["my7ShhHolesMarkBRightmd"+myHolesNum]=my7ShhHolesMarkBRightmd;
      markers.models["my7ShhHolesXMarkBRightmd"+myHolesNum]=my7ShhHolesXMarkBRightmd;
      /*****************************************************************************/ 
      
    }else if(area==3){
    //画孔 C位置孔
          //标记7字拉手孔C位置孔右上
          var my7ShhHolesCRightup= new makerjs.models.Holes(d/2, [[xc, yc]]);
          var my7ShhHolesMarkCRightup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkCRightup.origin = [xc, yc];      
          var my7ShhHolesXMarkCRightup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkCRightup.origin =[xc, yc];      
          var my7ShhHolesYMarkCRightup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkCRightup.origin = [xc, yc];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0, y0+markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([x0,y0], [x0+markShiftThree, y0]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([0,0], [0, y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkCRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkCRightup, new makerjs.paths.Line([0,y0+markShift], [x0, y0+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkCRightup, TxtX, [0, y0+markShiftThree], [x0,y0+markShiftThree]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkCRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkCRightup, TxtY, [x0+markShiftThree,0], [x0+markShiftThree, y0]);//Y文字标注
    /*****************************************************************************/ 
     //标记7字拉手孔C位置孔右中
     var my7ShhHolesCRightmd= new makerjs.models.Holes(d/2, [[xc-w, yc]]);
          var my7ShhHolesMarkCRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkCRightmd.origin = [xc-w, yc];       
          var my7ShhHolesYMarkCRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkCRightmd.origin = [xc-w, yc];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [0, h+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkCRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
     //标记7字拉手孔C位置孔左中
     var my7ShhHolesCLeftmd= new makerjs.models.Holes(d/2, [[xc-w, yc-h]]);
          var my7ShhHolesMarkCLeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkCLeftmd.origin = [xc-w, yc-h];      
          var my7ShhHolesXMarkCLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkCLeftmd.origin =[xc-w, yc-h];      
          var my7ShhHolesYMarkCLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkCLeftmd.origin = [xc-w, yc-h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [w+x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [0, h+y0+markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/  
    makerjs.model.addPath(my7ShhHolesYMarkCLeftmd, new makerjs.paths.Line([x0+w+markShift,0], [x0+w+markShift, h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkCLeftmd, TxtH, [x0+w+markShiftThree,0], [x0+w+markShiftThree, h]);//Y文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkCLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkCLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkCLeftmd, new makerjs.paths.Line([0,y0+h+markShift], [w, y0+h+markShift]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkCLeftmd, TxtW, [0, y0+h+markShiftThree], [w,y0+h+markShiftThree]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesCRightup"+myHolesNum]=my7ShhHolesCRightup;
    cutPlate.models["my7ShhHolesCRightup"+myHolesNum]=my7ShhHolesCRightup;
    markers.models["my7ShhHolesCRightup"+myHolesNum]=my7ShhHolesCRightup;
    markers.models["my7ShhHolesMarkCRightup"+myHolesNum]=my7ShhHolesMarkCRightup;
    markers.models["my7ShhHolesXMarkCRightup"+myHolesNum]=my7ShhHolesXMarkCRightup;
    markers.models["my7ShhHolesYMarkCRightup"+myHolesNum]=my7ShhHolesYMarkCRightup;
    /*****************************************************************************/
    plate.models["my7ShhHolesCRightmd"+myHolesNum]=my7ShhHolesCRightmd;
    cutPlate.models["my7ShhHolesCRightmd"+myHolesNum]=my7ShhHolesCRightmd;
    markers.models["my7ShhHolesCRightmd"+myHolesNum]=my7ShhHolesCRightmd;
    markers.models["my7ShhHolesMarkCRightmd"+myHolesNum]=my7ShhHolesMarkCRightmd;
    markers.models["my7ShhHolesYMarkCRightmd"+myHolesNum]=my7ShhHolesYMarkCRightmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesCLeftmd"+myHolesNum]=my7ShhHolesCLeftmd;
    cutPlate.models["my7ShhHolesCLeftmd"+myHolesNum]=my7ShhHolesCLeftmd;
    markers.models["my7ShhHolesCLeftmd"+myHolesNum]=my7ShhHolesCLeftmd;
    markers.models["my7ShhHolesMarkCLeftmd"+myHolesNum]=my7ShhHolesMarkCLeftmd;
    markers.models["my7ShhHolesXMarkCLeftmd"+myHolesNum]=my7ShhHolesXMarkCLeftmd;
    markers.models["my7ShhHolesYMarkCLeftmd"+myHolesNum]=my7ShhHolesYMarkCLeftmd;
    /*****************************************************************************/ 
      
    }else if(area==7){
    //画孔 G位置孔
          //标记7字拉手孔 G位置孔左上
          var my7ShhHolesGLeftup= new makerjs.models.Holes(d/2, [[xg+w, yg+h]]);
          var my7ShhHolesMarkGLeftup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkGLeftup.origin = [xg+w, yg+h];     
          var my7ShhHolesYMarkGLeftup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkGLeftup.origin = [xg+w, yg+h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([-x0-w-markShiftFour,0], [0, 0]));//Y轴加长标线
   // makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [w+d+markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([-x0,-y0-h], [-x0-markShiftThree, -y0-h]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [0, -y0-h-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkGLeftup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesYMarkGLeftup, new makerjs.paths.Line([-w-x0-markShiftTwo,0], [-w-x0-markShiftTwo, -h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkGLeftup, TxtH, [-w-x0-markShiftFour, -h], [-w-x0-markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/ 
    //  //标记7字拉手孔 G位置孔左中
     var my7ShhHolesGLeftmd= new makerjs.models.Holes(d/2, [[xg, yg]]);
          var my7ShhHolesMarkGLeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkGLeftmd.origin = [xg, yg];       
          var my7ShhHolesXMarkGLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkGLeftmd.origin = [xg, yg];       
          var my7ShhHolesYMarkGLeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkGLeftmd.origin = [xg, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [-x0-markShiftFour, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([-x0,-y0], [-x0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([-x0-markShiftFour,-y0], [-x0, -y0]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkGLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesXMarkGLeftmd, new makerjs.paths.Line([0,-y0-markShiftTwo], [-x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkGLeftmd, TxtX, [-x0,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkGLeftmd, new makerjs.paths.Line([-x0-markShiftTwo,0], [-x0-markShiftTwo, -y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkGLeftmd, TxtY, [-x0-markShiftFour, -y0], [-x0-markShiftFour,0]);//Y文字标注
    // /*****************************************************************************/ 
     //标记7字拉手孔 G位置孔右中
     var my7ShhHolesGRightmd= new makerjs.models.Holes(d/2, [[xg+w, yg]]);
          var my7ShhHolesMarkGRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkGRightmd.origin = [xg+w, yg];      
          var my7ShhHolesXMarkGRightmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkGRightmd.origin =[xg+w, yg];      
          var my7ShhHolesYMarkGRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkGRightmd.origin = [xg+w, yg];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [-w, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkGRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkGRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkGRightmd, new makerjs.paths.Line([0,-y0-markShiftTwo], [-w, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkGRightmd, TxtW, [-w,-y0-markShiftFour], [0, -y0-markShiftFour]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesGLeftup"+myHolesNum]=my7ShhHolesGLeftup;
    cutPlate.models["my7ShhHolesGLeftup"+myHolesNum]=my7ShhHolesGLeftup;
    markers.models["my7ShhHolesGLeftup"+myHolesNum]=my7ShhHolesGLeftup;
    markers.models["my7ShhHolesMarkGLeftup"+myHolesNum]=my7ShhHolesMarkGLeftup;
    markers.models["my7ShhHolesYMarkGLeftup"+myHolesNum]=my7ShhHolesYMarkGLeftup;
    /*****************************************************************************/
    plate.models["my7ShhHolesGLeftmd"+myHolesNum]=my7ShhHolesGLeftmd;
    cutPlate.models["my7ShhHolesGLeftmd"+myHolesNum]=my7ShhHolesGLeftmd;
    markers.models["my7ShhHolesGLeftmd"+myHolesNum]=my7ShhHolesGLeftmd;
    markers.models["my7ShhHolesMarkGLeftmd"+myHolesNum]=my7ShhHolesMarkGLeftmd;
    markers.models["my7ShhHolesXMarkGLeftmd"+myHolesNum]=my7ShhHolesXMarkGLeftmd;
    markers.models["my7ShhHolesYMarkGLeftmd"+myHolesNum]=my7ShhHolesYMarkGLeftmd;
    // /*****************************************************************************/ 
    plate.models["my7ShhHolesGRightmd"+myHolesNum]=my7ShhHolesGRightmd;
    cutPlate.models["my7ShhHolesGRightmd"+myHolesNum]=my7ShhHolesGRightmd;
    markers.models["my7ShhHolesGRightmd"+myHolesNum]=my7ShhHolesGRightmd;
    markers.models["my7ShhHolesMarkGRightmd"+myHolesNum]=my7ShhHolesMarkGRightmd;
    markers.models["my7ShhHolesXMarkGRightmd"+myHolesNum]=my7ShhHolesXMarkGRightmd;
    /*****************************************************************************/ 
     
    }else if(area==8){
      //画孔 H位置孔
            //标记7字拉手孔H位置孔右上
            var my7ShhHolesHRightup= new makerjs.models.Holes(d/2, [[xh-w/2, yh]]);
            var my7ShhHolesMarkHRightup = new makerjs.models.Square(0); //孔的原点       
            my7ShhHolesMarkHRightup.origin = [xh-w/2, yh];      
            var my7ShhHolesXMarkHRightup = new makerjs.models.Square(0);      
            my7ShhHolesXMarkHRightup.origin =[xh-w/2, yh];      
            var my7ShhHolesYMarkHRightup = new makerjs.models.Square(0);      
            my7ShhHolesYMarkHRightup.origin = [xh-w/2, yh];  
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkHRightup, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(my7ShhHolesMarkHRightup, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(my7ShhHolesXMarkHRightup, new makerjs.paths.Line([0,0], [0, -y0]));//X短标线
      makerjs.model.addCaption(my7ShhHolesXMarkHRightup, TxtY, [0-markShift, -y0], [0-markShift,0]);//X文字标注
      /*****************************************************************************/  
       //标记7字拉手孔H位置孔右中
       var my7ShhHolesHRightmd= new makerjs.models.Holes(d/2, [[xh+w/2, yh]]);
            var my7ShhHolesMarkHRightmd = new makerjs.models.Square(0); //孔的原点       
            my7ShhHolesMarkHRightmd.origin = [xh+w/2, yh];       
            var my7ShhHolesYMarkHRightmd = new makerjs.models.Square(0);      
            my7ShhHolesYMarkHRightmd.origin = [xh+w/2, yh];  
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkHRightmd, new makerjs.paths.Line([0,0], [0, -y0]));//从孔圆心出发X方向横向标线
      makerjs.model.addPath(my7ShhHolesMarkHRightmd, new makerjs.paths.Line([-w,0], [0, 0]));//从孔圆心出发Y方向竖向标线
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkHRightmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(my7ShhHolesMarkHRightmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(my7ShhHolesYMarkHRightmd, new makerjs.paths.Line([-w,0], [0, 0]));//Y短标线
      makerjs.model.addCaption(my7ShhHolesYMarkHRightmd, TxtW, [-w,0+markShift], [0, 0+markShift]);//Y文字标注
      /*****************************************************************************/ 
       //标记7字拉手孔H位置孔左中
       var my7ShhHolesHLeftmd= new makerjs.models.Holes(d/2, [[xh+w/2, yh+h]]);
            var my7ShhHolesMarkHLeftmd = new makerjs.models.Square(0); //孔的原点       
            my7ShhHolesMarkHLeftmd.origin = [xh+w/2, yh+h];      
            var my7ShhHolesXMarkHLeftmd = new makerjs.models.Square(0);      
            my7ShhHolesXMarkHLeftmd.origin =[xh+w/2, yh+h];      
            var my7ShhHolesYMarkHLeftmd = new makerjs.models.Square(0);      
            my7ShhHolesYMarkHLeftmd.origin = [xh+w/2, yh+h];  
      /*****************************************************************************/ 
      makerjs.model.addPath(my7ShhHolesMarkHLeftmd, new makerjs.paths.Line([0,0], [d, -d]));//直径标线
      makerjs.model.addCaption(my7ShhHolesMarkHLeftmd, TxtD, [0,0], [d+markHolesOneD, -d-markHolesOneD]);//直径文字标注
      /*****************************************************************************/   
      makerjs.model.addPath(my7ShhHolesXMarkHLeftmd, new makerjs.paths.Line([0,-h], [0, 0]));//X短标线
      makerjs.model.addCaption(my7ShhHolesXMarkHLeftmd, TxtH, [0-markShift,-h], [0-markShift, 0]);//X文字标注
      /*****************************************************************************/  
      plate.models["my7ShhHolesHRightup"+myHolesNum]=my7ShhHolesHRightup;
      cutPlate.models["my7ShhHolesHRightup"+myHolesNum]=my7ShhHolesHRightup;
      markers.models["my7ShhHolesHRightup"+myHolesNum]=my7ShhHolesHRightup;
      markers.models["my7ShhHolesMarkHRightup"+myHolesNum]=my7ShhHolesMarkHRightup;
      markers.models["my7ShhHolesXMarkHRightup"+myHolesNum]=my7ShhHolesXMarkHRightup;
      markers.models["my7ShhHolesYMarkHRightup"+myHolesNum]=my7ShhHolesYMarkHRightup;
      /*****************************************************************************/
      plate.models["my7ShhHolesHRightmd"+myHolesNum]=my7ShhHolesHRightmd;
      cutPlate.models["my7ShhHolesHRightmd"+myHolesNum]=my7ShhHolesHRightmd;
      markers.models["my7ShhHolesHRightmd"+myHolesNum]=my7ShhHolesHRightmd;
      markers.models["my7ShhHolesMarkHRightmd"+myHolesNum]=my7ShhHolesMarkHRightmd;
      markers.models["my7ShhHolesYMarkHRightmd"+myHolesNum]=my7ShhHolesYMarkHRightmd;
      /*****************************************************************************/ 
      plate.models["my7ShhHolesHLeftmd"+myHolesNum]=my7ShhHolesHLeftmd;
      cutPlate.models["my7ShhHolesHLeftmd"+myHolesNum]=my7ShhHolesHLeftmd;
      markers.models["my7ShhHolesHLeftmd"+myHolesNum]=my7ShhHolesHLeftmd;
      markers.models["my7ShhHolesMarkHLeftmd"+myHolesNum]=my7ShhHolesMarkHLeftmd;
      markers.models["my7ShhHolesXMarkHLeftmd"+myHolesNum]=my7ShhHolesXMarkHLeftmd;
      /*****************************************************************************/ 
       
    }else if(area==9){
    //画孔 I位置孔
          //标记7字拉手孔I位置孔右上
          var my7ShhHolesIRightup= new makerjs.models.Holes(d/2, [[xi, yi]]);
          var my7ShhHolesMarkIRightup = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkIRightup.origin = [xi, yi];      
          var my7ShhHolesXMarkIRightup = new makerjs.models.Square(0);      
          my7ShhHolesXMarkIRightup.origin =[xi, yi];      
          var my7ShhHolesYMarkIRightup = new makerjs.models.Square(0);      
          my7ShhHolesYMarkIRightup.origin = [xi, yi];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0], [x0, -y0-markShiftFour]));//Y轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([x0,-y0], [x0+markShiftThree, -y0]));//X轴加长标线
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([0,0], [0, -y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightup, new makerjs.paths.Line([0,0], [d, d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkIRightup, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkIRightup, new makerjs.paths.Line([0,-y0-markShiftTwo], [x0, -y0-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkIRightup, TxtX, [0, -y0-markShiftFour], [x0,-y0-markShiftFour]);//X文字标注
    /*****************************************************************************/     
    makerjs.model.addPath(my7ShhHolesYMarkIRightup, new makerjs.paths.Line([x0+markShift,0], [x0+markShift, -y0]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkIRightup, TxtY, [x0+markShiftThree, -y0], [x0+markShiftThree,0]);//Y文字标注
    /*****************************************************************************/ 
    /*****************************************************************************/ 
     //标记7字拉手孔I位置孔右中
     var my7ShhHolesIRightmd= new makerjs.models.Holes(d/2, [[xi-w, yi]]);
          var my7ShhHolesMarkIRightmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkIRightmd.origin = [xi-w, yi];       
          var my7ShhHolesYMarkIRightmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkIRightmd.origin = [xi-w, yi];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [0, -h-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkIRightmd, new makerjs.paths.Line([0,0], [d, d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkIRightmd, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/  
    /*****************************************************************************/
     //标记7字拉手孔I位置孔左中
     var my7ShhHolesILeftmd= new makerjs.models.Holes(d/2, [[xi-w, yi+h]]);
          var my7ShhHolesMarkILeftmd = new makerjs.models.Square(0); //孔的原点       
          my7ShhHolesMarkILeftmd.origin = [xi-w, yi+h];      
          var my7ShhHolesXMarkILeftmd = new makerjs.models.Square(0);      
          my7ShhHolesXMarkILeftmd.origin =[xi-w, yi+h];      
          var my7ShhHolesYMarkILeftmd = new makerjs.models.Square(0);      
          my7ShhHolesYMarkILeftmd.origin = [xi-w, yi+h];  
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [w+x0+markShiftThree, 0]));//从孔圆心出发X方向横向标线
    makerjs.model.addPath(my7ShhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [0, -h-y0-markShiftFour]));//从孔圆心出发Y方向竖向标线
    /*****************************************************************************/  
    makerjs.model.addPath(my7ShhHolesYMarkILeftmd, new makerjs.paths.Line([x0+w+markShift,0], [x0+w+markShift, -h]));//Y短标线
    makerjs.model.addCaption(my7ShhHolesYMarkILeftmd, TxtH, [x0+w+markShiftThree, -h], [x0+w+markShiftThree,0]);//Y文字标注
    /*****************************************************************************/ 
    makerjs.model.addPath(my7ShhHolesMarkILeftmd, new makerjs.paths.Line([0,0], [d, d]));//直径标线
    makerjs.model.addCaption(my7ShhHolesMarkILeftmd, TxtD, [0,0], [d+markHolesOneD, d+markHolesOneD]);//直径文字标注
    /*****************************************************************************/   
    makerjs.model.addPath(my7ShhHolesXMarkILeftmd, new makerjs.paths.Line([0,-y0-h-markShiftTwo], [w, -y0-h-markShiftTwo]));//X短标线
    makerjs.model.addCaption(my7ShhHolesXMarkILeftmd, TxtW, [0, -y0-h-markShiftFour], [w,-y0-h-markShiftFour]);//X文字标注
    /*****************************************************************************/  
    plate.models["my7ShhHolesIRightup"+myHolesNum]=my7ShhHolesIRightup;
    cutPlate.models["my7ShhHolesIRightup"+myHolesNum]=my7ShhHolesIRightup;
    markers.models["my7ShhHolesIRightup"+myHolesNum]=my7ShhHolesIRightup;
    markers.models["my7ShhHolesMarkIRightup"+myHolesNum]=my7ShhHolesMarkIRightup;
    markers.models["my7ShhHolesXMarkIRightup"+myHolesNum]=my7ShhHolesXMarkIRightup;
    markers.models["my7ShhHolesYMarkIRightup"+myHolesNum]=my7ShhHolesYMarkIRightup;
    /*****************************************************************************/
    plate.models["my7ShhHolesIRightmd"+myHolesNum]=my7ShhHolesIRightmd;
    cutPlate.models["my7ShhHolesIRightmd"+myHolesNum]=my7ShhHolesIRightmd;
    markers.models["my7ShhHolesIRightmd"+myHolesNum]=my7ShhHolesIRightmd;
    markers.models["my7ShhHolesMarkIRightmd"+myHolesNum]=my7ShhHolesMarkIRightmd;
    markers.models["my7ShhHolesYMarkIRightmd"+myHolesNum]=my7ShhHolesYMarkIRightmd;
    /*****************************************************************************/ 
    plate.models["my7ShhHolesILeftmd"+myHolesNum]=my7ShhHolesILeftmd;
    cutPlate.models["my7ShhHolesILeftmd"+myHolesNum]=my7ShhHolesILeftmd;
    markers.models["my7ShhHolesILeftmd"+myHolesNum]=my7ShhHolesILeftmd;
    markers.models["my7ShhHolesMarkILeftmd"+myHolesNum]=my7ShhHolesMarkILeftmd;
    markers.models["my7ShhHolesXMarkILeftmd"+myHolesNum]=my7ShhHolesXMarkILeftmd;
    markers.models["my7ShhHolesYMarkILeftmd"+myHolesNum]=my7ShhHolesYMarkILeftmd;
    /*****************************************************************************/
    
    }

myHolesNum++;
return{plate,cutPlate, markers,myHolesNum}
};


export default Shaped7HandleHole;